import Divider from 'components/common/Divider';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import serviceUrl from '../../../services/serviceUrlList';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import { useForm } from 'react-hook-form';
import useDelete from 'hooks/useDelete';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import TextEditor from 'components/common/TextEditor';
import useUpdate from 'hooks/useUpdate';
import usePost from 'hooks/usePost';
import SeoSetting from 'components/common/SeoSetting';
import { AuthContext } from 'context/Context';

const AddGlossary = () => {
  const [formData, setFormData] = useState({
    is_active: true,
    name: '',
    description: '',
    seo_setting: {},
    site: 1
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [description, setDescription] = useState('');
  const [seoSetting, setSeoSetting] = useState({});
  const { id } = useParams();

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { response } = id
    ? useFetch({
        url: serviceUrl.content.glossary.detail + id
      })
    : {};
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.content.glossary.detail + id
  });
  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.content.glossary.detail + id,
    payload: formData
  });
  const [postResponse, postRequest] = usePost({
    url: serviceUrl.content.glossary.list,
    payload: formData
  });
  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };
  const handleChangeEditor = value => {
    setFormData({ ...formData, description: value });
  };
  const handleSeoValue = seoValue => {
    setFormData({
      ...formData,
      seo_setting: seoValue
    });
  };

  useEffect(() => {
    if (id) {
      setFormData({ ...formData, updated_by: user.id });
    } else {
      setFormData({ ...formData, created_by: user.id });
    }
  }, []);

  const onSubmit = async () => {
    if (id) {
      updateRequest();
    } else {
      postRequest();
    }
  };
  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.error === null) {
      handleRouteList();
      toast.success(`Keyword created`, {
        theme: 'colored'
      });
    } else {
      if (postResponse.error.data.name) {
        toast.error(`Name field must be unique!`, {
          theme: 'colored'
        });
      }
    }
  }, [postResponse]);
  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.error) return;
    if (updateResponse.error === null) {
      handleRouteList();
      toast.success(`Keyword updated`, {
        theme: 'colored'
      });
    } else {
      if (updateResponse.error.data.name) {
        toast.error(`Name field must be unique!`, {
          theme: 'colored'
        });
      }
    }
  }, [updateResponse]);

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Keyword',
      description: 'Are you sure? Selected keyword will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    handleRouteList();
    toast.success(`Keyword deleted`, {
      theme: 'colored'
    });
  }, [deleteResponse]);

  const handleRouteList = () => {
    navigate('/cms/glossary');
  };

  useEffect(() => {
    if (!response) return;
    setValue('name', response.data.name);
    setDescription(response.data.description);
    setFormData({
      ...formData,
      name: response.data.name,
      description: response.data.description,
      is_active: response.data.is_active ? true : false
    });
    setSeoSetting(response.data.seo_setting);
  }, [response]);

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {id ? 'Update Keyword' : 'Create Keyword'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -10
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                name="is_active"
                value={formData.is_active}
                onChange={handleChange}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                {...register('name', { required: true })}
                isInvalid={errors.name}
                type="text"
                name="name"
                onChange={handleChange}
                tabIndex={1}
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <TextEditor
                value={description}
                name="description"
                tabIndex={2} // tabIndex of textarea
                onChange={handleChangeEditor} // preferred to use only this option to update the content for performance reasons
              />
            </Form.Group>
            <Divider />
            <SeoSetting
              seoValues={handleSeoValue}
              defaultValue={seoSetting}
              register={register}
              errors={errors}
            />
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </Fragment>
  );
};

export default AddGlossary;
