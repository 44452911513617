import PageLoadingWrapper from 'components/loading/PageLoadingWrapper';
import AuthProvider from 'context/AuthProvider';
import LoaderProvider from 'context/LoaderProvider';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';

const queryClient = new QueryClient({
  defaultOptions: { queries: { cacheTime: 0 } }
});

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <LoaderProvider>
        <PageLoadingWrapper>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Layout />
            </AuthProvider>
          </QueryClientProvider>
        </PageLoadingWrapper>
      </LoaderProvider>
    </Router>
  );
};

export default App;
