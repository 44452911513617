import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CharFilter from 'components/common/CharFilter';

const GlossaryTableFilter = props => {
  const { charFilterValue } = props;

  const handleCharFilter = char => {
    charFilterValue(char);
  };

  return (
    <Row className="flex-between-center mt-2">
      <Col xs="auto" sm={6} lg={4}>
        <AdvanceTableSearchBox table size="sm" placeholder="Filter Keywords" />
      </Col>
      <Col xs="auto" sm={12} lg={8}>
        <CharFilter selected={handleCharFilter} />
      </Col>
    </Row>
  );
};

GlossaryTableFilter.propTypes = {
  charFilterValue: PropTypes.func
};

export default GlossaryTableFilter;
