import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext } from 'context/Context';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';
import moment from 'moment';
import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formDataReducer } from 'reducers/formDataReducer';
import { createTagFromContent } from 'services/product';
import serviceUrl from 'services/serviceUrlList';
import { createBlog, updateBlog } from './api';
import AddBlogForm from './module/AddBlogForm';

const AddBlog = () => {
  const { user } = useContext(AuthContext);
  const [formData, dispatch] = useReducer(formDataReducer, {
    is_active: true,
    publish_date: moment(),
    name: '',
    summary: '',
    content: '',
    embedded_code: '',
    video_id: '',
    author: '',
    tag_names: [],
    related_product: '',
    category: null
  });

  const [defaultImg, setDefaultImg] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);

  const [seoSetting, setSeoSetting] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const { slug } = useParams();

  const navigate = useNavigate();

  const { state } = useLocation();

  const pageOptions = [
    { value: 'highlight', label: 'Blog Highlight' },
    { value: 'detail', label: 'Blog Detail' },
    { value: 'listing', label: 'Blog Listing' }
  ];
  const deviceOptions = [
    { value: 'desktop', label: 'Desktop' },
    { value: 'mobile', label: 'Mobile' }
  ];

  const { response } = slug
    ? useFetch({
        url: serviceUrl.content.blog.detail + slug
      })
    : {};
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.content.blog.detail + slug
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const newFormData = {
      ...formData,
      ...removedFiles.reduce((acc, file) => {
        acc[file] = null;
        return acc;
      }, {})
    };
    newFormData.publish_date = moment(newFormData.publish_date).format(
      'YYYY-MM-DDThh:mm'
    );

    newFormData.created_by = String(user.id);
    if (newFormData.category) {
      newFormData.category = newFormData.category?.value;
    } else {
      delete newFormData.category;
    }
    if (newFormData.tag_names.length > 0) {
      newFormData.tag_names = newFormData.tag_names.join('-!-');
    }
    // to add slug ids to h2s
    const parser = new DOMParser();
    const doc = parser.parseFromString(formData.content, 'text/html');
    const h2Elements = doc.querySelectorAll('h2');
    h2Elements.forEach(h2 => {
      const text = h2.textContent.trim();
      const slug = text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
      h2.id = slug;
    });
    newFormData.content = doc.body.innerHTML;

    if (slug) {
      // UPDATE PROCESS

      if (newFormData.audio.url || newFormData.audio.url === null) {
        delete newFormData.audio;
      }
      let fData = new FormData();

      const imageKeys = Object.keys(newFormData).filter(
        key => key.startsWith('image') && key !== 'image_alt_text'
      );

      for (const key in newFormData) {
        if (!imageKeys.includes(key)) {
          if (newFormData[key] || newFormData[key] === '') {
            fData.append(key, newFormData[key]);
          }
        }
        if (newFormData[key] === null && imageKeys.includes(key)) {
          fData.append(key, new Blob(), '');
        }
        if (
          imageKeys.includes(key) &&
          typeof newFormData[key] !== 'string' &&
          newFormData[key] !== null
        ) {
          console.log('key ' + key);
          fData.append(key, newFormData[key]);
        }
      }

      handleUpdateBlog(fData);
    } else {
      let fData = new FormData();
      for (const key in newFormData) {
        if (newFormData[key]) {
          fData.append(key, newFormData[key]);
        }
      }
      handleCreateBlog(fData);
    }
  };

  const handleUpdateBlog = async fData => {
    const response = await updateBlog(fData, slug);
    if (response.status === 200) {
      toast.success(`Blog updated`, {
        theme: 'colored'
      });
      // to sync tag names for content and product services
      await createTagFromContent({
        tag_names: formData.tag_names,
        updated_by: user.id
      });
      handleRouteList();
    } else {
      if (response.data.name) {
        toast.error(`Name field must be unique!`, {
          theme: 'colored'
        });
      } else {
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      }
    }
  };

  const handleCreateBlog = async fData => {
    const response = await createBlog(fData);
    if (response.status === 201) {
      toast.success(`Blog created`, {
        theme: 'colored'
      });
      // to sync tag names for content and product services
      await createTagFromContent({
        tag_names: formData.tag_names,
        created_by: user.id
      });
      handleRouteList();
    } else {
      if (response.data.name) {
        toast.error(`Name field must be unique!`, {
          theme: 'colored'
        });
      }
    }
  };

  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    handleRouteList();
    toast.success(`Blog deleted`, {
      theme: 'colored'
    });
  }, [deleteResponse]);

  const handleRouteList = () => {
    navigate('/cms/blog?' + state);
  };

  useEffect(() => {
    if (!response) return;
    const form_data = {
      is_active: response.data.is_active,
      name: response.data.name,
      publish_date: response.data.publish_date,
      summary: response.data.summary ?? '',
      content: response.data.content ?? '',
      image_alt_text: response.data.image_alt_text ?? '',
      audio: response.data.audio,
      embedded_code: response.data.embedded_code ?? '',
      video_id: response.data.video_id ?? '',
      author: response.data.author ?? '',
      category: response.data.category
        ? {
            value: response.data.category?.id,
            label: response.data.category?.name
          }
        : null,
      tag_names: response.data.tags.map(dt => dt.name),
      related_product: response.data.related_product && {
        label: (
          <div key={response.data.related_product.slug}>
            {response.data.related_product.files &&
              response.data.related_product.files[0] && (
                <img
                  src={response.data.related_product.files[0].file}
                  alt={response.data.related_product.files[0].alt_text}
                  width={50}
                  height={50}
                  className="pe-2"
                />
              )}
            <span>{response.data.related_product.name}</span>
          </div>
        ),
        value: response.data.related_product.slug
      }
    };
    console.log(form_data);
    dispatch({ type: 'update', payload: { ...form_data } });

    // check setsettings comes json or string
    let seoTemp = '';
    try {
      seoTemp = JSON.parse(response.data.seo_setting);
    } catch (error) {
      seoTemp = response.data.seo_setting;
    }
    setSeoSetting(seoTemp);

    let temp = pageOptions
      .flatMap(page =>
        deviceOptions.map(device => {
          const imageKey = `image_${page.value}_${device.value}`;
          if (response.data[imageKey]) {
            dispatch({
              type: 'update',
              payload: { [imageKey]: response.data[imageKey] }
            });
            return {
              image: response.data[imageKey],
              label: `${page.label} ${device.label}`,
              key: `image_${page.value}_${device.value}`
            };
          } else {
            return null;
          }
        })
      )
      .filter(item => item !== null);

    setDefaultImg(temp);
  }, [response]);

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {slug ? 'Update Blog' : 'Create Blog'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <AddBlogForm
            slug={slug}
            formData={formData}
            setFormData={dispatch}
            handleSubmit={handleSubmit}
            setConfirmText={setConfirmText}
            setShowConfirm={setShowConfirm}
            seoSetting={seoSetting}
            handleRouteList={handleRouteList}
            defaultImg={defaultImg}
            deviceOptions={deviceOptions}
            pageOptions={pageOptions}
            setRemovedFiles={setRemovedFiles}
          />
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </Fragment>
  );
};

export default AddBlog;
