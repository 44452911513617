import LoadingWrapper from 'components/loading/LoadingWrapper';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getOrder } from 'services/order';
import ODCustomer from './module/ODCustomer';
import ODHeader from './module/ODHeader';
import ODProducts from './module/ODProducts';
import ODTotal from './module/ODTotal';
import ODRefundReason from './module/ODRefundReason';

function OrderDetail() {
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    [`order-detail-${id}`],
    () => getOrder(id),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  if (isLoading) {
    return (
      <LoadingWrapper loading={isLoading}>
        <div
          style={{
            height: 600
          }}
        ></div>
      </LoadingWrapper>
    );
  }

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="p-2">
        <div className="row">
          <div className="col-8">
            <ODHeader data={data} />
            <ODProducts data={data} />
            <ODTotal data={data} />
            <ODRefundReason data={data} />
          </div>
          <div className="col-4">
            <ODCustomer data={data} />
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
}

export default OrderDetail;
