import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function ODHeader({ data }) {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Flex justifyContent="between">
          <Flex alignItems="start">
            <div className="me-2">
              <Link to="/order/order-list">
                <BsArrowLeft color="#000" />
              </Link>
            </div>
            <div>
              <div className="d-flex gap-2 align-items-center">
                <span className="font-weight-bold">#{data.id}</span>
                <SoftBadge pill bg="success" className="d-block">
                  {data.paymentStatus === 1
                    ? 'Unpaid'
                    : data.paymentStatus === 2
                    ? 'Paid'
                    : data.paymentStatus === 3
                    ? 'Refunded'
                    : 'Partially Refunded'}
                </SoftBadge>
                {/* <SoftBadge pill bg="secondary" className="d-block">
                  Unfullfilled
                </SoftBadge> */}
              </div>
              <div className="d-flex gap-2 align-items-center">
                <span className="fs--2">
                  {moment(data.created_at).format('MMM DD, yyyy HH:mm')} from{' '}
                  {data.sale_channel === 0 ? 'WEB' : 'mWEB'}
                </span>
              </div>
            </div>
          </Flex>
          <CardDropdown
            title="Actions"
            icon="no-icon"
            btnRevealClass="btn-falcon-default"
          >
            <div className="py-2">
              <Dropdown.Item>Print Shipment Label</Dropdown.Item>
              <Dropdown.Item>Cancel fulfillment</Dropdown.Item>
              <Dropdown.Item>Cancel Order</Dropdown.Item>
            </div>
          </CardDropdown>
        </Flex>
      </Card.Body>
    </Card>
  );
}
ODHeader.propTypes = {
  data: PropTypes.object
};

export default ODHeader;
