import { useState, useEffect } from 'react';
import axios from 'axios';
import { protectedApi } from 'services/axios';

const useFetch = params => {
  const { url } = params;
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    protectedApi
      .get(url, { CancelToken: source.token })
      .then(response_ => setResponse(response_))
      .catch(err => setError(err.response))
      .finally(() => setLoading(false));
    return () => {
      source.cancel();
    };
  }, []);

  const refetch = () => {
    setLoading(true);
    protectedApi
      .get(url)
      .then(response_ => setResponse(response_))
      .catch(err => setError(err))
      .finally(() => setLoading(false));
  };

  return { response, loading, error, refetch };
};

export default useFetch;
