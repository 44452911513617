import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  createCountingDetail,
  deleteCountingDetail,
  getCounting,
  getCountingDetail,
  getStocksWithQrcode,
  updateCountingDetail
} from 'services/warehouse';
import TableHeader from 'components/common/TableHeader';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { statusOptions } from './module/Static';
import AppContext, { AuthContext } from 'context/Context';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';

const UpdateCountingItem = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const initialForm = {
    count: 0,
    qrCode: ''
  };
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    inventoryCountingMeta: id
  });
  const [prdList, setPrdList] = useState([initialForm]);
  const { user } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: '',
    description: '',
    button: '',
    action: () => {}
  });

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const { data } = useQuery(
    ['getCounting'],
    () =>
      getCounting({
        id: id
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const { data: dataDetail, refetch } = useQuery(
    ['getCountingDetail', queryObj],
    () =>
      getCountingDetail({
        ...queryObj
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRowId(null);
    setValue('description', '');
  };
  useEffect(() => {
    setValue('inventoryCountingMeta', id);
  }, []);

  // pagination dispatch start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // pagination dispatch end

  const columns = [
    {
      accessor: 'qrCode',
      Header: 'QR Code',
      headerProps: { className: 'pe-1 text-start', style: { width: 120 } }
    },
    {
      accessor: 'barcode',
      Header: 'Barcode (SKU)',
      headerProps: { className: 'pe-1 text-start', style: { width: 100 } }
    },
    {
      accessor: 'name',
      Header: 'Product Name',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'category',
      Header: 'Product Category',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'skt',
      Header: 'SKT',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'count',
      Header: 'Quantity',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: { style: { width: 30 } }
    },
    {
      accessor: 'none',
      Header: '',
      headerProps: { className: 'pe-1 text-end' },
      cellProps: { className: 'pe-1 text-end', style: { width: 135 } }
    }
  ];

  const onSubmit = data => {
    if (data?.index) {
      const payload = { ...data, ...prdList[data.index], createdBy: user.id };
      delete payload.barcode;
      delete payload.category;
      delete payload.name;
      delete payload.skt;
      delete payload.status;
      delete payload.index;
      delete payload.description;
      postRequest(payload);
    } else if (confirmationInfo.button === 'Update') {
      const payload = {
        ...data,
        count: prdList[selectedRowId].count,
        updatedBy: user.id
      };
      updateRequest(payload, prdList[selectedRowId].id);
    } else {
      const payload = {
        description: data.description
      };
      deleteRequest(payload, prdList[selectedRowId].id);
    }
  };

  const postRequest = async payload => {
    const response = await createCountingDetail(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Products added to counting.`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };
  const updateRequest = async (payload, id) => {
    const response = await updateCountingDetail(payload, id);
    if (response.status === 200) {
      handleRouteList();
      toast.success(`Counting product updated.`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };
  const deleteRequest = async (payload, id) => {
    const response = await deleteCountingDetail(payload, id);
    if (response.status === 204) {
      handleRouteList();
      toast.success(`Counting product deleted.`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  useEffect(() => {
    if (!dataDetail?.data) return;
    const res = dataDetail.data.results;
    const temp = res.map(row => {
      return {
        id: row.id,
        qrCode: row.qrCode,
        name: row.stock?.name,
        barcode: row.stock?.barcode,
        category: row.stock?.category?.name,
        skt: moment(row.skt).format('DD/MM/yyyy'),
        count: row.count
      };
    });
    setPrdList(temp);
  }, [dataDetail]);

  const handleRemove = index => {
    setSelectedRowId(index);
    setConfirmationInfo({
      title: 'Delete Product',
      description: 'Enter description for the delete action.',
      button: 'Delete'
    });
    openModal();
  };
  const handleUpdate = index => {
    setSelectedRowId(index);
    setConfirmationInfo({
      title: 'Update Product',
      description: 'Enter description for the update action.',
      button: 'Update'
    });
    if (!prdList[index]?.id) {
      onSubmit({ ...control._formValues, index: index });
    } else {
      openModal();
    }
  };

  // counting detail action start
  const onChange = ({ target }, rIndex, cName) => {
    const value = target.value;
    setPrdList(old =>
      old.map((row, rowIndex) => {
        if (rIndex === rowIndex) {
          return {
            ...old[rowIndex],
            [cName]: value
          };
        }
        return row;
      })
    );
  };
  const onBlur = (e, i) => {
    handleGetStock(e.target.value, i);
  };
  const handleGetStock = async (search, i) => {
    try {
      const response = await getStocksWithQrcode({ id: search });
      if (response.data.results.length === 1) {
        const res = response.data.results[0];
        setPrdList(old =>
          old.map((row, rowIndex) => {
            if (i === rowIndex) {
              return {
                ...old[rowIndex],
                barcode: res.stock.barcode,
                name: res.stock.name,
                category: res.stock.category?.name,
                skt: moment(res.skt).format('DD/MM/yyyy'),
                stock: res.stock.id
              };
            }
            return row;
          })
        );
      } else {
        toast.error('Stock does not exist with the qrcode!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const clearField = ({ target }, rIndex, cName) => {
    const value = target.value;
    if (value === '0') {
      setPrdList(old =>
        old.map((row, rowIndex) => {
          if (rIndex === rowIndex) {
            return {
              ...old[rowIndex],
              [cName]: ''
            };
          }
          return row;
        })
      );
    }
  };
  // counting detail action end

  const handleRouteList = () => {
    refetch();
    navigate(`/stock/counting-detail/${id}`);
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <TableHeader
            title="Update Counting Item"
            isBulkAction={false}
            leading={{ icon: 'arrow-left', callBack: handleRouteList }}
            table
          />
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="date">
                  <Col xs={4}>
                    <Form.Label>Date</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      value={
                        moment(data?.data.results[0].date).format(
                          'DD/MM/yyyy'
                        ) ?? ''
                      }
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="status">
                  <Col xs={4}>
                    <Form.Label>Status</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      value={
                        statusOptions.filter(
                          e => e.value === data?.data.results[0].status
                        )[0]?.label ?? ''
                      }
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="storageLocation"
                >
                  <Col xs={4}>
                    <Form.Label>Warehouse</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      value={data?.data.results[0].storageLocation.name ?? ''}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="responsible">
                  <Col xs={4}>
                    <Form.Label>Responsible</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      value={data?.data.results[0].responsibleName ?? ''}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group as={Row} className="mb-3" controlId="description">
                  <Col xs={4} lg={2}>
                    <Form.Label>Description</Form.Label>
                  </Col>
                  <Col xs={8} lg={10}>
                    <Form.Control
                      value={data?.data.results[0].description ?? ''}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Col xs={12}>
              <Card className="mb-3">
                <Card.Body className="p-0">
                  <Table size="sm" striped="true" className="fs--1 mb-0">
                    <thead className="bg-200 text-900 text-nowrap align-middle text-center">
                      <tr>
                        {columns.map((column, index) => (
                          <th key={index} {...column.headerProps}>
                            {column.Header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {prdList.map((row, rIndex) => {
                        return (
                          <Fragment key={rIndex}>
                            <tr className="align-middle">
                              {columns.map((column, cIndex) => {
                                if (
                                  column.accessor === 'qrCode' ||
                                  column.accessor === 'count'
                                ) {
                                  return (
                                    <td key={cIndex} {...column.cellProps}>
                                      {
                                        <input
                                          className="form-control p-0 px-1"
                                          value={row[column.accessor]}
                                          onChange={e =>
                                            onChange(e, rIndex, column.accessor)
                                          }
                                          onFocus={e =>
                                            clearField(
                                              e,
                                              rIndex,
                                              column.accessor
                                            )
                                          }
                                          onBlur={
                                            column.accessor === 'qrCode'
                                              ? e => onBlur(e, rIndex)
                                              : null
                                          }
                                        />
                                      }
                                    </td>
                                  );
                                } else if (column.accessor === 'none') {
                                  return (
                                    <td key={cIndex} {...column.cellProps}>
                                      {prdList.length > 1 && (
                                        <IconButton
                                          onClick={() => handleRemove(rIndex)}
                                          variant="secondary"
                                          size="sm"
                                          icon="trash"
                                          transform="shrink-3"
                                          className="me-1"
                                        ></IconButton>
                                      )}
                                      <IconButton
                                        onClick={() =>
                                          setPrdList([...prdList, initialForm])
                                        }
                                        variant="primary"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                      ></IconButton>
                                      <IconButton
                                        onClick={() => handleUpdate(rIndex)}
                                        className="ms-1"
                                        variant="success"
                                        size="sm"
                                        icon="save"
                                        transform="shrink-3"
                                      ></IconButton>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td key={cIndex} {...column.cellProps}>
                                      {row[column.accessor]}
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <Modal show={isModalOpen} onHide={closeModal} contentClassName="border">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">{confirmationInfo.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="description">
              <Form.Label className="fs-0">
                {confirmationInfo.description}
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Description"
                rows={3}
                isInvalid={errors.description}
                {...register('description', {
                  required: 'This field cannot be empty!'
                })}
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button variant="danger" className="px-3 mx-2" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {!prdList[selectedRowId]?.id ? 'Save' : confirmationInfo.button}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateCountingItem;
