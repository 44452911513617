import React from 'react';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { Button, Modal } from 'react-bootstrap';

const ConfirmationModal = props => {
  const {
    showConfirm,
    setShowConfirm,
    request,
    title,
    description,
    type = 'delete',
    btnSecondary = 'Close',
    buttonText = 'Delete'
  } = props;

  const handleOk = () => {
    request();
    setShowConfirm(false);
  };

  return (
    <Modal
      show={showConfirm}
      onHide={() => setShowConfirm(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <FalconCloseButton onClick={() => setShowConfirm(false)} />
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowConfirm(false)}>
          {btnSecondary}
        </Button>
        <Button
          variant={type === 'delete' ? 'danger' : 'primary'}
          onClick={handleOk}
        >
          {buttonText === 'Delete'
            ? type === 'delete'
              ? 'Delete'
              : 'Update'
            : buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  showConfirm: PropTypes.bool.isRequired,
  setShowConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  request: PropTypes.func.isRequired,
  type: PropTypes.string,
  btnSecondary: PropTypes.string,
  buttonText: PropTypes.string
};

export default ConfirmationModal;
