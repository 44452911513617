import React, { useContext, useEffect, useReducer, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import { Card, Dropdown } from 'react-bootstrap';
import serviceUrl from '../../../services/serviceUrlList';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useDelete from 'hooks/useDelete';
import TableHeader from 'components/common/TableHeader';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getProductQuestions } from 'services/product';
import { queryReducer } from 'reducers/queryReducer';
import { AuthContext } from 'context/Context';

const UserQuestions = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const { user } = useContext(AuthContext);

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.question.detail + selectedRowId?.id,
    payload: { updated_by: user.id }
  });
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useQuery(
    ['getProductQuestions', queryObj],
    () => getProductQuestions(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  const handleDeleteItem = id => {
    setSelectedRowId({ id: id, type: 'delete' });
    setShowConfirm(true);
  };
  useEffect(() => {
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    refetch();
    setShowConfirm(false);
    if (deleteResponse.error) {
      toast.error(`Something went wrong!`, {
        theme: 'colored'
      });
      return;
    }
    toast.success(`Question deleted`, {
      theme: 'colored'
    });
    setSelectedRowId(null);
  }, [deleteResponse]);

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end
  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2',
        style: { width: 20 }
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return <Link to={`/product/question-update/${id}`}>{id}</Link>;
      }
    },
    {
      accessor: 'product.name',
      Header: 'Product Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'created_by.full_name',
      Header: 'User Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2',
        style: { width: 150, maxWidth: 150 }
      }
    },
    {
      accessor: 'question',
      Header: 'Questions',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2 text-truncate',
        style: { width: 150, maxWidth: 150 }
      }
    },
    {
      accessor: 'question_type',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { question_type } = rowData.row.original;
        if (question_type === 1) {
          return (
            <SoftBadge pill bg="secondary" className="d-block">
              Product
            </SoftBadge>
          );
        } else if (question_type === 2) {
          return (
            <SoftBadge pill bg="primary" className="d-block">
              Expert
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="info" className="d-block">
            Contact Us
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'replied_by.full_name',
      Header: 'Replied By',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2',
        style: { width: 150, maxWidth: 150 }
      }
    },
    {
      accessor: 'response',
      Header: 'Response',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2 text-truncate',
        style: { maxWidth: 150, width: 150 }
      }
    },
    {
      accessor: 'is_replied',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_replied } = rowData.row.original;
        if (is_replied) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Replied
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="info" className="d-block">
            New
          </SoftBadge>
        );
      }
    },

    {
      accessor: 'none',
      Header: 'Actions',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id, is_replied } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                disabled={is_replied}
                onClick={() => navigate(`/product/question-reply/${id}`)}
              >
                Reply
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigate(`/product/question-update/${id}`)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          data={data?.data.results || []}
          columns={columns}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="User Questions"
                handleRefetch={refetch}
                table
                isBulkAction={false}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Question"
        description="Are you sure the question you selected will be deleted?"
        request={deleteRequest}
        type={'delete'}
      />
    </div>
  );
};

export default UserQuestions;
