import React, { useState } from 'react';
import DefinitionCard from '../DefinitionCard';
import BlogCategory from './tabs/BlogCategory';
import FaqCategory from './tabs/FaqCategory';
import Tag from './tabs/Tag';

const Content = () => {
  const [activeTab, setActiveTab] = useState('faqcategory');
  const [newClickCount, setNewClickCount] = useState(0);
  const tabs = [
    {
      name: 'FAQ Category',
      eventKey: 'faqcategory'
    },
    {
      name: 'Blog Category',
      eventKey: 'blogCategory'
    },
    {
      name: 'Tag',
      eventKey: 'tag'
    }
  ];
  const tabChange = tab => {
    setActiveTab(tab);
    setNewClickCount(0);
  };

  const handleNew = () => {
    setNewClickCount(newClickCount + 1);
  };

  return (
    <div>
      <DefinitionCard
        title="Content"
        tabs={tabs}
        tabChange={tabChange}
        defaultActiveKey={tabs[0].eventKey}
        tabContent={
          activeTab === 'faqcategory' ? (
            <FaqCategory newClickCount={newClickCount} />
          ) : activeTab === 'blogCategory' ? (
            <BlogCategory newClickCount={newClickCount} />
          ) : activeTab === 'tag' ? (
            <Tag newClickCount={newClickCount} />
          ) : null
        }
        handleNew={handleNew}
      />
    </div>
  );
};

export default Content;
