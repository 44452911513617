import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineRight } from 'react-icons/ai';
import Flex from 'components/common/Flex';

const ProductFilterListItem = props => {
  const { name, onClick } = props;
  return (
    <Flex
      onClick={onClick}
      alignItems="center"
      justifyContent="between"
      className={'rounded-3 bg-light p-3 order-product-filter-list mb-2'}
    >
      <>
        <h6 className="mb-0">{name}</h6>
        <span className="fs--2 text-600 mb-0">
          <AiOutlineRight />
        </span>
      </>
    </Flex>
  );
};

ProductFilterListItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ProductFilterListItem;
