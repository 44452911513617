import PropTypes from 'prop-types';
import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';

const BasketTableFilter = ({ handleFilter }) => {
  return (
    <Row className="flex-between-center">
      <Col xs={12} className="mb-4">
        <Nav variant="tabs" defaultActiveKey="active">
          <Nav.Item>
            <Nav.Link
              eventKey="active"
              onClick={() => handleFilter('is_active', true)}
            >
              Active
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="passive"
              onClick={() => handleFilter('is_active', false)}
            >
              Passive
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="all"
              onClick={() => handleFilter('is_active', null)}
            >
              All
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
    </Row>
  );
};

BasketTableFilter.propTypes = {
  eventKey: PropTypes.func,
  handleSearch: PropTypes.func,
  handleFilter: PropTypes.func
};

export default BasketTableFilter;
