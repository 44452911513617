import SoftBadge from 'components/common/SoftBadge';
import AppContext from 'context/Context';
import { currencyFormat } from 'helpers/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

const ProductPrice = props => {
  const { slug } = useParams();
  const { formValues, setFormValues, shippingCost, user } = props;
  const [inputValues, setInputValues] = useState({
    price: '',
    post_price: '',
    kdv: ''
  });
  const [discount, setDiscount] = useState('0');
  const [priceList, setPriceList] = useState([]);
  const [editNewPriceToggle, setEditNewPriceToggle] = useState(false);
  const {
    config: { isDark }
  } = useContext(AppContext);

  useEffect(() => {
    if (!formValues.prices) return;
    let reversed_list = formValues.prices.sort((a, b) => {
      return -(a.id - b.id);
    });
    setPriceList(reversed_list);
  }, [formValues.is_update]);

  useEffect(() => {
    if (slug) return;
    if (
      !formValues.bundle_quantity ||
      formValues.bundle_quantity.length === 0 ||
      !formValues.bundlePrice
    )
      return;
    let reversed_list = formValues.bundlePrice.sort((a, b) => {
      return -(a.id - b.id);
    });
    setPriceList(reversed_list);
  }, [formValues.bundlePrice]);

  useEffect(() => {
    if (formValues.set_quantity === undefined || slug) return;
    if (!formValues.defaultPrice) return;
    // SHIPPING COSTTA HEPSIJET FIRMA ADI DEGISTIRILIRSE BURALAR PATLAR
    let shippingPrice = 0;
    const hepsiJet = shippingCost?.data?.results.find(
      dt => dt.company_name === 'HepsiJet'
    );
    if (hepsiJet) {
      const desiList = [0, 1, 2, 3, 4, 5];
      const desi = desiList.includes(formValues.desi) ? formValues.desi : 0;
      shippingPrice = hepsiJet.desi_with_tax.find(dt => dt.desi === desi).price;
    }
    const priceCalculate = pr => {
      return (
        pr * formValues.set_quantity -
        shippingPrice * (formValues.set_quantity / 2 - 0.5)
      );
    };
    const newPrice = [
      {
        total_price: priceCalculate(formValues.defaultPrice.price),
        total_post_price: priceCalculate(formValues.defaultPrice.post_price),
        price:
          priceCalculate(formValues.defaultPrice.price) *
          ((100 - formValues.defaultPrice.kdv) / 100).toFixed(2),
        post_price:
          priceCalculate(formValues.defaultPrice.post_price) *
          ((100 - formValues.defaultPrice.kdv) / 100).toFixed(2),
        kdv: formValues.defaultPrice.kdv,
        is_active: true
      }
    ];
    setPriceList(newPrice);
  }, [formValues.set_quantity]);

  useEffect(() => {
    const list = priceList.map((dt, i) => ({
      id: dt.id,
      total_price: Number(dt.total_price),
      total_post_price: Number(dt.total_post_price),
      price: Number(dt.total_price * ((100 - dt.kdv) / 100)).toFixed(2),
      post_price: Number(dt.total_post_price * ((100 - dt.kdv) / 100)).toFixed(
        2
      ),
      kdv: Number(dt.kdv),
      is_active: i === 0 ? true : false,
      created_by: user.id
    }));
    setFormValues({ ...formValues, prices: list });
  }, [priceList]);

  const handleChange = event => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };
  const handleSelectChange = event => {
    setInputValues({ ...inputValues, kdv: event.value });
  };
  const handleSave = () => {
    if (
      !inputValues.price ||
      !inputValues.post_price ||
      (!inputValues.kdv && inputValues.kdv !== 0)
    ) {
      toast.error(`Fill all fields!`, {
        theme: 'colored'
      });
      return;
    }
    if (Number(inputValues.price) > Number(inputValues.post_price)) {
      toast.error(`Price cannot be greater then post price!`, {
        theme: 'colored'
      });
      return;
    }
    if (
      Number(inputValues.price) === 0 ||
      Number(inputValues.post_price) === 0
    ) {
      toast.error(`Price and post price cannot be 0!`, {
        theme: 'colored'
      });
      return;
    }
    const old_list = [...priceList];
    old_list.splice(0, editNewPriceToggle ? 1 : 0, {
      created_at: moment(),
      total_price: Number(inputValues.price).toFixed(2),
      total_post_price: Number(inputValues.post_price).toFixed(2),
      kdv: inputValues.kdv,
      price: (inputValues.price * ((100 - inputValues.kdv) / 100)).toFixed(2),
      post_price: (
        inputValues.post_price *
        ((100 - inputValues.kdv) / 100)
      ).toFixed(2),
      created_by: { first_name: 'You' },
      is_active: true
    });
    if (!editNewPriceToggle) {
      setEditNewPriceToggle(true);
      const objIndex = old_list.findIndex(
        (obj, i) => i !== 0 && obj?.is_active === true
      );
      if (objIndex !== -1) {
        old_list[objIndex].is_active = false;
      }
    }

    setPriceList(old_list);
  };

  useEffect(() => {
    if (inputValues.price && inputValues.post_price) {
      setDiscount(
        (
          100 *
          ((parseFloat(inputValues.post_price) -
            parseFloat(inputValues.price)) /
            parseFloat(inputValues.post_price))
        ).toFixed(1)
      );
    }
  }, [inputValues]);

  const priceRows = priceList.map((row, i) => {
    return (
      <tr key={i}>
        <td>{moment(row.created_at).format('DD.MM.YYYY HH:mm')}</td>
        <td className="text-end">{currencyFormat(row.price)} ₺</td>
        <td className="text-end">{currencyFormat(row.post_price)} ₺</td>
        <td className="text-end">{row.kdv} %</td>
        <td className="text-end">{currencyFormat(row.total_price)} ₺</td>
        <td className="text-end">{currencyFormat(row.total_post_price)} ₺</td>
        <td>
          {row.created_by?.id === user.id ? 'You' : row.created_by?.first_name}
        </td>
        <td>
          {row.is_active ? (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          ) : (
            <SoftBadge pill bg="danger" className="d-block">
              Passive
            </SoftBadge>
          )}
        </td>
      </tr>
    );
  });

  const columns = [
    {
      accessor: 'dateTime',
      Header: 'Date & Time'
    },
    {
      accessor: 'price',
      Header: 'Price',
      headerProps: { className: 'pe-1 text-center' }
    },
    {
      accessor: 'post_price',
      Header: 'Post Price',
      headerProps: { className: 'pe-1 text-center' }
    },
    {
      accessor: 'kdv',
      Header: 'KDV',
      headerProps: { className: 'pe-1 text-center' }
    },
    {
      accessor: 'total_price',
      Header: 'Total Price',
      headerProps: { className: 'pe-1 text-center' }
    },
    {
      accessor: 'total_post_price',
      Header: 'Total Post Price',
      headerProps: { className: 'pe-1 text-center' }
    },
    {
      accessor: 'created_by',
      Header: 'Created By',
      headerProps: { className: 'pe-1 text-center' }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'text-center' }
    }
  ];
  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Product Price
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={3}>
            <Form.Group className="mb-3" controlId="price">
              <Form.Label className="fs-0">Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={inputValues.price}
                onChange={handleChange}
                className="input-spin-none"
                onWheel={e => e.target.blur()}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group className="mb-3" controlId="post_price">
              <Form.Label className="fs-0">Post Price</Form.Label>
              <Form.Control
                type="number"
                name="post_price"
                value={inputValues.post_price}
                onChange={handleChange}
                className="input-spin-none"
                onWheel={e => e.target.blur()}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group className="mb-3" controlId="KDV">
              <Form.Label className="fs-0">KDV</Form.Label>
              <Select
                options={[
                  { value: 0, label: 0 },
                  { value: 1, label: 1 },
                  { value: 10, label: 10 },
                  { value: 20, label: 20 }
                ]}
                placeholder="Select KDV"
                classNamePrefix="react-select"
                name="kdv"
                value={{ value: inputValues.kdv, label: inputValues.kdv }}
                onChange={handleSelectChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={2} className="position-relative">
            <Form.Group className="mb-3" controlId="discount">
              <Form.Label className="fs-0">Discount (%)</Form.Label>
              <Form.Control
                type="number"
                value={discount}
                disabled
                plaintext
                onWheel={e => e.target.blur()}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={1} className="position-relative">
            <Button
              variant="success"
              onClick={handleSave}
              className="position-absolute bottom-0 mb-3"
            >
              Add
            </Button>
          </Col>
          <Col xs={12}>
            <Table striped bordered hover size="sm" className="border">
              <thead className={isDark ? 'bg-soft-dark' : 'bg-body'}>
                <tr>
                  {columns.map(col => {
                    return (
                      <th key={col.accessor} {...col.headerProps}>
                        {col.Header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>{priceRows}</tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

ProductPrice.propTypes = {
  formValues: PropTypes.object,
  user: PropTypes.object,
  setFormValues: PropTypes.func,
  shippingCost: PropTypes.object
};

export default ProductPrice;
