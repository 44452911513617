import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Form, Button, Table } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { protectedApi } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';
import { createGoal, createPersona, getPersonas } from 'services/product';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';

const ProductPersona = props => {
  const { formValues, setFormValues } = props;
  const [selectedPersona, setSelectedPersona] = useState();
  const [selectedGoal, setSelectedGoal] = useState();
  const [personaSearch, setPersonaSearch] = useState('');

  const { data: personaData, refetch } = useQuery(
    ['get-personas', personaSearch],
    () => getPersonas({ search: personaSearch }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const handleSave = () => {
    const oldData = formValues.persona_and_goals || [];
    const newData = [
      ...oldData,
      {
        persona: {
          id: selectedPersona.value,
          name: selectedPersona.label
        },
        goal: {
          id: selectedGoal.value,
          name: selectedGoal.label
        }
      }
    ];
    setFormValues({
      ...formValues,
      persona_and_goals: newData
    });
    setSelectedGoal(null);
    setSelectedPersona(null);
  };

  const handleSelectChange = async (info, target) => {
    if (target.name === 'persona') {
      if (info.__isNew__) {
        // create new persona
        const response = await createPersona({ name: info.value });
        if (response.status === 201) {
          toast.success(`Persona created successfully!`, {
            theme: 'colored'
          });
          setSelectedPersona({
            value: response.data.id,
            label: response.data.name
          });
          refetch();
        } else {
          toast.error(`Failed to create persona!`, {
            theme: 'colored'
          });
        }
        return;
      }
      setSelectedPersona({
        value: info.value,
        label: info.label
      });
      return;
    }
    // target.name === 'goal
    if (info.__isNew__) {
      // create new goal
      if (!selectedPersona) {
        toast.error(`Please select a persona first!`, {
          theme: 'colored'
        });
        setSelectedGoal(null);
        return;
      }
      const response = await createGoal({
        name: info.value,
        persona: selectedPersona.value
      });
      if (response.status === 201) {
        toast.success(`Goal created successfully!`, {
          theme: 'colored'
        });
        setSelectedGoal({
          value: response.data.id,
          label: response.data.name
        });
        refetch();
      } else {
        toast.error(`Failed to create goal!`, {
          theme: 'colored'
        });
      }
      return;
    }
    setSelectedGoal({
      value: info.value,
      label: info.label
    });
  };

  const personaOptions = personaData?.data.results.map(dt => ({
    value: dt.id,
    label: dt.name
  }));
  const goalOptions = personaData?.data.results
    .find(dt => dt.id === selectedPersona?.value)
    ?.goals.map(d => ({
      value: d.id,
      label: d.name
    }));

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Persona & Goals
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={3}>
            <Form.Group className="mb-3" controlId="type">
              <Form.Label className="fs-0">Persona</Form.Label>
              <CreatableSelect
                classNamePrefix="react-select"
                name="persona"
                placeholder="Persona"
                onChange={handleSelectChange}
                options={personaOptions}
                value={selectedPersona}
                onInputChange={setPersonaSearch}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group className="mb-3" controlId="content">
              <Form.Label className="fs-0">Goal</Form.Label>
              <CreatableSelect
                classNamePrefix="react-select"
                name="goal"
                placeholder="Goal"
                onChange={handleSelectChange}
                options={goalOptions}
                value={selectedGoal}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={2} className="position-relative">
            <Button
              variant="success"
              onClick={handleSave}
              className="mb-3"
              style={{ marginTop: 33 }}
            >
              Add
            </Button>
          </Col>
          <Col xs={12} lg={4} className="mt-4">
            {formValues.persona_and_goals && (
              <Table size="sm" striped="true" className="fs--1 mb-0">
                <thead className="bg-200 text-900 text-nowrap align-middle text-center">
                  <tr>
                    <th className="border-0">Persona</th>
                    <th className="border-0">Goal</th>
                    <th className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {formValues.persona_and_goals?.map((dt, idx) => (
                    <tr key={idx}>
                      <td className="align-middle text-center">
                        {dt.persona.name}
                      </td>
                      <td className="align-middle text-center">
                        {dt.goal.name}
                      </td>
                      <td className="align-middle text-center">
                        <IconButton
                          onClick={() => {
                            const newData = formValues.persona_and_goals.filter(
                              (d, i) => i !== idx
                            );
                            setFormValues({
                              ...formValues,
                              persona_and_goals: newData
                            });
                          }}
                          variant="falcon-danger"
                          size="sm"
                          icon="trash"
                          transform="shrink-3"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

ProductPersona.propTypes = {
  formValues: PropTypes.object,
  setFormValues: PropTypes.func
};

export default ProductPersona;

export const getAttributesDistinct = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.persona.distinct,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
