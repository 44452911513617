import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AppContext, { AuthContext } from 'context/Context';
import useDelete from 'hooks/useDelete';
import serviceUrl from 'services/serviceUrlList';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { queryReducer } from 'reducers/queryReducer';
import {
  createDeclinedReasons,
  getDeclinedReasons,
  updateDeclinedReasons
} from 'services/order';
import DeclinedReasonsDnd from 'components/dnd/DeclinedReasonsDnd';

const DeclinedReasons = props => {
  const { newClickCount } = props;
  const { user } = useContext(AuthContext);
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm();

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };

  const {
    data,
    isLoading: tableLoading,
    refetch
  } = useQuery(
    ['declined-reasons', queryObj],
    () => getDeclinedReasons(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.user.agreementType.detail + selectedRowId
  });

  useEffect(() => {
    if (newClickCount === 0) return;
    openCreateModal(true);
  }, [newClickCount]);

  const onSubmit = data => {
    if (selectedRowId === null) {
      postRequest({ ...data, createdBy: user.id });
    } else {
      updateRequest({ ...data, updatedBy: user.id });
    }
  };
  const updateRequest = async payload => {
    const response = await updateDeclinedReasons(
      { ...payload, updated_by: user.id },
      selectedRowId
    );
    if (response.status === 200) {
      closeCreateModal();
      refetch();
      toast.success(`Decline Reason updated`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.name[0], {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const postRequest = async payload => {
    const response = await createDeclinedReasons({
      ...payload,
      created_by: user.id,
      updated_by: user.id,
      orderNo: data?.count + 1
    });
    if (response.status === 201) {
      closeCreateModal();
      refetch();
      toast.success(`Decline Reason created`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      if (response.data.error) {
        toast.error(response.data.error, {
          theme: 'colored'
        });
      }
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    closeCreateModal();
    refetch();
    setSelectedRowId(null);
    if (deleteResponse.error === null) {
      toast.success(`Decline Reason deleted`, {
        theme: 'colored'
      });
    } else {
      toast.error(deleteResponse.error.data.message, {
        theme: 'colored'
      });
    }
  }, [deleteResponse]);

  const handleModalDeleteButton = () => {
    if (selectedRowId === null) {
      closeCreateModal();
    } else {
      handleDeleteItem(selectedRowId);
    }
  };

  const clearFormData = () => {
    clearErrors();
    reset();
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.count
    });
  }, [data?.count]);
  // ss pagination end
  return (
    <div className="p-2">
      <LoadingWrapper loading={tableLoading}>
        <Row className="mb-3">
          <Col xs="12">
            {data && data.count > 0 ? (
              <DeclinedReasonsDnd data={data.results} refetch={refetch} />
            ) : (
              <div className="text-center">No decline reason found</div>
            )}
          </Col>
        </Row>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Add Decline Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="fs-0">Name</Form.Label>
              <Form.Control
                {...register('name', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.name}
                type="text"
                name="name"
                placeholder="Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label className="fs-0">Description</Form.Label>
              <Form.Control
                {...register('description', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.description}
                type="text"
                as="textarea"
                name="description"
                placeholder="Description"
                maxLength={255}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.description?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={handleModalDeleteButton}
            >
              {selectedRowId === null ? 'Cancel' : 'Delete'}
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Decline Reason"
        description="Are you sure the decline reason you selected will be deleted?"
        request={deleteRequest}
        type={'delete'}
      />
    </div>
  );
};

DeclinedReasons.propTypes = {
  newClickCount: PropTypes.number
};

export default DeclinedReasons;
