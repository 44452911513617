import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';
import ModalSelectItem from './ModalSelectItem';
import SelectedItems from './SelectedItems';

const CardValue = props => {
  const {
    register,
    setValue,
    control,
    discountTypesWatch,
    clearField,
    defaultType
  } = props;
  const [isPercentage, setIsPercentage] = useState(true);
  const [toggleBrowse, setToggleBrowse] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemType, setItemType] = useState('');

  const searchRef = useRef();

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const appliesTypeX = useWatch({ control, name: 'applies_type_x' });
  const appliesToX = useWatch({ control, name: 'applies_to_x' });

  useEffect(() => {
    setIsPercentage(defaultType === 1);
  }, []);

  useEffect(() => {
    setToggleBrowse(['2', '3'].includes(appliesTypeX));
    if (!['2', '3'].includes(appliesTypeX)) return;
    setSelectedItems(appliesToX ?? []);
    // itemType 4 === productCategory
    setItemType(
      appliesTypeX === '2' ? 'collection' : appliesTypeX === 3 ? 'product' : '4'
    );
  }, [appliesTypeX]);

  const handleChange = ({ target }) => {
    if (target.value === '1') {
      setToggleBrowse(false);
      setSelectedItems([]);
    } else if (target.value === '2') {
      setToggleBrowse(true);
      setItemType('collection');
      setSelectedItems([]);
    } else if (target.value === '3') {
      setToggleBrowse(true);
      setItemType('product');
      setSelectedItems([]);
    } else if (target.value === '4') {
      setToggleBrowse(true);
      setItemType('productCategory');
      setSelectedItems([]);
    }
  };
  const handleInputChange = event => {
    openModal();
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    if (isPercentage) {
      setValue('value_type', 1);
    } else {
      setValue('value_type', 2);
    }
  }, [isPercentage]);

  useEffect(() => {
    const temp_arr = selectedItems.map(e => e.id);
    setValue('applies_to_x', temp_arr.toString());
  }, [selectedItems]);

  const modalProps = {
    searchValue,
    setSearchValue,
    showModal,
    closeModal,
    selectedItems,
    setSelectedItems,
    itemType
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title className="mb-3">Value</Card.Title>
          <Flex direction="row">
            <Form.Group className="pe-2" controlId="discountCode">
              <InputGroup>
                <Button
                  variant={isPercentage ? 'secondary' : 'outline-secondary'}
                  onClick={() => setIsPercentage(true)}
                  id="btnPercentage"
                >
                  Percentage
                </Button>
                <Button
                  variant={isPercentage ? 'outline-secondary' : 'secondary'}
                  onClick={() => setIsPercentage(false)}
                  id="btnFixed"
                >
                  Fixed Amount
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="flex-fill" controlId="percentage">
              <InputGroup>
                {!isPercentage && <InputGroup.Text>₺</InputGroup.Text>}
                <Form.Control
                  {...register('value')}
                  placeholder={isPercentage ? '' : '0.00'}
                  name="value"
                  onFocus={clearField}
                  type="number"
                  onWheel={e => e.target.blur()}
                />
                {isPercentage && <InputGroup.Text>%</InputGroup.Text>}
              </InputGroup>
            </Form.Group>
          </Flex>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        {discountTypesWatch == 1 && (
          <Card.Body>
            <Card.Title>Applies to</Card.Title>
            <Form.Group className="mb-1" controlId="applies_type_x_1">
              <Form.Check
                {...register('applies_type_x')}
                inline
                type="radio"
                value={1}
                className="me-2"
                defaultChecked
                onChange={handleChange}
              />
              <Form.Label className="mb-0">All</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1" controlId="applies_type_x_3">
              <Form.Check
                {...register('applies_type_x')}
                inline
                type="radio"
                value={3}
                className="me-2"
                onChange={handleChange}
              />
              <Form.Label className="mb-0">Spesific Products</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1" controlId="applies_type_x_4">
              <Form.Check
                {...register('applies_type_x')}
                inline
                type="radio"
                value={4}
                className="me-2"
                onChange={handleChange}
              />
              <Form.Label className="mb-0">Spesific Category</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1" controlId="applies_type_x_2">
              <Form.Check
                {...register('applies_type_x')}
                inline
                type="radio"
                value={2}
                className="me-2"
                onChange={handleChange}
              />
              <Form.Label className="mb-0">Spesific Collections</Form.Label>
            </Form.Group>
            {toggleBrowse && (
              <Row>
                <Col xs={12}>
                  <InputGroup>
                    <InputGroup.Text>
                      <BsSearch />
                    </InputGroup.Text>
                    <Form.Control
                      ref={searchRef}
                      type="text"
                      name="search-open-modal"
                      placeholder={
                        itemType === 'collection'
                          ? 'Search Collections'
                          : itemType === 'product'
                          ? 'Search Products'
                          : 'Search Categories'
                      }
                      onChange={handleInputChange}
                    />
                    <Button
                      variant="outline-primary"
                      onClick={handleInputChange}
                    >
                      Browse
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <SelectedItems
                  items={selectedItems}
                  setItems={setSelectedItems}
                  itemType={itemType}
                />
              </Col>
            </Row>
          </Card.Body>
        )}
        <ModalSelectItem {...modalProps} />
      </Card>
    </>
  );
};

CardValue.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  clearField: PropTypes.func,
  control: PropTypes.object,
  discountTypesWatch: PropTypes.number,
  id: PropTypes.string,
  defaultType: PropTypes.number
};

export default CardValue;
