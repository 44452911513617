import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import CardDropdown from 'components/common/CardDropdown';
import CustomSelect from 'components/common/CustomSelect';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Col, Dropdown, Nav, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import {
  getBrandsMini,
  getProductCategoriesMini,
  getProductCollectionsMini,
  getTagsMini
} from 'services/product';

const ProductsTableFilter = ({
  handleProductSearch,
  handleCategoryFilter,
  handleCollectionFilter,
  handleBrandFilter,
  handleTagFilter,
  handleTabFilter,
  handleExport,
  handleImportPrices,
  isLoadingExport
}) => {
  const { data: collectionData } = useQuery(
    ['product-list-collections-mini'],
    () => getProductCollectionsMini(),
    {
      keepPreviousData: false
    }
  );
  const { data: categoriesData } = useQuery(
    ['product-list-categories-mini'],
    () => getProductCategoriesMini(),
    {
      keepPreviousData: false
    }
  );
  const { data: tagsData } = useQuery(
    ['product-list-tags-mini'],
    () => getTagsMini(),
    {
      keepPreviousData: false
    }
  );
  const { data: brandData } = useQuery(
    ['product-list-brands-mini'],
    () => getBrandsMini(),
    {
      keepPreviousData: false
    }
  );

  const excelUploadRef = useRef(null);

  const brandOptions =
    brandData?.map(dt => ({
      value: dt.id,
      label: dt.name
    })) ?? [];
  const tagOptions =
    tagsData?.data.results.map(dt => ({
      value: dt.name,
      label: dt.name
    })) ?? [];

  const collectionOptions =
    collectionData?.data.results.map(dt => ({
      value: dt.id,
      label: dt.name
    })) ?? [];
  const categoryOptions =
    categoriesData?.data.results.map(dt => ({
      value: dt.id,
      label: dt.name
    })) ?? [];

  return (
    <>
      <Row className="flex-between-center">
        <Col xs={12} className="mb-4">
          <Nav
            variant="tabs"
            defaultActiveKey="active"
            onSelect={val => handleTabFilter(val)}
          >
            <Nav.Item>
              <Nav.Link eventKey="active">Active</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="passive">Passive</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="draft">Draft</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="archived">Archived</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="all">All</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs="auto" sm={6} lg={3}>
          <AdvanceTableSearchBox
            table
            size="sm"
            placeholder="Filter Products"
            setGlobalFilter={handleProductSearch}
          />
        </Col>
        <Col xs="auto" lg={9}>
          <Row>
            <Col xs="auto" sm={6} lg={3}>
              <CustomSelect
                options={collectionOptions}
                onChange={handleCollectionFilter}
                placeholder="Collection"
              />
            </Col>
            <Col xs="auto" sm={6} lg={3}>
              <CustomSelect
                options={categoryOptions}
                onChange={handleCategoryFilter}
                placeholder="Category"
              />
            </Col>
            <Col xs="auto" sm={6} lg={2}>
              <CustomSelect
                options={brandOptions}
                onChange={handleBrandFilter}
                placeholder="Brand"
              />
            </Col>
            <Col xs="auto" sm={6} lg={2}>
              <CustomSelect
                options={tagOptions}
                onChange={handleTagFilter}
                placeholder="Tag"
              />
            </Col>
            <Col xs="auto" sm={6} lg={2}>
              <LoadingWrapper loading={isLoadingExport}>
                <CardDropdown
                  iconClassName="fs--1"
                  icon="file"
                  title="Import/Export"
                  btnRevealClass="btn-falcon-default"
                >
                  <div className="py-2">
                    <Dropdown.Item onClick={e => handleExport(e.target.name)}>
                      Export
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        excelUploadRef.current.click();
                      }}
                    >
                      Import Prices
                    </Dropdown.Item>
                  </div>
                </CardDropdown>
              </LoadingWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={excelUploadRef}
        accept=".xlsx, .xls"
        onChange={handleImportPrices}
      />
    </>
  );
};

export default ProductsTableFilter;

ProductsTableFilter.propTypes = {
  handleProductSearch: PropTypes.func,
  handleCategoryFilter: PropTypes.func,
  handleCollectionFilter: PropTypes.func,
  handleBrandFilter: PropTypes.func,
  handleTagFilter: PropTypes.func,
  handleExport: PropTypes.func,
  handleImportPrices: PropTypes.func,
  isLoadingExport: PropTypes.bool,
  handleTabFilter: PropTypes.func
};
