import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { currencyFormat } from 'helpers/utils';
import InputMask from 'react-input-mask';
import DiscountModal from './DiscountModal';
import { AuthContext, LoaderContext } from 'context/Context';
import {
  checkDiscountForOrder,
  createOrder,
  payment3DSecureInit
} from 'services/order';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ShippingDiscountModal from './ShippingDiscountModal';
import Divider from 'components/common/Divider';
import FalconCloseButton from 'components/common/FalconCloseButton';
const defShippingCostInfo = {
  id: null,
  title: '---',
  amount: 0
};

const defDiscountInfo = {
  id: null,
  title: '---',
  amount: 0,
  isCombined: true,
  discounts: []
};

const defCreditCardInfo = {
  cvc: '',
  expiry: '',
  number: '',
  name: ''
};

const PaymentInfo = props => {
  const { selectedProducts, selectedUser, selectedAddress } = props;
  const { setLoading } = useContext(LoaderContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [payment, setPayment] = useState(0);
  const [discountInfo, setDiscountInfo] = useState(defDiscountInfo);
  const [shippingCostInfo, setShippingCostInfo] = useState(defShippingCostInfo);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const [isShippingDiscountModalOpen, setIsShippingDiscountModalOpen] =
    useState(false);
  const [creditCardInfo, setCreditCardInfo] = useState(defCreditCardInfo);
  const [smsHtml, setSmsHtml] = useState(null);
  const [focus, setFocus] = useState('');
  const openDiscountModal = () => {
    if (!selectedUser.id) {
      toast.error(`To be able to define discount you must select a user!`, {
        theme: 'colored'
      });
      return;
    }
    setIsDiscountModalOpen(true);
  };
  const openShippingDiscountModal = () => {
    setIsShippingDiscountModalOpen(true);
  };

  const closeSmsModal = () => {
    setIsSmsModalOpen(false);
  };

  useEffect(() => {
    setShippingCostInfo(defShippingCostInfo);
    setDiscountInfo(defDiscountInfo);
  }, [selectedProducts]);

  const handleMarkAsPaid = async () => {
    if (!selectedUser.id || !selectedProducts) {
      toast.error(`Please select products and customer!`, {
        theme: 'colored'
      });
      return;
    }
    if (selectedUser.user_address.length === 0) {
      toast.error(`Customer doesn't have an address!`, {
        theme: 'colored'
      });
      return;
    }
    const discountIds = [];
    if (discountInfo.id) {
      discountIds.push(discountInfo.id);
    }
    if (shippingCostInfo.id) {
      discountIds.push(shippingCostInfo.id);
    }
    const payload = {
      user_id: selectedUser.id,
      user_full_name: `${selectedUser.first_name} ${selectedUser.middle_name} ${selectedUser.last_name}`,
      user_email: selectedUser.email,
      address: selectedUser.user_address[selectedAddress],
      total_amount:
        SubTotal + KdvTotal + shippingCostInfo.amount - discountInfo.amount,
      total_tax: KdvTotal,
      created_by: user.id,
      is_shipment_free: shippingCostInfo.amount === 0 ? true : false,
      order_items: selectedProducts.map(product => ({
        product_id: product.id,
        product_name: product.name,
        product_slug: product.slug,
        product_photo: product.file,
        product_price: product.price.total_price,
        product_tax: product.price.kdv,
        sku: product.sku,
        skt: product.skt,
        quantity: product.quantity,
        product_type: product.type,
        product_skus: product.products?.map(dt => dt.sku).join(','),
        product_set_quantity: product.set_quantity,
        discount_ids:
          discountInfo.discounts.length > 0 &&
          discountInfo.discounts[0].product_id === product.id
            ? String(discountInfo.id)
            : null,
        discount_amount:
          discountInfo.discounts.length > 0
            ? discountInfo.discounts.map(dt => {
                if (dt.product_id === product.id) {
                  let val = 0;
                  // if discount_value_type === 1 -> percant %
                  if (dt.discount_value_type === 1) {
                    // make discount and add to discount variable
                    val +=
                      product.price.total_price * (dt.discount_value / 100);
                  } else {
                    val += dt.discount_value;
                  }
                  return val;
                }
                return null;
              })[0]
            : null
      })),
      discount_ids: discountIds.join(','),
      discount_amount: discountInfo.amount
    };
    const response = await createOrder(payload);
    if (response.status === 201) {
      toast.success(`Order created successfully`, {
        theme: 'colored'
      });
      navigate('/order/order-list');
    } else {
      toast.error(`Uppsss Something went wrong!`, {
        theme: 'colored'
      });
    }
  };

  const handleCreditCard = async order_id => {
    // const payload = {
    //   order: order_id,
    //   cardHolderName: 'Salih Bilgin',
    //   cardNumber: '5440781876776024',
    //   expireYear: '2025',
    //   expireMonth: '03',
    //   cvc: '526'
    // };
    const payload = {
      order: order_id,
      cardHolderName: creditCardInfo.name,
      cardNumber: creditCardInfo.number.replace(/ /g, ''),
      expireYear: '20' + creditCardInfo.expiry.slice(-2),
      expireMonth: creditCardInfo.expiry.slice(0, 2),
      cvc: creditCardInfo.cvc
    };
    const paymentRequest = await payment3DSecureInit(payload);
    console.log('PAYMENT RESPONSE: ', paymentRequest);
    setSmsHtml(paymentRequest.data);
    setIsSmsModalOpen(true);
  };

  const handleWithCreditCard = async () => {
    // handleCreditCard(18);
    // return;
    if (!selectedUser.id || !selectedProducts) {
      toast.error(`Please select products and customer!`, {
        theme: 'colored'
      });
      return;
    }
    if (selectedUser.user_address.length === 0) {
      toast.error(`Customer doesn't have an address!`, {
        theme: 'colored'
      });
      return;
    }
    setLoading(true);
    const discountIds = [];
    if (discountInfo.id) {
      discountIds.push(discountInfo.id);
    }
    if (shippingCostInfo.id) {
      discountIds.push(shippingCostInfo.id);
    }
    const payload = {
      user_id: selectedUser.id,
      user_full_name: `${selectedUser.first_name} ${selectedUser.middle_name} ${selectedUser.last_name}`,
      user_email: selectedUser.email,
      address: selectedUser.user_address[selectedAddress],
      total_amount:
        SubTotal + KdvTotal + shippingCostInfo.amount - discountInfo.amount,
      total_tax: KdvTotal,
      created_by: user.id,
      is_shipment_free: shippingCostInfo.amount === 0 ? true : false,
      order_items: selectedProducts.map(product => ({
        product_id: product.id,
        product_name: product.name,
        product_slug: product.slug,
        product_photo: product.file,
        product_price: product.price.total_price,
        product_tax: product.price.kdv,
        sku: product.sku,
        skt: product.skt,
        quantity: product.quantity,
        product_type: product.type,
        product_skus: product.products?.map(dt => dt.sku).join(','),
        product_set_quantity: product.set_quantity,
        discount_ids:
          discountInfo.discounts.length > 0 &&
          discountInfo.discounts[0].product_id === product.id
            ? String(discountInfo.id)
            : null,
        discount_amount:
          discountInfo.discounts.length > 0
            ? discountInfo.discounts.map(dt => {
                if (dt.product_id === product.id) {
                  let val = 0;
                  // if discount_value_type === 1 -> percant %
                  if (dt.discount_value_type === 1) {
                    // make discount and add to discount variable
                    val +=
                      product.price.total_price * (dt.discount_value / 100);
                  } else {
                    val += dt.discount_value;
                  }
                  return val.toFixed(2);
                }
                return null;
              })[0]
            : null
      })),
      discount_ids: discountIds.join(','),
      discount_amount: discountInfo.amount
    };
    const response = await createOrder(payload);
    console.log('RESPONSE: ', response);
    if (response.status === 201) {
      handleCreditCard(response.data.id);
    } else {
      toast.error(`Uppsss Something went wrong!`, {
        theme: 'colored'
      });
    }
    setLoading(false);
  };

  // Set discount
  const handleSetDiscount = async discount => {
    setLoading(true);
    const payload = {
      discount_id: discount.id,
      user_id: selectedUser.id,
      products: selectedProducts.map(product => ({
        id: product.id,
        quantity: product.quantity,
        total_price: product.price.total_price
      }))
    };
    const response = await checkDiscountForOrder(payload);
    if (response.status === 403) {
      toast.error(response.data.message, {
        theme: 'colored'
      });
    } else if (response.status === 200) {
      let discount_ = 0;
      response.data.map(dt => {
        // Find the product
        const product = selectedProducts.filter(
          pr => pr.id === dt.product_id
        )[0];
        // Calculate the total price of 1 product
        const totalPrice = product.price.price * product.quantity;
        // if discount_value_type === 1 -> percant %
        if (dt.discount_value_type === 1) {
          // make discount and add to discount variable
          discount_ += totalPrice * (dt.discount_value / 100);
        } else {
          discount_ += dt.discount_value;
        }
      });
      setDiscountInfo({
        id: discount.id,
        title: discount.title,
        amount: discount_,
        isCombined: response.data[0].discount_is_combined,
        discounts: response.data
      });
      setShippingCostInfo(defShippingCostInfo);
    }
    setLoading(false);
    setIsDiscountModalOpen(false);
  };

  // Shipping discount handler
  const handleShippingDiscount = discount => {
    setLoading(true);
    setShippingCostInfo({ id: discount.id, title: discount.title, amount: 0 });
    setIsShippingDiscountModalOpen(false);
    setLoading(false);
  };

  const handleCreditCardInfo = event => {
    const { name, value } = event.target;
    setCreditCardInfo({ ...creditCardInfo, [name]: value });
  };

  const handleInputFocus = e => {
    setFocus(e.target.name);
  };

  const SubTotal = selectedProducts.reduce(
    (sum, product) => sum + product.price.price * product.quantity,
    0
  );

  const KdvTotal = selectedProducts.reduce(
    (sum, product) =>
      sum +
      product.quantity *
        (((product.price.price - discountInfo.amount) * product.price.kdv) /
          100),
    0
  );

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Payment</h5>
      </Card.Header>
      <Card.Body>
        <Row className="mb-3">
          <Col xs={4}>
            <span>Sub Total</span>
          </Col>
          <Col xs={4}>
            <span></span>
          </Col>
          <Col xs={4} className="d-flex justify-content-end">
            <span>{currencyFormat(SubTotal)} ₺</span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4}>
            <span onClick={openDiscountModal} className="blue-text-btn">
              Add Discount
            </span>
          </Col>
          <Col xs={4}>
            <span>{discountInfo.title}</span>
          </Col>
          <Col xs={4} className="d-flex justify-content-end">
            <span>
              {discountInfo.amount !== 0 && '- '}
              {currencyFormat(discountInfo.amount)} ₺
            </span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4}>
            <span
              onClick={() => {
                if (!discountInfo.isCombined) {
                  toast.error(
                    `Your discount cannot be combined with a shipping discount!`,
                    {
                      theme: 'colored'
                    }
                  );
                  return;
                }
                openShippingDiscountModal();
              }}
              className="blue-text-btn"
            >
              Add Shipping
            </span>
          </Col>
          <Col xs={4}>
            <span>{shippingCostInfo.title}</span>
          </Col>
          <Col xs={4} className="d-flex justify-content-end">
            <span>{currencyFormat(shippingCostInfo.amount)} ₺</span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4}>
            <span>Estimated Tax</span>
          </Col>
          <Col xs={4}>
            <span>
              {[...new Set(selectedProducts.map(dt => dt.price.kdv))].map(
                (kdv, i, arr) =>
                  arr.length - 1 === i ? `${kdv}%` : `${kdv}%, `
              )}
            </span>
          </Col>
          <Col xs={4} className="d-flex justify-content-end">
            <span>{currencyFormat(KdvTotal)} ₺</span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4}>
            <b>Total</b>
          </Col>
          <Col xs={4}>
            <span></span>
          </Col>
          <Col xs={4} className="d-flex justify-content-end">
            <span>
              {currencyFormat(
                SubTotal +
                  KdvTotal +
                  shippingCostInfo.amount -
                  discountInfo.amount
              )}{' '}
              ₺
            </span>
          </Col>
        </Row>
        {payment === 1 && (
          <div>
            <Divider />
            <Row className="gx-3">
              <Col xs={6}>
                <Row>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label
                      className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                      htmlFor="number"
                    >
                      Card Number
                    </Form.Label>
                    <InputMask
                      mask="9999 9999 9999 9999"
                      maskChar=" "
                      name="number"
                      onChange={handleCreditCardInfo}
                      placeholder="XXXX XXXX XXXX XXXX"
                      onFocus={handleInputFocus}
                    >
                      {inputProps => (
                        <Form.Control {...inputProps} type="text" />
                      )}
                    </InputMask>
                  </Form.Group>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label
                      className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                      htmlFor="name"
                    >
                      Name of Card Owner
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleCreditCardInfo}
                      placeholder="John Doe"
                      maxLength={51}
                      onFocus={handleInputFocus}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={6}>
                    <Form.Label
                      className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                      htmlFor="expiry"
                    >
                      Exp Date
                    </Form.Label>
                    <InputMask
                      mask="99/99"
                      maskChar=" "
                      name="expiry"
                      onChange={handleCreditCardInfo}
                      placeholder="12/28"
                      onFocus={handleInputFocus}
                    >
                      {inputProps => (
                        <Form.Control {...inputProps} type="text" />
                      )}
                    </InputMask>
                  </Form.Group>
                  <Form.Group as={Col} xs={6}>
                    <Form.Label
                      className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                      htmlFor="cvc"
                    >
                      CVC
                    </Form.Label>
                    <Form.Control
                      name="cvc"
                      onChange={handleCreditCardInfo}
                      placeholder="123"
                      maxLength="3"
                      pattern="[0-9]{3}"
                      type="text"
                      onFocus={handleInputFocus}
                    />
                  </Form.Group>
                  <Col xs={12} className="d-flex justify-content-end mt-3">
                    <Button onClick={handleWithCreditCard}>
                      Send Transaction
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Cards
                  number={creditCardInfo.number}
                  expiry={creditCardInfo.expiry}
                  cvc={creditCardInfo.cvc}
                  name={creditCardInfo.name}
                  focused={focus}
                />
              </Col>
            </Row>
          </div>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Button variant="outline-secondary" className="px-3 mx-2">
          Send Invoice
        </Button>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Collect Payment
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setPayment(1)}>
              Enter Credit Card
            </Dropdown.Item>
            <Dropdown.Item onClick={handleMarkAsPaid}>
              Mark as Paid
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Footer>

      {isDiscountModalOpen && (
        <DiscountModal
          show={isDiscountModalOpen}
          setShow={setIsDiscountModalOpen}
          userId={selectedUser.id}
          handleSetDiscount={handleSetDiscount}
        />
      )}

      {isShippingDiscountModalOpen && (
        <ShippingDiscountModal
          show={isShippingDiscountModalOpen}
          setShow={setIsShippingDiscountModalOpen}
          handleSetDiscount={handleShippingDiscount}
        />
      )}
      {smsHtml && (
        <Modal
          show={isSmsModalOpen}
          size={'lg'}
          centered
          onHide={closeSmsModal}
        >
          <Modal.Header>
            <Modal.Title>3D Secure Sms Confirmation</Modal.Title>
            <FalconCloseButton onClick={closeSmsModal} />
          </Modal.Header>
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{
                __html: `${smsHtml}`
              }}
            ></div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeSmsModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Card>
  );
};

PaymentInfo.propTypes = {
  selectedProducts: PropTypes.array,
  selectedUser: PropTypes.object,
  selectedAddress: PropTypes.number
};

export default PaymentInfo;
