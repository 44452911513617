import React from 'react';
import PropTypes from 'prop-types';
import HorizontalDnd from './HorizontalDnd';

function HomepageList({ state, setState, section, children }) {
  return (
    <div className="row mt-3">
      <div className="col-3">{children}</div>
      <div className="col-9">
        <HorizontalDnd
          data={state}
          isDeletable={true}
          setState={val => {
            setState(prev => ({
              ...prev,
              [section]: val
            }));
          }}
        />
      </div>
    </div>
  );
}

export default HomepageList;

HomepageList.propTypes = {
  state: PropTypes.array,
  children: PropTypes.node,
  setState: PropTypes.func,
  section: PropTypes.string
};
