import { LoaderContext } from 'context/Context';
import { useState, useCallback, useContext } from 'react';
import { publicApi } from 'services/axios';

const useAuthentication = params => {
  const { setLoading } = useContext(LoaderContext);
  const { url, payload } = params;
  const [response, setResponse] = useState({
    data: null,
    error: null
  });

  const postRequest = useCallback(() => {
    setLoading(true);
    publicApi
      .post(url, payload)
      .then(res => {
        setResponse({ data: res.data, error: null });
      })
      .catch(err => setResponse({ data: null, error: err.response }))
      .finally(() => setLoading(false));
  }, [url, JSON.stringify(payload)]);

  return [response, postRequest];
};

export default useAuthentication;
