import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import useUpdate from 'hooks/useUpdate';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import { getBlogComments } from 'services/content';
import serviceUrl from 'services/serviceUrlList';
import BlogCommentTableFilter from './module/BlogCommentTableFilter';

const BlogComments = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [isActive, setIsActive] = useState();

  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const { data, isLoading, refetch } = useQuery(
    ['getBlogs', queryObj],
    () => getBlogComments(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.content.contentComment.detail + selectedRowId,
    payload: { is_active: isActive, is_replied: true }
  });

  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.error) return;
    if (updateResponse.error === null) {
      toast.success(`Comment updated`, {
        theme: 'colored'
      });
      refetch();
    } else {
      if (updateResponse.error.data.name) {
        toast.error(`Name field must be unique!`, {
          theme: 'colored'
        });
      }
    }
  }, [updateResponse]);

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2 text-end'
      },
      Cell: dt => {
        const { created_at } = dt.row.original;
        return <>{moment(created_at).format('DD/MM/YYYY')}</>;
      }
    },
    {
      accessor: 'full_name',
      Header: 'Customer Name',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'email',
      Header: 'Customer e-Mail',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'message',
      Header: 'Comment',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'blog.name',
      Header: 'Blog Name',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },

    {
      accessor: 'is_active',
      Header: 'Comment Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_active } = rowData.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success">
              Approved
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger">
            Declined
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'is_replied',
      Header: 'Control Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_replied } = rowData.row.original;
        if (is_replied) {
          return (
            <SoftBadge pill bg="success">
              Done
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="info">
            New
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'updated_at',
      Header: 'Control Date',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2 text-end'
      },
      Cell: dt => {
        const { created_at } = dt.row.original;
        return <>{moment(created_at).format('DD/MM/YYYY')}</>;
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handleUpdate(id, true)}>
                Approve
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleUpdate(id, false)}>
                Decline
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleUpdate = (id, bool) => {
    setSelectedRowId(id);
    if (bool) {
      setIsActive(true);
      setConfirmText({
        title: 'Comment Update',
        description: 'Are you sure? Selected comment will be approved!'
      });
    } else {
      setIsActive(false);
      setConfirmText({
        title: 'Comment Update',
        description: 'Are you sure? Selected comment will be declined!'
      });
    }
    setShowConfirm(true);
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Blog Comments"
                handleRefetch={refetch}
                table
                isBulkAction={false}
              />
              <BlogCommentTableFilter
                queryObj={queryObj}
                setQueryObj={setQueryObj}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={updateRequest}
        type="update"
      />
    </div>
  );
};

export default BlogComments;
