import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

function CustomerQuestions({ data }) {
  if (!data) return null;
  return (
    <Card className="mb-3">
      <Card.Header>
        <h5>Questions</h5>
      </Card.Header>
      <Card.Body>
        <h6>Product</h6>
        {data.customer_product_questions ? (
          data.customer_product_questions?.map(dt => (
            <div key={dt.id} className="row">
              <div className="col-9">{dt.question}</div>
              <div className="col-3">
                {dt.is_replied ? 'Replied' : 'Unreplied'}
              </div>
            </div>
          ))
        ) : (
          <div>Customer has 0 product questions</div>
        )}
        <h6 className="mt-3">Specialist</h6>
        {data.customer_specialist_questions ? (
          data.customer_specialist_questions?.map(dt => (
            <div key={dt.id} className="row">
              <div className="col-9">{dt.question}</div>
              <div className="col-3">
                {dt.is_replied ? 'Replied' : 'Unreplied'}
              </div>
            </div>
          ))
        ) : (
          <div>Customer has 0 specialist questions</div>
        )}
      </Card.Body>
    </Card>
  );
}

export default CustomerQuestions;
CustomerQuestions.propTypes = {
  data: PropTypes.object
};
