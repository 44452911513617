import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import MultipleImageUpload from 'components/common/MultipleImageUpload';
import SelectBox from 'components/common/SelectBox';
import SeoSetting from 'components/common/SeoSetting';
import TextEditor from 'components/common/TextEditor';
import AutocompleteTag from 'components/input/AutocompleteTag';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import AppContext, { AuthContext, LoaderContext } from 'context/Context';
import { IMAGE_SIZES } from 'helpers/image';
import { isEmpty } from 'helpers/utils';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Table
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { createTagFromProduct } from 'services/content';
import {
  getProductCollections,
  getProductTags,
  getTaxonomy
} from 'services/product';
import serviceUrl from '../../../services/serviceUrlList';
import { createCollection, updateCollection } from '../api';

const keyOptions = [
  { value: 'name', label: 'Product Name' },
  { value: 'prices', label: 'Product Price' },
  { value: 'tags', label: 'Product Tag' },
  { value: 'collections', label: 'Product Collection' },
  { value: 'stock_quantity', label: 'Inventory Stock' }
];
const typeOptionsAll = [
  { value: 'iexact', label: 'is equal to', not: false },
  { value: 'iexact', label: 'is not equal to', not: true },
  { value: 'istartswith', label: 'starts with', not: false },
  { value: 'iendswith', label: 'ends with', not: false },
  { value: 'icontains', label: 'contains', not: false },
  { value: 'icontains', label: 'does not contain', not: true },
  { value: 'gt', label: 'is greater than', not: false },
  { value: 'lt', label: 'is less than', not: false }
];
const typeOptions1 = [
  { value: 'iexact', label: 'is equal to' },
  { value: 'iexact', label: 'is not equal to', not: true },
  { value: 'istartswith', label: 'starts with' },
  { value: 'iendswith', label: 'ends with' },
  { value: 'icontains', label: 'contains' },
  { value: 'icontains', label: 'does not contain', not: true }
];
const typeOptions2 = [
  { value: 'iexact', label: 'is equal to' },
  { value: 'iexact', label: 'is not equal to', not: true }
];
const typeOptions3 = [
  { value: 'iexact', label: 'is equal to' },
  { value: 'gt', label: 'is greater than' },
  { value: 'lt', label: 'is less than' }
];
const typeOptions4 = [
  { value: 'iexact', label: 'is equal to' },
  { value: 'iexact', label: 'is not equal to', not: true },
  { value: 'gt', label: 'is greater than' },
  { value: 'lt', label: 'is less than' }
];

const AddCollection = () => {
  const formData = new FormData();
  const [selectedTags, setSelectedTags] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);

  const [conditionKey, setConditionKey] = useState(keyOptions[0]);
  const [conditionType, setConditionType] = useState({});
  const [conditionValue, setConditionValue] = useState({});
  const [conditions, setConditions] = useState([]);
  const [typeOptions, setTypeOptions] = useState(typeOptions1);
  const [isValueSelect, setIsValueSelect] = useState(false);
  const [valueOptions, setValueOptions] = useState([]);
  const [taxonomyOptions, setTaxonomyOptions] = useState([]);
  const { setLoading } = useContext(LoaderContext);

  const [searchTermTag, setSearchTermTag] = useState('');
  const [searchTermTaxonomy, setSearchTermTaxonomy] = useState('');
  const [searchTermCollection, setSearchTermCollection] = useState('');

  const [error, setError] = useState({});

  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [seoSetting, setSeoSetting] = useState({});
  const [isManual, setIsManual] = useState(true);

  const [removedFiles, setRemovedFiles] = useState([]);
  const [defaultImg, setDefaultImg] = useState([]);
  const [deviceType, setDeviceType] = useState({});

  const deviceOptions = [
    { value: 'desktop', label: 'Desktop' },
    { value: 'mobile', label: 'Mobile' }
  ];
  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      // display: 'flex',
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset'
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  const { id } = useParams();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      is_active: true,
      description: '',
      footer_description: '',
      seo_setting: {},
      is_manual: true,
      is_all_condition: 'true',
      conditions: []
    }
  });
  const { user } = useContext(AuthContext);

  const { response } = id
    ? useFetch({
        url: serviceUrl.product.collection.detail + id
      })
    : {};
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.collection.detail + id
  });

  const { data: tagResponse } = useQuery(
    ['productTags', searchTermTag],
    () => getProductTags({ limit: 10, name__istartswith: searchTermTag }),
    { staleTime: Infinity, keepPreviousData: true }
  );

  const { data: taxonomyResponse } = useQuery(
    ['productTaxonomy', searchTermTaxonomy],
    () =>
      getTaxonomy({ limit: 50, description__istartswith: searchTermTaxonomy }),
    { staleTime: Infinity, keepPreviousData: true }
  );

  const { data: collectionResponse } = useQuery(
    ['productCollections', searchTermCollection],
    () => getProductCollections({ limit: 10, search: searchTermCollection }),
    { staleTime: Infinity, keepPreviousData: true }
  );

  const tagOptions = tagResponse?.map(tag => ({
    label: tag.name,
    value: tag.name
  }));

  useEffect(() => {
    if (!taxonomyResponse) return;
    setTaxonomyOptions(
      taxonomyResponse?.map(taxonomy => ({
        label: taxonomy.description,
        value: taxonomy.id
      }))
    );
  }, [taxonomyResponse]);

  const collectionOptions = collectionResponse?.data.results?.map(
    collection => ({
      label: collection.name,
      value: collection.id
    })
  );

  useEffect(() => {
    if (!collectionResponse) return;
    setValueOptions(
      collectionResponse?.data.results?.map(collection => ({
        label: collection.name,
        value: collection.name
      }))
    );
  }, [collectionResponse]);

  useEffect(() => {
    if (!tagResponse) return;
    setValueOptions(
      tagResponse?.map(tag => ({
        label: tag.name,
        value: tag.name
      }))
    );
  }, [tagResponse]);

  // seo setting start
  const handleImgFile = imageObjs => {
    if (!deviceType.value) return;
    imageObjs.map(imageObj => {
      setValue(`image_${imageObj.id}`, imageObj.file);
    });
  };

  const handleSeoValue = seoValue => {
    setValue('seo_setting', JSON.stringify(seoValue));
  };
  // seo setting end

  useEffect(() => {
    if (id) {
      setValue('updated_by', user.id);
    } else {
      setValue('created_by', user.id);
    }
  }, []);

  const onSubmit = async values => {
    setLoading(true);
    let data = {
      ...values,
      ...removedFiles.reduce((acc, file) => {
        acc[file] = null;
        return acc;
      }, {})
    };
    if (!isManual && conditions.length === 0) {
      toast.error('You have to enter condition', { theme: 'colored' });
      setLoading(false);
      return;
    }

    if (conditions.length > 0) {
      const temp = conditions.map(condition => {
        const key = condition.key.value ?? condition.key;
        const type = condition.type.value ?? condition.type;
        const not = condition.not.toString();
        const value = condition.value.value ?? condition.value;
        return {
          key: key,
          type:
            typeOptionsAll.filter(tp => tp.label === type)[0]?.value ?? type,
          value: value,
          not: not
        };
      });
      data.conditions = temp;
    }
    data.conditions = JSON.stringify(data.conditions);
    // if (!data.image) data.image = '';

    const imageKeys = Object.keys(data).filter(
      key => key.startsWith('image') && key !== 'image_alt_text'
    );

    data.google_taxonomy = data.google_taxonomy?.value ?? data.google_taxonomy;
    if (!data.google_taxonomy) delete data.google_taxonomy;

    Object.keys(data).map(key => {
      if (!imageKeys.includes(key)) {
        formData.set(key, data[key]);
      }
      if (data[key] === null && imageKeys.includes(key)) {
        formData.append(key, new Blob(), '');
      }
      if (
        imageKeys.includes(key) &&
        typeof data[key] !== 'string' &&
        data[key] !== null
      ) {
        formData.set(key, data[key]);
      }
    });
    if (id) {
      const response = await updateCollection(formData, id);
      if (!response.data) {
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        toast.success('Collection successfully updated', { theme: 'colored' });
        // to sync tag names for content and product services
        createTagFromProduct({
          tag_names: selectedTags,
          updated_by: user.id
        });
        handleRouteList();
      } else if (response.status === 400 && response.data.name) {
        toast.error(`Name: ${response.data.name[0]}`, {
          theme: 'colored'
        });
      } else {
        toast.error('Something went wrong! Collection cannot updated.', {
          theme: 'colored'
        });
      }
    } else {
      const response = await createCollection(formData);
      if (!response.data) {
        setLoading(false);
        return;
      }
      if (response.status === 201) {
        toast.success('Collection successfully created', { theme: 'colored' });
        // to sync tag names for content and product services
        createTagFromProduct({
          tag_names: selectedTags,
          created_by: user.id
        });
        handleRouteList();
      } else if (response.status === 400 && response.data.name) {
        toast.error(`Name: ${response.data.name[0]}`, {
          theme: 'colored'
        });
      } else {
        toast.error('Something went wrong! Collection cannot created.', {
          theme: 'colored'
        });
      }
    }
    setLoading(false);
  };

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Collection',
      description: 'Are you sure? Selected collection will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    toast.success(`Collection deleted`, {
      theme: 'colored'
    });
    handleRouteList();
  }, [deleteResponse]);

  const handleRouteList = () => {
    navigate('/product/product-collection');
  };

  useEffect(() => {
    if (!response) return;
    const data = response.data;
    setValue('name', data.name);
    setValue('description', data.description);
    setValue('image_alt_text', data.image_alt_text);
    setValue('footer_description', data.footer_description);
    setValue('is_active', data.is_active);
    setValue('seo_setting', data.seo_setting);
    setValue('is_all_condition', (!!data.is_all_condition)?.toString());
    setValue('is_manual', data.is_manual?.toString());

    setValue(
      'google_taxonomy',
      data.google_taxonomy
        ? {
            label: data.google_taxonomy?.description,
            value: data.google_taxonomy?.id
          }
        : undefined
    );
    setIsManual(data.is_manual);
    if (data.conditions && data.conditions.length > 0) {
      setConditions(
        data.conditions.map(condition => ({
          key: condition.key,
          label: keyOptions.filter(e => e.value === condition.key)[0]?.label,
          value: condition.value,
          type: typeOptionsAll.filter(
            e =>
              e.value === condition.type && condition.not === e.not?.toString()
          )[0]?.label,
          not: condition.not
        }))
      );
    }
    setSelectedTags(data.tags.map(dt => dt.name));

    let temp = deviceOptions
      .map(device => {
        const imageKey = `image_${device.value}`;
        if (data[imageKey] !== null) {
          setValue(imageKey, data[imageKey]);
          return {
            image: data[imageKey],
            label: `${device.label}`,
            key: `image_${device.value}`
          };
        } else {
          return null;
        }
      })
      .filter(item => item !== null);

    setDefaultImg(temp);

    // setDefaultImg({
    //   path: response.data.image,
    //   alt: response.data.image_alt_text
    // });
    setSeoSetting(response.data.seo_setting);
  }, [response]);

  useEffect(() => {
    setValue('tag_names', selectedTags);
  }, [selectedTags]);

  // automated functions start
  const handleChangeKey = option => {
    setConditionKey(option);
    setConditionType('');
    setConditionValue('');

    switch (option.value) {
      case 'name':
        setTypeOptions(typeOptions1);
        setIsValueSelect(false);
        break;
      case 'prices':
        setTypeOptions(typeOptions4);
        setIsValueSelect(false);
        break;
      case 'tags':
        setTypeOptions(typeOptions2);
        setIsValueSelect(true);
        setValueOptions(tagOptions);
        break;
      case 'collections':
        setTypeOptions(typeOptionsAll.slice(0, 1));
        setIsValueSelect(true);
        setValueOptions(collectionOptions);
        break;
      case 'stock_quantity':
        setTypeOptions(typeOptions3);
        setIsValueSelect(false);
        break;
    }
  };
  const handleChangeType = option => {
    setConditionType(option);
    setConditionValue('');
  };

  const handleChangeValue = option => {
    setConditionValue(option);
  };

  const handleAdd = () => {
    // to provide key, value, type not empty but when compare-at price selected, value will be empty
    if (!conditionKey?.value || !conditionType?.value || !conditionValue) {
      toast.error('Select required fields...', { theme: 'colored' });
      return;
    }

    if (conditionKey.value !== 'tags' && conditionKey.value !== 'collections') {
      if (conditionValue === '') {
        setError({ value: { type: 'required' } });
        return;
      } else if (conditionKey.value !== 'name' && isNaN(conditionValue)) {
        setError({ value: { type: 'number' } });
        return;
      } else {
        setError({});
      }
    }

    setConditions([
      ...conditions,
      {
        key: conditionKey,
        value: conditionValue,
        type: conditionType,
        not: typeOptionsAll.filter(t => t.label === conditionType.label)[0].not
          ? 'true'
          : 'false'
      }
    ]);
    setConditionKey(keyOptions[0]);
    setConditionType('');
    setConditionValue('');
  };

  const handleRemove = key => {
    setConditions(
      conditions.filter(condition => conditions[key] !== condition)
    );
  };

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'type',
      Header: 'Type',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'value',
      Header: 'Value',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'none',
      Header: ''
    }
  ];

  const metaRows = conditions.map((condition, i) => {
    return (
      <tr key={i}>
        <td>{condition.key?.label ?? condition.label}</td>
        <td>{condition.type?.label ?? condition.type}</td>
        <td>{condition.value?.label ?? condition.value}</td>
        <td className="text-end">
          <IconButton
            onClick={() => handleRemove(i)}
            variant="danger"
            size="sm"
            icon="xmark"
            transform="shrink-3"
          ></IconButton>
        </td>
      </tr>
    );
  });

  const handleImageClick = e => {
    if (isEmpty(deviceType)) {
      e.preventDefault();
      toast.error('Please select device type!', {
        theme: 'colored'
      });
    } else if (getValues(`image_${deviceType.value}`)) {
      e.preventDefault();
      toast.error('An image already selected for this field!', {
        theme: 'colored'
      });
    }
  };

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {id ? 'Update Collection' : 'Create Collection'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -10
              }}
            >
              <Form.Select
                {...register('is_active', { required: true })}
                size="sm"
                className="mb-3"
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                {...register('name', { required: true })}
                isInvalid={errors.name}
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <TextEditor
                name="description"
                control={control}
                maxLength={5000}
                tabIndex={1} // tabIndex of textarea
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="footer_description">
              <Form.Label>Footer Description</Form.Label>
              <TextEditor
                name="footer_description"
                control={control}
                maxLength={10000}
                tabIndex={1} // tabIndex of textarea
              />
            </Form.Group>
            <Form.Group controlId="imageUpload" className="mb-3">
              <Form.Label>Upload Image</Form.Label>
              <MultipleImageUpload
                file={handleImgFile}
                defaultImg={defaultImg}
                onClick={handleImageClick}
                device={deviceType}
                altText={getValues('image_alt_text')}
                setRemovedFiles={setRemovedFiles}
                imageSize={
                  deviceType?.value
                    ? IMAGE_SIZES['collection'][deviceType.value]
                    : []
                }
              >
                <Flex>
                  <Select
                    className="me-2"
                    options={deviceOptions}
                    styles={selectStyles}
                    placeholder="Device Type"
                    onChange={val => setDeviceType(val)}
                  />
                </Flex>
              </MultipleImageUpload>
              <Form.Control
                {...register('image_alt_text')}
                type="text"
                size="sm"
                className="w-25 mt-2"
                placeholder="Alt Text"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="tag">
              <Form.Label>Tags</Form.Label>
              <AutocompleteTag
                placeholder="Select your tags"
                selected={selectedTags}
                onChange={setSelectedTags}
                service="product"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="google_taxonomy">
              <Form.Label>Google Category</Form.Label>
              <SelectBox
                isClearable
                options={taxonomyOptions}
                onChange={value => setValue('google_taxonomy', value)}
                name="google_taxonomy"
                control={control}
                placeholder="Select Google Category"
                classNamePrefix="react-select"
                onInputChange={value => setSearchTermTaxonomy(value)}
              />
            </Form.Group>
            <Divider />
            <Form.Check
              type="radio"
              id="manual"
              className="d-flex align-items-center"
            >
              <Form.Check.Input
                type="radio"
                value={true}
                defaultChecked
                {...register('is_manual')}
                onChange={e => setIsManual(e.target.value)}
              />
              <div>
                <Form.Check.Label className="mb-0 ms-2">
                  Manuel
                </Form.Check.Label>
                <div className="form-text ms-2 mt-0">
                  Add products to this collection one by one.
                </div>
              </div>
            </Form.Check>
            <Form.Check
              type="radio"
              id="automated"
              className="d-flex align-items-center"
            >
              <Form.Check.Input
                type="radio"
                value={false}
                {...register('is_manual')}
                onChange={e => {
                  setIsManual(e.target.value);
                }}
              />
              <div>
                <Form.Check.Label className="mb-0 ms-2">
                  Automated
                </Form.Check.Label>
                <div className="form-text ms-2 mt-0">
                  Existing and future products that match the conditions you set
                  will automatically be added to this collection.
                </div>
              </div>
            </Form.Check>
            {(isManual === false || isManual === 'false') && (
              <>
                <Form.Group className="mt-3">
                  <h6>Conditions</h6>
                  <div className="d-flex gap-3">
                    <span className="fs--1">Products must match:</span>
                    <Form.Check
                      type="radio"
                      id="allConditions"
                      className="d-flex"
                    >
                      <Form.Check.Input
                        {...register('is_all_condition')}
                        type="radio"
                        value={true}
                      />
                      <Form.Check.Label className="mb-0 ms-2">
                        all conditions
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check
                      type="radio"
                      id="anyConditions"
                      className="d-flex"
                    >
                      <Form.Check.Input
                        {...register('is_all_condition')}
                        type="radio"
                        value={false}
                      />
                      <Form.Check.Label className="mb-0 ms-2">
                        any conditions
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="conditionKey">
                  <Col sm={3} className="mb-2">
                    <Select
                      options={keyOptions}
                      classNamePrefix="react-select"
                      onChange={handleChangeKey}
                      value={conditionKey}
                    />
                  </Col>
                  <Col sm={3} className="mb-2">
                    <Select
                      key={`condition_type__${conditionKey.value}`}
                      options={typeOptions}
                      placeholder="Type"
                      classNamePrefix="react-select"
                      onChange={handleChangeType}
                      value={conditionType}
                    />
                  </Col>

                  <Col sm={3}>
                    {isValueSelect ? (
                      <Select
                        key={`condition_value__${conditionKey.value}${conditionType.value}`}
                        options={valueOptions}
                        classNamePrefix="react-select"
                        placeholder="Value"
                        onChange={handleChangeValue}
                        value={conditionValue}
                        onInputChange={value =>
                          conditionKey.value === 'tags'
                            ? setSearchTermTag(value)
                            : setSearchTermCollection(value)
                        }
                      />
                    ) : (
                      <InputGroup>
                        {conditionKey.value === 'prices' && (
                          <InputGroup.Text>₺</InputGroup.Text>
                        )}
                        <Form.Control
                          key={`condition_value__${conditionKey.value}${conditionType.value}`}
                          name="value"
                          placeholder="Value"
                          isInvalid={!!error.value}
                          type={conditionKey.value !== 'name' ? 'number' : ''}
                          onChange={e => {
                            setConditionValue(e.target.value);
                            if (
                              conditionKey.value !== 'name' &&
                              isNaN(e.target.value)
                            ) {
                              setError({ value: { type: 'number' } });
                              return;
                            } else {
                              setError({});
                            }
                          }}
                          value={conditionValue.label}
                        />

                        <Form.Control.Feedback type="invalid">
                          {error.value && error.value.type === 'required' && (
                            <>
                              Enter some text for {conditionKey.label}{' '}
                              {conditionType.label}.
                            </>
                          )}
                          {error.value && error.value.type === 'number' && (
                            <>
                              Enter numbers only for {conditionKey.label}{' '}
                              {conditionType.label}.
                            </>
                          )}
                        </Form.Control.Feedback>
                      </InputGroup>
                    )}
                  </Col>

                  <Col sm={2}>
                    <IconButton
                      onClick={handleAdd}
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                    >
                      <span className="d-none d-sm-inline-block ms-1">Add</span>
                    </IconButton>
                  </Col>
                  <Col xs={12}>
                    {Object.keys(conditions).length > 0 && (
                      <Table striped hover size="sm" className="border">
                        <thead className={isDark ? 'bg-soft-dark' : 'bg-body'}>
                          <tr>
                            {columns.map(col => {
                              return (
                                <th key={col.accessor} {...col.headerProps}>
                                  {col.Header}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>{metaRows}</tbody>
                      </Table>
                    )}
                  </Col>
                </Form.Group>
              </>
            )}
            <Divider />
            <SeoSetting
              seoValues={handleSeoValue}
              defaultValue={seoSetting}
              register={register}
              errors={errors}
            />
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </Fragment>
  );
};

export default AddCollection;
