import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { getDiscountsForOrder } from 'services/order';
import { useQuery } from 'react-query';

const DiscountTypes = {
  // 1: 'Amount off products',
  2: 'Amount off order',
  // 3: 'Buy X get Y',
  4: 'Free Shipping'
};

function DiscountModal({ show, setShow, userId, handleSetDiscount }) {
  const { data: discounts } = useQuery(
    ['discounts-for-order'],
    () => getDiscountsForOrder(userId),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const DiscountList =
    discounts &&
    Object.keys(discounts).map(dt => {
      if (dt === '4') return;
      return (
        <Col xs={6} key={dt}>
          <h5>{DiscountTypes[dt]}</h5>
          <ul>
            {discounts[dt].map(discount => {
              return (
                <li
                  className="blue-text-btn"
                  onClick={() => handleSetDiscount(discount)}
                  key={discount.id}
                >
                  {discount.title}
                </li>
              );
            })}
          </ul>
        </Col>
      );
    });

  return (
    <Modal show={show} size={'lg'} centered onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>Discount</Modal.Title>
        <FalconCloseButton onClick={() => setShow(false)} />
      </Modal.Header>
      <Modal.Body>
        <Row>{DiscountList}</Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
DiscountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  handleSetDiscount: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired
};

export default DiscountModal;
