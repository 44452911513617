import React, { useEffect, useReducer, useState } from 'react';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Dropdown } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useQuery } from 'react-query';
import { getTransactions101 } from 'services/warehouse';
import { queryReducer } from 'reducers/queryReducer';
import moment from 'moment';
import CardDropdown from 'components/common/CardDropdown';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LotList = props => {
  const { newClickCount } = props;
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const { isLoading, data } = useQuery(
    ['lotlist', queryObj, sort],
    () =>
      getTransactions101({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: true
    }
  );
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);

  const navigate = useNavigate();

  const columns = [
    {
      accessor: 'lotNumber',
      Header: 'Lot Number',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      disableSortBy: true,
      Cell: dt => {
        const { lotNumber } = dt.row.original;
        return (
          <Link to={`/stock/inventory-update/${lotNumber}`}>{lotNumber}</Link>
        );
      }
    },
    {
      accessor: 'account.name',
      Header: 'Vendor',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true
    },
    {
      accessor: 'date',
      Header: 'Date',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { date } = rowData.row.original;
        return moment(date).format('DD/MM/YYYY');
      }
    },
    {
      accessor: 'storageLocation.name',
      Header: 'Warehouse',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true
    },
    {
      accessor: 'docType',
      Header: 'Document Type',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { waybill } = rowData.row.original;
        return <>{waybill !== '' ? 'Waybill' : 'Invoice'}</>;
      },
      disableSortBy: true
    },
    {
      accessor: 'docNumber',
      Header: 'Document Number',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { waybill, invoices } = rowData.row.original;
        return <>{waybill !== '' ? waybill : invoices}</>;
      },
      disableSortBy: true
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: () => {
        // const { original } = rowData.row;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <>
                <Dropdown.Item>Edit</Dropdown.Item>
                <Dropdown.Item>Print Qrcode</Dropdown.Item>
                <Dropdown.Item>Delete</Dropdown.Item>
              </>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  useEffect(() => {
    if (newClickCount === 0) return;
    handleNew();
  }, [newClickCount]);

  const handleNew = () => {
    navigate('/stock/inventory-add');
  };

  return (
    <div className="p-3">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          sortable
          pagination
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              rowClassName="align-middle"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0'
              }}
              sort={sort}
              setSort={setSort}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>
    </div>
  );
};
LotList.propTypes = {
  newClickCount: PropTypes.number
};

export default LotList;
