import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';

export const AdvanceTablePagination = ({
  pageCount,
  gotoPage,
  initialPage = 0,
  urlPagination = false
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
    if (urlPagination) {
      setSearchParams(prev => {
        const search = new URLSearchParams(prev);
        search.set('limit', 10);
        search.set('offset', selected * 10);
        return search.toString();
      });
    }
    gotoPage(selected);
  };

  useEffect(() => {
    setCurrentPage(searchParams.get('offset') / 10);
  }, [searchParams]);

  return (
    <div className="d-flex justify-content-center">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={onPageChange}
        pageRangeDisplayed={10}
        pageCount={pageCount <= 0 ? initialPage + 1 : pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        containerClassName={'pagination'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        forcePage={currentPage}
      />
    </div>
  );
};

AdvanceTablePagination.propTypes = {
  pageCount: PropTypes.number,
  initialPage: PropTypes.number,
  gotoPage: PropTypes.func,
  urlPagination: PropTypes.bool
};

export default AdvanceTablePagination;
