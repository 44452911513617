import { protectedApi } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const createGiftCartTheme = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.giftcardtheme.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateGiftCartTheme = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.giftcardtheme.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
