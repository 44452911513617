import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ChangePassword from './ChangePassword';
import LoginSession from './LoginSession';
import ProfileForm from './ProfileForm';

const Profile = () => {
  return (
    <Row>
      <Col xs={12} lg={8}>
        <ProfileForm />
        <LoginSession />
      </Col>
      <Col xs={12} lg={4}>
        <ChangePassword />
      </Col>
    </Row>
  );
};

export default Profile;
