import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

function LoadingCard({
  cardClassName = 'mb-3',
  bodyClassName = '',
  bodyStyle = {}
}) {
  return (
    <Card className={cardClassName}>
      <Card.Body className={bodyClassName} style={bodyStyle}></Card.Body>
    </Card>
  );
}

export default LoadingCard;

LoadingCard.propTypes = {
  cardClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  bodyStyle: PropTypes.object
};
