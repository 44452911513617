// This function will return image width and height
export const getDimensions = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      const image = new Image();

      image.onload = () => {
        const width = image.width;
        const height = image.height;

        resolve({ width, height });
      };

      image.src = event.target.result;
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const checkIfImage = file => {
  if (!file.type.startsWith('image/')) {
    return false;
  }
  return true;
};

export const IMAGE_MIN_WIDTH = 434;
export const MOBILE_IMAGE_MIN_WIDTH = 230;

export const IMAGE_MIN = [434, 434]; // width - height
export const MOBILE_IMAGE_MIN = [230, 230];

export const IMAGE_SIZES = {
  brand: {
    product_detail: {
      desktop: [200, 67],
      mobile: [200, 67]
    },
    product_list: {
      desktop: [922, 230],
      mobile: [345, 160]
    }
  },
  collection: {
    desktop: [922, 230],
    mobile: [345, 160]
  },
  blog: {
    detail: {
      desktop: [609, 507],
      mobile: [345, 288]
    },
    listing: {
      desktop: [295, 246],
      mobile: [97, 81]
    },
    highlight: {
      desktop: [445, 369],
      mobile: [345, 286]
    }
  },
  home: {
    main: {
      desktop: [822, 468],
      mobile: [345, 395]
    },
    side: {
      desktop: [400, 225],
      mobile: [345, 225]
    }
  }
};
