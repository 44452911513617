import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

function CustomerSpent({ data }) {
  if (!data) return null;

  if (!data.total_order_count) {
    return (
      <Card className="mb-3">
        <Card.Body>
          <div>Customers has 0 order</div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div className="mx-auto text-center">
            <h5>Amount Spent</h5>
            <h4>{data.total_order_amount?.toFixed(2)} TL</h4>
          </div>
          <hr className="vertical-line mx-auto" />
          <div className="mx-auto text-center">
            <h5>Orders</h5>
            <h4>{data.total_order_count}</h4>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CustomerSpent;

CustomerSpent.propTypes = {
  data: PropTypes.object
};
