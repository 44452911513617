import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import Flex from 'components/common/Flex';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';

const BlogCommentTableFilter = ({ queryObj, setQueryObj }) => {
  const commentStatus = [
    { value: 'true', label: 'Approved' },
    { value: 'false', label: 'Declined' }
  ];

  const repliedStOptions = [
    { value: 'true', label: 'Done' },
    { value: 'false', label: 'New' }
  ];
  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      // display: 'flex',
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset'
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  const handleRangeChange = range => {
    if (!range) {
      const temp = { ...queryObj };
      delete temp.created_at__gte;
      delete temp.created_at__lte;
      setQueryObj(temp);
    } else {
      setQueryObj({
        ...queryObj,
        created_at__gte: moment(range[0]).format(),
        created_at__lte: moment(range[1]).format()
      });
    }
  };

  return (
    <Row className="flex-between-center mt-2">
      <Col xs="auto" sm={6} lg={3}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter Comments"
          setGlobalFilter={val => setQueryObj({ ...queryObj, search: val })}
        />
      </Col>
      <Col xs="auto" lg={9}>
        <Flex justifyContent={'start'}>
          <Col>
            <DateRangePicker
              onChange={handleRangeChange}
              value={[queryObj?.created_at__gte, queryObj?.created_at__lte]}
              format="dd/MM/yyyy"
            />
          </Col>
          <Col>
            <Select
              className="me-2"
              options={commentStatus}
              placeholder="Comment Status"
              styles={selectStyles}
              onChange={val =>
                setQueryObj({ ...queryObj, is_active: val?.value })
              }
              isClearable
            />
          </Col>

          <Col>
            <Select
              className="me-2"
              options={repliedStOptions}
              placeholder="Control Status"
              styles={selectStyles}
              onChange={val =>
                setQueryObj({ ...queryObj, is_replied: val?.value })
              }
              isClearable
            />
          </Col>
        </Flex>
      </Col>
    </Row>
  );
};

export default BlogCommentTableFilter;

BlogCommentTableFilter.propTypes = {
  queryObj: PropTypes.object,
  setQueryObj: PropTypes.func
};
