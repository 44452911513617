import jsPDF from 'jspdf';
import { setFontOpenSans } from 'pages/stock/counting/module/opensans';
import html2canvas from 'html2canvas';

export const printQrCode = async (ids, count = 1) => {
  setFontOpenSans();
  const height = 20 * (ids.length + parseInt(count) - 1);
  let y = 2;
  const doc = new jsPDF('p', 'mm', [20, height + 1]);
  await Promise.all(
    ids.map(async id => {
      const content = document.getElementById(id);

      await html2canvas(content).then(canvas => {
        const dataUrl = canvas.toDataURL();
        for (let index = 0; index < count; index++) {
          doc.addImage(dataUrl, 'PNG', 2, y, 16, 16);
          y += 20;
          doc.setFontSize(5);
          doc.text('Fit1001', 2, y - 2);
          doc.text(id.toString(), 18, y - 2, {
            align: 'right'
          });
        }
      });
    })
  );

  doc.autoPrint();
  doc.output('dataurlnewwindow');
};
