import IconButton from 'components/common/IconButton';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getAnomymousView, getCustomerView } from 'services/user';
import CustomerAddress from './module/CustomerAddress';
import CustomerFavorites from './module/CustomerFavorites';
import CustomerInfo from './module/CustomerInfo';
import CustomerInterests from './module/CustomerInterests';
import CustomerLastOrder from './module/CustomerLastOrder';
import CustomerPaymentMethods from './module/CustomerPaymentMethods';
import CustomerQuestions from './module/CustomerQuestions';
import CustomerSpent from './module/CustomerSpent';

function CustomerDetail() {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { data: customerData, isLoading: customerLoading } = useQuery(
    [`customer-detail-${id}`],
    () => getCustomerView({ customer_id: id }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const { data: anonymousData, isLoading: anonymousLoading } = useQuery(
    [`customer-anonymous-${id}`],
    () => getAnomymousView(id),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (searchParams.has('anonymous')) {
      if (!anonymousData) return;
      console.log('if anonymous');
      setData(anonymousData[0]);
      setIsLoading(anonymousLoading);
    } else {
      if (!customerData) return;
      console.log('else anonymous');
      setData(customerData);
      setIsLoading(customerLoading);
    }
  }, [anonymousData, customerData]);

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="p-2">
        <Card className="mb-3">
          <Card.Header className="d-flex justify-content-between align-item-center">
            <span>Customer</span>
            <IconButton
              className="border-300 me-2"
              iconClassName="me-1"
              variant="outline-secondary"
              size="sm"
              icon="chevron-left"
              transform="shrink-4"
              as={Link}
              to="/customers"
            >
              Go Back
            </IconButton>
          </Card.Header>
          {/* <Card.Body></Card.Body>
          <Card.Footer className="d-flex justify-content-end"></Card.Footer> */}
        </Card>
        <div className="row">
          <div className="col-6">
            <CustomerInfo data={data} />
            <CustomerSpent data={data} />
            <CustomerLastOrder data={data} />
            <CustomerQuestions data={data} />
          </div>
          <div className="col-6">
            <Card className="mb-3">
              <Card.Body style={{ minHeight: 626 }}>
                <CustomerAddress data={data} />
                <hr />
                <CustomerPaymentMethods data={data} />
                <hr />
                <CustomerInterests data={data} />
                <hr />
                <CustomerFavorites data={data} />
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
}

export default CustomerDetail;
