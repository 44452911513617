import IconButton from 'components/common/IconButton';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import usePost from 'hooks/usePost';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import serviceUrl from '../../services/serviceUrlList.json';
import CardDropdown from './CardDropdown';

const TableHeader = props => {
  const {
    title,
    selectedRowIds,
    ids,
    handleRefetch,
    handleNew,
    newLabel = 'New',
    isBulkAction = true,
    bulkTable,
    bulkService = 'product',
    bulkDelete = true,
    bulkArchive = true,
    bulkActive = true,
    bulkExtra = [],
    isCustomNew = false,
    handleExport,
    handleImport,
    customNew = [],
    actionBtn = [],
    leading,
    isBulkButton = true
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [action, setAction] = useState(undefined);
  const [payload, setPayload] = useState(undefined);
  const [postResponse, postRequest] = usePost({
    url: serviceUrl[bulkService].bulk_actions,
    payload: payload
  });

  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    toast.success(`Bulk action completed`, {
      theme: 'colored'
    });
    handleRefetch();
  }, [postResponse]);

  const handleSetAction = e => {
    setAction(e.target.value);
  };

  useEffect(() => {
    if (!action) return;
    if (!isBulkButton) {
      submitBulkAction();
    }
  }, [action]);

  const submitBulkAction = () => {
    if (action === undefined) {
      toast.error(`Select action for bulk action!`, {
        theme: 'colored'
      });
      return;
    }
    const payload_ = {
      table_name: bulkTable,
      id_list: ids
    };
    if (action === 'delete') {
      payload_.is_deleted = true;
      setConfirmText({
        title: 'Bulk Delete action',
        description: 'Are you sure? Selected row/s will be deleted!',
        type: 'delete'
      });
    } else if (action === 'archive') {
      payload_.is_active = false;
      setConfirmText({
        title: 'Bulk status action',
        description: 'Are you sure? Selected row/s will be archieved!',
        type: 'update'
      });
    } else if (action === 'activate') {
      payload_.is_active = true;
      setConfirmText({
        title: 'Bulk status action',
        description: 'Are you sure? Selected row/s will be activated!',
        type: 'update'
      });
    } else {
      bulkExtra.map(el => {
        if (action === el.value) {
          setConfirmText({
            title: 'Bulk action',
            description: el.label,
            type: el.type ?? 'update'
          });
          el.callBack(ids);
          return;
        }
      });
      return;
    }
    setShowConfirm(true);
    setPayload({ ...payload_ });
  };
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        {leading && (
          <IconButton
            onClick={leading.callBack}
            variant="default"
            size="sm"
            icon={leading.icon}
            transform="shrink-3"
            className="me-2 text-600 btn-link"
          ></IconButton>
        )}
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{title}</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {isBulkAction && Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              onChange={handleSetAction}
              aria-label="Bulk actions"
            >
              <option>Bulk Actions</option>
              {bulkDelete && <option value="delete">Delete</option>}
              {bulkArchive && <option value="archive">Archive</option>}
              {bulkActive && <option value="activate">Activate</option>}
              {bulkExtra.map(el => (
                <option key={el.value} value={el.value}>
                  {el.label}
                </option>
              ))}
            </Form.Select>
            {isBulkButton && (
              <Button
                onClick={submitBulkAction}
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            )}
          </div>
        ) : (
          <div id="orders-actions" className="d-flex">
            {actionBtn.length > 0 &&
              actionBtn.map((e, i) => (
                <Button
                  key={i}
                  onClick={e.callBack}
                  variant="falcon-default"
                  size="sm"
                  transform="shrink-3"
                  className="me-2 text-600 btn-link"
                  {...e.prop}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    {e.label}
                  </span>
                </Button>
              ))}
            {handleImport && (
              <IconButton
                onClick={handleImport}
                variant="falcon-default"
                size="sm"
                icon="arrow-up"
                transform="shrink-3"
                className="me-2 text-600 btn-link"
              >
                <span className="d-none d-sm-inline-block ms-1">Import</span>
              </IconButton>
            )}
            {handleExport && (
              <IconButton
                onClick={handleExport}
                variant="falcon-default"
                size="sm"
                icon="external-link-alt"
                transform="shrink-3"
                className="me-2 text-600 btn-link"
              >
                <span className="d-none d-sm-inline-block ms-1">Export</span>
              </IconButton>
            )}
            {!isCustomNew ? (
              handleNew && (
                <IconButton
                  onClick={handleNew}
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  className="text-600 btn-link"
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    {newLabel}
                  </span>
                </IconButton>
              )
            ) : (
              <CardDropdown
                iconClassName="fs--1"
                icon="plus"
                title="New"
                btnRevealClass="btn-falcon-default"
              >
                <div className="py-2">
                  {customNew.map((el, i) => {
                    return (
                      <Dropdown.Item key={i} onClick={el.callBack}>
                        {el.label}
                      </Dropdown.Item>
                    );
                  })}
                </div>
              </CardDropdown>
            )}
          </div>
        )}
      </Col>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={postRequest}
        type={confirmText.type}
      />
    </Row>
  );
};

TableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  title: PropTypes.string,
  ids: PropTypes.array,
  handleRefetch: PropTypes.func,
  handleNew: PropTypes.func,
  newLabel: PropTypes.string,
  isBulkAction: PropTypes.bool,
  bulkTable: PropTypes.string,
  bulkService: PropTypes.string,
  bulkActive: PropTypes.bool,
  bulkArchive: PropTypes.bool,
  bulkDelete: PropTypes.bool,
  bulkExtra: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      callBack: PropTypes.func.isRequired,
      type: PropTypes.string
    })
  ),
  isCustomNew: PropTypes.bool,
  handleImport: PropTypes.func,
  handleExport: PropTypes.func,
  customNew: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callBack: PropTypes.func.isRequired
    })
  ),
  actionBtn: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callBack: PropTypes.func.isRequired,
      icon: PropTypes.string,
      prop: PropTypes.object
    })
  ),
  leading: PropTypes.shape({
    callBack: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired
  }),
  isBulkButton: PropTypes.bool
};

export default TableHeader;
