import TextEditor from 'components/common/TextEditor';
import { AuthContext } from 'context/Context';
import useFetch from 'hooks/useFetch';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createAgreement, getAgreementCodes } from 'services/user';
import serviceUrl from '../../../services/serviceUrlList';

const AddAgreement = () => {
  const formData = {
    isActive: true,
    title: '',
    content: '',
    isMandatory: false,
    site: 1
  };
  const { id } = useParams();

  const { user } = useContext(AuthContext);
  const [saveAsVersion, setSaveAsVersion] = useState(false);
  const navigate = useNavigate();
  const {
    setValue,
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: formData });

  const { data: codes } = useQuery(
    ['agreement-codes'],
    () => getAgreementCodes(),
    {
      enabled: id ? false : true,
      keepPreviousData: false
    }
  );
  console.log('CODES: ', codes);

  const { response } = id
    ? useFetch({
        url: serviceUrl.user.agreement.detail + id
      })
    : {};
  useEffect(() => {
    if (id) {
      setValue('updatedBy', user.id);
    } else {
      setValue('createdBy', user.id);
    }
  }, []);

  const handleSaveAsVersion = async () => {
    setSaveAsVersion(true);
  };

  const onSubmit = async data => {
    const payload = { ...data };

    payload.saveAsVersion = saveAsVersion;
    if (!id) {
      payload.saveAsVersion = true;
      if (codes.map(item => item.name).includes(payload.code)) {
        toast.error('Another active agreement with code already exists.', {
          theme: 'colored'
        });
        return;
      }
    } else {
      payload.id = Number(id);
    }

    postRequest(payload);
  };

  const postRequest = async payload => {
    const response = await createAgreement(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Agreement created`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      if (response.data?.title) {
        toast.error(response.data?.title[0], {
          theme: 'colored'
        });
      } else if (response.data?.code) {
        toast.error(response.data?.code[0], {
          theme: 'colored'
        });
      } else if (response.data.error) {
        toast.error('Another active agreement with code already exists.', {
          theme: 'colored'
        });
      }
    } else {
      console.log('ERROR:', response.data);
      toast.error('Something went wrong!!', {
        theme: 'colored'
      });
    }
  };

  const handleRouteList = () => {
    navigate('/agreements');
  };
  useEffect(() => {
    if (!response?.data) return;
    const data = response.data;
    setValue('title', data.title);
    setValue('content', data.content);
    setValue('isMandatory', data.isMandatory);
    setValue('code', data.code);
  }, [response]);

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {id ? 'Update Agreement' : 'Create Agreement'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row} className="mb-3" controlId="title">
              <Form.Label column lg={3}>
                Agreement Name
              </Form.Label>
              <Col lg={9}>
                <Form.Control
                  {...register('title', { required: true })}
                  isInvalid={errors.title}
                  type="text"
                />
              </Col>
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="code">
              <Form.Label column lg={3}>
                Agreement Code
              </Form.Label>
              <Col lg={9}>
                <Form.Control
                  {...register('code', { required: true })}
                  isInvalid={errors.code}
                  type="text"
                  maxLength={3}
                  disabled={id}
                />
              </Col>
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="content">
              <Form.Label column lg={3}>
                Content
              </Form.Label>
              <Col lg={9}>
                <TextEditor
                  name="content"
                  control={control}
                  tabIndex={2} // tabIndex of textarea
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="isMandatory">
              <Form.Label column lg={3}></Form.Label>
              <Col lg={9}>
                <Form.Check {...register('isMandatory')} label="Is Mandatory" />
              </Col>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleRouteList}
                variant="secondary"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                style={{ width: 150 }}
                className="mx-2"
              >
                {id ? 'Update' : 'Save'}
              </Button>
              {id && (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSaveAsVersion}
                  // style={{ width: 150 }}
                >
                  Save As Version
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </Fragment>
  );
};

export default AddAgreement;
