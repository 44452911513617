import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CharacterLimit from 'components/common/CharacterLimit';
const CardNotes = props => {
  const { formData, handleChange } = props;

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Card Notes</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="note">
              <Form.Label>Notes</Form.Label>
              <CharacterLimit>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  placeholder="Notes"
                  rows={3}
                  name="note"
                  onChange={handleChange}
                  value={formData.note}
                />
              </CharacterLimit>
              <Form.Text>
                These notes are private and won't be shared with the customer
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};
CardNotes.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  handleChange: PropTypes.func
};
export default CardNotes;
