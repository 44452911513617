import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';

const CollectionsTableFilter = ({ eventKey, handleCollectionSearch }) => {
  const handleFilter = ek => {
    eventKey(ek);
  };

  return (
    <Row className="flex-between-center">
      <Col xs={12} className="mb-4">
        <Nav variant="tabs" defaultActiveKey="active" onSelect={handleFilter}>
          <Nav.Item>
            <Nav.Link eventKey="active">Active</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="passive">Passive</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="deleted">Deleted</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="all">All</Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col xs="auto" sm={6} lg={4}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter Collections"
          setGlobalFilter={handleCollectionSearch}
        />
      </Col>
    </Row>
  );
};

CollectionsTableFilter.propTypes = {
  eventKey: PropTypes.func,
  handleCollectionSearch: PropTypes.func
};

export default CollectionsTableFilter;
