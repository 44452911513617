import CardDropdown from 'components/common/CardDropdown';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import AppContext, { AuthContext } from 'context/Context';
import useDelete from 'hooks/useDelete';
import usePost from 'hooks/usePost';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import serviceUrl from 'services/serviceUrlList';
import {
  createShippingCost,
  getShippingCost,
  updateShippingCost
} from 'services/warehouse';

const ShippingCost = props => {
  const { newClickCount } = props;
  const { user } = useContext(AuthContext);
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      company_name: ''
    }
  });

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };

  const {
    data,
    isLoading: tableLoading,
    refetch
  } = useQuery(['getShippingCost', queryObj], () => getShippingCost(queryObj), {
    keepPreviousData: false,
    staleTime: Infinity
  });

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.warehouse.shippingcost.detail + selectedRowId
  });

  useEffect(() => {
    if (newClickCount === 0) return;
    openCreateModal(true);
  }, [newClickCount]);

  const onSubmit = async data => {
    console.log('DATA: ', data);
    const desi_prices = Array.from({ length: 6 }).map((_, index) => ({
      desi: index,
      price: Number(data['desi_' + index])
    }));
    const desi_with_tax = Array.from({ length: 6 }).map((_, index) => ({
      desi: index,
      price: Number(data['desi_' + index + '_with_tax'])
    }));
    const payload = {
      company_name: data.company_name,
      desi_prices,
      desi_with_tax,
      createdBy: user.id,
      updatedBy: user.id
    };
    console.log('PAYLOAD: ', payload);
    if (selectedRowId === null || selectedRowId === undefined) {
      handleCreate(payload);
    } else {
      handleUpdate(payload);
    }
  };
  const handleUpdate = async data => {
    const response = await updateShippingCost(data, selectedRowId);
    if (response.status === 200) {
      closeCreateModal();
      refetch();
      toast.success(`Shipping cost updated`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      if (response.data.errors) {
        toast.error(response.data.errors.non_field_errors[0], {
          theme: 'colored'
        });
        return;
      }
      toast.error(`The shipping cost you are trying to create already exists`, {
        theme: 'colored'
      });
    } else {
      toast.error(`Something went wrong`, {
        theme: 'colored'
      });
    }
  };
  const handleCreate = async data => {
    const response = await createShippingCost(data);
    if (response.data.status === 201) {
      closeCreateModal();
      refetch();
      toast.success(`Shipping cost created`, {
        theme: 'colored'
      });
    } else if (response.data.status === 400) {
      if (response.data.errors) {
        toast.error(response.data.errors.non_field_errors[0], {
          theme: 'colored'
        });
        return;
      }
      toast.error(`The shipping cost you are trying to create already exists`, {
        theme: 'colored'
      });
    } else {
      toast.error(`Something went wrong`, {
        theme: 'colored'
      });
    }
  };
  const hanldeCopyItem = dt => {
    setValue('company_name', dt.company_name);
    openCreateModal(true);
  };

  const handleEditItem = dt => {
    setSelectedRowId(dt.id);
    setValue('company_name', dt.company_name);
    dt.desi_prices.forEach((price, index) => {
      setValue(`desi_${index}`, price.price);
    });
    dt.desi_with_tax.forEach((price, index) => {
      setValue(`desi_${index}_with_tax`, price.price);
    });
    openCreateModal(true);
  };

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    closeCreateModal();
    if (deleteResponse.error === null) {
      refetch();
      toast.success(`Shipping Cost deleted`, {
        theme: 'colored'
      });
    } else {
      if (deleteResponse.error.data.status === 405) {
        toast.error(deleteResponse.error.data.message, {
          theme: 'colored'
        });
      } else {
        toast.error('Oopss! Something went wrong :(', {
          theme: 'colored'
        });
      }
    }
    setSelectedRowId(null);
  }, [deleteResponse]);

  const handleModalDeleteButton = () => {
    if (selectedRowId === null) {
      closeCreateModal();
    } else {
      handleDeleteItem(selectedRowId);
    }
  };

  const clearFormData = () => {
    clearErrors();
    reset();
  };
  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'company_name',
      Header: 'Company Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { company_name } = rowData.row.original;
        return (
          <a href="#" onClick={() => handleEditItem(rowData.row.original)}>
            {company_name}
          </a>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => hanldeCopyItem(dt.row.original)}>
                Copy
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleEditItem(dt.row.original)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={tableLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <TableHeader
            table
            handleRefetchAll={refetch}
            setQueryObj={setQueryObj}
          />
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 align-middle'
              }}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
        size="xl"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Add Shipping Cost</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="company_name">
              <Form.Label className="fs-0">Company Name</Form.Label>
              <Form.Control
                {...register('company_name', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.company_name}
                type="text"
                name="company_name"
                placeholder="Company Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.company_name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Table>
              <thead>
                <tr className="text-center font-weight-bold">
                  <td
                    style={{
                      borderRight: '1px solid #dee2e6'
                    }}
                    colSpan="6"
                  >
                    Desi (Vergi Hariç)
                  </td>
                  <td colSpan="6">Desi (Vergi Dahil)</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <td
                      height={30}
                      width={90}
                      key={index}
                      className="p-1"
                      style={{
                        borderRight:
                          index === 5 ? '1px solid #dee2e6' : undefined,
                        borderBottom: 'none'
                      }}
                    >
                      <Form.Group className="mb-3" controlId={`desi_${index}`}>
                        <Form.Label className="fs-0 d-block text-center">
                          {index}
                        </Form.Label>
                        <Form.Control
                          {...register(`desi_${index}`, {
                            required: 'This field cannot be empty!'
                          })}
                          isInvalid={errors['desi_' + index]}
                          type="number"
                          name={`desi_${index}`}
                          size="sm"
                          step="0.01"
                          className="text-center"
                          onWheel={e => e.target.blur()}
                        />
                      </Form.Group>
                    </td>
                  ))}
                  {Array.from({ length: 6 }).map((_, index) => (
                    <td
                      key={index}
                      height={30}
                      width={90}
                      className="p-1"
                      style={{
                        borderBottom: 'none'
                      }}
                    >
                      <Form.Group
                        className="mb-3"
                        controlId={`desi_${index}_with_tax`}
                      >
                        <Form.Label className="fs-0 d-block text-center">
                          {index}
                        </Form.Label>
                        <Form.Control
                          {...register(`desi_${index}_with_tax`, {
                            required: 'This field cannot be empty!'
                          })}
                          isInvalid={errors['desi_' + index + '_with_tax']}
                          type="number"
                          name={`desi_${index}_with_tax`}
                          size="sm"
                          step="0.01"
                          className="text-center"
                          onWheel={e => e.target.blur()}
                        />
                      </Form.Group>
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={handleModalDeleteButton}
            >
              {selectedRowId === null ? 'Cancel' : 'Delete'}
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Shipping Cost"
        description="Are you sure the shipping cost you selected will be deleted?"
        request={deleteRequest}
        type={'delete'}
      />
    </div>
  );
};

ShippingCost.propTypes = {
  newClickCount: PropTypes.number
};

export default ShippingCost;

const TableHeader = props => {
  const { selectedRowIds, ids, handleRefetchAll, setQueryObj } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [action, setAction] = useState(undefined);
  const [payload, setPayload] = useState(undefined);
  const [postResponse, postRequest] = usePost({
    url: serviceUrl.warehouse.bulk_actions,
    payload: payload
  });

  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    toast.success(`Bulk action completed`, {
      theme: 'colored'
    });
    handleRefetchAll();
  }, [postResponse]);

  const handleSetAction = e => {
    setAction(e.target.value);
  };

  const submitBulkAction = () => {
    if (action === undefined) {
      toast.error(`Select action for bulk action!`, {
        theme: 'colored'
      });
      return;
    }
    const payload_ = {
      table_name: 'ShippingCost',
      id_list: ids
    };
    if (action === 'delete') {
      payload_.is_deleted = true;
      setConfirmText({
        title: 'Bulk Delete action',
        description: 'Are you sure? Selected shipping costs will be deleted!',
        type: 'delete'
      });
    }
    setShowConfirm(true);
    setPayload({ ...payload_ });
  };

  const handleShippingCostSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      company_name__istartswith: value
    }));
  };

  return (
    <Row className="">
      <Col xs={6} className="d-flex align-items-center pe-0">
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter shipping costs by the name"
          setGlobalFilter={handleShippingCostSearch}
        />
      </Col>
      <Col xs={4} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              value={action}
              onChange={handleSetAction}
              size="sm"
              aria-label="Bulk actions"
            >
              <option hidden>Bulk Actions</option>
              <option value="delete">Delete</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={submitBulkAction}
            >
              Apply
            </Button>
          </div>
        ) : null}
      </Col>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={postRequest}
        type={confirmText.type}
      />
    </Row>
  );
};

TableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  ids: PropTypes.array,
  handleRefetchAll: PropTypes.func,
  setQueryObj: PropTypes.func
};
