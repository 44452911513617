import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import serviceUrl from '../../../services/serviceUrlList';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { toast } from 'react-toastify';
import useDelete from 'hooks/useDelete';
import { useNavigate } from 'react-router-dom';
import TableHeader from 'components/common/TableHeader';
import GiftCardTableFilter from './module/GiftCardTableFilter';
import moment from 'moment';
import { deleteGiftCard } from '../api';
import { Link } from 'react-router-dom';
import { currencyFormat } from 'helpers/utils';
import { useQuery } from 'react-query';
import { getGiftCards } from 'services/product';
import { getGiftCardUsers } from 'services/user';
import { queryReducer } from 'reducers/queryReducer';

const GiftCard = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowUserId, setSelectedRowUserId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const { data, isLoading, refetch } = useQuery(
    ['getGiftCards', queryObj],
    () => getGiftCards(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const { data: userData } = useQuery(
    ['getGiftCardUsers'],
    () => getGiftCardUsers(),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.giftcard.detail + selectedRowId
  });

  const handleDeleteItem = row => {
    setSelectedRowId(row.id);
    setSelectedRowUserId(row.gift_user_id);
    setConfirmText({
      title: 'Delete Gift Card',
      description: 'Are you sure? Selected gift card will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };

  useEffect(() => {
    if (deleteResponse.data === '') {
      try {
        deleteGiftCard(selectedRowUserId);
        refetch();
        setShowConfirm(false);
        toast.success(`Gift card deleted`, {
          theme: 'colored'
        });
        setSelectedRowId(null);
        setSelectedRowUserId(null);
      } catch (error) {
        console.log(error);
      }
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (!data?.data || !userData?.data) return;
    const dt1 = data.data.results;
    const dt2 = userData.data.results;
    setTableData(
      dt1.map(row1 => ({
        ...dt2.filter(row2 => row1.gift_user_id === row2.id)[0],
        ...row1
      }))
    );
  }, [data, userData]);

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const handleGiftCardSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search: value
    }));
  };

  const columns = [
    {
      accessor: 'code',
      Header: 'Title/Code',
      headerProps: { className: 'pe-1, text-start' },
      cellProps: {
        className: 'py-2 text-start'
      },
      Cell: rowData => {
        const { code, id, title } = rowData.row.original;
        return (
          <Link to={`/product/gift-card-update/${id}`}>
            {title}
            <br />
            {code}
          </Link>
        );
      }
    },
    {
      accessor: 'boughtBy',
      Header: 'Bought By',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'customer_name',
      Header: 'Bought For',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'remaining',
      Header: 'Remaining/Value',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { value } = rowData.row.original;
        return `${currencyFormat(value)} / ${currencyFormat(value)} ₺`;
      }
    },
    {
      accessor: 'created_at',
      Header: 'Created Date',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return moment(created_at).format('DD/MM/YYYY');
      }
    },
    {
      accessor: 'expiration_date',
      Header: 'Expire Date',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { expiration_date } = rowData.row.original;
        return moment(expiration_date).format('DD/MM/YYYY');
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={status === 1 ? 'success' : status === 2 ? 'warning' : 'danger'}
            className="d-block"
          >
            {status === 1 ? 'New' : status === 2 ? 'Sold' : 'Returned'}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { original } = rowData.row;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              {original.staus !== 2 ? (
                <>
                  <Dropdown.Item
                    onClick={() =>
                      navigate(`/product/gift-card-update/${original.id}`)
                    }
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDeleteItem(original)}>
                    Delete
                  </Dropdown.Item>
                </>
              ) : (
                <Dropdown.Item
                  onClick={() =>
                    navigate(`/product/gift-card-view/${original.id}`)
                  }
                >
                  View
                </Dropdown.Item>
              )}
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleNew = () => {
    navigate('/product/gift-card-add');
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={tableData || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Gift Cards"
                handleRefetch={refetch}
                table
                handleNew={handleNew}
                isBulkAction={false}
              />
              <GiftCardTableFilter
                handleGiftCardSearch={handleGiftCardSearch}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={deleteRequest}
        type={confirmText.type}
      />
    </div>
  );
};

export default GiftCard;
