import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { AiOutlineDelete } from 'react-icons/ai';
import { HorizontalDndStrict } from './HorizontalDndStrict';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginRight: 8,

  // change background colour if dragging
  position: 'relative',
  border: '1px solid #ccc',
  width: 150,
  height: 150,
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: 2,
  width: '100%'
});

const HorizontalDnd = props => {
  const { data, setState, isDeletable } = props;
  const [draggableData, setDraggableData] = useState();
  useEffect(() => {
    if (data.length === 0 || !data[0]) return;
    setDraggableData(data);
  }, [data]);
  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = [...draggableData];
      const reorderedItems = reorder(items, source.index, destination.index);
      setState(reorderedItems);
    }
  };

  const handleDeleteItem = id => {
    const newData = draggableData.filter(item => item.object_id !== id);
    setState(newData);
  };

  if (data.length === 0) return <></>;
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          <Col xs={12}>
            <HorizontalDndStrict droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="border bg-white dark__bg-1000 rounded-2 py-3 mb-3"
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {draggableData.map((item, index) => (
                    <Draggable
                      key={item.object_id}
                      draggableId={`drag-${item.object_id}`}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided?.draggableProps.style
                          )}
                        >
                          <Image
                            src={item.image.file ?? item.image.url}
                            fluid
                            style={{
                              objectFit: 'contain',
                              aspectRatio: '1/1'
                            }}
                          />
                          <div
                            style={{
                              position: 'absolute',
                              bottom: 2,
                              left: 2,
                              backgroundColor: '#FFFFFF',
                              width: '99%'
                            }}
                          >
                            <span
                              style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                WebkitLineClamp: 2,
                                maxWidth: 196
                              }}
                            >
                              {item.display_name}
                            </span>
                          </div>
                          {isDeletable && (
                            <AiOutlineDelete
                              style={{
                                fontSize: 18,
                                position: 'absolute',
                                top: 2,
                                right: 2
                              }}
                              onClick={() => handleDeleteItem(item.object_id)}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </HorizontalDndStrict>
          </Col>
        </Row>
      </DragDropContext>
    </div>
  );
};

HorizontalDnd.propTypes = {
  data: PropTypes.array,
  setState: PropTypes.func,
  setSelectedItem: PropTypes.func,
  isDeletable: PropTypes.bool
};

export default HorizontalDnd;
