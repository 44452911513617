import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { getShippingDiscounts } from 'services/order';
import { useQuery } from 'react-query';

function ShippingDiscountModal({ show, setShow, handleSetDiscount }) {
  const { data: discounts } = useQuery(
    ['shipping-discounts-for-order'],
    () => getShippingDiscounts(),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const DiscountList =
    discounts &&
    discounts.map(discount => {
      return (
        <li
          className="blue-text-btn"
          onClick={() => handleSetDiscount(discount)}
          key={discount.id}
        >
          {discount.title}
        </li>
      );
    });

  return (
    <Modal show={show} size={'lg'} centered onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>Discount</Modal.Title>
        <FalconCloseButton onClick={() => setShow(false)} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={6}>
            <h5>Shipping Discount</h5>
            <ul>{DiscountList}</ul>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
ShippingDiscountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  handleSetDiscount: PropTypes.func.isRequired
};

export default ShippingDiscountModal;
