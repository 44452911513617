import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AiOutlineRight } from 'react-icons/ai';
import discountTypes from './discountTypes.json';

const ModalSelectDiscountType = props => {
  const { showModal, handleCloseModal, onClick } = props;
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Select Campaign Type</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <>
          {discountTypes.map((type, index) => {
            return (
              <Flex
                key={index}
                onClick={() => onClick(type.value)}
                alignItems="center"
                justifyContent="between"
                className={
                  'rounded-3 bg-light p-3 order-product-filter-list mb-2'
                }
              >
                <>
                  <Flex direction={'column'}>
                    <h6 className="mb-0">{type.title}</h6>
                    <span className="mb-0">{type.subtitle}</span>
                  </Flex>
                  <span className="fs--2 text-600 mb-0">
                    <AiOutlineRight />
                  </span>
                </>
              </Flex>
            );
          })}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
ModalSelectDiscountType.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ModalSelectDiscountType;
