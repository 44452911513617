import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { getSize } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Card, Col, Dropdown, Image, Row } from 'react-bootstrap';
import { StrictModeDroppable } from './StrictModeDroppable';

const DraggableImageList = props => {
  const { data, setState, handleRemove, handleSetAltText } = props;
  const [draggableData, setDraggableData] = useState(data);
  useEffect(() => {
    if (!Array.isArray(data)) return;
    setDraggableData(data);
  }, [data]);
  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = [...draggableData];
      const reorderedItems = reorder(items, source.index, destination.index);
      setState(reorderedItems);
    }
  };

  if (data?.length === 0) return <></>;
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          <Col xs={12}>
            <StrictModeDroppable droppableId="droppable" type="DRAG">
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="border bg-white dark__bg-1000 rounded-2 py-3 mb-3"
                >
                  {draggableData.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={`drag${item.id}`}
                      index={index}
                    >
                      {provided => (
                        <div>
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="mb-2 mx-1 kanban-item shadow-sm dark__bg-1100"
                          >
                            <Card.Body>
                              <Flex
                                justifyContent="between"
                                alignItems="center"
                                className="flex-1"
                              >
                                <Image
                                  rounded
                                  src={item.file.preview || item.file}
                                  alt={item.alt_text}
                                  style={{
                                    width: 80,
                                    height: 80,
                                    objectFit: 'contain'
                                  }}
                                />
                                <div className="mx-3">
                                  <h6>{item.file.path}</h6>
                                  <Flex
                                    className="position-relative"
                                    alignItems="center"
                                  >
                                    <p className="mb-0 fs--1 text-400 line-height-1">
                                      <strong>
                                        {getSize(item.file.size || item.size)}
                                      </strong>
                                    </p>
                                  </Flex>
                                  <Flex
                                    className="position-relative"
                                    alignItems="center"
                                  >
                                    <p className="mb-0 fs--1 text-400 line-height-1">
                                      <strong>{`Alt Text: ${item.alt_text}`}</strong>
                                    </p>
                                  </Flex>
                                </div>
                                <CardDropdown>
                                  <div className="py-2">
                                    <Dropdown.Item
                                      className="text-danger"
                                      onClick={() => handleRemove(item)}
                                    >
                                      Remove
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => handleSetAltText(item)}
                                    >
                                      Set Alt Text
                                    </Dropdown.Item>
                                  </div>
                                </CardDropdown>
                              </Flex>
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </Col>
        </Row>
      </DragDropContext>
    </div>
  );
};

DraggableImageList.propTypes = {
  data: PropTypes.array,
  setState: PropTypes.func,
  handleRemove: PropTypes.func,
  handleSetAltText: PropTypes.func
};

export default DraggableImageList;
