import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthentication from 'hooks/useAuthentication';
import serviceUrl from 'services/serviceUrlList';
import { toast } from 'react-toastify';

const PasswordResetForm = ({ hasLabel }) => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    new_password1: '',
    new_password2: '',
    uid: uid,
    token: token
  });

  const [postResponse, postRequest] = useAuthentication({
    url: serviceUrl.authentication.reset_password,
    payload: formData
  });

  useEffect(() => {
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.error === null) {
      navigate('/authentication/login');
      toast.success(`Login with your new password!`, {
        theme: 'colored'
      });
    } else {
      toast.error('Token expired please ask a new token...', {
        theme: 'colored'
      });
    }
  }, [postResponse]);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (formData.new_password1 !== formData.new_password2) {
      toast.error('Passwords does not match!', { theme: 'colored' });
    } else {
      postRequest();
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.new_password1}
          name="new_password1"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.new_password2}
          name="new_password2"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.new_password1 || !formData.new_password2}
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
