import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { createMainMenu, updateMainMenu } from 'services/product';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const initialForm = {
  display_name: '',
  slug_type: '',
  slug: ''
};

function AddMainMenu({ brands, collections, mainMenuData, editItem, refetch }) {
  const { mmid } = useParams();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const {
    handleSubmit,
    control,
    register,
    resetField,
    formState: { errors },
    setValue,
    reset
  } = useForm({ defaultValues: initialForm });
  const slug_type = useWatch({ control, name: 'slug_type' });
  const [modalOpen, setModalOpen] = useState(false);
  const [editItemData, setEditItemData] = useState({});

  const closeModal = () => {
    setModalOpen(false);
    reset();
  };
  const openModal = () => {
    setModalOpen(true);
  };
  const onSubmit = async data => {
    if (mainMenuData.length === 1 && mainMenuData[0].display_name === '') {
      toast.error(
        'You must add display name to first menu item before adding another one!',
        {
          theme: 'colored'
        }
      );
      return;
    }
    const payload = {
      display_name: data.display_name,
      slug_type: data.slug_type.value,
      object_id: data.slug_type.value === 'pages' ? data.slug : data.slug.value,
      header_menu: mmid
    };
    if (editItemData.id) {
      const response = await updateMainMenu(editItemData.id, payload);
      console.log('UPD: ', response);
      setEditItemData({});
      toast.success(`Updated successfully!`, {
        theme: 'colored'
      });
    } else {
      payload.order = mainMenuData.length + 1;
      const response = await createMainMenu(payload);
      console.log('RESPONSE: ', response);
      toast.success(`Saved successfully!`, {
        theme: 'colored'
      });
    }
    refetch();
    closeModal();
    reset();
  };

  useEffect(() => {
    if (!editItem) return;
    setEditItemData(editItem);
    // console.log('editItem: ', editItem);
    setValue('display_name', editItem.display_name);
    setValue('slug_type', {
      value: editItem.slug_type,
      label: editItem.slug_type[0].toUpperCase() + editItem.slug_type.slice(1)
    });

    if (editItem.slug_type === 'pages') {
      setValue('slug', editItem.object_id);
    } else {
      setValue('slug', {
        value: editItem.object_id,
        label: '' // SLUG_TYPE ALANINA GÖRE SLUG VERİSİNİN ADINI AL VE BURAYA YAZ.
      });

      switch (editItem.slug_type) {
        case 'brand': {
          const marka = brands.find(dt => dt.slug === editItem.slug);
          setValue('slug', { value: Number(marka.id), label: marka.name });
          break;
        }
        case 'collection': {
          const koleksiyon = collections.find(dt => dt.slug === editItem.slug);
          setValue('slug', {
            value: Number(koleksiyon.id),
            label: koleksiyon.name
          });
          break;
        }
        default:
          break;
      }
    }
    openModal();
  }, [editItem]);

  const slugOptions = slug_type
    ? slug_type.value === 'brand'
      ? brands.map(dt => ({ value: dt.id, label: dt.name, id: dt.id }))
      : slug_type.value === 'collection'
      ? collections.map(dt => ({ value: dt.id, label: dt.name, id: dt.id }))
      : []
    : [];

  const customStylesSlugType = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.slug_type
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };
  const customStylesSlug = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.slug
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };
  return (
    <>
      <Button onClick={openModal}>Add Menu</Button>
      <Modal show={modalOpen} onHide={closeModal}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">
              {editItemData.id ? 'Update' : 'Add'} Main Menu
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="display_name">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                {...register('display_name')}
                isInvalid={errors.display_name}
                type="text"
                name="display_name"
                placeholder="Display Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.display_name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="slug_type">
              <Form.Label>Slug Type</Form.Label>
              <Controller
                name="slug_type"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    options={[
                      { value: 'collection', label: 'Collection' },
                      { value: 'brand', label: 'Brand' },
                      { value: 'pages', label: 'Pages' }
                    ]}
                    onChange={val => {
                      onChange(val);
                      resetField('slug');
                    }}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    placeholder="Slug Type"
                    isSearchable={false} // Arama çubuğunu devre dışı bırakır
                    classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                    components={{
                      IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                    }}
                    className={errors.slug_type ? 'is-invalid' : ''}
                    styles={{ ...customStylesSlugType }}
                  />
                )}
                rules={{ required: 'This field cannot be empty!' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.slug_type?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="slug">
              <Form.Label>Slug</Form.Label>
              {slug_type.value !== 'pages' ? (
                <Controller
                  name="slug"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      isDisabled={!slug_type}
                      options={slugOptions}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      placeholder="Slug"
                      isSearchable={true} // Arama çubuğunu devre dışı bırakır
                      classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                      components={{
                        IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                      }}
                      className={errors.slug ? 'is-invalid' : ''}
                      styles={{ ...customStylesSlug }}
                    />
                  )}
                  rules={{ required: 'This field cannot be empty!' }}
                />
              ) : (
                <Form.Control
                  {...register('slug')}
                  isInvalid={errors.slug}
                  type="text"
                  name="slug"
                  placeholder="Url"
                />
              )}
              <Form.Control.Feedback type="invalid">
                {errors.slug?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button variant="danger" className="px-3 mx-2" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => {}}
              className="px-5 mx-0"
            >
              {editItemData.id ? 'Update' : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddMainMenu;

AddMainMenu.propTypes = {
  brands: PropTypes.array,
  collections: PropTypes.array,
  mainMenuData: PropTypes.array,
  editItem: PropTypes.object,
  refetch: PropTypes.func
};
