import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import moment from 'moment';
import DatePickerCustomInput from 'components/common/DatePickerCustomInput';

const CardSpecification = props => {
  const { id, formData, setFormData, handleChange } = props;

  const handleChangeDate = (value, name) => {
    setFormData({ ...formData, [name]: moment(value).format('YYYY-MM-DD') });
  };

  const clearField = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if ((name === 'value' || name === 'price') && value === '0') {
      setFormData({ ...formData, [name]: '' });
    } else if (name === 'count' && value === '1') {
      setFormData({ ...formData, [name]: '' });
    }
  };
  const isValideDate = (date, name) => {
    const currentDate = new Date();
    if (name !== 'expire') {
      const expireDate = new Date(formData.expiration_date);
      if (date > currentDate && date < expireDate) return date;
    } else {
      if (date > currentDate) return date;
    }
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Card Specifications
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={4}>
            <Form.Group className="mb-3" controlId="value">
              <Form.Label className="fs-0">Value</Form.Label>
              <InputGroup>
                <InputGroup.Text>₺</InputGroup.Text>
                <Form.Control
                  type="number"
                  name="value"
                  value={formData.value}
                  onChange={handleChange}
                  onFocus={clearField}
                  className="input-spin-none"
                  onWheel={e => e.target.blur()}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Group className="mb-3" controlId="price">
              <Form.Label className="fs-0">Price</Form.Label>
              <InputGroup>
                <InputGroup.Text>₺</InputGroup.Text>
                <Form.Control
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  onFocus={clearField}
                  className="input-spin-none"
                  onWheel={e => e.target.blur()}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} lg={4}>
            {!id && (
              <Form.Group className="mb-3" controlId="count">
                <Form.Label className="fs-0">Count</Form.Label>
                <Form.Control
                  type="number"
                  name="count"
                  value={formData.count}
                  onChange={handleChange}
                  onFocus={clearField}
                  className="input-spin-none"
                  onWheel={e => e.target.blur()}
                />
              </Form.Group>
            )}
          </Col>
          <Col xs={12} lg={4}>
            <Form.Group className="mb-3" controlId="expiration_date">
              <Form.Label className="fs-0">Expiration Date</Form.Label>
              <DatePickerCustomInput
                filterDate={date => isValideDate(date, 'expire')}
                selected={
                  formData.expiration_date
                    ? new Date(formData.expiration_date)
                    : new Date(moment().add(1, 'years'))
                }
                onChange={date => handleChangeDate(date, 'expiration_date')}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholder="Expiration Date"
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Group className="mb-3" controlId="send_date">
              <Form.Label className="fs-0">Send Date</Form.Label>
              <DatePickerCustomInput
                filterDate={date => isValideDate(date, 'send')}
                selected={
                  formData.send_date ? new Date(formData.send_date) : new Date()
                }
                onChange={date => handleChangeDate(date, 'send_date')}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholder="Send Date"
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Group className="mb-3" controlId="activation_date">
              <Form.Label className="fs-0">Activation Date</Form.Label>
              <DatePickerCustomInput
                filterDate={date => isValideDate(date, 'active')}
                selected={
                  formData.activation_date
                    ? new Date(formData.activation_date)
                    : new Date()
                }
                onChange={date => handleChangeDate(date, 'activation_date')}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholder="Activation Date"
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

CardSpecification.propTypes = {
  id: PropTypes.string,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  handleChange: PropTypes.func
};
export default CardSpecification;
