import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const DatePickerCustomInput = ({
  placeholder,
  size,
  style,
  allowClear,
  disabled = false,
  ...props
}) => {
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => {
    const handleClear = e => {
      e.stopPropagation();

      if (onChange) {
        onChange({ target: { value: '' } }); // Clear the date value
      }
    };
    return (
      <div className="position-relative">
        <Form.Control
          ref={ref}
          onClick={onClick}
          defaultValue={value}
          className="ps-4"
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          size={size}
          style={style}
        />
        <FontAwesomeIcon
          icon="calendar-alt"
          className="text-primary position-absolute top-50 translate-middle-y ms-2"
        />
        {allowClear && value && (
          <FontAwesomeIcon
            onClick={handleClear}
            icon="close"
            className="text-primary position-absolute top-50 end-0 me-2 text-secondary translate-middle-y ms-2"
          />
        )}
      </div>
    );
  });
  return <DatePicker {...props} customInput={<CustomInput />} />;
};

DatePickerCustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object
};

export default DatePickerCustomInput;
