import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import { MegaMenuStrictDroppable } from '../../modules/MegaMenuStrict';
import MenuChildDraggableItem from './MenuChildDraggbleItem';
import { updateMenuItem } from 'services/product';

function MenuItemChildDnd({
  childItems,
  handleEdit,
  refetch,
  menuMiniRefetch
}) {
  const [draggableData, setDraggableData] = useState([]);

  useEffect(() => {
    if (childItems) {
      setDraggableData(childItems);
    }
  }, [childItems]);

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = async result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = [...draggableData];
      const reorderedItems = reorder(items, source.index, destination.index);

      reorderedItems.forEach(async (item, index) => {
        await updateMenuItem(item.id, { order: index + 1 });
      });

      const newList = reorderedItems.map((item, index) => ({
        ...item,
        order: index + 1
      }));
      setDraggableData(newList);
    }
    // Sürükleme işlemi sona erdiğinde yapılacak işlemler
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row>
        <Col xs={12}>
          <MegaMenuStrictDroppable droppableId="droppable" direction="vertical">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className=""
                style={{ minHeight: 100 }}
              >
                {draggableData.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={`drag-${item.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <MenuChildDraggableItem
                        provided={provided}
                        snapshot={snapshot}
                        item={item}
                        handleEdit={handleEdit}
                        refetch={refetch}
                        menuMiniRefetch={menuMiniRefetch}
                        draggableData={draggableData}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </MegaMenuStrictDroppable>
        </Col>
      </Row>
    </DragDropContext>
  );
}

export default MenuItemChildDnd;

MenuItemChildDnd.propTypes = {
  childItems: PropTypes.array,
  handleEdit: PropTypes.func,
  refetch: PropTypes.func,
  menuMiniRefetch: PropTypes.func
};
