import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card } from 'react-bootstrap';

function CustomerLastOrder({ data }) {
  if (!data) return null;

  if (data.last_order_date === null) {
    return (
      <Card className="mb-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5>Last Order Spent</h5>
        </Card.Header>
        <Card.Body>
          <div>Customers has 0 order</div>
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5>Last Order Spent</h5>
        <span>{moment(data.last_order_date).format('DD.MM.YYYY HH:mm')}</span>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-4">
            <span>#{data.last_order_no}</span>
          </div>
          <div className="col-4">
            <span>PAID STATUS</span>
          </div>
          <div className="col-4">
            <span>{data.last_order_amount?.toFixed(2)} TL</span>
          </div>
        </div>
        <hr />
        {data.last_order_items?.map(dt => (
          <div key={dt.id} className="row mt-3 d-flex align-items-center">
            <div className="col-2">
              <img
                src={dt.product_photo[0].file}
                width={50}
                alt="product image"
              />
            </div>
            <div className="col-5">
              <span>{dt.product_name}</span>
            </div>
            <div className="col-2">
              <span>x{dt.quantity}</span>
            </div>
            <div className="col-3">
              <span>{dt.product_price?.toFixed(2)} TL</span>
            </div>
          </div>
        ))}
        <hr />
        <div className="d-flex justify-content-end">
          <Button variant="outline-secondary">View All Orders</Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CustomerLastOrder;

CustomerLastOrder.propTypes = {
  data: PropTypes.object
};
