import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { currencyFormat } from 'helpers/utils';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Image,
  Nav,
  NavDropdown,
  Row
} from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { queryReducer } from 'reducers/queryReducer';
import { getAbandoned } from 'services/order';
import BasketTableFilter from './module/BasketTableFilter';

const BasketList = () => {
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    is_active: true
  });

  const { data, isLoading } = useQuery(
    ['getAbandoned', queryObj],
    () => getAbandoned(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const navigate = useNavigate();

  const handleCustomerDetail = id => {
    navigate(`/customer/${id}`);
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const handleFilter = (key, value) => {
    if (value === null) {
      // eslint-disable-next-line no-unused-vars
      const { [key]: omit, ...rest } = queryObj;
      setQueryObj(rest);
      return;
    }
    setQueryObj(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
      // Cell: dt => {
      //   const { id } = dt.row.original;
      //   return <Link to={`/order/abandoned-detail/${id}`}>{id}</Link>;
      // }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      headerProps: { className: 'pe-1', width: 150 },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: dt => {
        const { created_at } = dt.row.original;
        return <>{moment(created_at).format('DD/MM/YYYY HH:mm')}</>;
      }
    },
    {
      accessor: 'user_id',
      Header: 'Customer',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'text-start' },
      Cell: rowData => {
        const { user_id } = rowData.row.original;
        return (
          <Nav className="text-center d-block">
            <NavDropdown
              title={
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <span>{user_id?.full_name}</span>
                  <FaChevronDown size={'10px'} />
                </div>
              }
            >
              <div style={{ paddingLeft: 16, width: 250 }}>
                <h6>
                  <b>{user_id?.full_name}</b>
                </h6>

                <h6>{user_id?.email}</h6>
                <h6>{user_id?.phone}</h6>
                <NavDropdown.Divider />
                <Button
                  variant="outline-primary"
                  size="sm"
                  style={{ marginTop: 5 }}
                  onClick={() => handleCustomerDetail(user_id?.id)}
                >
                  View Customer
                </Button>
              </div>
            </NavDropdown>
          </Nav>
        );
      }
    },
    {
      accessor: 'items',
      Header: 'Items',
      headerProps: { className: 'pe-1 text-center', width: 80 },
      disableSortBy: true,
      Cell: rowData => {
        const { cart_items } = rowData.row.original;
        const item_count = cart_items?.reduce(
          (sum, dt) => sum + dt.item_quantity,
          0
        );

        return (
          <Nav className="text-center d-block">
            <NavDropdown
              title={
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <span>
                    {item_count > 1 ? `${item_count} items` : '1 item'}
                  </span>
                  <FaChevronDown size={'10px'} />
                </div>
              }
            >
              <div
                style={{
                  padding: '8px 0px 0px 8px',
                  width: 300,
                  maxHeight: 306,
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
              >
                {cart_items.map(dt => {
                  if (!dt.product) {
                    return null;
                  }
                  return (
                    <Row key={dt.product.id} style={{ minHeight: 80 }}>
                      <Col xs={2}>
                        <div style={{ position: 'relative' }}>
                          <div className="circle-number">
                            {dt.item_quantity}
                          </div>
                          <Image
                            style={{
                              padding: '4px',
                              border: '1px solid #dde0e4',
                              borderRadius: '8px',
                              objectFit: 'contain',
                              maxHeight: 38,
                              maxWidth: 38
                            }}
                            src={dt.product?.image}
                            width={38}
                            height={38}
                            alt={dt.product.name}
                          />
                        </div>
                      </Col>
                      <Col xs={9} style={{ marginLeft: 10 }}>
                        <a href={`/product/product-update/${dt.product.slug}`}>
                          {dt.product.name}
                        </a>
                        <h6>SKU {dt.product.sku}</h6>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </NavDropdown>
          </Nav>
        );
      }
    },
    {
      accessor: 'total_price',
      Header: 'Total',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'py-2 text-center' },
      Cell: rowData => {
        const { total_price } = rowData.row.original;
        return `${currencyFormat(total_price)} ₺`;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: { className: 'pe-1 text-start', width: 50 },
      disableSortBy: true,
      cellProps: { className: 'text-start' },
      Cell: rowData => {
        const { status } = rowData.row.original;

        switch (status) {
          case 1:
            return 'Active';
          case 2:
            return 'Abandoned';
          case 3:
            return 'Completed';
          case 4:
            return 'Error';
          default:
            break;
        }
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          pagination
          data={data?.data.results || []}
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader title="Basket List" table isBulkAction={false} />
              <BasketTableFilter handleFilter={handleFilter} />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>
    </div>
  );
};

export default BasketList;
