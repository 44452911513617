import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';

const SelectedItems = props => {
  const { items, setItems, itemType } = props;
  const handleRemove = id => {
    const new_items = items.filter(dt => dt.id !== id);
    setItems(new_items);
  };

  return (
    <div className="mt-3">
      {items.length > 0 && (
        <>
          <Row className="gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
            <Col xs={9} md={8} className="py-2">
              Name
            </Col>
          </Row>
          {items.map((item, i) => {
            return (
              <Flex
                key={i}
                justifyContent={'between'}
                className="gx-card mx-0 py-1 align-items-center border-bottom border-200"
              >
                <div className="d-flex align-items-center">
                  <Link to="!#">
                    {itemType === 'product' && item.files?.length > 0 && (
                      <img
                        src={item.files[0].file}
                        width="60"
                        alt={item.name}
                        className="img-fluid rounded-1 me-3 d-none d-md-block"
                      />
                    )}
                  </Link>
                  <div className="flex-1">
                    <h5 className="fs-0">
                      <Link to="!#" className="text-900">
                        {item.name ?? item.email}
                      </Link>
                    </h5>
                    {item.first_name && (
                      <span>{`${item.first_name} ${item.middle_name} ${item.last_name}`}</span>
                    )}
                  </div>
                </div>
                <div className="fs--2 fs-md--1">
                  <IconButton
                    onClick={() => handleRemove(item.id)}
                    variant="danger"
                    size="sm"
                    icon="xmark"
                    transform="shrink-3"
                  ></IconButton>
                </div>
              </Flex>
            );
          })}
        </>
      )}
    </div>
  );
};

SelectedItems.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
  itemType: PropTypes.string
};

export default SelectedItems;
