import Divider from 'components/common/Divider';
import SeoSetting from 'components/common/SeoSetting';
import TextEditor from 'components/common/TextEditor';
import AutocompleteTag from 'components/input/AutocompleteTag';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext } from 'context/Context';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { createTagFromProduct } from 'services/content';
import {
  createProductCategory,
  getProductCategoriesMini,
  getProductCategory,
  updateProductCategory
} from 'services/product';

const AddCategory = () => {
  const formData = new FormData();
  const [selectedTags, setSelectedTags] = useState([]);
  const [parent, setParent] = useState(undefined);
  const [showConfirm, setShowConfirm] = useState(false);

  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [seoSetting, setSeoSetting] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      is_active: true,
      description: '',
      footer_description: '',
      tags: [],
      seo_setting: {}
    }
  });
  const { user } = useContext(AuthContext);

  const response = id
    ? useQuery([`category-info-${id}`, id], () => getProductCategory(id), {
        keepPreviousData: false,
        staleTime: Infinity
      })
    : {};

  const { data: parentResponse } = useQuery(
    ['getProductCategoriesMini'],
    () => getProductCategoriesMini({ is_active: true }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  // seo setting start

  const handleSeoValue = seoValue => {
    setValue('seo_setting', JSON.stringify(seoValue));
  };
  // seo setting end

  useEffect(() => {
    if (id) {
      setValue('updated_by', user.id);
    } else {
      setValue('created_by', user.id);
    }
  }, []);

  const onSubmit = async values => {
    const data = {
      ...values,
      tag_names: selectedTags,
      parent: parent ? parent.value : null
    };
    delete data.tags;
    if (data.tag_names.length === 0) delete data.tag_names;
    if (!data.image) delete data.image;
    Object.keys(data).map(key => {
      if (key === 'parent' && !data[key]) {
        return;
      }
      formData.set(key, data[key]);
    });
    if (id) {
      const response = await updateProductCategory(id, formData);
      if (!response.data) return;
      if (response.status === 200) {
        toast.success('Category successfully updated', { theme: 'colored' });
        // to sync tag names for content and product services
        createTagFromProduct({
          tag_names: selectedTags,
          updated_by: user.id
        });
        handleRouteList();
      } else if (response.status === 400 && response.data.name) {
        toast.error(`Name: ${response.data.name[0]}`, {
          theme: 'colored'
        });
      } else {
        toast.error('Something went wrong! Category cannot updated.', {
          theme: 'colored'
        });
      }
    } else {
      const response = await createProductCategory(formData);
      if (!response.data) return;
      if (response.status === 201) {
        toast.success('Category successfully created', { theme: 'colored' });
        // to sync tag names for content and product services
        createTagFromProduct({
          tag_names: selectedTags,
          created_by: user.id
        });
        handleRouteList();
      } else if (response.status === 400 && response.data.name) {
        toast.error(`Name: ${response.data.name[0]}`, {
          theme: 'colored'
        });
      } else {
        toast.error('Something went wrong! Category cannot created.', {
          theme: 'colored'
        });
      }
    }
  };

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Category',
      description: 'Are you sure? Selected category will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };

  const handleRouteList = () => {
    navigate('/product/product-categories');
  };

  const parentOptions = parentResponse?.data.results
    .filter(dt => dt.id !== Number(id))
    .map(dt => {
      return {
        value: dt.id,
        label: dt.name
      };
    });
  useEffect(() => {
    if (!response || response.status !== 'success') return;
    const {
      name,
      description,
      footer_description,
      is_active,
      seoSetting,
      tags,
      parent: parent_,
      seo_setting
    } = response.data.data;
    setValue('name', name);
    setValue('description', description);
    setValue('footer_description', footer_description);
    setValue('is_active', is_active);
    setParent(parent_);
    setValue('seo_setting', seoSetting);
    setSelectedTags(tags.map(dt => dt.name));
    setSeoSetting(seo_setting);
  }, [response.status]);

  useEffect(() => {
    setValue('tag_names', selectedTags);
  }, [selectedTags]);

  const handleChangeSelect = value => {
    setParent(value);
  };

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {id ? 'Update Category' : 'Create Category'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -10
              }}
            >
              <Form.Select
                {...register('is_active', { required: true })}
                size="sm"
                className="mb-3"
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                {...register('name', { required: true })}
                isInvalid={errors.name}
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <TextEditor
                name="description"
                control={control}
                maxLength={5000}
                tabIndex={1} // tabIndex of textarea
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="footer_description">
              <Form.Label>Footer Description</Form.Label>
              <TextEditor
                name="footer_description"
                control={control}
                maxLength={5000}
                tabIndex={1} // tabIndex of textarea
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="tag">
              <Form.Label>Tags</Form.Label>
              <AutocompleteTag
                placeholder="Select your tags"
                selected={selectedTags}
                onChange={setSelectedTags}
                service="product"
              />
            </Form.Group>
            <Form.Group controlId="parent">
              <Form.Label className="fs-0">Parent</Form.Label>
              <Select
                options={parentOptions}
                placeholder="Select parent"
                classNamePrefix="react-select"
                isClearable
                name="parent"
                value={parent}
                onChange={handleChangeSelect}
              />
            </Form.Group>
            <Divider />
            <SeoSetting
              seoValues={handleSeoValue}
              defaultValue={seoSetting}
              register={register}
              errors={errors}
            />
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={() => {}}
      />
    </Fragment>
  );
};

export default AddCategory;
