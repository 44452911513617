import CardDropdown from 'components/common/CardDropdown';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import AppContext, { AuthContext } from 'context/Context';
import useDelete from 'hooks/useDelete';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import serviceUrl from 'services/serviceUrlList';
import {
  createCommunicationSubject,
  getCommunicationSubjects,
  updateCommunicationSubject
} from 'services/user';

const CommunicationSubjects = props => {
  const { newClickCount } = props;
  const { user } = useContext(AuthContext);
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm();

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };

  const {
    data,
    isLoading: tableLoading,
    refetch
  } = useQuery(
    ['getCommunicationSubjects', queryObj],
    () => getCommunicationSubjects(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  // TODO: serviceUrl.user.communicationSubject will be updated, It is dummy now!
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.user.communicationSubject.detail + selectedRowId
  });

  useEffect(() => {
    if (newClickCount === 0) return;
    openCreateModal(true);
  }, [newClickCount]);

  const onSubmit = data => {
    if (selectedRowId === null) {
      postRequest({ ...data, createdBy: user.id });
    } else {
      updateRequest({ ...data, updatedBy: user.id });
    }
  };
  const updateRequest = async payload => {
    const response = await updateCommunicationSubject(payload, selectedRowId);
    if (response.status === 200) {
      closeCreateModal();
      refetch();
      toast.success(`Communication subject updated`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.name, {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const postRequest = async payload => {
    const response = await createCommunicationSubject(payload);
    if (response.status === 201) {
      closeCreateModal();
      refetch();
      toast.success(`Communication Subject created`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.subject, {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const handleEditItem = dt => {
    setSelectedRowId(dt.id);
    setValue('subject', dt.subject);
    openCreateModal(true);
  };

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    closeCreateModal();
    refetch();
    setSelectedRowId(null);
    if (deleteResponse.error === null) {
      toast.success(`Communication Subject deleted`, {
        theme: 'colored'
      });
    } else {
      toast.error(deleteResponse.error.data.message, {
        theme: 'colored'
      });
    }
  }, [deleteResponse]);

  const handleModalDeleteButton = () => {
    if (selectedRowId === null) {
      closeCreateModal();
    } else {
      handleDeleteItem(selectedRowId);
    }
  };

  const clearFormData = () => {
    clearErrors();
    reset();
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'subject',
      Header: 'Subject',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { subject } = rowData.row.original;
        return (
          <a href="#" onClick={() => handleEditItem(rowData.row.original)}>
            {subject}
          </a>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handleEditItem(dt.row.original)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleSearch = value => {
    console.log(value);
    setQueryObj(prevState => ({
      ...prevState,
      subject__istartswith: value
    }));
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={tableLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Row>
            <Col xs={6} className="d-flex align-items-center pe-0">
              <AdvanceTableSearchBox
                size="sm"
                placeholder="Filter communication subjects"
                setGlobalFilter={handleSearch}
              />
            </Col>
          </Row>
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 align-middle'
              }}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Add Communication Subjects</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="subject">
              <Form.Label className="fs-0">Subject</Form.Label>
              <Form.Control
                {...register('subject', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.subject}
                type="text"
                placeholder="Subject"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.subject?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={handleModalDeleteButton}
            >
              {selectedRowId === null ? 'Cancel' : 'Delete'}
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Communication Subject"
        description="Are you sure? These permissions will also be removed from user data."
        request={deleteRequest}
        type={'delete'}
        btnSecondary="Cancel"
      />
    </div>
  );
};

CommunicationSubjects.propTypes = {
  newClickCount: PropTypes.number
};

export default CommunicationSubjects;
