import Flex from 'components/common/Flex';
import TextEditor from 'components/common/TextEditor';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import useFetch from 'hooks/useFetch';
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import serviceUrl from 'services/serviceUrlList';
import DraggableList from './DraggableList';

const ProductDescriptions = props => {
  const { register, formValues, setFormValues } = props;
  const [descriptionName, setDescriptionName] = useState('');
  const [descriptionDetail, setDescriptionDetail] = useState('');
  const [descriptions, setDescriptions] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [editItemName, setEditItemName] = useState();
  const [editOrDelete, setEditOrDelete] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [selectedCopyProduct, setSelectedCopyProduct] = useState({});
  const [convertImage, setConvertImage] = useState(false);
  const [bgColor, setBgColor] = useState('#ffffff');

  const { response } = useFetch({
    url: serviceUrl.product.product.mini_desc
  });
  const productOptions = response?.data.results.map(dt => ({
    value: dt.id,
    label: dt.name,
    descriptions: dt.descriptions
  }));

  const handleSelectedItem = (item, name) => {
    setEditItemName(item.name);
    setEditItem(item);
    setEditOrDelete(name);
  };

  useEffect(() => {
    setDescriptions(formValues.descriptions);
  }, [formValues.descriptions]);

  useEffect(() => {
    setFormValues({ ...formValues, descriptions: descriptions });
  }, [descriptions]);

  const handleRemoveDescription = () => {
    const filtered_desc = descriptions.filter(dt => dt.id !== editItem.id);
    setDescriptions(filtered_desc);
  };
  const handleSetShowConfirm = status => {
    setConfirm(status);
    setEditItem({});
  };

  useEffect(() => {
    if (editOrDelete === 'edit') {
      if (editItem.id) {
        setDescriptionName(editItem.name);
        setDescriptionDetail(editItem.detail);
      }
    } else {
      if (editItem.id) {
        setConfirm(true);
      }
    }
  }, [editItem]);

  const handleSave = async () => {
    const isAlreadyHas = descriptions.some(e => e.name === descriptionName);
    if (editItemName !== descriptionName && isAlreadyHas) {
      toast.error(`A description by this name already exist!`, {
        theme: 'colored'
      });
      return;
    }
    const oldDesc = [...descriptions];
    if (descriptionName === '' || descriptionDetail === '') {
      toast.error(`Description name or detail can not be empty!`, {
        theme: 'colored'
      });
      return;
    }
    if (!editItem.id) {
      // Yeni bir description create ediliyorsa
      const desc = {
        id:
          descriptions.length === 0
            ? 1
            : descriptions[descriptions.length - 1].id + 1,
        name: descriptionName,
        detail: descriptionDetail,
        isImageDescription: convertImage
      };
      oldDesc.push(desc);
    } else {
      // Bir description düzenleniyorsa
      oldDesc.find(desc => desc.id === editItem.id).name = descriptionName;
      oldDesc.find(desc => desc.id === editItem.id).detail = descriptionDetail;
      setEditItem({});
    }
    if (convertImage) {
      handleConvertImage(oldDesc);
      setConvertImage(false);
    }
    setDescriptions(oldDesc);
    setDescriptionName('');
    setDescriptionDetail('');
  };

  const handleConvertImage = async descriptions => {
    const desc = document.createElement('div');
    desc.innerHTML = descriptionDetail;
    desc.setAttribute(
      'style',
      `width: 500px; height:500px; background:${bgColor}`
    );
    desc.classList.add(
      'd-flex',
      'justify-content-center',
      'align-items-center'
    );
    document.body.appendChild(desc);
    const canvas = await html2canvas(desc);
    document.body.removeChild(desc);
    canvas.toBlob(async blob => {
      const img = document.createElement('img');
      img.src = URL.createObjectURL(blob);
      const imgFile = new File([blob], descriptionName);
      Object.assign(imgFile, {
        preview: URL.createObjectURL(blob)
      });
      setFormValues({
        ...formValues,
        descriptions: descriptions,
        files: [
          ...formValues.files,
          {
            file: imgFile,
            alt_text: descriptionName,
            id: formValues.files.length + 1
          }
        ]
      });
    });
  };

  const handleCancelEdit = () => {
    setEditItem({});
    setDescriptionName('');
    setDescriptionDetail('');
  };

  const handleCopyDescription = e => {
    e.preventDefault();
    if (!selectedCopyProduct.descriptions) {
      toast.error(
        `Please select the product whose description you want to copy`,
        {
          theme: 'colored'
        }
      );
      return;
    }
    setFormValues({
      ...formValues,
      descriptions: selectedCopyProduct.descriptions
    });
  };

  return (
    <div>
      <Row>
        <Col xs={9}>
          <Row>
            <Col xs={9}>
              <Form.Group className="">
                <Form.Label>Select Product to Copy Description</Form.Label>
                <Select
                  options={productOptions}
                  placeholder="Select the product whose description you want to copy"
                  classNamePrefix="react-select"
                  name="copy_product_description"
                  onChange={setSelectedCopyProduct}
                />
              </Form.Group>
            </Col>
            <Col xs={3} className="align-self-end col-3 text-end">
              <Button onClick={handleCopyDescription}>Copy Descripiton</Button>
            </Col>
          </Row>
        </Col>
        <Col xs={9}>
          <Form.Group className="mb-3" controlId="descriptionName">
            <Form.Label>Description Name</Form.Label>
            <Form.Control
              {...register('descriptionName')}
              type="text"
              name="descriptionName"
              value={descriptionName}
              onChange={e => setDescriptionName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description Detail</Form.Label>
            <TextEditor
              value={descriptionDetail}
              name="description"
              tabIndex={2}
              onChange={setDescriptionDetail}
            />
          </Form.Group>
          <Flex justifyContent="between" alignItems="right" className="flex-1">
            <Form.Group
              className="d-flex gap-2 align-items-center"
              controlId="convertToImage"
            >
              <Form.Check
                type="checkbox"
                className="d-inline-block"
                name="convertToImage"
                label="Convert To Image"
                checked={convertImage}
                onChange={e => setConvertImage(e.target.checked)}
              />
              {convertImage && (
                <Form.Control
                  type="color"
                  defaultValue="#ffffff"
                  title="Choose background color"
                  onChange={e => setBgColor(e.target.value)}
                />
              )}
            </Form.Group>
            <div>
              <Button
                className="mx-3"
                variant="secondary"
                onClick={handleCancelEdit}
                style={{ visibility: editItem.id ? 'visible' : 'hidden' }}
              >
                Cancel
              </Button>
              <Button variant="success" onClick={handleSave}>
                {editItem.id ? 'Update' : 'Add'} Description
              </Button>
            </div>
          </Flex>
        </Col>
        <Col xs={3} className="mt-4">
          <DraggableList
            data={descriptions}
            setState={setDescriptions}
            setSelectedItem={handleSelectedItem}
            isEditable={true}
            isDeletable={true}
          />
        </Col>
      </Row>
      <ConfirmationModal
        showConfirm={confirm}
        setShowConfirm={handleSetShowConfirm}
        title={'Product description deleted'}
        description={'The product description will be deleted, are you sure?'}
        type={'delete'}
        request={handleRemoveDescription}
      />
    </div>
  );
};

ProductDescriptions.propTypes = {
  register: PropTypes.func,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func
};

export default ProductDescriptions;
