import Divider from 'components/common/Divider';
import React, { useContext, useEffect, useState } from 'react';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext } from 'context/Context';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { createWarehouse, updateWarehouse } from 'services/warehouse';
import PhoneInput from 'components/input/PhoneInput';
import serviceUrl from '../../../services/serviceUrlList';

const AddWarehouse = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      isActive: true,
      name: '',
      address: '',
      city: '',
      district: '',
      contactName: '',
      contactPhone: '',
      contactEmail: ''
    }
  });

  useEffect(() => {
    if (id) {
      setValue('updated_by', user.id);
    } else {
      setValue('created_by', user.id);
    }
  }, []);

  const { response } = id
    ? useFetch({
        url: serviceUrl.warehouse.warehouse.detail + id
      })
    : {};
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.warehouse.warehouse.detail + id
  });

  const onSubmit = async data => {
    if (id) {
      updateRequest(data);
    } else {
      postRequest(data);
    }
  };

  const updateRequest = async payload => {
    const response = await updateWarehouse(payload, id);
    if (response.status === 200) {
      handleRouteList();
      toast.success(`Warehouse updated`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.name[0], {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };
  const postRequest = async payload => {
    const response = await createWarehouse(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Warehouse created`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.name[0], {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Warehouse',
      description: 'Are you sure? Selected warehouse will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    handleRouteList();
    toast.success(`Warehouse deleted`, {
      theme: 'colored'
    });
  }, [deleteResponse]);

  useEffect(() => {
    if (!response) return;
    const { data } = response;
    setValue('isActive', data.isActive);
    setValue('name', data.name);
    setValue('address', data.address);
    setValue('city', data.city);
    setValue('district', data.district);
    setValue('contactName', data.contactName);
    setValue('contactPhone', data.contactPhone);
    setValue('contactEmail', data.contactEmail);
  }, [response]);

  const handleRouteList = () => {
    navigate('/stock/warehouse');
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {id ? 'Update' : 'Create'} Warehouse
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -40
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                required
                {...register('isActive', { required: true })}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="name">
                  <Form.Label as={Col} xs={2}>
                    Name
                  </Form.Label>
                  <Col xs={10}>
                    <Form.Control
                      {...register('name', {
                        required: 'This field cannot be empty!'
                      })}
                      isInvalid={errors.name}
                      type="text"
                      name="name"
                      placeholder="Warehouse name"
                      tabIndex={1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.name?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="address">
                  <Form.Label as={Col} xs={2}>
                    Address
                  </Form.Label>
                  <Col xs={10}>
                    <Form.Control
                      {...register('address')}
                      as="textarea"
                      placeholder="Address"
                      rows={3}
                      name="address"
                      tabIndex={2}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="district">
                  <Form.Label as={Col} xs={2}>
                    District
                  </Form.Label>
                  <Col xs={10}>
                    <Form.Control
                      {...register('district')}
                      type="text"
                      name="district"
                      placeholder="District"
                      tabIndex={3}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="city">
                  <Form.Label as={Col} xs={2}>
                    City
                  </Form.Label>
                  <Col xs={10}>
                    <Form.Control
                      {...register('city')}
                      type="text"
                      name="city"
                      placeholder="City"
                      tabIndex={4}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Divider />
              <Col xs={12} lg={4}>
                <Form.Group as={Row} className="mb-3" controlId="responsible">
                  <Form.Label as={Col} xs={4}>
                    Responsible
                  </Form.Label>
                  <Col xs={8}>
                    <Form.Control
                      {...register('contactName')}
                      type="text"
                      name="contactName"
                      placeholder="Responsible"
                      tabIndex={5}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group as={Row} className="mb-3" controlId="phone">
                  <Form.Label as={Col} xs={4}>
                    Phone Number
                  </Form.Label>
                  <Col xs={8}>
                    <Controller
                      name="contactPhone"
                      control={control}
                      // eslint-disable-next-line no-unused-vars
                      render={({ field: { ref, ...rest } }) => (
                        <PhoneInput name="phone" {...rest} />
                      )}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group as={Row} className="mb-3" controlId="mail">
                  <Form.Label as={Col} xs={4}>
                    E-mail
                  </Form.Label>
                  <Col xs={8}>
                    <Form.Control
                      {...register('contactEmail', {
                        pattern: {
                          value: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
                          message: 'Entered value does not match email format'
                        }
                      })}
                      isInvalid={errors?.contactEmail}
                      type="text"
                      name="contactEmail"
                      placeholder="E-Mail"
                      tabIndex={7}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.contactEmail?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </>
  );
};

export default AddWarehouse;
