import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Col, Row } from 'react-bootstrap';

export const data = [
  {
    id: 0,
    title: 'Windows Chrome Login',
    text: 'Logged in from near Istanbul',
    time: '30.01.2023 14:22'
  },
  {
    id: 1,
    title: 'Macos Safari Login',
    text: 'Logged in from near Istanbul',
    time: '29.01.2023 14:22'
  },
  {
    id: 2,
    title: 'Android App Login',
    text: 'Logged in from near Istanbul',
    time: '28.01.2023 14:22'
  },
  {
    id: 3,
    title: 'Iphone App Login',
    text: 'Logged in from near Istanbul',
    time: '27.01.2023 14:22'
  },
  {
    id: 4,
    title: 'Windows Chrome Login',
    text: 'Logged in from near Istanbul',
    time: '26.01.2023 14:22'
  }
];

const Activity = ({ activity: { title, text, time }, isLast }) => {
  return (
    <Row className="mx-3">
      <Col>
        <Row
          className={classNames('g-3 mt-1 ', {
            'border-bottom pb-card': !isLast
          })}
        >
          <Col>
            <h6 className="text-800 mb-1">{title}</h6>
            <p className="fs--1 text-600 mb-0">{text}</p>
          </Col>
          <Col xs="auto">
            <p className="fs--2 text-500 mb-0">{time}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const LoginSession = () => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Login Sessions
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {data.map((activity, index) => (
          <Activity
            key={activity.id}
            activity={activity}
            isLast={index === data.length - 1}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired
  }),
  isLast: PropTypes.bool
};

export default LoginSession;
