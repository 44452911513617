import { protectedApi } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const createBlog = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.blog.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateBlog = async (payload, slug) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.content.blog.detail + slug,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
