import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getServingUnitMini } from 'services/product';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

// Highlight array olarak servise kayıt edilemiyor. Cümle içerisinde virgül kullanılma ihtimaline karşın aşşağıdaki değer ile ayrılarak yazılıyor.
const HIGHLIGHT_SEPERATOR = '-!-';

function ProductHighlight({ register, errors, formValues, setFormValues }) {
  const [highlightInput, setHighlightInput] = useState('');
  const { data } = useQuery(['serving-unit-mini'], () => getServingUnitMini(), {
    keepPreviousData: false,
    staleTime: Infinity
  });
  const servingUnitOptions = data?.map(dt => ({
    value: dt.id,
    label: dt.name
  }));

  const handleServingUnitChange = value => {
    setFormValues({ ...formValues, serving_unit: value });
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleHighlight = event => {
    event.preventDefault();
    setHighlightInput(event.target.value);
  };
  const handleHighlightKeyDown = event => {
    if (event.key !== 'Enter') return;
    event.preventDefault();
    let highlightArr = formValues.highlight_detail
      ? formValues.highlight_detail.split(HIGHLIGHT_SEPERATOR)
      : [];
    if (highlightArr.length >= 3) {
      toast.error(`Maximum 3 highlight!`, {
        theme: 'colored'
      });
      return;
    }
    highlightArr.push(highlightInput);
    setFormValues({
      ...formValues,
      highlight_detail: highlightArr.join(HIGHLIGHT_SEPERATOR)
    });
    setHighlightInput('');
  };

  const handleRemoveHighlight = index => {
    const highlightArr = [
      ...formValues.highlight_detail.split(HIGHLIGHT_SEPERATOR)
    ];
    highlightArr.splice(index, 1);
    setFormValues({
      ...formValues,
      highlight_detail: highlightArr.join(HIGHLIGHT_SEPERATOR)
    });
  };
  const highlightRender =
    formValues.highlight_detail && formValues.highlight_detail.length > 0 ? (
      formValues.highlight_detail.split(HIGHLIGHT_SEPERATOR).map((dt, i) => (
        <div
          key={i}
          className="border px-2 rounded-3 bg-white dark__bg-1000 my-1 fs--1 mx-1"
        >
          <span className="mx-2 flex-1">{dt}</span>
          <span
            className="text-300 p-1 ml-auto cursor-pointer"
            onClick={() => handleRemoveHighlight(i)}
          >
            <FontAwesomeIcon icon="times" />
          </span>
        </div>
      ))
    ) : (
      <></>
    );

  return (
    <Row>
      <Form.Group as={Col} xs={4} className="mb-3" controlId="serving_size">
        <Form.Label>Serving Size</Form.Label>
        <Form.Control
          {...register('serving_size', { required: false })}
          isInvalid={errors.serving_size}
          type="number"
          name="serving_size"
          value={formValues.serving_size}
          onChange={handleChange}
          className="input-spin-none"
          onWheel={e => e.target.blur()}
        />
        <Form.Control.Feedback type="invalid">
          This field cannot be empty!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={4} className="mb-3" controlId="serving_capacity">
        <Form.Label>Serving Capacity</Form.Label>
        <Form.Control
          {...register('serving_capacity', { required: false })}
          isInvalid={errors.serving_capacity}
          type="number"
          name="serving_capacity"
          value={formValues.serving_capacity}
          onChange={handleChange}
          className="input-spin-none"
          onWheel={e => e.target.blur()}
        />
        <Form.Control.Feedback type="invalid">
          This field cannot be empty!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={4} className="mb-3" controlId="serving_unit">
        <Form.Label>Serving Unit</Form.Label>
        <Select
          options={servingUnitOptions}
          placeholder="Select Serving Unit"
          classNamePrefix="react-select"
          name="copy_product_description"
          value={formValues.serving_unit}
          onChange={handleServingUnitChange}
        />
        <Form.Control.Feedback type="invalid">
          This field cannot be empty!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={4} className="mb-3" controlId="highlight_title">
        <Form.Label>Highlight Title</Form.Label>
        <Form.Control
          {...register('highlight_title', { required: false })}
          isInvalid={errors.highlight_title}
          type="text"
          name="highlight_title"
          value={formValues.highlight_title}
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          This field cannot be empty!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={8} className="mb-3" controlId="highlight_detail">
        <Form.Label>Highlight Detail</Form.Label>
        <Form.Control
          type="text"
          name="highlight_detail"
          placeholder="Split your higlights with enter"
          value={highlightInput}
          onChange={handleHighlight}
          onKeyDown={handleHighlightKeyDown}
        />
        <Flex
          direction={'row'}
          alignItems="center"
          wrap="wrap"
          className="mt-2"
        >
          {highlightRender}
        </Flex>
      </Form.Group>
    </Row>
  );
}

ProductHighlight.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func
};

export default ProductHighlight;
