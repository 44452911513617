import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CheckoutDetail from './module/CheckoutDetail';
import CustomerInfo from './module/CustomerInfo';

const AbandonedDetail = () => {
  return (
    <Row>
      <Col xs={12} lg={8}>
        <CheckoutDetail />
      </Col>
      <Col xs={12} lg={4}>
        <CustomerInfo />
      </Col>
    </Row>
  );
};

export default AbandonedDetail;
