export const queryReducer = (state, { type, payload }) => {
  switch (type) {
    case 'PAGE_CHANGED':
      return {
        ...state,
        pageIndex: payload
      };
    case 'PAGE_SIZE_CHANGED':
      return {
        ...state,
        pageSize: payload
      };
    case 'TOTAL_COUNT_CHANGED':
      return {
        ...state,
        totalCount: payload
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
