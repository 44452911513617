import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ImagePreview from './ImagePreview';

const ProductMedia = props => {
  const { formValues, setFormValues } = props;

  const setState = (value, isMobile) => {
    if (!value) return;
    if (isMobile) {
      setFormValues({ ...formValues, files_mobile: value });
    } else {
      setFormValues({ ...formValues, files: value });
    }
  };

  return (
    <Card className="mb-3">
      <Card.Header>Media</Card.Header>
      <Card.Body>
        <Row>
          <Col lg={6}>
            <span>Desktop</span>
            <ImagePreview
              formValues={formValues}
              setFormValues={setFormValues}
              setState={setState}
            />
          </Col>
          <Col lg={6}>
            <span>Mobile</span>
            <ImagePreview
              formValues={formValues}
              setFormValues={setFormValues}
              setState={setState}
              isMobile={true}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

ProductMedia.propTypes = {
  formValues: PropTypes.object,
  setFormValues: PropTypes.func
};

export default ProductMedia;
