import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import serviceUrl from '../../../services/serviceUrlList';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import useDelete from 'hooks/useDelete';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import Divider from 'components/common/Divider';
import SeoSetting from 'components/common/SeoSetting';
import TextEditor from 'components/common/TextEditor';
import { AuthContext } from 'context/Context';
import { useForm } from 'react-hook-form';
import SelectBox from 'components/common/SelectBox';
import { createFaq, updateFaq } from 'services/content';

const AddFaq = () => {
  const initialForm = {
    is_active: true,
    category: '',
    title: '',
    description: '',
    site: 1,
    seo_setting: ''
  };
  const [seoSetting, setSeoSetting] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const { slug } = useParams();
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: initialForm });

  const { response } = slug
    ? useFetch({
        url: serviceUrl.content.faq.detail + slug
      })
    : {};
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.content.faq.detail + slug
  });

  const { response: categoriesResponse } = useFetch({
    url: serviceUrl.content.faqcategory.list
  });
  const categoryOptions = categoriesResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });

  const handleSeoValue = seoValue => {
    setValue('seo_setting', seoValue);
  };

  const onSubmit = async data => {
    if (slug) {
      updateRequest({ ...data, category: data.category?.value });
    } else {
      postRequest({ ...data, category: data.category?.value });
    }
  };

  const updateRequest = async payload => {
    const response = await updateFaq(payload, slug);
    if (response.status === 200) {
      handleRouteList();
      toast.success(`Faq updated`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.title[0], {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const postRequest = async payload => {
    const response = await createFaq(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Faq created`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.title[0], {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  useEffect(() => {
    if (slug) {
      setValue('updated_by', user.id);
    } else {
      setValue('created_by', user.id);
    }
  }, []);

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Faq',
      description: 'Are you sure? Selected faq will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    handleRouteList();
    toast.success(`Faq deleted`, {
      theme: 'colored'
    });
  }, [deleteResponse]);

  const handleRouteList = () => {
    navigate('/cms/faq');
  };

  useEffect(() => {
    if (!response) return;
    const { data } = response;
    setValue('is_active', data.is_active);
    setValue(
      'category',
      data.category?.id
        ? { value: data.category.id, label: data.category.name }
        : undefined
    );
    setValue('description', data.description);
    setValue('title', data.title);

    setSeoSetting(data.seo_setting);
  }, [response]);

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {slug ? 'Update Faq' : 'Create Faq'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -10
              }}
            >
              <Form.Select
                {...register('is_active', { required: true })}
                size="sm"
                className="mb-3"
                name="is_active"
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="category">
              <Form.Label>Category</Form.Label>
              <SelectBox
                control={control}
                name="category"
                rules={{ required: true }}
                className={errors.category ? 'is-invalid' : ''}
                options={categoryOptions}
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                {...register('title', { required: true })}
                isInvalid={errors.title}
                type="text"
                name="title"
                placeholder="Title"
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <TextEditor
                name="description"
                control={control}
                tabIndex={1} // tabIndex of textarea
              />
            </Form.Group>
            <Divider />
            <SeoSetting
              seoValues={handleSeoValue}
              defaultValue={seoSetting}
              register={register}
              errors={errors}
            />
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!slug ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {slug ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </Fragment>
  );
};

export default AddFaq;
