import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import AppContext from 'context/Context';
import useDelete from 'hooks/useDelete';
import usePost from 'hooks/usePost';
import useUpdate from 'hooks/useUpdate';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import { protectedApi } from 'services/axios';
import { getAttributes } from 'services/product';
import serviceUrl from 'services/serviceUrlList';

const Attributes = props => {
  const { newClickCount } = props;
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    is_active: true,
    type: '',
    content: ''
  });
  const [distinctValues, setDistinctValues] = useState({});
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm();

  const openCreateModal = () => {
    getDistinct();
    setIsCreateModalOpen(true);
  };
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };

  const {
    data,
    isLoading: tableLoading,
    refetch
  } = useQuery(['getAttributes', queryObj], () => getAttributes(queryObj), {
    keepPreviousData: false,
    staleTime: Infinity
  });

  const [postResponse, postRequest] = usePost({
    url: serviceUrl.product.attribute.list,
    payload: {
      ...formData,
      type: formData.type?.value,
      content: formData.content?.value
    }
  });
  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.product.attribute.detail + selectedRowId,
    payload: {
      ...formData,
      type: formData.type?.value,
      content: formData.content?.value
    }
  });
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.attribute.detail + selectedRowId
  });

  const getDistinct = async () => {
    const response = await getAttributesDistinct();
    const type = response.data.result.map(dt => ({
      value: dt.type,
      label: dt.type
    }));
    setDistinctValues({ type: type });
  };

  useEffect(() => {
    if (newClickCount === 0) return;
    openCreateModal(true);
  }, [newClickCount]);

  const onSubmit = () => {
    if (selectedRowId === null) {
      postRequest();
    } else {
      // Update Request !
      updateRequest();
    }
  };
  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.error === null) {
      closeCreateModal();
      refetch();
      toast.success(`Attribute created`, {
        theme: 'colored'
      });
    } else {
      if (postResponse.error.data.non_field_errors) {
        toast.error(`Already Exist!`, {
          theme: 'colored'
        });
      }
    }
  }, [postResponse]);
  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.error) return;
    if (updateResponse.error === null) {
      closeCreateModal();
      refetch();
      toast.success(`Attribute updated`, {
        theme: 'colored'
      });
    } else {
      if (updateResponse.error.data.non_field_errors) {
        toast.error(`Already Exist!`, {
          theme: 'colored'
        });
      }
    }
  }, [updateResponse]);

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = async (info, target) => {
    let name = target.name;
    setFormData({ ...formData, [name]: info });
    setValue(`${name}`, info);
    if (name === 'type') {
      const response = await getAttributesDistinct({
        attribute_type: info.value
      });
      const content = response.data.result.map(dt => ({
        value: dt.content,
        label: dt.content
      }));
      setDistinctValues(prevState => ({
        ...prevState,
        content: content
      }));
    }
  };

  const handleEditItem = dt => {
    setSelectedRowId(dt.id);
    setFormData({
      is_active: dt.is_active,
      type: { label: dt.type, value: dt.type },
      content: { label: dt.content, value: dt.content }
    });
    setValue('type', { label: dt.type, value: dt.type });
    setValue('content', { label: dt.content, value: dt.content });
    openCreateModal(true);
  };

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    closeCreateModal();
    if (deleteResponse.error === null) {
      refetch();
      toast.success(`Attribute deleted`, {
        theme: 'colored'
      });
    } else {
      if (deleteResponse.error.data.status === 405) {
        toast.error(deleteResponse.error.data.message, {
          theme: 'colored'
        });
      } else {
        toast.error('Oopss! Something went wrong :(', {
          theme: 'colored'
        });
      }
    }
    setSelectedRowId(null);
  }, [deleteResponse]);

  const handleModalDeleteButton = () => {
    if (selectedRowId === null) {
      closeCreateModal();
    } else {
      handleDeleteItem(selectedRowId);
    }
  };

  const clearFormData = () => {
    setFormData({
      is_active: true,
      type: '',
      content: ''
    });
    clearErrors();
    reset();
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'type',
      Header: 'Type',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { type } = rowData.row.original;
        return (
          <a href="#" onClick={() => handleEditItem(rowData.row.original)}>
            {type}
          </a>
        );
      }
    },
    {
      accessor: 'content',
      Header: 'Content',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: dt => {
        const { is_active } = dt.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handleEditItem(dt.row.original)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={tableLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <TableHeader
            table
            handleRefetchAll={refetch}
            setQueryObj={setQueryObj}
          />
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 align-middle'
              }}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Add Attribute</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                top: 10
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                name="is_active"
                required
                value={formData.is_active}
                onChange={handleChange}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="type">
              <Form.Label className="fs-0">Type</Form.Label>
              <CreatableSelect
                {...register('type', {
                  required: 'This field cannot be empty!'
                })}
                className={errors.type ? 'is-invalid' : ''}
                classNamePrefix="react-select"
                name="type"
                value={formData?.type}
                placeholder="Attribute Type"
                onChange={handleSelectChange}
                isClearable
                options={distinctValues.type}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.type?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="content">
              <Form.Label className="fs-0">Content</Form.Label>
              <CreatableSelect
                {...register('content', {
                  required: 'This field cannot be empty!'
                })}
                className={errors.content ? 'is-invalid' : ''}
                classNamePrefix="react-select"
                name="content"
                value={formData?.content}
                placeholder="Attribute Content"
                onChange={handleSelectChange}
                isDisabled={formData.type === ''}
                isClearable
                options={distinctValues?.content}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.content?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={handleModalDeleteButton}
            >
              {selectedRowId === null ? 'Cancel' : 'Delete'}
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Attribute"
        description="Are you sure the attribute you selected will be deleted?"
        request={deleteRequest}
        type={'delete'}
      />
    </div>
  );
};

Attributes.propTypes = {
  newClickCount: PropTypes.number
};

export default Attributes;

const TableHeader = props => {
  const { selectedRowIds, ids, handleRefetchAll, setQueryObj } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [action, setAction] = useState(undefined);
  const [payload, setPayload] = useState(undefined);
  const [postResponse, postRequest] = usePost({
    url: serviceUrl.product.bulk_actions,
    payload: payload
  });

  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    toast.success(`Bulk action completed`, {
      theme: 'colored'
    });
    handleRefetchAll();
  }, [postResponse]);

  const handleSetAction = e => {
    setAction(e.target.value);
  };

  const submitBulkAction = () => {
    if (action === undefined) {
      toast.error(`Select action for bulk action!`, {
        theme: 'colored'
      });
      return;
    }
    const payload_ = {
      table_name: 'ProductAttribute',
      id_list: ids
    };
    if (action === 'delete') {
      payload_.is_deleted = true;
      setConfirmText({
        title: 'Bulk Delete action',
        description: 'Are you sure? Selected attributes will be deleted!',
        type: 'delete'
      });
    } else if (action === 'passive') {
      payload_.is_active = false;
      setConfirmText({
        title: 'Bulk status action',
        description: 'Are you sure? Selected attributes will be archieved!',
        type: 'update'
      });
    } else if (action === 'active') {
      payload_.is_active = true;
      setConfirmText({
        title: 'Bulk status action',
        description: 'Are you sure? Selected attributes will be activated!',
        type: 'update'
      });
    }
    setShowConfirm(true);
    setPayload({ ...payload_ });
  };

  const handleAttributesSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search_value: value
    }));
  };

  return (
    <Row className="">
      <Col xs={6} className="d-flex align-items-center pe-0">
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter attributes by the type or content"
          setGlobalFilter={handleAttributesSearch}
        />
      </Col>
      <Col xs={4} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              value={action}
              onChange={handleSetAction}
              size="sm"
              aria-label="Bulk actions"
            >
              <option hidden>Bulk Actions</option>
              <option value="delete">Delete</option>
              <option value="passive">Passive</option>
              <option value="active">Active</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={submitBulkAction}
            >
              Apply
            </Button>
          </div>
        ) : null}
      </Col>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={postRequest}
        type={confirmText.type}
      />
    </Row>
  );
};

TableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  ids: PropTypes.array,
  handleRefetchAll: PropTypes.func,
  setQueryObj: PropTypes.func
};

export const getAttributesDistinct = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.attribute.distinct,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
