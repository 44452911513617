import React from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

const SelectBox = props => {
  const { name, control, options, rules, ...otherProps } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <ReactSelect {...field} options={options} {...otherProps} />
      )}
    />
  );
};

SelectBox.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.array,
  rules: PropTypes.object,
  otherProps: PropTypes.any
};

export default SelectBox;
