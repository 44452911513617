import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import SelectedProductList from './SelectedProductList';
import SelectProductsModal from './SelectProductsModal';

const SelectProducts = ({ selectedProducts, setSelectedProducts }) => {
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handelInputChange = event => {
    openModal();
    setSearchValue(event.target.value);
  };

  const modalProps = {
    searchValue,
    setSearchValue,
    showModal,
    closeModal,
    selectedProducts,
    setSelectedProducts
  };
  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Products</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={9}>
            <InputGroup>
              <InputGroup.Text>
                <BsSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="search-open-modal"
                placeholder="Search Products"
                value={''}
                onChange={handelInputChange}
              />
            </InputGroup>
          </Col>
          <Col xs={3}>
            <Button
              variant="outline-primary"
              className="px-3 mx-2"
              onClick={openModal}
            >
              Browse
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SelectedProductList
              products={selectedProducts}
              setProducts={setSelectedProducts}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
      <SelectProductsModal {...modalProps} />
    </Card>
  );
};

SelectProducts.propTypes = {
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func
};

export default SelectProducts;
