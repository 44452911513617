import Flex from 'components/common/Flex';
import MultipleImageUpload from 'components/common/MultipleImageUpload';
import SeoSetting from 'components/common/SeoSetting';
import TextEditor from 'components/common/TextEditor';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext } from 'context/Context';
import { IMAGE_SIZES } from 'helpers/image';
import { isEmpty } from 'helpers/utils';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { createBrand, getBrand, updateBrand } from 'services/product';

const AddBrand = () => {
  const formData = new FormData();
  const [showConfirm, setShowConfirm] = useState(false);
  const [seoSetting, setSeoSetting] = useState({});

  const [removedFiles, setRemovedFiles] = useState([]);
  const [defaultImg, setDefaultImg] = useState([]);
  const [pageName, setPageName] = useState({});
  const [deviceType, setDeviceType] = useState({});

  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      is_active: true,
      description: '',
      footer_description: ''
    }
  });
  const { user } = useContext(AuthContext);

  const pageOptions = [
    { value: 'product_detail', label: 'Product Detail' },
    { value: 'product_list', label: 'Product List' }
  ];
  const deviceOptions = [
    { value: 'desktop', label: 'Desktop' },
    { value: 'mobile', label: 'Mobile' }
  ];
  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      // display: 'flex',
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset'
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  const response = id
    ? useQuery(['brand-info', id], () => getBrand(id), {
        keepPreviousData: false,
        staleTime: Infinity
      })
    : {};

  useEffect(() => {
    if (id) {
      setValue('updated_by', user.id);
    } else {
      setValue('created_by', user.id);
    }
  }, []);

  const handleSeoValue = seoValue => {
    setValue('seo_setting', JSON.stringify(seoValue));
  };

  const onSubmit = async values => {
    const data = {
      ...values,
      ...removedFiles.reduce((acc, file) => {
        acc[file] = null;
        return acc;
      }, {})
    };
    const imageKeys = Object.keys(data).filter(
      key => key.startsWith('image') && key !== 'image_alt_text'
    );
    Object.keys(data).map(key => {
      if (!imageKeys.includes(key)) {
        formData.set(key, data[key]);
      }
      if (data[key] === null && imageKeys.includes(key)) {
        formData.append(key, new Blob(), '');
      }
      if (
        imageKeys.includes(key) &&
        typeof data[key] !== 'string' &&
        data[key] !== null
      ) {
        formData.set(key, data[key]);
      }
    });
    if (id) {
      const response = await updateBrand(id, formData);
      if (!response.data) return;
      if (response.status === 200) {
        toast.success('Brand successfully updated', { theme: 'colored' });
        handleRouteList();
      } else if (response.status === 400 && response.data.name) {
        toast.error(`Name: ${response.data.name[0]}`, {
          theme: 'colored'
        });
      } else {
        toast.error('Something went wrong! Brand cannot updated.', {
          theme: 'colored'
        });
      }
    } else {
      const response = await createBrand(formData);
      if (!response.data) return;
      if (response.status === 201) {
        toast.success('Brand successfully created', { theme: 'colored' });
        handleRouteList();
      } else if (response.status === 400 && response.data.name) {
        toast.error(`Name: ${response.data.name[0]}`, {
          theme: 'colored'
        });
      } else {
        toast.error('Something went wrong! Brand cannot created.', {
          theme: 'colored'
        });
      }
    }
  };

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Brand',
      description: 'Are you sure? Selected brand will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };

  const handleRouteList = () => {
    navigate('/product/product-brands');
  };

  useEffect(() => {
    if (!response || response.status !== 'success') return;
    const {
      name,
      description,
      footer_description,
      is_active,
      seo_setting,
      image_alt_text
    } = response.data.data;
    setValue('name', name);
    setValue('description', description);
    setValue('footer_description', footer_description);
    setValue('is_active', is_active);
    setValue('image_alt_text', image_alt_text);
    let temp = pageOptions
      .flatMap(page =>
        deviceOptions.map(device => {
          const imageKey = `image_${page.value}_${device.value}`;
          if (response.data.data[imageKey] !== null) {
            setValue(imageKey, response.data.data[imageKey]);
            return {
              image: response.data.data[imageKey],
              label: `${page.label} ${device.label}`,
              key: `image_${page.value}_${device.value}`
            };
          } else {
            return null;
          }
        })
      )
      .filter(item => item !== null);

    setDefaultImg(temp);
    setSeoSetting(seo_setting);
  }, [response.status]);

  const handleImgFile = imageObjs => {
    if (!pageName.value || !deviceType.value) return;
    imageObjs.map(imageObj => {
      setValue(`image_${imageObj.id}`, imageObj.file);
    });
  };

  const handleImageClick = e => {
    if (isEmpty(pageName) || isEmpty(deviceType)) {
      e.preventDefault();
      toast.error('Please select page name and device type!', {
        theme: 'colored'
      });
    } else if (getValues(`image_${pageName.value}_${deviceType.value}`)) {
      e.preventDefault();
      toast.error('An image already selected for this field!', {
        theme: 'colored'
      });
    }
  };

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {id ? 'Update Brand' : 'Create Brand'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -10
              }}
            >
              <Form.Select
                {...register('is_active', { required: true })}
                size="sm"
                className="mb-3"
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                {...register('name', { required: true })}
                isInvalid={errors.name}
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <TextEditor
                name="description"
                control={control}
                maxLength={5000}
                tabIndex={1} // tabIndex of textarea
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="footer_description">
              <Form.Label>Footer Description</Form.Label>
              <TextEditor
                name="footer_description"
                control={control}
                maxLength={5000}
                tabIndex={1} // tabIndex of textarea
              />
            </Form.Group>
            <Form.Group controlId="imageUpload" className="mb-3">
              <Form.Label>Upload Image</Form.Label>
              <MultipleImageUpload
                file={handleImgFile}
                defaultImg={defaultImg}
                onClick={handleImageClick}
                page={pageName}
                device={deviceType}
                altText={getValues('image_alt_text')}
                setRemovedFiles={setRemovedFiles}
                imageSize={
                  pageName?.value && deviceType?.value
                    ? IMAGE_SIZES['brand'][pageName.value][deviceType.value]
                    : []
                }
              >
                <Flex>
                  <Select
                    className="me-2 mb-2"
                    options={pageOptions}
                    styles={selectStyles}
                    placeholder="Page Name"
                    onChange={val => setPageName(val)}
                  />
                  <Select
                    className="me-2"
                    options={deviceOptions}
                    styles={selectStyles}
                    placeholder="Device Type"
                    onChange={val => setDeviceType(val)}
                  />
                </Flex>
              </MultipleImageUpload>
              <Form.Control
                {...register('image_alt_text')}
                type="text"
                size="sm"
                className="w-25 mt-2"
                placeholder="Alt Text"
              />
            </Form.Group>
            <SeoSetting
              seoValues={handleSeoValue}
              defaultValue={seoSetting}
              register={register}
              errors={errors}
            />
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={() => {}}
      />
    </Fragment>
  );
};

export default AddBrand;
