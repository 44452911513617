import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { MegaMenuStrictDroppable } from './MegaMenuStrict';
import { MdDragIndicator } from 'react-icons/md';
import { deleteMainMenu, updateMainMenu } from 'services/product';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { useParams } from 'react-router-dom';

function MegaMenuDnd({ data, handleEdit, refetch }) {
  const { mmid } = useParams();
  const [draggableData, setDraggableData] = useState(data);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  useEffect(() => {
    setDraggableData(data);
  }, [data]);

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };
  const onDragEnd = async result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = [...draggableData];
      const reorderedItems = reorder(items, source.index, destination.index);

      reorderedItems.forEach(async (item, index) => {
        await updateMainMenu(item.id, { order: index + 1 });
      });
      // await Promise.all(promise);
      const newList = reorderedItems.map((item, index) => ({
        ...item,
        order: index + 1
      }));
      setDraggableData(newList);
    }
    // Sürükleme işlemi sona erdiğinde yapılacak işlemler
  };

  const handleDeleteItem = async () => {
    await deleteMainMenu(deleteItem.id);
    // if deleteItem.id is !draggableData[0].id || !draggableData[draggableData.length -1].id then update
    if (deleteItem.id !== draggableData[draggableData.length - 1].id) {
      const filteredData = draggableData.filter(dt => dt.id !== deleteItem.id);
      filteredData.forEach(async (item, index) => {
        await updateMainMenu(item.id, { order: index + 1 });
      });
      setTimeout(async () => {
        await refetch();
        toast.success(`Menu item successfully deleted!`, {
          theme: 'colored'
        });
      }, 1000);
    } else {
      await refetch();
      toast.success(`Menu item successfully deleted!`, {
        theme: 'colored'
      });
    }
  };

  const handleDelete = async item => {
    setShowDeleteConfirm(true);
    setDeleteItem(item);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row>
        <Col xs={12}>
          <MegaMenuStrictDroppable droppableId="droppable" direction="vertical">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="border bg-white dark__bg-1000 rounded-2 py-3 mb-3"
              >
                {draggableData.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={`drag-${item.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          className="d-flex gap-5"
                          style={{
                            backgroundColor: snapshot.isDragging
                              ? 'lightblue'
                              : undefined,
                            padding: 8,
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            margin: 2
                          }}
                        >
                          <div {...provided.dragHandleProps}>
                            <MdDragIndicator />
                          </div>
                          <div style={{ width: 40 }}>{item.order}</div>
                          <div>
                            <a href={`/cms/megamenu/${mmid}/${item.id}`}>
                              {item.display_name
                                ? item.display_name
                                : '[Seviyesiz]'}
                            </a>
                          </div>
                          <div style={{ marginLeft: 'auto' }}>
                            <CardDropdown iconClassName="fs--1">
                              <div className="py-2">
                                <Dropdown.Item
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDelete(item)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </div>
                            </CardDropdown>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </MegaMenuStrictDroppable>
        </Col>
      </Row>
      <ConfirmationModal
        showConfirm={showDeleteConfirm}
        setShowConfirm={setShowDeleteConfirm}
        title="Delete Menu Item"
        description="Are you sure you want to delete this menu item?"
        request={handleDeleteItem}
        type="delete"
      />
    </DragDropContext>
  );
}

export default MegaMenuDnd;

MegaMenuDnd.propTypes = {
  data: PropTypes.array,
  handleEdit: PropTypes.func,
  refetch: PropTypes.func
};
