import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'context/Context';

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const value = { user, setUser };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthProvider;
