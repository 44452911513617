/* eslint-disable no-unused-vars */
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext, LoaderContext } from 'context/Context';
import { saveAs } from 'file-saver';
import { currencyFormat } from 'helpers/utils';
import useUpdate from 'hooks/useUpdate';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import {
  deleteProduct,
  getProductList,
  productBulkPriceUpdate,
  updateProduct
} from 'services/product';
import * as XLSX from 'xlsx';
import serviceUrl from '../../../services/serviceUrlList';
import ProductsTableFilter from './module/ProductsTableFilter';
import BulkPricesImportResponseModal from './module/BulkPricesImportResponseModal';

const ProductList = () => {
  const { setLoading } = useContext(LoaderContext);
  const { user } = useContext(AuthContext);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [bulkPriceImportError, setBulkPriceImportError] = useState({
    isOpen: false,
    title: 'Bulk Prices Import Error',
    message: '',
    list: []
  });
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: '',
    request: () => {}
  });
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    is_active: true
  });
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const { isLoading, data, refetch } = useQuery(
    ['product-list', queryObj, sort],
    () =>
      getProductList({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: false
    }
  );
  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.product.product.detail + selectedRowId?.id,
    payload: { is_active: false }
  });
  const navigate = useNavigate();
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );

  const handleProductSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search: value
    }));
  };

  const handleCollectionFilter = values => {
    setQueryObj(prev => ({
      ...prev,
      collections__id__in: values.map(el => el.value).join(',')
    }));
  };
  const handleCategoryFilter = values => {
    setQueryObj(prev => ({
      ...prev,
      category_id__in: values.map(el => el.value).join(',')
    }));
  };
  const handleBrandFilter = values => {
    setQueryObj(prev => ({
      ...prev,
      brand_id__in: values.map(el => el.value).join(',')
    }));
  };
  const handleTagFilter = values => {
    setQueryObj(prev => ({
      ...prev,
      tags__name__in: values.map(el => el.value).join(',')
    }));
  };

  const handleTabFilter = value => {
    if (value === 'all') {
      setQueryObj(prevState => {
        const { is_active, is_draft, is_archived, ...rest } = prevState;
        return rest;
      });
    } else if (value === 'active') {
      setQueryObj(prev => {
        const { is_draft, is_archived, ...rest } = prev;
        return {
          ...rest,
          is_active: true
        };
      });
    } else if (value === 'passive') {
      setQueryObj(prev => {
        const { is_draft, is_archived, ...rest } = prev;
        return {
          ...rest,
          is_active: false
        };
      });
    } else if (value === 'draft') {
      setQueryObj(prev => {
        const { is_active, is_archived, ...rest } = prev;
        return {
          ...rest,
          is_draft: true
        };
      });
    } else if (value === 'archived') {
      setQueryObj(prev => {
        const { is_active, is_draft, ...rest } = prev;
        return {
          ...rest,
          is_archived: true
        };
      });
    }
  };

  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);

  const handleDeleteItem = row => {
    setSelectedRowId({ id: row.slug, type: 'delete', product_type: row.type });
    setShowConfirm(true);
  };
  const handleArchieveItem = id => {
    setSelectedRowId({ id: id, type: 'update' });
    setShowConfirm(true);
  };
  const handleNewProduct = () => {
    navigate('/product/product-add?type=single');
  };
  const handleNewSetProduct = () => {
    navigate('/product/product-add?type=set');
  };
  const handleNewBundleProduct = () => {
    navigate('/product/product-add?type=bundle');
  };

  useEffect(() => {
    setTableData(data?.data.results);
  }, [data]);

  useEffect(() => {
    if (!selectedRowId) return;
    if (selectedRowId.type === 'update') {
      setConfirmText({
        title: 'Archieve Product',
        description: 'Are you sure? Selected product will be archieved!',
        type: 'update',
        request: updateRequest
      });
    } else if (selectedRowId.type === 'delete') {
      setConfirmText({
        title: 'Delete Product',
        description: 'Are you sure? Selected product will be deleted!',
        type: 'delete',
        request: handleDeleteProduct
      });
    }
  }, [selectedRowId]);

  const handleDeleteProduct = async () => {
    setLoading(true);
    let deleteResponse;
    if (selectedRowId.product_type !== 1) {
      const updateProduct_ = await updateProduct(
        { products: [] },
        selectedRowId.id
      );
      if (updateProduct_.status === 200) {
        deleteResponse = await deleteProduct(user.id, selectedRowId.id);
      }
      // products alınını temizlemek için istek çık sonra sil
    } else {
      // direkt sil
      deleteResponse = await deleteProduct(user.id, selectedRowId.id);
    }
    if (!deleteResponse) {
      toast.error(`Uppss! something went wrong!!`, {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }
    if (deleteResponse.status === 204) {
      refetch();
      toast.success(`Product deleted`, {
        theme: 'colored'
      });
    } else {
      toast.error(`Uppss! something went wrong!!`, {
        theme: 'colored'
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.error) return;
    refetch();
    toast.success(`Product updated`, {
      theme: 'colored'
    });
  }, [updateResponse]);
  const columns = [
    {
      accessor: 'name',
      Header: 'Display Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { display_name, files, slug } = rowData.row.original;
        return (
          <Link to={`/product/product-update/${slug}`}>
            <Flex alignItems="center">
              {files?.length > 0 && (
                <img
                  className="rounded-1 border border-200 me-2 img-50-contain"
                  src={files[0].file}
                  width="60"
                  alt={files[0].alt_text}
                  style={{
                    aspectRatio: '3/2',
                    objectFit: 'contain'
                  }}
                />
              )}
              <div className="flex-1">
                <h5 className="mb-0 fs--1">{display_name}</h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'category.name',
      Header: 'Category',
      cellProps: { className: 'text-center' }
    },
    {
      accessor: 'collections',
      Header: 'Collections',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { collections } = rowData.row.original;
        return collections.map((el, i) => {
          return (
            <span key={el.id}>
              {el.name}
              {collections.length - 1 !== i ? ', ' : ''}
            </span>
          );
        });
      }
    },
    {
      accessor: 'main_collection.name',
      Header: 'Main Collection',
      cellProps: { className: 'text-center' }
    },
    {
      accessor: 'price',
      Header: 'Price',
      cellProps: { className: 'white-space-nowrap text-center' },
      Cell: rowData => {
        const { total_price } = rowData.row.original;
        return <span>{currencyFormat(total_price)} ₺</span>;
      }
    },
    {
      accessor: 'stock_quantity',
      Header: 'Stock Count',
      cellProps: { className: 'text-center' }
    },

    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_active } = rowData.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },

    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { slug } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(`/product/product-update/${slug}`)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleArchieveItem(slug);
                }}
              >
                Archive
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleDeleteItem(rowData.row.original)}
              >
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];
  const handleImportPrices = async event => {
    if (isLoadingExport) return;
    setIsLoadingExport(true);
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    toast.info(
      'Importing product prices. This process can take a while, Do not close your window!',
      {
        theme: 'colored'
      }
    );
    const response = await productBulkPriceUpdate(formData);
    if (response.status === 200) {
      toast.success('Product prices updated successfully', {
        theme: 'colored'
      });
    } else {
      if (response.data.list) {
        setBulkPriceImportError({
          isOpen: true,
          title: response.data.message,
          list: response.data.list
        });
      } else {
        // serializer erros.
        toast.error('Something went wrong, Please check your excel file!', {
          theme: 'colored'
        });
      }
    }
    setIsLoadingExport(false);
    event.target.value = '';
  };

  const handleExportExcel = async () => {
    if (isLoadingExport) return;
    setIsLoadingExport(true);
    try {
      const response = await getProductList({ limit: 100000 });
      const dt = response?.data?.results ?? [];
      const header = {
        sku: 'SKU',
        name: 'Name',
        display_name: 'Display Name',
        total_price: 'Total Price',
        total_post_price: 'Total Post Price',
        kdv: 'KDV',
        brand: 'Brand',
        tags: 'Tags',
        collections: 'Collections',
        main_collection: 'MainCollection',
        skt: 'SKT',
        highlight_title: 'Highlight Title',
        highlight_detail: 'Highlight Detail',
        page_title: 'Meta Description',
        meta_desc: 'Meta Title'
      };

      const body = [];
      dt?.forEach(row => {
        const tempRow = [];
        Object.keys(header).forEach(column => {
          let value = null;
          if (
            column === 'total_price' ||
            column === 'total_post_price' ||
            column === 'kdv'
          ) {
            const activePrice = row.prices.filter(price => price.is_active)[0];
            value =
              column !== 'kdv'
                ? `${currencyFormat(activePrice ? activePrice[column] : 0)} ₺`
                : `%${activePrice ? activePrice[column] : 0}`;
          } else if (column === 'tags' || column === 'collections') {
            value = row[column].map(col => col.name).join(', ');
          } else if (
            column === 'tags' ||
            column === 'main_collection' ||
            column === 'brand'
          ) {
            value = row[column]?.name;
          } else if (column === 'page_title') {
            value = row.seo_setting && row.seo_setting[column];
          } else if (column === 'meta_desc') {
            value = row.seo_setting && row.seo_setting[column]?.description;
          }
          tempRow.push(value ?? row[column]);
        });

        body.push(tempRow);
      });

      const worksheet = XLSX.utils.aoa_to_sheet([
        Object.values(header),
        ...body
      ]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        `Product List ${moment().format('DD.MM.YYYY')}.xlsx`
      );

      // eslint-disable-next-line no-empty
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={tableData || []}
          selection
          sortable
          pagination
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Products"
                handleRefetch={refetch}
                table
                handleNew={handleNewProduct}
                bulkTable={'Product'}
                isCustomNew={true}
                customNew={[
                  { label: 'Single', callBack: handleNewProduct },
                  { label: 'Set', callBack: handleNewSetProduct },
                  { label: 'Bundle', callBack: handleNewBundleProduct }
                ]}
              />
              <ProductsTableFilter
                handleProductSearch={handleProductSearch}
                handleCategoryFilter={handleCategoryFilter}
                handleCollectionFilter={handleCollectionFilter}
                handleBrandFilter={handleBrandFilter}
                handleTagFilter={handleTagFilter}
                handleTabFilter={handleTabFilter}
                handleExport={handleExportExcel}
                handleImportPrices={handleImportPrices}
                isLoadingExport={isLoadingExport}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                rowClassName="align-middle"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                height={'auto'}
                sort={sort}
                setSort={setSort}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={confirmText.request}
        type={confirmText.type}
      />
      <BulkPricesImportResponseModal
        isOpen={bulkPriceImportError.isOpen}
        closeModal={() => {
          setBulkPriceImportError({ isOpen: false });
        }}
        title={bulkPriceImportError.title}
      >
        {bulkPriceImportError.list?.map((error, i) => (
          <p
            style={{
              paddingBottom: '10px',
              borderBottom: '1px solid #f0f0f0'
            }}
            key={i}
          >
            {error}
          </p>
        ))}
      </BulkPricesImportResponseModal>
    </div>
  );
};

export default ProductList;
