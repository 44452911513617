import React, { useEffect, useReducer, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Card, Dropdown } from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import TableHeader from 'components/common/TableHeader';
import { useNavigate } from 'react-router-dom';
import CardDropdown from 'components/common/CardDropdown';
import serviceUrl from 'services/serviceUrlList';
import useDelete from 'hooks/useDelete';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { Link } from 'react-router-dom';
import { queryReducer } from 'reducers/queryReducer';
import { useQuery } from 'react-query';
import { getWarehouses } from 'services/warehouse';

const WarehouseList = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });

  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.warehouse.warehouse.detail + selectedRowId
  });
  const navigate = useNavigate();

  const { isLoading, data, refetch } = useQuery(
    ['users', queryObj, sort],
    () =>
      getWarehouses({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: true
    }
  );

  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setConfirmText({
      title: 'Delete Warehouse',
      description: 'Are you sure? Selected warehouse will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };

  useEffect(() => {
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    refetch();
    toast.success(`Warehouse deleted`, {
      theme: 'colored'
    });
    setSelectedRowId(null);
  }, [deleteResponse]);

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: dt => {
        const { name, id } = dt.row.original;
        return <Link to={`/stock/warehouse-detail/${id}`}>{name}</Link>;
      }
    },
    {
      accessor: 'location',
      Header: 'Location',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: dt => {
        const { city, district } = dt.row.original;
        return (
          <>
            {district} {city}
          </>
        );
      },
      disableSortBy: true
    },
    {
      accessor: 'contactName',
      Header: 'Responsible',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true
    },
    {
      accessor: 'product_type_quantity',
      Header: 'Product Type Quantity',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      disableSortBy: true
    },
    {
      accessor: 'product_quantity',
      Header: 'Product Quantity',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      disableSortBy: true
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(`/stock/warehouse-update/${id}`)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleNew = () => {
    navigate('/stock/warehouse-add');
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          sortable
          pagination
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Warehouses"
                isBulkAction={false}
                handleNew={handleNew}
                table
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                rowClassName="align-middle"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                setSort={setSort}
                sort={sort}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={deleteRequest}
        type={confirmText.type}
      />
    </div>
  );
};

export default WarehouseList;
