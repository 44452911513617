import PropTypes from 'prop-types';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconButton from 'components/common/IconButton';
import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';

const GiftCardTableFilter = ({ handleGiftCardSearch }) => {
  return (
    <Row className="flex-between-center">
      <Col xs={12} className="mb-4">
        <Nav variant="tabs" defaultActiveKey="all">
          <Nav.Item>
            <Nav.Link eventKey="all">All</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="redeemable">Redeemable</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="full">Full</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="partial">Partial</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="empty">Empty</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="disabled">Disabled</Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col xs="auto" sm={6} lg={4}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter Gift Cards"
          setGlobalFilter={handleGiftCardSearch}
        />
      </Col>
      <Col xs="auto" lg={8} className="d-flex justify-content-end">
        <Row>
          <Col xs="auto">
            <IconButton
              // onClick={handleNew}
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
          </Col>
          <Col xs="auto">
            <IconButton
              // onClick={handleNew}
              variant="falcon-default"
              size="sm"
              icon="arrow-up-wide-short"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Sort</span>
            </IconButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
GiftCardTableFilter.propTypes = {
  handleGiftCardSearch: PropTypes.func
};

export default GiftCardTableFilter;
