import React, { useState } from 'react';
import DefinitionCard from '../DefinitionCard';
import CancelRefundReasons from './tabs/CancelRefundReasons';
import CommunicationSubjects from './tabs/CommunicationSubjects';
import DeclinedReasons from './tabs/DeclinedReasons';
import InterestArea from './tabs/InterestArea';

const General = () => {
  const [activeTab, setActiveTab] = useState('communication_subject');
  const [newClickCount, setNewClickCount] = useState(0);
  const tabs = [
    {
      name: 'Communication Subject',
      eventKey: 'communication_subject'
    },
    {
      name: 'Interest Area',
      eventKey: 'interest_area'
    },
    {
      name: 'Cancellation Reason',
      eventKey: 'cancel_refund_reasons'
    },
    {
      name: 'Declined Reason',
      eventKey: 'declined_reasons'
    }
  ];
  const tabChange = tab => {
    setActiveTab(tab);
    setNewClickCount(0);
  };

  const handleNew = () => {
    setNewClickCount(newClickCount + 1);
  };

  return (
    <div>
      <DefinitionCard
        title="General"
        tabs={tabs}
        tabChange={tabChange}
        defaultActiveKey={tabs[0].eventKey}
        tabContent={
          activeTab === 'communication_subject' ? (
            <CommunicationSubjects newClickCount={newClickCount} />
          ) : activeTab === 'interest_area' ? (
            <InterestArea newClickCount={newClickCount} />
          ) : activeTab === 'cancel_refund_reasons' ? (
            <CancelRefundReasons newClickCount={newClickCount} />
          ) : activeTab === 'declined_reasons' ? (
            <DeclinedReasons newClickCount={newClickCount} />
          ) : null
        }
        handleNew={handleNew}
      />
    </div>
  );
};

export default General;
