import AppContext from 'context/Context';
import { IMAGE_SIZES, getDimensions } from 'helpers/image';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';

function BannerInput({
  height = 225,
  id,
  products,
  blogs,
  brands,
  collections,
  handleBannerPayload,
  initialValue,
  handleInputChange,
  slugLoading
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const {
    config: { isDark }
  } = useContext(AppContext);
  const initialForm = {
    banner_id: id,
    name: '',
    image: null,
    image_alt_text: '',
    slug_type: '',
    object_id: '',
    is_active: true
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    formState: { errors },
    resetField
  } = useForm({ defaultValues: initialForm });

  const slug_type = useWatch({ control, name: 'slug_type' });

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
    handleValues();
  };

  const handleImageChange = async event => {
    const file_ = event.target.files[0];
    const { name } = event.target;
    if (!file_) return;
    const dimensions = await getDimensions(file_);
    const { width, height } = dimensions;
    const limits =
      id === 1
        ? IMAGE_SIZES['home']['main'][name === 'image' ? 'desktop' : 'mobile']
        : IMAGE_SIZES['home']['side'][name === 'image' ? 'desktop' : 'mobile'];
    if (width < limits[0] || height < limits[1]) {
      toast.error(
        `Image size must be greater than ${limits[0]}x${limits[1]}px`,
        {
          theme: 'colored'
        }
      );
      event.preventDefault();
      setValue('image', null);
      return;
    }
  };

  const handleValues = async () => {
    if (!initialValue) return;
    setValue('name', initialValue.name);
    // setValue('image', initialValue.image);
    setImagePreview(initialValue.image);
    setValue('image_alt_text', initialValue.image_alt_text);
    setValue('slug_type', {
      value: initialValue.slug_type,
      label: initialValue.slug_type
    });
    switch (initialValue.slug_type) {
      case 'product': {
        const urun = products.find(dt => dt.id === initialValue.object_id);
        setValue('object_id', { value: urun?.id, label: urun?.name });
        break;
      }
      case 'blog': {
        const newBlog = blogs.find(dt => dt.id === initialValue.object_id);
        setValue('object_id', { value: newBlog?.id, label: newBlog?.name });
        break;
      }

      case 'brand': {
        const marka = brands.find(dt => dt.id === initialValue.object_id);
        setValue('object_id', { value: marka.id, label: marka.name });
        break;
      }
      case 'collection': {
        const koleksiyon = collections.find(
          dt => dt.id === initialValue.object_id
        );
        setValue('object_id', { value: koleksiyon.id, label: koleksiyon.name });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (!initialValue) return;
    handleValues();
  }, [initialValue]);

  const onSubmit = data => {
    if ((!initialValue || !initialValue.image) && !data.image) {
      setError('image', { message: 'This field cannot be empty!' });
      return;
    }
    const payload = {
      ...data,
      object_id: data.object_id.value,
      slug_type: data.slug_type.value
    };
    if (data.image) {
      payload.image = data.image[0];
      setImagePreview(URL.createObjectURL(data.image[0]));
    }
    if (data.image_mobile) {
      payload.image_mobile = data.image_mobile[0];
      setImagePreview(URL.createObjectURL(data.image_mobile[0]));
    }
    handleBannerPayload(id, payload);
    closeModal();
  };
  const objectIdOptions =
    slug_type.value === 'product'
      ? products.map(dt => ({ value: dt.id, label: dt.name }))
      : slug_type.value === 'blog'
      ? blogs.map(dt => ({ value: dt.id, label: dt.name }))
      : slug_type.value === 'brand'
      ? brands.map(dt => ({ value: dt.id, label: dt.name }))
      : slug_type.value === 'collection'
      ? collections.map(dt => ({ value: dt.id, label: dt.name }))
      : [];
  const customStylesSlugType = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.slug_type
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };
  const customStylesSlug = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.object_id
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          border: '1px solid #ddd',
          borderRadius: 8,
          height: height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          background: imagePreview
            ? `url(${imagePreview}) no-repeat center center`
            : undefined,
          backgroundSize: imagePreview ? 'contain' : undefined
        }}
        onClick={openModal}
      >
        {!imagePreview && 'Banner 1'}
      </div>
      <Modal show={isModalOpen} onHide={closeModal} contentClassName="border">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Banner {id}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="fs-0">Name</Form.Label>
              <Form.Control
                {...register('name', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.name}
                type="text"
                name="name"
                placeholder="Name"
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="image">
              <Form.Label className="fs-0">Image</Form.Label>
              <Row className="mb-3">
                <Form.Label as={Col} xs={2} className="fs-0">
                  Desktop
                </Form.Label>
                <Col xs={10}>
                  <Form.Control
                    {...register('image', {
                      required: false
                    })}
                    isInvalid={errors.image}
                    type="file"
                    placeholder="Image"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.image?.message}
                </Form.Control.Feedback>
              </Row>
              <Row>
                <Form.Label as={Col} xs={2} className="fs-0">
                  Mobile
                </Form.Label>
                <Col xs={10}>
                  <Form.Control
                    {...register('image_mobile', {
                      required: false
                    })}
                    isInvalid={errors.image_mobile}
                    type="file"
                    placeholder="Image"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.image_mobile?.message}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="image_alt_text">
              <Form.Label className="fs-0">Image alt text</Form.Label>
              <Form.Control
                {...register('image_alt_text', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.image}
                type="text"
                name="image_alt_text"
                placeholder="Image Alt Text"
              />
              <Form.Control.Feedback type="invalid">
                {errors.image_alt_text?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="slug_type">
              <Form.Label className="fs-0">Slug Type</Form.Label>
              <Controller
                name="slug_type"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    options={[
                      { value: 'product', label: 'Product' },
                      { value: 'blog', label: 'Blog' },
                      { value: 'collection', label: 'Collection' },
                      { value: 'brand', label: 'Brand' }
                    ]}
                    onChange={e => {
                      onChange(e);
                      resetField('object_id');
                    }}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    placeholder="Slug Type"
                    isSearchable={false} // Arama çubuğunu devre dışı bırakır
                    classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                    components={{
                      IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                    }}
                    className={errors.slug_type ? 'is-invalid' : ''}
                    styles={{ ...customStylesSlugType }}
                  />
                )}
                rules={{ required: 'This field cannot be empty!' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.slug_type?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="object_id">
              <Form.Label className="fs-0">Slug</Form.Label>
              <Controller
                name="object_id"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    isDisabled={!slug_type}
                    options={objectIdOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    placeholder="Slug"
                    onInputChange={val =>
                      handleInputChange(val, slug_type.value)
                    }
                    isLoading={slugLoading}
                    isSearchable={true} // Arama çubuğunu devre dışı bırakır
                    classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                    components={{
                      IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                    }}
                    className={errors.object_id ? 'is-invalid' : ''}
                    styles={{ ...customStylesSlug }}
                  />
                )}
                rules={{ required: 'This field cannot be empty!' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.object_id?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button variant="danger" className="px-3 mx-2" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default BannerInput;

BannerInput.propTypes = {
  height: PropTypes.number,
  id: PropTypes.number,
  products: PropTypes.array,
  blogs: PropTypes.array,
  brands: PropTypes.array,
  collections: PropTypes.array,
  handleBannerPayload: PropTypes.func,
  initialValue: PropTypes.object,
  handleInputChange: PropTypes.func,
  slugLoading: PropTypes.bool
};
