import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import useDelete from 'hooks/useDelete';
import moment from 'moment';
import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import serviceUrl from 'services/serviceUrlList';
import { getCustomers } from 'services/user';
import CustomerTableFilter from './module/CustomerTableFilter';

const CustomerList = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    page: 1,
    conditions: []
  });

  const navigate = useNavigate();
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const { data, isLoading, refetch } = useQuery(
    ['getUsers', queryObj, sort],
    () =>
      getCustomers({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.user.customer.detail2 + selectedRowId
  });

  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    refetch();
    toast.success(`Customer deleted`, {
      theme: 'colored'
    });
    setSelectedRowId(null);
  }, [deleteResponse]);

  const handleEditItem = ({ id }) => {
    navigate(`/customer/${id}`);
  };

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setShowConfirm(true);
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: Number(pageSize),
      page: pageIndex + 1
    });
  }, [pageSize, pageIndex, totalCount]);

  const handleCustomerSearch = value => {
    const newConditions = [...queryObj.conditions];
    const searchCondition = newConditions.find(dt => dt.key === 'full_name');
    if (searchCondition) {
      searchCondition.value = value;
    } else {
      newConditions.push({
        key: 'full_name',
        type: 'in',
        value
      });
    }
    setQueryObj(prevState => ({
      ...prevState,
      conditions: newConditions
    }));
  };
  const handleSubscriptionFilter = (option, key, type) => {
    const newConditions = [...queryObj.conditions];
    const searchCondition = newConditions.find(dt => dt.key === key);
    if (option === null) {
      clearCondition(key);
      return;
    }
    if (searchCondition) {
      searchCondition.value = option.value;
    } else {
      newConditions.push({
        key: key,
        type: type,
        value: option.value
      });
    }
    setQueryObj(prevState => ({
      ...prevState,
      conditions: newConditions
    }));
  };
  const clearCondition = key => {
    const newConditions = [...queryObj.conditions];
    const clearCondition = newConditions.find(dt => dt.key === key);
    if (clearCondition) {
      newConditions.splice(newConditions.indexOf(clearCondition), 1);
    }
    setQueryObj(prevState => ({
      ...prevState,
      conditions: newConditions
    }));
  };

  useEffect(() => {
    if (!data?.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.count
    });
  }, [data?.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'full_name',
      Header: 'Customer Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { full_name, id } = rowData.row.original;
        return <Link to={`/customer/${id}`}>{full_name}</Link>;
      }
    },
    {
      accessor: 'is_subscribe_user',
      Header: 'Membership',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'text-start'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { is_subscribe_user } = rowData.row.original;
        if (is_subscribe_user) {
          return (
            <SoftBadge pill bg="success">
              Member
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger">
            Non-Member
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'newsletter',
      Header: 'Newsletter',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { is_newsletter_registered } = rowData.row.original;
        return is_newsletter_registered ? (
          <SoftBadge pill bg="success">
            Yes
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="danger">
            No
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'is_iys_registered',
      Header: 'IYS',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { is_iys_registered } = rowData.row.original;
        return is_iys_registered ? 'Approved' : 'Not approved';
      }
    },
    {
      accessor: 'city',
      Header: 'Location',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { city } = rowData.row.original;
        return <Fragment>{city}</Fragment>;
      }
    },
    {
      accessor: 'total_order_count',
      Header: 'Orders',
      headerProps: { className: 'pe-1 text-end' },
      cellProps: {
        className: 'py-2 text-end'
      },
      Cell: rowData => {
        const { total_order_count } = rowData.row.original;
        return <Fragment>{total_order_count ?? 0}</Fragment>;
      }
    },
    {
      accessor: 'total_order_amount',
      Header: 'Spent',
      headerProps: { className: 'pe-1 text-end' },
      cellProps: {
        className: 'py-2 text-end'
      },
      Cell: rowData => {
        const { total_order_amount } = rowData.row.original;
        return (
          <Fragment>{`${total_order_amount?.toFixed(2) ?? 0} ₺`}</Fragment>
        );
      }
    },
    {
      accessor: 'date_joined',
      Header: 'Joined',
      headerProps: { className: 'pe-1 text-end' },
      cellProps: {
        className: 'py-2 text-end'
      },
      Cell: rowData => {
        const { date_joined } = rowData.row.original;
        return <span>{moment(date_joined).format('DD/MM/YYYY HH:mm')}</span>;
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      headerProps: { className: 'pe-1 text-end' },
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handleEditItem(dt.row.original)}>
                Go Detail
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];
  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.customer_list || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Customers"
                bulkTable="customer"
                handleRefetchAll={refetch}
                table
              />
              <CustomerTableFilter
                handleCustomerSearch={handleCustomerSearch}
                handleSubscriptionFilter={handleSubscriptionFilter}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                rowClassName="align-middle"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                sort={sort}
                setSort={setSort}
              />
            </Card.Body>
            <Card.Footer>{<AdvanceTablePagination table />}</Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Customer"
        description="Are you sure the customer you selected will be deleted?"
        request={deleteRequest}
        type={'delete'}
      />
    </div>
  );
};

export default CustomerList;
