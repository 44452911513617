import React, { useEffect, useReducer, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { queryReducer } from 'reducers/queryReducer';
import {
  getCounting,
  getCountingReport,
  updateCounting
} from 'services/warehouse';
import TableHeader from 'components/common/TableHeader';
import { useForm } from 'react-hook-form';
import { statusOptions } from './module/Static';
import SelectBox from 'components/common/SelectBox';
import moment from 'moment';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { toast } from 'react-toastify';
import ModalSelectExportType from './module/ModalSelectExportType';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { setFontOpenSans } from './module/opensans';

const CountingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    countingMetaId: id
  });
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const {
    setValue,
    control,
    formState: { errors }
  } = useForm();

  const { data } = useQuery(
    ['getCounting'],
    () =>
      getCounting({
        id: id
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const { data: dataDetail, refetch } = useQuery(
    ['getCountingReport', queryObj, sort],
    () =>
      getCountingReport({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);

  const columns = [
    {
      accessor: 'qrCode',
      Header: 'QR Code',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'pe-1 text-end' }
    },
    {
      accessor: 'stock__barcode',
      Header: 'Barcode (SKU)',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'pe-1 text-end' }
    },
    {
      accessor: 'stock__name',
      Header: 'Product Name',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'stock__category__name',
      Header: 'Product Category',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'qrCode__stockLotTrace__date',
      Header: 'SKT',
      headerProps: { className: 'pe-1 text-start', style: { width: 80 } },
      Cell: rowData => {
        const { qrCode__stockLotTrace__date } = rowData.row.original;
        return <>{moment(qrCode__stockLotTrace__date).format('DD/MM/yyyy')} </>;
      }
    },
    {
      accessor: 'quaintity',
      Header: 'Quantity',
      headerProps: { className: 'pe-1 text-end', style: { width: 30 } },
      cellProps: { className: 'pe-1 text-end' }
    }
  ];

  useEffect(() => {
    refetch();
    if (!data?.data) return;
    const res = data.data.results[0];
    setValue(
      'status',
      statusOptions.filter(e => e.value === res.status)
    );
    setIsBtnDisabled(res.status !== 1);
    setIsStatusDisabled(res.status > 1);
  }, [data]);

  const handleStatusChange = option => {
    setConfirmText({
      title: 'Status Change',
      description: `Are you sure, you are about to change statuss to ${option.label}!`,
      type: 'update',
      data: option
    });
    setShowConfirm(true);
  };
  const handleStatusUpdate = async () => {
    const response = await updateCounting(
      { status: confirmText.data.value },
      id
    );
    if (response.status === 200) {
      toast.success(`Counting status updated.`, {
        theme: 'colored'
      });
      setValue('status', confirmText.data);
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const handleNew = () => {
    navigate(`/stock/counting-product-add/${id}`);
  };
  const handleUpdate = () => {
    navigate(`/stock/counting-product-update/${id}`);
  };
  const handleExport = async type => {
    closeModal();
    const res = await getCountingReport({ countingMetaId: id });
    if (res.status !== 200) {
      toast.error('Data cannot be fetched.', { theme: 'colored' });
      return;
    }
    const headerNames = columns.map(column => column.Header);
    const exportData = res?.data.map(row =>
      columns.map(column => row[column.accessor])
    );

    if (type === 'PDF') {
      setFontOpenSans();
      const doc = new JsPDF('p', 'pt');
      var totalPagesExp = '{total_pages_count_string}';
      const text = `Date: ${moment(data?.data.results[0].date).format(
        'DD/MM/yyyy'
      )}\nWarehouse: ${
        data?.data.results[0].storageLocation.name
      }\nResponsible: ${data?.data.results[0].responsible}\nDescription: ${
        data?.data.results[0].description
      }`;

      var pageContent = function (data) {
        // FOOTER
        var str = 'Page ' + data.pageCount;
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp;
        }
        var pageHeight =
          doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        doc.setFontSize(8);
        doc.text(str, data.settings.margin.left, pageHeight - 10);

        // HEADER
        doc.setFont('OpenSans', 'normal');
        doc.setTextColor(40);
        doc.setFontSize(16);
        doc.text('Inventory Counting Report', data.settings.margin.left, 22);
        doc.setFontSize(10);
        doc.text(text, data.settings.margin.left, 40);
      };

      doc.autoTable({
        didDrawPage: pageContent,
        head: [headerNames],
        body: exportData,
        margin: { top: 10, bottom: 10 },
        startY: 90,
        bodyStyles: { valign: 'top' },
        styles: {
          font: 'OpenSans',
          overflow: 'linebreak',
          showHeader: 'firstPage'
        },
        columnStyles: { text: { cellWidth: 'wrap' } }
      });

      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
      }
      doc.save(`InventoryCountingDetail-${id}.pdf`);
      return false;
    } else if (type === 'CSV') {
      // CSV example
      headerNames.push('Date', 'Warehouse', 'Responsible', 'Description');
      exportData.map(row => {
        return row.push(
          moment(data?.data.results[0].date).format('DD/MM/yyyy'),
          data?.data.results[0].storageLocation.name,
          data?.data.results[0].responsible,
          data?.data.results[0].description
        );
      });
      const csvString = Papa.unparse({ fields: headerNames, data: exportData });
      const csvBlob = new Blob([csvString], { type: 'text/csv' });
      FileSaver.saveAs(csvBlob, `InventoryCountingDetail-${id}.csv`);
    }
    return false;
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <TableHeader
            title="Inventory Counting Detail"
            isBulkAction={false}
            actionBtn={[
              {
                label: 'Export',
                callBack: openModal,
                prop: {
                  disabled: isBtnDisabled
                }
              },
              {
                label: 'Update',
                callBack: handleUpdate,
                prop: {
                  disabled: isBtnDisabled
                }
              },
              {
                label: 'Add',
                callBack: handleNew,
                prop: {
                  disabled: isBtnDisabled
                }
              }
            ]}
            table
          />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group as={Row} className="mb-3" controlId="date">
                <Col xs={4}>
                  <Form.Label>Date</Form.Label>
                </Col>
                <Col xs={8}>
                  <Form.Control
                    value={
                      moment(data?.data.results[0].date).format('DD/MM/yyyy') ??
                      ''
                    }
                    plaintext
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group as={Row} className="mb-3" controlId="status">
                <Col xs={4}>
                  <Form.Label>Status</Form.Label>
                </Col>
                <Col xs={8}>
                  <SelectBox
                    options={statusOptions}
                    onChange={handleStatusChange}
                    name="status"
                    control={control}
                    rules={{ required: 'This field cannot be empty' }}
                    className={errors.status ? 'is-invalid' : ''}
                    placeholder="Status"
                    classNamePrefix="react-select"
                    isDisabled={isStatusDisabled}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors?.status?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group as={Row} className="mb-3" controlId="storageLocation">
                <Col xs={4}>
                  <Form.Label>Warehouse</Form.Label>
                </Col>
                <Col xs={8}>
                  <Form.Control
                    value={data?.data.results[0].storageLocation.name ?? ''}
                    plaintext
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group as={Row} className="mb-3" controlId="responsible">
                <Col xs={4}>
                  <Form.Label>Responsible</Form.Label>
                </Col>
                <Col xs={8}>
                  <Form.Control
                    value={data?.data.results[0].responsible ?? ''}
                    plaintext
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group as={Row} className="mb-3" controlId="description">
                <Col xs={4} lg={2}>
                  <Form.Label>Description</Form.Label>
                </Col>
                <Col xs={8} lg={10}>
                  <Form.Control
                    value={data?.data.results[0].description ?? ''}
                    plaintext
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Col xs={12}>
            <AdvanceTableWrapper
              columns={columns}
              data={dataDetail?.data || []}
              pagination
              queryPageIndex={pageIndex}
              totalCount={totalCount}
              dispatch={dispatch}
            >
              <Card className="mb-3">
                <Card.Body className="p-0">
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 table-bordered'
                    }}
                    rowClassName="align-middle"
                    setSort={setSort}
                    sort={sort}
                  />
                </Card.Body>
                <Card.Footer>
                  <AdvanceTablePagination table />
                </Card.Footer>
              </Card>
            </AdvanceTableWrapper>
          </Col>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={handleStatusUpdate}
        type={confirmText.type}
      />
      <ModalSelectExportType
        showModal={showModal}
        handleCloseModal={closeModal}
        onClick={handleExport}
      />
    </>
  );
};

export default CountingDetail;
