import React from 'react';
import LoginForm from './LoginForm';
import bgImg from 'assets/img/generic/14.jpg';
import Flex from 'components/common/Flex';
import AuthLayout from './AuthLayout';

const Login = () => {
  return (
    <AuthLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Login</h3>
      </Flex>
      <LoginForm layout="split" hasLabel />
    </AuthLayout>
  );
};

export default Login;
