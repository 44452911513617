import { protectedApi } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const createCollection = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.collection.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateCollection = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.collection.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createGiftCard = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.giftcard.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateGiftCard = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.user.giftcard.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteGiftCard = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.user.giftcard.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getGiftCard = async id => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.giftcard.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateOrder = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.order.order.detail2 + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const cancelFullfillment = async deliveryCode => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.transport.detail + deliveryCode + '/'
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
