import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext } from 'context/Context';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createHeaderBanner, updateHeaderBanner } from 'services/content';
import serviceUrl from '../../../services/serviceUrlList';

function AddHeaderBanner() {
  const initialForm = {
    is_active: true,
    image: '',
    text: '',
    url: '',
    bg_color: '#123456',
    font_color: '#FFFFFF',
    site: 1
    // publish_order: 1
  };
  const fileInput = useRef(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [image, setImage] = useState('');
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const { slug } = useParams();
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: initialForm });

  const { response } = slug
    ? useFetch({
        url: serviceUrl.content.headerBanner.detail + slug
      })
    : {};
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.content.headerBanner.detail + slug
  });

  const onSubmit = async data => {
    let fData = new FormData();
    for (const key in data) {
      if (data[key]) {
        fData.append(key, data[key]);
      }
    }
    if (slug) {
      updateRequest(fData);
    } else {
      postRequest(fData);
    }
  };

  const updateRequest = async payload => {
    const response = await updateHeaderBanner(slug, payload);
    if (response.status === 200) {
      handleRouteList();
      toast.success(`Header banner updated`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.title[0], {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const postRequest = async payload => {
    const response = await createHeaderBanner(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Header banner created`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.title[0], {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  useEffect(() => {
    if (slug) {
      setValue('updated_by', user.id);
    } else {
      setValue('created_by', user.id);
    }
  }, []);

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Policy',
      description: 'Are you sure? Selected Header banner will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    handleRouteList();
    toast.success(`Header banner deleted`, {
      theme: 'colored'
    });
  }, [deleteResponse]);

  const handleRouteList = () => {
    navigate('/cms/header-banner');
  };

  useEffect(() => {
    if (!response) return;
    const { data } = response;
    setValue('is_active', data.is_active);
    setValue('text', data.text);
    setValue('url', data.url);
    setValue('bg_color', data.bg_color);
    setValue('font_color', data.font_color);
    // setValue('publish_order', data.publish_order);
    setValue('name', data.name);
    setImage(data.image);
  }, [response]);
  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {slug ? 'Update Header Banner' : 'Create Header Banner'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -10
              }}
            >
              <Form.Select
                {...register('is_active', { required: true })}
                size="sm"
                className="mb-3"
                name="is_active"
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Row>
              <Form.Group as={Col} md="4" className="mb-3" controlId="text">
                <Form.Label>Content</Form.Label>
                <Form.Control
                  {...register('text', { required: true })}
                  isInvalid={errors.text}
                  type="text"
                  name="text"
                  placeholder="text"
                />
                <Form.Control.Feedback type="invalid">
                  This field cannot be empty!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" className="mb-3" controlId="url">
                <Form.Label>Url</Form.Label>
                <Form.Control
                  {...register('url', { required: true })}
                  isInvalid={errors.url}
                  type="text"
                  name="url"
                  placeholder="Url"
                />
                <Form.Control.Feedback type="invalid">
                  This field cannot be empty!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  {...register('name', { required: true })}
                  isInvalid={errors.name}
                  type="text"
                  name="name"
                  placeholder="Name"
                />
                <Form.Control.Feedback type="invalid">
                  This field cannot be empty!
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4" className="mb-3" controlId="image">
                <Form.Label style={{ display: 'block' }}>Image</Form.Label>
                <Form.Control
                  {...register('image', { required: false })}
                  isInvalid={errors.image}
                  type="file"
                  name="image"
                  style={{ display: 'none' }}
                  onChange={event => {
                    const file_ = event.target.files[0];
                    if (!file_) return;
                    Object.assign(file_, {
                      preview: URL.createObjectURL(file_)
                    });
                    setImage(file_.preview);
                    setValue('image', file_);
                  }}
                  ref={fileInput}
                />
                <Button onClick={() => fileInput.current.click()}>
                  Upload Image
                </Button>
                {image && (
                  <Image
                    src={image}
                    width={50}
                    height={50}
                    style={{ marginLeft: 20, objectFit: 'contain' }}
                  />
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" className="mb-3" controlId="bg_color">
                <Form.Label>Background Color</Form.Label>
                <Form.Control
                  {...register('bg_color', { required: true })}
                  isInvalid={errors.bg_color}
                  name="bg_color"
                  type="color"
                />
                <Form.Control.Feedback type="invalid">
                  This field cannot be empty!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                md="4"
                className="mb-3"
                controlId="font_color"
              >
                <Form.Label>Font Color</Form.Label>
                <Form.Control
                  {...register('font_color', { required: true })}
                  isInvalid={errors.font_color}
                  name="font_color"
                  type="color"
                />
                <Form.Control.Feedback type="invalid">
                  This field cannot be empty!
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {/* <Row>
              <Form.Group
                as={Col}
                md="3"
                className="mb-3"
                controlId="publish_order"
              >
                <Form.Label>Publish Order</Form.Label>
                <Form.Control
                  {...register('publish_order', { required: true })}
                  isInvalid={errors.publish_order}
                  type="number"
                  name="publish_order"
                  placeholder="Publish Order"
                />
                <Form.Control.Feedback type="invalid">
                  This field cannot be empty!
                </Form.Control.Feedback>
              </Form.Group>
            </Row> */}
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!slug ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {slug ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </Fragment>
  );
}

export default AddHeaderBanner;
