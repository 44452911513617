import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const VariantsTableFilter = ({ eventKey, handleVariantSearch }) => {
  const handleFilter = ek => {
    eventKey(ek);
  };

  return (
    <Row className="flex-between-center">
      <Col xs={12} className="mb-4">
        <Nav variant="tabs" defaultActiveKey="all" onSelect={handleFilter}>
          <Nav.Item>
            <Nav.Link eventKey="all">All</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="active">Active</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="archieved">Archived</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="deleted">Deleted</Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col xs="auto" sm={6} lg={4}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter Variants"
          setGlobalFilter={handleVariantSearch}
        />
      </Col>
    </Row>
  );
};

VariantsTableFilter.propTypes = {
  eventKey: PropTypes.func,
  handleVariantSearch: PropTypes.func
};

export default VariantsTableFilter;
