import React from 'react';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { Button, Modal } from 'react-bootstrap';

const BulkPricesImportResponseModal = props => {
  const { isOpen, closeModal, title, children } = props;

  return (
    <Modal
      show={isOpen}
      onHide={() => closeModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <FalconCloseButton onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant={'primary'} onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

BulkPricesImportResponseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node
};

export default BulkPricesImportResponseModal;
