import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import logo from 'assets/img/fit1001/logo.png';
import useAuthentication from 'hooks/useAuthentication';
import serviceUrl from 'services/serviceUrlList';
import { toast } from 'react-toastify';

const ConfirmEmail = () => {
  const [confirmationStep, setConfirmationStep] = useState(0);
  const [email, setEmail] = useState('');
  const [confirmMailSend, setConfirmMailSend] = useState(false);
  const { key } = useParams();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [resendPostResponse, resendPostRequest] = useAuthentication({
    url: serviceUrl.authentication.resend_confirm_email,
    payload: { email: email }
  });
  const [postResponse, postRequest] = useAuthentication({
    url: serviceUrl.authentication.confirm_email,
    payload: { key: key }
  });
  useEffect(() => {
    postRequest();
  }, []);

  useEffect(() => {
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.error === null) {
      toast.success(`Your E-mail is confirmed!`, {
        theme: 'colored'
      });
      setConfirmationStep(1);
      setTimeout(() => {
        navigate('authentication/login');
      }, 1000);
    } else {
      toast.error(`Provided credentials are not correct!`, {
        theme: 'colored'
      });
      setConfirmationStep(2);
    }
  }, [postResponse]);

  const handleSubmit = e => {
    e.preventDefault();
    resendPostRequest();
  };

  useEffect(() => {
    if (!resendPostResponse.data && !resendPostResponse.error) return;
    if (resendPostResponse.error === null) {
      toast.success(`Your confirmation mail is sended!`, {
        theme: 'colored'
      });
      setConfirmMailSend(true);
    } else {
      toast.error(`Something went wrong!`, {
        theme: 'colored'
      });
      setConfirmMailSend(false);
    }
  }, [resendPostResponse]);

  return (
    <Row className="mt-5">
      <Col
        xs={12}
        lg={{ span: 8, offset: 2 }}
        className="justify-content-center"
      >
        <Card>
          <Card.Body className="text-center py-5">
            <Row className="justify-content-center">
              <Col xs={7} md={5}>
                <img className="img-fluid" src={logo} alt="image" width="58%" />
              </Col>
            </Row>
            <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
              E-mail Confirmation
            </h3>
            <p className="lead mb-5">
              {confirmationStep === 0
                ? 'Please wait we are confirming your e-mail address'
                : confirmationStep === 1
                ? 'Your e-mail is confirmed! We are sending you to login page'
                : 'Your token is expired. Please ask for another token!'}
            </p>
            {confirmationStep === 2 && !confirmMailSend && (
              <Row className="justify-content-center">
                <Col md={7}>
                  <Form as={Row} className="g-2">
                    <Col sm>
                      <Form.Control
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email address"
                        aria-label=""
                      />
                    </Col>
                    <Col sm="auto">
                      <Form.Group>
                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          className="d-block w-100"
                        >
                          Ask Token
                        </Button>
                      </Form.Group>
                    </Col>
                  </Form>
                </Col>
              </Row>
            )}
            {confirmMailSend && (
              <p className="lead mb-5">
                Confirmation mail sended. Please check your mails.
              </p>
            )}
          </Card.Body>
          <Card.Footer className="bg-light text-center pt-4"></Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

export default ConfirmEmail;
