/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';

const AdvanceTableSearchBox = ({
  globalFilter,
  setGlobalFilter = () => {},
  placeholder = 'Search...',
  size = 'sm'
}) => {
  const [value, setValue] = useState(globalFilter);
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || '');
  }, 200);

  useEffect(() => {
    if (!searchParams.has('search')) return;
    setValue(searchParams.get('search'));
  }, [searchParams]);

  return (
    <InputGroup className="position-relative">
      <FormControl
        value={value || ''}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange(value);
          setSearchParams(prev => {
            const search = new URLSearchParams(prev);
            if (value) {
              search.set('search', value);
              search.set('offset', 0);
              search.set('limit', 10);
            } else {
              search.delete('search');
            }
            return search.toString();
          });
        }}
        size={size}
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
      />
      <InputGroup.Text className="bg-transparent">
        <FontAwesomeIcon icon="search" className="fs--1 text-600" />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default AdvanceTableSearchBox;
