import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  renderRowSubComponent,
  height = 415,
  minHeight = 415,
  setSort = () => {},
  sort = {
    direction: 'none',
    accessor: ''
  }
}) => {
  // server side sorting
  const onHeaderClick = async column => {
    const temp = { ...sort };
    if (column.id !== temp.accessor) {
      temp.direction = 'none';
    }
    if (column.canSort) {
      switch (temp.direction) {
        case 'none':
          temp.direction = 'ASC';
          temp.accessor = column.id;
          break;
        case 'ASC':
          temp.direction = 'DESC';
          temp.accessor = column.id;
          break;
        case 'DESC':
          temp.direction = 'none';
          temp.accessor = '';
          break;
      }
    }
    setSort(temp);
  };
  return (
    <SimpleBarReact
      forceVisible
      style={{
        height: page.length < 6 ? height : undefined,
        minHeight: minHeight,
        overflowX: 'hidden'
      }}
    >
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
                onClick={() => (column.canSort ? onHeaderClick(column) : null)}
              >
                {column.render('Header')}
                {column.canSort ? (
                  sort.direction !== 'none' && column.id === sort.accessor ? (
                    sort.direction === 'DESC' ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Fragment key={i}>
                <tr className={rowClassName} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded &&
                row.original[tableProps.subvalue].length > 0 ? (
                  <>{renderRowSubComponent({ row })}</>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  renderRowSubComponent: PropTypes.func,
  height: PropTypes.any,
  minHeight: PropTypes.any,
  setSort: PropTypes.func,
  sort: PropTypes.object
};

export default AdvanceTable;
