import Flex from 'components/common/Flex';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';

const CustomerTableFilter = ({
  handleCustomerSearch,
  handleSubscriptionFilter
}) => {
  const subscriptionOptions = [
    { value: 'true', label: 'Member' },
    { value: 'false', label: 'Non-Member' }
  ];
  const newsletterOptions = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' }
  ];

  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      // display: 'flex',
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset'
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  return (
    <Row className="flex-start mt-2">
      <Col xs="auto" sm={6} lg={3}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter Customers"
          setGlobalFilter={handleCustomerSearch}
        />
      </Col>
      <Col xs="auto" lg={6}>
        <Flex justifyContent={'start'}>
          <Col>
            <Select
              className="me-2"
              options={subscriptionOptions}
              placeholder="Membership"
              styles={selectStyles}
              onChange={val => {
                handleSubscriptionFilter(val, 'is_subscribe_user', 'equal');
              }}
              isClearable
            />
          </Col>
          <Col>
            <Select
              className="me-2"
              options={newsletterOptions}
              placeholder="Newsletter"
              styles={selectStyles}
              onChange={val => {
                handleSubscriptionFilter(
                  val,
                  'is_newsletter_registered',
                  'equal'
                );
              }}
              isClearable
            />
          </Col>
        </Flex>
      </Col>
    </Row>
  );
};

export default CustomerTableFilter;

CustomerTableFilter.propTypes = {
  handleCustomerSearch: PropTypes.func,
  handleSubscriptionFilter: PropTypes.func,
  cityList: PropTypes.array
};
