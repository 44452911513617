import { formatNumber } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

const baseStyle = {
  display: 'block'
};
const labelStyle = {
  ...baseStyle,
  fontSize: 14,
  fontWeight: 400
};

const titleStyle = {
  ...baseStyle,
  fontSize: 15,
  fontWeight: 600
};

function ODTotal({ data }) {
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex gap-1">
          <div style={{ width: 200 }}>
            <span style={{ ...titleStyle, fontWeight: 400 }}>Subtotal</span>
            {data.discount_title && (
              <span style={{ ...titleStyle, fontWeight: 400 }}>Discount</span>
            )}
            <span style={{ ...titleStyle, fontWeight: 400 }}>Shipping</span>
            <span style={{ ...titleStyle }}>Total</span>
          </div>
          <div>
            <span style={{ ...labelStyle }}>{data.total_quantity} item</span>
            {data.discount_title && (
              <span style={{ ...labelStyle }}>{data.discount_title}</span>
            )}
            <span style={{ ...labelStyle }}>
              {data.is_shipment_free ? 'Free Shipment' : ''}
            </span>
          </div>
          <div className="ml-auto text-end">
            <span style={{ ...labelStyle }}>
              ₺{formatNumber(data.sub_total)}
            </span>
            {data.discount_title && (
              <span style={{ ...labelStyle }}>
                -₺{formatNumber(data.discount_amount)}
              </span>
            )}
            <span style={{ ...labelStyle }}>
              ₺
              {data.is_shipment_free
                ? formatNumber(0)
                : formatNumber(data.shipment_cost)}
            </span>
            <span style={{ ...labelStyle, fontWeight: 600 }}>
              ₺{formatNumber(data.total_amount)}
            </span>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <span style={{ ...titleStyle, fontWeight: 400 }}>
            Paid by customer
          </span>
          <span style={{ ...labelStyle }}>
            ₺{formatNumber(data.total_amount)}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
}
ODTotal.propTypes = {
  data: PropTypes.object
};

export default ODTotal;
