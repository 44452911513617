import DatePickerCustomInput from 'components/common/DatePickerCustomInput';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
const CardActiveDates = props => {
  const { setValue, control } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);

  const startDateWatch = useWatch({ control, name: 'start_date' });
  const endDateWatch = useWatch({ control, name: 'end_date' });

  const isValideDate = (date, name) => {
    const currentDate = new Date();
    if (name === 'start') {
      if (date >= moment(currentDate).startOf('day')) return date;
    } else {
      const sDate = new Date(startDate);
      if (
        date >= moment(currentDate).startOf('day') &&
        date > moment(sDate).startOf('day')
      )
        return date;
    }
  };

  useEffect(() => {
    setStartDate(moment(startDateWatch).toDate());
  }, [startDateWatch]);
  useEffect(() => {
    setEndDate(moment(endDateWatch).toDate());
  }, [endDateWatch]);

  const handleChange = (date, name) => {
    if (name === 'start') {
      setStartDate(date);
      setValue('start_date', moment(date).format('YYYY-MM-DDTHH:mm'));
    } else {
      setEndDate(date);
      setValue('end_date', moment(date).format('YYYY-MM-DDTHH:mm'));
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Active dates</Card.Title>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-1" controlId="startDate">
              <Form.Label>Start date</Form.Label>
              <DatePickerCustomInput
                filterDate={date => isValideDate(date, 'start')}
                selected={startDate}
                onChange={date => handleChange(date, 'start')}
                className="form-control"
                placeholderText="Select start date"
                timeIntervals={60}
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                showTimeSelect
                fixedHeight
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-1" controlId="endDate">
              <Form.Label>End date</Form.Label>
              <DatePickerCustomInput
                filterDate={date => isValideDate(date, 'end')}
                selected={endDate}
                onChange={date => handleChange(date, 'end')}
                formatWeekDay={day => day.slice(0, 3)}
                className="form-control"
                placeholderText="Select end date"
                timeIntervals={60}
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                showTimeSelect
                fixedHeight
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
CardActiveDates.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object
};
export default CardActiveDates;
