import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useQuery } from 'react-query';
import { getProductTags } from 'services/product';
import { getContentTags } from 'services/content';

function AutocompleteTag({
  placeholder = '',
  selected = [],
  onChange,
  service = 'product'
}) {
  const ref = useRef(null);
  const [value, setValue] = useState('');

  const { data } = useQuery(
    ['tags', value],
    () => {
      if (value.length < 1) return;
      if (service === 'product') {
        return getProductTags({ limit: 10, name__istartswith: value });
      } else if (service === 'content') {
        return getContentTags(`?limit=10&name__istartswith=${value}`);
      }
    },
    {
      keepPreviousData: false
    }
  );

  const handleOnChange = val => {
    if (typeof val[val.length - 1] === 'object') {
      const lastObject = val[val.length - 1];
      val.pop();
      val.push(lastObject.label);
    }
    onChange(val);
  };

  const options = data ? data.map(dt => dt.name) : [];

  return (
    <Typeahead
      id="basic-typeahead-multiple"
      allowNew
      multiple
      ref={ref}
      placeholder={placeholder}
      selected={selected}
      onChange={handleOnChange}
      options={options}
      onInputChange={setValue}
      onKeyDown={event => {
        if (event.key === 'Enter' && value.length > 0) {
          const temp = [...selected];
          temp.push(value);
          onChange(temp);
          setValue('');
          ref.current.clear();
        }
      }}
    />
  );
}

AutocompleteTag.propTypes = {
  placeholder: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  service: PropTypes.string
};

export default AutocompleteTag;
