import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePickerCustomInput from '../../../../components/common/DatePickerCustomInput';
import { createStock, getStocksWithSearch } from 'services/warehouse';
import CreatableSelect from 'react-select/creatable';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import AppContext from 'context/Context';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getProductListMini } from 'services/product';

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  setData,
  type = 'text',
  isDisabled
}) => {
  // We need to keep and update the state of the cell normally
  const [formValue, setFormValue] = useState(initialValue);
  const [selectOptions, setSelectOptions] = useState([]);
  const [isNameDisabled, setIsNameDisabled] = useState(false);

  // MODAL FUNCTIONS START //
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const width = useWatch({ control, name: 'width' });
  const height = useWatch({ control, name: 'height' });
  const length = useWatch({ control, name: 'length' });

  useEffect(() => {
    if (!width || !height || !length) return;
    setValue('desi', Math.ceil((width * height * length) / 3000));
  }, [width, height, length]);

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
  };
  const clearFormData = () => {
    clearErrors();
    reset();
  };
  const onSubmit = async data => {
    const response = await createStock([data]);
    console.log('RESPONSE: ', response);
    if (response.status === 201) {
      closeCreateModal();
      const dt = response.data.data[0];
      setData(old =>
        old.map((row, rowIndex) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              barcode: {
                label: dt.barcode,
                value: dt.id,
                name: dt.name
              },
              name: {
                label: dt.name,
                value: dt.id,
                name: dt.name
              },
              stock: dt.id
            };
          }
          return row;
        })
      );
      toast.success('Stock created successfully.', { theme: 'colored' });
    } else if (response.data?.errors[0].name) {
      toast.error(response.data?.errors[0].name[0], { theme: 'colored' });
    } else {
      toast.error('Something went wrong.', { theme: 'colored' });
    }
  };
  // MODAL FUNCTIONS END//

  const onChange = e => {
    if (type === 'text' || type === 'number') {
      const value = e.target.value;
      setFormValue(value);
    } else if (type === 'date') {
      setData(old =>
        old.map((row, rowIndex) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [id]: e
            };
          }
          return row;
        })
      );
    } else if (type === 'select') {
      setFormValue(e);
      setData(old =>
        old.map((row, rowIndex) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              barcode: e ? { label: e?.barcode, value: e?.value } : '',
              name: e ? { label: e?.name, value: e?.value } : '',
              stock: e?.__isNew__ ? null : e?.value
            };
          }
          return row;
        })
      );
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    setData(old =>
      old.map((row, rowIndex) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [id]: formValue
          };
        }
        return row;
      })
    );
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setFormValue(initialValue);
  }, [initialValue]);

  const handleOptions = search => {
    if (search.length > 1) {
      handleGetStocks(search);
    }
  };

  const handleGetStocks = async search => {
    try {
      const response = await getStocksWithSearch({ search });
      if (response.data.results.length > 0) {
        const temp_arr = response.data.results.map(dt => ({
          label: id === 'barcode' ? dt.barcode : dt.name,
          value: dt.id,
          name: dt.name,
          barcode: dt.barcode
        }));
        setSelectOptions(temp_arr);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const clearField = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if (value === '0') {
      setFormValue(name, '');
    }
  };
  const handleGetProduct = async sku => {
    const res = await getProductListMini({ sku: sku });
    if (res?.data.count > 0) {
      setValue('name', res.data.results[0].name);
      setIsNameDisabled(true);
    } else {
      setIsNameDisabled(false);
    }
  };

  return (
    <>
      {type === 'text' || type === 'number' ? (
        <input
          type={type}
          value={formValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={clearField}
          style={{
            width: '100%'
          }}
          className="input-spin-none form-control"
          disabled={id === 'name' && isDisabled}
        />
      ) : type === 'select' ? (
        <>
          <CreatableSelect
            classNamePrefix="react-select"
            value={formValue}
            placeholder=""
            onChange={onChange}
            isClearable
            options={selectOptions}
            onInputChange={handleOptions}
            onCreateOption={value => {
              setValue('barcode', value);
              openCreateModal();
            }}
          />
          <Modal
            show={isCreateModalOpen}
            onHide={closeCreateModal}
            contentClassName="border"
          >
            <Form onSubmit={stopPropagate(handleSubmit(onSubmit))}>
              <Modal.Header
                closeButton
                closeVariant={isDark ? 'white' : undefined}
                className="bg-light px-card border-bottom-0"
              >
                <Modal.Title as="h5">Add Stock Card</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-card">
                <Row>
                  <Form.Group
                    as={Col}
                    xs={6}
                    className="mb-3"
                    controlId="barcode"
                  >
                    <Form.Label className="fs-0">Barcode</Form.Label>
                    <Form.Control
                      {...register('barcode', {
                        required: 'This field cannot be empty!'
                      })}
                      isInvalid={errors.barcode}
                      type="text"
                      placeholder="Barcode"
                      autoFocus
                      onBlur={e => handleGetProduct(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.barcode?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} className="mb-3" controlId="sku">
                    <Form.Label className="fs-0">SKU</Form.Label>
                    <Form.Control
                      {...register('sku', {
                        required: 'This field cannot be empty!'
                      })}
                      isInvalid={errors.sku}
                      type="text"
                      placeholder="SKU"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.sku?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label className="fs-0">Product Name</Form.Label>
                  <Form.Control
                    {...register('name', {
                      required: 'This field cannot be empty!'
                    })}
                    isInvalid={errors.name}
                    type="text"
                    placeholder="Product name"
                    disabled={isNameDisabled}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={6}
                    className="mb-3"
                    controlId="width"
                  >
                    <Form.Label className="fs-0">Width</Form.Label>
                    <Form.Control
                      {...register('width', {
                        required: 'This field cannot be empty!'
                      })}
                      isInvalid={errors.width}
                      type="number"
                      placeholder="Width"
                      step="0.01"
                      onWheel={e => e.target.blur()}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.width?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={6}
                    className="mb-3"
                    controlId="height"
                  >
                    <Form.Label className="fs-0">Height</Form.Label>
                    <Form.Control
                      {...register('height', {
                        required: 'This field cannot be empty!'
                      })}
                      isInvalid={errors.height}
                      type="number"
                      placeholder="Height"
                      step="0.01"
                      onWheel={e => e.target.blur()}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.height?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={6}
                    className="mb-3"
                    controlId="weight"
                  >
                    <Form.Label className="fs-0">Weight</Form.Label>
                    <Form.Control
                      {...register('weight', {
                        required: 'This field cannot be empty!'
                      })}
                      isInvalid={errors.weight}
                      type="number"
                      step="0.01"
                      onWheel={e => e.target.blur()}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.weight?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={6}
                    className="mb-3"
                    controlId="length"
                  >
                    <Form.Label className="fs-0">Length</Form.Label>
                    <Form.Control
                      {...register('length', {
                        required: 'This field cannot be empty!'
                      })}
                      isInvalid={errors.length}
                      type="number"
                      placeholder="Length"
                      step="0.01"
                      onWheel={e => e.target.blur()}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.length?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="desi">
                  <Form.Label className="fs-0">Desi</Form.Label>
                  <Form.Control
                    {...register('desi')}
                    isInvalid={errors.desi}
                    type="number"
                    placeholder=""
                    disabled
                    onWheel={e => e.target.blur()}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="bg-light px-card border-top-0">
                <Button
                  variant="danger"
                  className="px-3 mx-2"
                  onClick={closeCreateModal}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="px-5 mx-0">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      ) : (
        <DatePickerCustomInput
          selected={new Date(formValue)}
          onChange={onChange}
          dateFormat="dd/MM/yyyy"
          className="form-control"
          placeholder="Date"
        />
      )}
    </>
  );
};

EditableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  column: PropTypes.object,
  setData: PropTypes.func,
  setQuery: PropTypes.func,
  type: PropTypes.string,
  control: PropTypes.object,
  setValue: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default EditableCell;

// used to prevent parent submit in nedted forms
export function stopPropagate(callback) {
  return e => {
    e.preventDefault();
    e.stopPropagation();
    callback();
  };
}
