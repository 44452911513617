import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, Nav } from 'react-bootstrap';

const DefinitionCard = props => {
  const { title, tabs, tabChange, defaultActiveKey, tabContent, handleNew } =
    props;
  const showTabs = tabs?.map(tab => {
    return (
      <Nav.Item key={tab.name}>
        <Nav.Link eventKey={tab.eventKey}>{tab.name}</Nav.Link>
      </Nav.Item>
    );
  });

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{title}</h5>
          </Col>
          <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            <div id="orders-actions">
              {/* <IconButton
                variant="falcon-default"
                size="sm"
                icon="arrow-up"
                transform="shrink-3"
              >
                <span className="d-none d-sm-inline-block ms-1">Import</span>
              </IconButton>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="external-link-alt"
                transform="shrink-3"
                className="mx-2"
              >
                <span className="d-none d-sm-inline-block ms-1">Export</span>
              </IconButton> */}
              <IconButton
                onClick={handleNew}
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
              >
                <span className="d-none d-sm-inline-block ms-1">Add</span>
              </IconButton>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Nav
          variant="tabs"
          defaultActiveKey={defaultActiveKey}
          onSelect={tabChange}
        >
          {showTabs}
        </Nav>
        <div className="mt-2">{tabContent}</div>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

DefinitionCard.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.array,
  tabChange: PropTypes.func,
  defaultActiveKey: PropTypes.string,
  tabContent: PropTypes.element,
  handleNew: PropTypes.func
};

export default DefinitionCard;
