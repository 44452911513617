import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import IconButton from './IconButton';

const SeoSetting = props => {
  const { defaultValue, seoValues, register, errors } = props;
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [metaName, setMetaName] = useState('');
  const [metaContent, setMetaContent] = useState('');
  const [pageTitle, setPageTitle] = useState('');

  const [metaDesc, setMetaDesc] = useState({});
  const [seoSetting, setSeoSetting] = useState({
    index: true,
    follow: true
  });
  const [error, setError] = useState({});

  useEffect(() => {
    setSeoSetting({ ...seoSetting, meta_desc: metaDesc });
  }, [metaDesc]);

  useEffect(() => {
    seoValues(seoSetting);
  }, [seoSetting]);

  useEffect(() => {
    setPageTitle(defaultValue?.page_title || '');
    setMetaDesc(defaultValue?.meta_desc || {});
    setSeoSetting({
      page_title: defaultValue?.page_title || '',
      meta_desc: defaultValue?.meta_desc || {},
      index: defaultValue?.index ?? true,
      follow: defaultValue?.follow ?? true
    });
  }, [defaultValue]);

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setSeoSetting({ ...seoSetting, [name]: value });
    if (name === 'page_title') {
      setPageTitle(value);
    }
  };
  const handleChangeSelect = target => {
    setMetaName(target?.value || '');
    setMetaContent('');
    setError({});
  };

  const handleAdd = () => {
    if (metaName === '' || metaContent === '') {
      toast.error('Fill name and content'), { theme: 'colored' };
      return;
    }
    if (metaName === 'description' && metaContent.length < 70) {
      setError({ ...error, meta_desc: { type: 'minLength' } });
      return;
    } else if (metaName === 'description' && metaContent.length > 255) {
      setError({ ...error, meta_desc: { type: 'maxLength' } });
      return;
    } else {
      setError({});
    }
    const meta_desc = { ...metaDesc };
    meta_desc[metaName] = metaContent;
    setMetaDesc(meta_desc);
  };
  const handleRemove = key => {
    const meta_desc = { ...metaDesc };
    delete meta_desc[key];
    setMetaDesc(meta_desc);
  };

  const handleCheckboxChange = ({ target }) => {
    let value = target.checked;
    let name = target.name;
    setSeoSetting({ ...seoSetting, [name]: value });
  };

  const selectOptions = [
    { value: 'author', label: 'Author' },
    { value: 'description', label: 'Description' }
  ];

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'content',
      Header: 'Content',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'none',
      Header: ''
    }
  ];

  const metaRows = Object.entries(metaDesc).map(([key, value]) => {
    return (
      <tr key={key}>
        <td>{key}</td>
        <td>{value}</td>
        <td className="text-end">
          <IconButton
            onClick={() => handleRemove(key)}
            variant="danger"
            size="sm"
            icon="xmark"
            transform="shrink-3"
          ></IconButton>
        </td>
      </tr>
    );
  });

  return (
    <Fragment>
      <Form.Group>
        <h5>Search engine listing</h5>
        <p>
          Add a title and description to see how this collection might appear in
          a search engine listing
        </p>
      </Form.Group>
      <Row>
        <Form.Group as={Col} md={4} className="mb-3" controlId="index">
          <Row>
            <Col md={2} className="d-flex align-items-center">
              <Form.Label style={{ margin: 0 }}>Index</Form.Label>
            </Col>
            <Col md={10}>
              <Form.Check
                name="index"
                checked={seoSetting.index}
                type="switch"
                className="switch-lg"
                label=""
                onChange={handleCheckboxChange}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group as={Col} md={4} controlId="follow">
          <Row>
            <Col md={2} className="d-flex align-items-center">
              <Form.Label style={{ margin: 0 }}>Follow</Form.Label>
            </Col>
            <Col md={10}>
              <Form.Check
                name="follow"
                checked={seoSetting.follow}
                type="switch"
                className="switch-lg"
                label=""
                onChange={handleCheckboxChange}
              />
            </Col>
          </Row>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="page_title">
        <Form.Label>Page Title</Form.Label>
        <Form.Control
          type="text"
          name="page_title"
          {...register('page_title', { maxLength: 255, minLength: 30 })}
          isInvalid={!!errors.page_title}
          onChange={handleChange}
          value={pageTitle}
        />
        <Form.Control.Feedback type="invalid">
          {errors.page_title && errors.page_title.type === 'minLength' && (
            <>Page title cannot be less than 30 characters.</>
          )}
          {errors.page_title && errors.page_title.type === 'maxLength' && (
            <>Page title cannot be more than 255 characters.</>
          )}
        </Form.Control.Feedback>
        {/* <Form.Text muted>0 of 70 characters used</Form.Text> */}
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="meta_desc">
        <Form.Label>Meta Description</Form.Label>
        <Col sm={3} className="mb-2">
          <Select
            isClearable
            options={selectOptions}
            placeholder="Name"
            classNamePrefix="react-select"
            onChange={handleChangeSelect}
          />
        </Col>
        <Col sm={7}>
          <Form.Control
            as="textarea"
            isInvalid={!!error.meta_desc}
            name="meta_desc"
            placeholder="Content"
            onChange={e => setMetaContent(e.target.value)}
            value={metaContent}
            rows={1}
          />
          <Form.Control.Feedback type="invalid">
            {error.meta_desc && error.meta_desc.type === 'minLength' && (
              <>Description cannot be less than 70 characters.</>
            )}
            {error.meta_desc && error.meta_desc.type === 'maxLength' && (
              <>Description cannot be more than 255 characters.</>
            )}
          </Form.Control.Feedback>
        </Col>
        <Col sm={2}>
          <IconButton
            onClick={handleAdd}
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
          >
            <span className="d-none d-sm-inline-block ms-1">Add</span>
          </IconButton>
        </Col>
        {/* <Form.Text muted>0 of 320 characters used</Form.Text> */}
        <Col xs={12}>
          {Object.keys(metaDesc).length > 0 && (
            <Table striped hover size="sm" className="border">
              <thead className={isDark ? 'bg-soft-dark' : 'bg-body'}>
                <tr>
                  {columns.map(col => {
                    return (
                      <th key={col.accessor} {...col.headerProps}>
                        {col.Header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>{metaRows}</tbody>
            </Table>
          )}
        </Col>
      </Form.Group>
    </Fragment>
  );
};

SeoSetting.propTypes = {
  seoValues: PropTypes.func,
  defaultValue: PropTypes.object,
  register: PropTypes.any,
  errors: PropTypes.object
};

export default SeoSetting;
