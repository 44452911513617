import Flex from 'components/common/Flex';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';

const NewsletterTableFilter = ({ queryObj, setQueryObj }) => {
  const statusOptions = [
    { value: 'true', label: 'Active' },
    { value: 'false', label: 'Passive' }
  ];

  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset'
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  return (
    <Row className="flex-start mt-2">
      <Col xs="auto" sm={6} lg={3}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Email Address"
          setGlobalFilter={val => {
            console.log(val);
            setQueryObj({ ...queryObj, email__istartswith: val });
          }}
        />
      </Col>
      <Col xs="auto" sm={6} lg={4}>
        <Flex justifyContent={'start'}>
          <Col>
            <Select
              className="me-2"
              options={statusOptions}
              placeholder="Status"
              styles={selectStyles}
              onChange={val => {
                setQueryObj({ ...queryObj, isActive: val?.value });
              }}
              isClearable
            />
          </Col>
        </Flex>
      </Col>
    </Row>
  );
};

export default NewsletterTableFilter;

NewsletterTableFilter.propTypes = {
  queryObj: PropTypes.object,
  setQueryObj: PropTypes.func
};
