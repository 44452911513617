import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import Flex from 'components/common/Flex';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';

const DraggableList = props => {
  const { data, setState, setSelectedItem, isEditable, isDeletable } = props;
  const [draggableData, setDraggableData] = useState(data);
  useEffect(() => {
    if (!data || data?.length === 0 || !data[0]) return;
    setDraggableData(data);
  }, [data]);
  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = [...draggableData];
      const reorderedItems = reorder(items, source.index, destination.index);
      setState(reorderedItems);
    }
  };

  // When user whats to edit or delete
  const handleEditDescription = (item, name) => {
    setSelectedItem(item, name);
  };

  if (!data || data?.length === 0) return <></>;
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          <Col xs={12}>
            <StrictModeDroppable droppableId="droppable" type="DRAG">
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="border bg-white dark__bg-1000 rounded-2 py-3 mb-3"
                >
                  {draggableData.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={`drag${item.id}`}
                      index={index}
                    >
                      {provided => (
                        <div>
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="mb-2 mx-1 kanban-item shadow-sm dark__bg-1100"
                          >
                            <Card.Body>
                              <Row>
                                <Col xs={9}>
                                  <p className="fs--1 fw-medium font-sans-serif mb-0">
                                    {item.name
                                      ? item.name
                                      : item.type + ' - ' + item.content}
                                  </p>
                                </Col>
                                <Col xs={3}>
                                  <Flex
                                    justifyContent="end"
                                    alignItems="center"
                                  >
                                    {isEditable && (
                                      <BiEditAlt
                                        style={{ fontSize: 24 }}
                                        onClick={() =>
                                          handleEditDescription(item, 'edit')
                                        }
                                      />
                                    )}
                                    {isDeletable && (
                                      <AiOutlineDelete
                                        style={{ fontSize: 24 }}
                                        onClick={() =>
                                          handleEditDescription(item, 'delete')
                                        }
                                      />
                                    )}
                                  </Flex>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </Col>
        </Row>
      </DragDropContext>
    </div>
  );
};

DraggableList.propTypes = {
  data: PropTypes.array,
  setState: PropTypes.func,
  setSelectedItem: PropTypes.func,
  isEditable: PropTypes.bool,
  isDeletable: PropTypes.bool
};

export default DraggableList;
