import CardDropdown from 'components/common/CardDropdown';
import SingleImageUpload from 'components/common/SingleImageUpload';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import AppContext from 'context/Context';
import useDelete from 'hooks/useDelete';
import usePost from 'hooks/usePost';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import { getGiftCardThemes } from 'services/product';
import serviceUrl from 'services/serviceUrlList';
import { createGiftCartTheme, updateGiftCartTheme } from '../api';

const GitfCardTheme = props => {
  const { newClickCount } = props;
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    is_active: true,
    name: '',
    image: '',
    image_alt_text: ''
  });
  const formData = new FormData();
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [defaultImg, setDefaultImg] = useState(undefined);
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm();

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };

  const {
    data,
    isLoading: tableLoading,
    refetch
  } = useQuery(
    ['getGiftCardThemes', queryObj],
    () => getGiftCardThemes(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.giftcardtheme.detail + selectedRowId
  });

  useEffect(() => {
    if (newClickCount === 0) return;
    openCreateModal(true);
  }, [newClickCount]);

  const onSubmit = async () => {
    Object.keys(formValues).map(key => {
      if (key === 'image' && typeof formValues[key] !== 'object') return;
      formData.set(key, formValues[key]);
    });
    if (selectedRowId === null) {
      // post request
      const response = await createGiftCartTheme(formData);
      if (response.status === 201) {
        closeCreateModal();
        refetch();
        toast.success(`Gift card theme created`, {
          theme: 'colored'
        });
      } else if (response.status === 4000) {
        if (response.data.name) {
          toast.error(`Name field must be unique!`, {
            theme: 'colored'
          });
        }
      }
    } else {
      // Update Request !
      const response = await updateGiftCartTheme(formData, selectedRowId);
      if (response.status === 200) {
        closeCreateModal();
        refetch();
        toast.success(`Gift card theme updated`, {
          theme: 'colored'
        });
      } else if (response.status === 4000) {
        if (response.data.name) {
          toast.error(`Name field must be unique!`, {
            theme: 'colored'
          });
        }
      }
    }
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEditItem = dt => {
    setSelectedRowId(dt.id);
    // setFormValues({ ...dt });
    setFormValues({
      name: dt.name,
      is_active: dt.is_active,
      image: dt.image,
      image_alt_text: dt.image_alt_text
    });
    setDefaultImg({
      path: dt.image,
      alt: dt.image_alt_text
    });
    setValue('name', dt.name);
    openCreateModal(true);
  };

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    closeCreateModal();
    if (deleteResponse.error === null) {
      refetch();
      toast.success(`Gift card theme deleted`, {
        theme: 'colored'
      });
    } else {
      if (deleteResponse.error.data.status === 405) {
        toast.error(deleteResponse.error.data.message, {
          theme: 'colored'
        });
      } else {
        toast.error('Oopss! Something went wrong :(', {
          theme: 'colored'
        });
      }
    }
    setSelectedRowId(null);
  }, [deleteResponse]);

  const handleModalDeleteButton = () => {
    if (selectedRowId === null) {
      closeCreateModal();
    } else {
      handleDeleteItem(selectedRowId);
    }
  };

  const clearFormData = () => {
    setFormValues({
      is_active: true,
      name: '',
      image: '',
      image_alt_text: ''
    });
    setDefaultImg(undefined);
    clearErrors();
    reset();
  };

  const handleImgFile = image => {
    setFormValues({ ...formValues, image: image });
  };
  const handleAltText = text => {
    setFormValues({ ...formValues, image_alt_text: text });
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <a href="#" onClick={() => handleEditItem(rowData.row.original)}>
            {name}
          </a>
        );
      }
    },
    {
      accessor: 'image',
      Header: 'Media',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { image, image_alt_text } = rowData.row.original;
        return (
          <Fragment>
            {image ? (
              <img
                className="rounded-1 border border-200 me-2 img-50-contain"
                src={image}
                width="60"
                alt={image_alt_text}
              />
            ) : (
              <Fragment></Fragment>
            )}
          </Fragment>
        );
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: dt => {
        const { is_active } = dt.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handleEditItem(dt.row.original)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={tableLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <TableHeader
            table
            handleRefetchAll={refetch}
            setQueryObj={setQueryObj}
          />
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 align-middle'
              }}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
        size="lg"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Add Gift Card Theme</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                top: 10
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                name="is_active"
                required
                value={formValues.is_active}
                onChange={handleChange}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="fs-0">Name</Form.Label>
              <Form.Control
                {...register('name', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.name}
                type="text"
                name="name"
                placeholder="Gift card theme name"
                value={formValues.name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="media">
              <Form.Label className="fs-0">Media</Form.Label>
              <SingleImageUpload
                file={handleImgFile}
                altText={handleAltText}
                defaultImg={defaultImg}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={handleModalDeleteButton}
            >
              {selectedRowId === null ? 'Cancel' : 'Delete'}
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Gift Card Theme"
        description="Are you sure the gift card theme you selected will be deleted?"
        request={deleteRequest}
        type={'delete'}
      />
    </div>
  );
};

GitfCardTheme.propTypes = {
  newClickCount: PropTypes.number
};

export default GitfCardTheme;

const TableHeader = props => {
  const { selectedRowIds, ids, handleRefetchAll, setQueryObj } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [action, setAction] = useState(undefined);
  const [payload, setPayload] = useState(undefined);
  const [postResponse, postRequest] = usePost({
    url: serviceUrl.product.bulk_actions,
    payload: payload
  });

  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    toast.success(`Bulk action completed`, {
      theme: 'colored'
    });
    handleRefetchAll();
  }, [postResponse]);

  const handleSetAction = e => {
    setAction(e.target.value);
  };

  const submitBulkAction = () => {
    if (action === undefined) {
      toast.error(`Select action for bulk action!`, {
        theme: 'colored'
      });
      return;
    }
    const payload_ = {
      table_name: 'GiftCardTheme',
      id_list: ids
    };
    if (action === 'delete') {
      payload_.is_deleted = true;
      setConfirmText({
        title: 'Bulk Delete action',
        description: 'Are you sure? Selected gift card theme will be deleted!',
        type: 'delete'
      });
    } else if (action === 'passive') {
      payload_.is_active = false;
      setConfirmText({
        title: 'Bulk status action',
        description:
          'Are you sure? Selected gift card theme will be archieved!',
        type: 'update'
      });
    } else if (action === 'active') {
      payload_.is_active = true;
      setConfirmText({
        title: 'Bulk status action',
        description:
          'Are you sure? Selected gift card theme will be activated!',
        type: 'update'
      });
    }
    setShowConfirm(true);
    setPayload({ ...payload_ });
  };

  const handleGiftCardThemeSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      name__istartswith: value
    }));
  };

  return (
    <Row className="">
      <Col xs={6} className="d-flex align-items-center pe-0">
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter gift card themes"
          setGlobalFilter={handleGiftCardThemeSearch}
        />
      </Col>
      <Col xs={4} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              value={action}
              onChange={handleSetAction}
              size="sm"
              aria-label="Bulk actions"
            >
              <option hidden>Bulk Actions</option>
              <option value="delete">Delete</option>
              <option value="passive">Passive</option>
              <option value="active">Active</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={submitBulkAction}
            >
              Apply
            </Button>
          </div>
        ) : null}
      </Col>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={postRequest}
        type={confirmText.type}
      />
    </Row>
  );
};

TableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  ids: PropTypes.array,
  handleRefetchAll: PropTypes.func,
  setQueryObj: PropTypes.func
};
