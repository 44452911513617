import React, { useEffect, useReducer, useState } from 'react';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getTransactionsByWarehouse } from 'services/warehouse';
import { queryReducer } from 'reducers/queryReducer';
import PropTypes from 'prop-types';

const Movements = ({ warehouseName }) => {
  const { id } = useParams();
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    storageLocation: id
  });
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const { isLoading, data } = useQuery(
    ['movementsByWarehouse', queryObj, sort],
    () =>
      getTransactionsByWarehouse({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);

  useEffect(() => {
    warehouseName(data?.data.results[0].storageLocation.name);
  }, [data]);
  const columns = [
    {
      accessor: 'stock.name',
      Header: 'Product Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'transactionType.name',
      Header: 'Type',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true
    },
    {
      accessor: 'storageLocation.contactName',
      Header: 'Responsible',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true
    },
    {
      accessor: 'date',
      Header: 'Date/Time',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      }
    },
    {
      accessor: 'quantity',
      Header: 'Quantity',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          sortable
          pagination
          perPage={10}
        >
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 align-middle'
            }}
            setSort={setSort}
            sort={sort}
          />
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>
    </div>
  );
};
Movements.propTypes = {
  warehouseName: PropTypes.func
};

export default Movements;
