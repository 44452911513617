import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { useNavigate } from 'react-router-dom';
import TableHeader from 'components/common/TableHeader';
import { getAgreements } from 'services/user';
import { useQuery } from 'react-query';
import { queryReducer } from 'reducers/queryReducer';

const Agreements = () => {
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    site__id: 1
  });
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery(
    ['getAgreements', queryObj],
    () => getAgreements(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'title',
      Header: 'Name',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'code',
      Header: 'Code',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'content',
      Header: 'Content',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2 text-truncate'
      },
      Cell: rowData => {
        const { content } = rowData.row.original;
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `${content
                .replace(/<\/?[^>]+(>|$)/g, ' ')
                .slice(0, 80)} ${content.length >= 80 ? '...' : ''}`
            }}
          ></div>
        );
      }
    },
    {
      accessor: 'isMandatory',
      Header: 'Is Mandatory',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { isMandatory } = rowData.row.original;
        if (isMandatory) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Mandatory
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Not Mandatory
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'platform',
      Header: 'Platform',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'version',
      Header: 'Version',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'isActive',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { isActive } = rowData.row.original;
        if (isActive) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id, isActive } = rowData.row.original;
        return isActive ? (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(`/agreement-update/${id}`)}
              >
                Edit
              </Dropdown.Item>
            </div>
          </CardDropdown>
        ) : (
          <></>
        );
      }
    }
  ];

  const handleNew = () => {
    navigate('/agreement-add');
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          pagination
          data={data?.data.results || []}
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader title="Agreements" table handleNew={handleNew} />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>
    </div>
  );
};

export default Agreements;
