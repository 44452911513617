import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { IMAGE_MIN, MOBILE_IMAGE_MIN } from 'helpers/image';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import DraggableImageList from './DraggableImageList';
const ImagePreview = props => {
  const { setState, formValues, setFormValues, isMobile } = props;
  const [files, setFiles] = useState([]);
  const [filesMobile, setFilesMobile] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [altText, setAltText] = useState('');
  const [isAltTextModalOpen, setIsAltTextModalOpen] = useState(false);
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    getFilesFromEvent: async event => {
      const files = event.target.files || event.dataTransfer.files;
      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise(resolve => {
          const image = new Image();
          let url;
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }
      return await Promise.all(promises);
    },
    validator: file => {
      // You can access width/height properties
      if (isMobile) {
        if (
          file.width < MOBILE_IMAGE_MIN[0] &&
          file.height < MOBILE_IMAGE_MIN[1]
        ) {
          return {
            title: 'Small Size',
            message: `Image size must be greater than ${MOBILE_IMAGE_MIN[0]}X${MOBILE_IMAGE_MIN[1]}px`
          };
        }
      } else {
        if (file.width < IMAGE_MIN[0] && file.height < IMAGE_MIN[1]) {
          return {
            title: 'Small Size',
            message: `Image size must be greater than ${IMAGE_MIN[0]}X${IMAGE_MIN[1]}px`
          };
        }
      }
      return null;
    },
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toast.error(fileRejections[0].errors[0].message, {
          theme: 'colored'
        });
        return;
      }
      const acc_files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const fileList = isMobile ? [...filesMobile] : [...files];
      const fileObj = {
        id: isMobile ? filesMobile.length + 1 : files.length + 1,
        file: acc_files[0],
        alt_text: '',
        size: acc_files[0].size,
        device_type: isMobile ? 'mobile' : 'desktop'
      };
      fileList.push(fileObj);
      if (isMobile) {
        setFilesMobile(fileList);
      } else {
        setFiles(fileList);
      }
    }
  });

  useEffect(() => {
    if (!formValues.files) return;
    setFiles(formValues.files);
  }, [formValues.files, formValues.is_update]);
  useEffect(() => {
    if (!formValues.files_mobile) return;
    setFilesMobile(formValues.files_mobile);
  }, [formValues.files_mobile, formValues.is_update]);

  useEffect(() => {
    setState(files);
  }, [files]);
  useEffect(() => {
    setState(filesMobile, true);
  }, [filesMobile]);

  const handleRemove = file => {
    setRemovedFiles([...removedFiles, file]);
    const new_files = isMobile
      ? filesMobile.filter(dt => dt.id !== file.id)
      : files.filter(dt => dt.id !== file.id);
    if (isMobile) {
      setFormValues({
        ...formValues,
        files_mobile: new_files,
        removedFiles: [...removedFiles, file]
      });
      setFilesMobile(new_files);
    } else {
      setFormValues({
        ...formValues,
        files: new_files,
        removedFiles: [...removedFiles, file]
      });
      setFiles(new_files);
    }
  };

  const handleSetAltText = file => {
    setSelectedFile(file);
    setIsAltTextModalOpen(true);
  };

  const handleSubmitModal = e => {
    e.preventDefault();
    const new_file_list = isMobile ? [...filesMobile] : [...files];
    const file_index = new_file_list.findIndex(fl => fl.id === selectedFile.id);
    new_file_list[file_index].alt_text = altText;
    if (isMobile) {
      setFilesMobile(new_file_list);
    } else {
      setFiles(new_file_list);
    }
    setAltText('');
    setIsAltTextModalOpen(false);
  };
  const closeAltTextModal = () => {
    setIsAltTextModalOpen(false);
  };
  const handleDeleteAltText = () => {
    setIsAltTextModalOpen(false);
  };

  const handleReorder = file => {
    if (isMobile) {
      setFilesMobile(file);
    } else {
      setFiles(file);
    }
    // setFormValues({ ...formValues, files: file });
  };

  return (
    <Row>
      <Flex>
        <div
          {...getRootProps({
            className: 'dropzone-area py-6 mx-1 d-flex align-items-center',
            style: { width: 250, height: 150 }
          })}
        >
          <input {...getInputProps()} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Drop your images here</p>
          </Flex>
        </div>
        <div className="mx-1">
          <DraggableImageList
            is_update={formValues.is_update}
            data={isMobile ? filesMobile : files}
            setState={handleReorder}
            handleRemove={handleRemove}
            handleSetAltText={handleSetAltText}
          />
        </div>
      </Flex>
      <Modal
        show={isAltTextModalOpen}
        onHide={closeAltTextModal}
        contentClassName="border"
      >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Set Alt Text</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="alt_text">
            <Form.Label className="fs-0">Alt Text</Form.Label>
            <Form.Control
              type="text"
              name="alt_text"
              placeholder="Alt Text"
              required
              value={altText}
              onChange={e => setAltText(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="danger"
            className="px-3 mx-2"
            onClick={handleDeleteAltText}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmitModal}
            className="px-5 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};
ImagePreview.propTypes = {
  setState: PropTypes.func,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func,
  isMobile: PropTypes.bool
};
export default ImagePreview;
