import is from 'is_js';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './MainLayout';

import { CloseButton } from 'components/common/Toast';
import Profile from 'pages/profile/Profile';
import { toast, ToastContainer } from 'react-toastify';
import ErrorLayout from './ErrorLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import AppContext, { AuthContext } from 'context/Context';
import ConfirmEmail from 'pages/authentication/ConfirmEmail';
import Login from 'pages/authentication/Login';
import Logout from 'pages/authentication/Logout';
import PasswordReset from 'pages/authentication/PasswordReset';
import AddBlog from 'pages/cms/blog/AddBlog';
import Blog from 'pages/cms/blog/Blog';
import BlogComments from 'pages/cms/blog/BlogComments';
import AddFaq from 'pages/cms/faq/AddFaq';
import Faq from 'pages/cms/faq/Faq';
import AddGlossary from 'pages/cms/glossary/AddGlossary';
import Glossary from 'pages/cms/glossary/Glossary';
import AddHeaderBanner from 'pages/cms/headerBanner/AddHeaderBanner';
import HeaderBanner from 'pages/cms/headerBanner/HeaderBanner';
import WebHomePage from 'pages/cms/homepage/WebHomePage';
import WebMegaMenuItem from 'pages/cms/megamenu/megamenuItem/WebMegaMenuItem';
import WebHeaderMenu from 'pages/cms/megamenu/WebHeaderMenu';
import WebMegaMenu from 'pages/cms/megamenu/WebMegaMenu';
import CustomerDetail from 'pages/customer/customerList/customerDetail/CustomerDetail';
import CustomerList from 'pages/customer/customerList/CustomerList';
import NewsletterList from 'pages/customer/customerList/NewsletterList';
import Dashboard from 'pages/dashboards/default/Dashboard';
import Content from 'pages/definitions/content/Content';
import Finance from 'pages/definitions/finance/Finance';
import General from 'pages/definitions/general/General';
import Definition from 'pages/definitions/product/Definition';
import Stock from 'pages/definitions/stock/Stock';
import AbandonedDetail from 'pages/order/abandoned/AbandonedDetail';
import AbandonedList from 'pages/order/abandoned/AbandonedList';
import BasketList from 'pages/order/basket/BasketList';
import OrderDetail from 'pages/order/detail/OrderDetail';
import AddOrder from 'pages/order/orderCreate/AddOrder';
import OrderList from 'pages/order/OrderList';
import ReturnList from 'pages/order/return/ReturnList';
import AddBrand from 'pages/product/brand/AddBrand';
import BrandList from 'pages/product/brand/BrandList';
import AddCategory from 'pages/product/category/AddCategory';
import CategoryList from 'pages/product/category/CategoryList';
import AddDiscount from 'pages/product/discount/AddDiscount';
import DiscountList from 'pages/product/discount/DiscountList';
import AddGiftCard from 'pages/product/giftCard/AddGiftCard';
import GiftCard from 'pages/product/giftCard/GiftCard';
import AddCollection from 'pages/product/productCollection/AddCollection';
import ProductCollection from 'pages/product/productCollection/ProductCollection';
import AddProduct from 'pages/product/productList/AddProduct';
import ProductList from 'pages/product/productList/ProductList';
import ReplyUserQuestion from 'pages/product/userQuestions/ReplyUserQuestion';
import UserQuestions from 'pages/product/userQuestions/UserQuestions';
import AddVariants from 'pages/product/variants/AddVariants';
import Variants from 'pages/product/variants/Variants';
import AddCounting from 'pages/stock/counting/AddCounting';
import AddCountingItem from 'pages/stock/counting/AddCountingItem';
import CountingDetail from 'pages/stock/counting/CountingDetail';
import CountingList from 'pages/stock/counting/CountingList';
import UpdateCountingItem from 'pages/stock/counting/UpdateCountingItem';
import AddStockEntry from 'pages/stock/Inventory/AddStockEntry';
import InventoryCard from 'pages/stock/Inventory/InventoryCard';
import TransactionList from 'pages/stock/transaction/TransactionList';
import AddTransfer from 'pages/stock/transfer/AddTransfer';
import TransferDetail from 'pages/stock/transfer/TransferDetail';
import TransferList from 'pages/stock/transfer/TransferList';
import AddWarehouse from 'pages/stock/warehouse/AddWarehouse';
import WarehouseDetail from 'pages/stock/warehouse/WarehouseDetail';
import WarehouseList from 'pages/stock/warehouse/WarehouseList';
import AddAgreement from 'pages/users/agreement/AddAgreement';
import Agreements from 'pages/users/agreement/Agreements';
import UserAgreements from 'pages/users/agreement/UserAgreements';
import UserList from 'pages/users/UserList';
import { isTokenVerified } from 'services/authentication';

const ToastContainerElement = () => {
  return (
    <ToastContainer
      closeButton={CloseButton}
      icon={false}
      position={toast.POSITION.BOTTOM_LEFT}
    />
  );
};
const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  useLayoutEffect(() => {
    const user_ = JSON.parse(window.localStorage.getItem('user'));
    if (user_ === null) return;
    setUser(user_);
    isTokenVerified()
      .then(res => {
        if (res === true) {
          setUser(user_);
        } else {
          setUser({});
          window.localStorage.removeItem('user');
          toast.error(`Token expired! Please login again`, {
            theme: 'colored'
          });
        }
      })
      .catch(err => {
        console.error('ERROR: ', err);
      });
  }, []);

  if (!user.id) {
    return (
      <>
        <Routes>
          <Route path="authentication/login" element={<Login />} />
          <Route
            path="authentication/reset-password/:uid/:token"
            element={<PasswordReset />}
          />
          <Route path="authentication/logout" element={<Logout />} />
          <Route
            path="authentication/confirm-email/:key"
            element={<ConfirmEmail />}
          />
          <Route path="*" element={<Login />} />
        </Routes>
        <ToastContainerElement />
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}
        <Route path="authentication/logout" element={<Logout />} />
        <Route element={<MainLayout />}>
          {/* Orders */}
          <Route path="order/order-list" element={<OrderList />} />
          <Route path="order/order-detail/:id" element={<OrderDetail />} />
          <Route path="order/order-add" element={<AddOrder />} />
          <Route path="order/campaigns" element={<DiscountList />} />
          <Route path="order/campaign-add" element={<AddDiscount />} />
          <Route path="order/campaign-update/:id" element={<AddDiscount />} />
          <Route path="order/baskets" element={<BasketList />} />
          <Route path="order/returns" element={<ReturnList />} />
          <Route path="order/abandoned" element={<AbandonedList />} />
          <Route
            path="order/abandoned-detail/:id"
            element={<AbandonedDetail />}
          />
          {/*Products*/}
          <Route path="product/product-list" element={<ProductList />} />
          <Route path="product/product-add" element={<AddProduct />} />
          <Route path="product/product-update/:slug" element={<AddProduct />} />
          <Route path="product/product-variants" element={<Variants />} />
          <Route path="product/variant-add" element={<AddVariants />} />
          <Route path="product/variant-update/:id" element={<AddVariants />} />
          <Route path="/product/product-brands" element={<BrandList />} />
          <Route path="product/product-brand-add" element={<AddBrand />} />
          <Route
            path="product/product-brand-update/:id"
            element={<AddBrand />}
          />
          <Route path="product/product-categories" element={<CategoryList />} />
          <Route
            path="product/product-category-add"
            element={<AddCategory />}
          />
          <Route
            path="product/product-category-update/:id"
            element={<AddCategory />}
          />
          <Route
            path="product/product-collection"
            element={<ProductCollection />}
          />
          <Route path="product/collection-add" element={<AddCollection />} />
          <Route
            path="product/collection-update/:id"
            element={<AddCollection />}
          />
          <Route path="product/gift-card" element={<GiftCard />} />
          <Route path="product/gift-card-add" element={<AddGiftCard />} />
          <Route
            path="product/gift-card-update/:id"
            element={<AddGiftCard />}
          />
          <Route
            path="product/gift-card-view/:id"
            element={<AddGiftCard readOnly />}
          />
          <Route path="product/user-questions" element={<UserQuestions />} />
          <Route
            path="product/question-reply/:id"
            element={<ReplyUserQuestion />}
          />
          <Route
            path="product/question-update/:id"
            element={<ReplyUserQuestion />}
          />

          {/*Definitions*/}
          <Route path="definitions/product" element={<Definition />} />
          <Route path="definitions/content" element={<Content />} />
          <Route path="definitions/stock" element={<Stock />} />
          <Route path="definitions/finance" element={<Finance />} />
          <Route path="definitions/general" element={<General />} />
          {/*CMS*/}
          <Route path="cms/blog" element={<Blog />} />
          <Route path="cms/blog-add" element={<AddBlog />} />
          <Route path="cms/blog-update/:slug" element={<AddBlog />} />
          <Route path="cms/blog/comments" element={<BlogComments />} />

          <Route path="cms/glossary" element={<Glossary />} />
          <Route path="cms/glossary-add" element={<AddGlossary />} />
          <Route path="cms/glossary-update/:id" element={<AddGlossary />} />
          <Route path="cms/faq" element={<Faq />} />
          <Route path="cms/faq-add" element={<AddFaq />} />
          <Route path="cms/faq-update/:slug" element={<AddFaq />} />
          <Route path="cms/header-banner" element={<HeaderBanner />} />
          <Route path="cms/header-banner-add" element={<AddHeaderBanner />} />
          <Route
            path="cms/header-banner-update/:slug"
            element={<AddHeaderBanner />}
          />
          <Route path="cms/homepage" element={<WebHomePage />} />
          <Route path="cms/megamenu" element={<WebHeaderMenu />} />
          <Route path="cms/megamenu/:mmid" element={<WebMegaMenu />} />
          <Route path="cms/megamenu/:mmid/:id" element={<WebMegaMenuItem />} />
          {/*Customers*/}
          <Route path="customers" element={<CustomerList />} />
          <Route path="customer/:id" element={<CustomerDetail />} />
          <Route path="customer/agreements" element={<UserAgreements />} />
          <Route path="customer/newsletter" element={<NewsletterList />} />
          {/* Messaging servisi kaldırıldı. */}
          {/* <Route path="customer/messaging" element={<Messaginglist />} />
          <Route
            path="customer/messaging-detail/:id"
            element={<MessagingDetail />}
          /> */}

          {/*Users*/}
          <Route path="users" element={<UserList />} />

          {/* Groups */}
          <Route path="agreements" element={<Agreements />} />
          <Route path="agreement-add" element={<AddAgreement />} />
          <Route path="agreement-update/:id" element={<AddAgreement />} />

          {/* Profile */}
          <Route path="user/profile" element={<Profile />} />
          {/* Stock */}
          <Route path="stock/warehouse" element={<WarehouseList />} />
          <Route path="stock/warehouse-add" element={<AddWarehouse />} />
          <Route path="stock/warehouse-update/:id" element={<AddWarehouse />} />
          <Route
            path="stock/warehouse-detail/:id"
            element={<WarehouseDetail />}
          />
          {/* <Route path="stock/inventory" element={<InventoryList />} /> */}
          <Route path="stock/inventory" element={<InventoryCard />} />
          <Route path="stock/inventory-add" element={<AddStockEntry />} />
          <Route
            path="stock/inventory-update/:id"
            element={<AddStockEntry />}
          />
          <Route path="stock/action" element={<TransactionList />} />
          <Route path="stock/transfer" element={<TransferList />} />
          <Route path="stock/transfer-add" element={<AddTransfer />} />
          <Route
            path="stock/transfer-detail/:id"
            element={<TransferDetail />}
          />
          <Route path="stock/counting" element={<CountingList />} />
          <Route path="stock/counting-add" element={<AddCounting />} />
          <Route path="stock/counting-update/:id" element={<AddCounting />} />
          <Route
            path="stock/counting-detail/:id"
            element={<CountingDetail />}
          />
          <Route
            path="stock/counting-product-add/:id"
            element={<AddCountingItem />}
          />
          <Route
            path="stock/counting-product-update/:id"
            element={<UpdateCountingItem />}
          />

          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainerElement />
    </>
  );
};

export default Layout;
