import { protectedApi, serializeQuery } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const getUsers = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.user.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getUserWithAddresses = async ({ search }) => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.userwithaddresses + '?limit=50&search=' + search
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getDynamicUsers = async ({ api, search }) => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user[api].list + '?limit=50&search=' + search
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getMessages = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.message.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getMessage = async id => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.message.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createMessage = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.user.message.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateMessage = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.user.message.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getUserAgreements = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.userAgreement.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getAgreements = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.agreement.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createAgreement = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.user.agreement.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getAgreementTypes = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.agreementType.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getAgreementCodes = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.agreementCode.list + serializeQuery(queryObj)
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};
export const createCommunicationSubject = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.user.communicationSubject.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateCommunicationSubject = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.user.communicationSubject.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getCommunicationSubjects = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.communicationSubject.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createInterestArea = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.user.interestArea.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateInterestArea = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.user.interestArea.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getInteresAreas = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.interestArea.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createAgreementType = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.user.agreementType.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateAgreementType = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.user.agreementType.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getGiftCardUsers = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.giftcard.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCustomerView = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.user.customer.detail,
      payload
    );
    return response.data.customer_info;
  } catch (error) {
    return error.response;
  }
};

export const getAnomymousView = async phone => {
  try {
    const response = await protectedApi.get(
      serviceUrl.order.anonymousUser.detail + '?user_phone=' + phone
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getCustomers = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.user.customer.list,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};
export const getNewsletters = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.user.newsletter.list + serializeQuery(queryObj)
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};
