import LoadingCard from 'components/loading/LoadingCard';
import { splitPhoneNumber } from 'helpers/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

function CustomerInfo({ data }) {
  console.log('data', data);
  if (!data) return <LoadingCard bodyStyle={{ height: 112 }} />;
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <span>{data.full_name}</span>
          <span>
            {data.is_subscribe_user ? 'Member' : 'Non-Member'} /{' '}
            {moment(data.date_joined).format('DD.MM.YYYY')}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span>{data.email}</span>
          <span>
            IYS: {data.is_iys_registered ? 'Approved' : 'Not approved'}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span>+90 {splitPhoneNumber(data.phone)}</span>
          <span>
            Newsletter: {data.is_newsletter_registered ? 'Yes' : 'No'}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CustomerInfo;

CustomerInfo.propTypes = {
  data: PropTypes.object
};
