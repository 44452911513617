import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import usePost from 'hooks/usePost';
import serviceUrl from 'services/serviceUrlList';
import { toast } from 'react-toastify';

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    old_password: '',
    new_password1: '',
    new_password2: ''
  });
  const [postResponse, postRequest] = usePost({
    url: serviceUrl.authentication.change_password,
    payload: formData
  });

  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.error === null) {
      toast.success(`Attribute created`, {
        theme: 'colored'
      });
    } else {
      if (postResponse.data.old_password) {
        toast.error(`Your old password is incorrect`, {
          theme: 'colored'
        });
        return;
      }
      toast.error(`Something went wrong! Please try again later`, {
        theme: 'colored'
      });
    }
  }, [postResponse]);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    postRequest();
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Change Password" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="old_password">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="text"
              value={formData.old_password}
              name="old_password"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="new_password1">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="text"
              value={formData.new_password1}
              name="new_password1"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="new_password2">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="text"
              value={formData.new_password2}
              name="new_password2"
              onChange={handleChange}
            />
          </Form.Group>
          <Button className="w-100" type="submit">
            Update Password
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
