import React, { useState } from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import Movements from './module/Movements';
import Products from './module/Products';

const WarehouseDetail = () => {
  const [activeTab, setActiveTab] = useState('products');
  const [whName, setWhName] = useState('');

  const tabChange = tab => {
    setActiveTab(tab);
  };
  const tabs = [
    {
      name: 'Products',
      eventKey: 'products'
    },
    {
      name: 'Transactions',
      eventKey: 'transactions'
    }
  ];
  const showTabs = tabs?.map(tab => {
    return (
      <Nav.Item key={tab.name}>
        <Nav.Link eventKey={tab.eventKey}>{tab.name}</Nav.Link>
      </Nav.Item>
    );
  });
  const warehouseName = name => {
    setWhName(name);
  };
  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              {whName} Details
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Nav variant="tabs" defaultActiveKey={activeTab} onSelect={tabChange}>
          {showTabs}
        </Nav>
        <div className="mt-2">
          {activeTab === 'products' ? (
            <Products warehouseName={warehouseName} />
          ) : (
            <Movements warehouseName={warehouseName} />
          )}
        </div>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

export default WarehouseDetail;
