import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { getUserWithAddresses } from 'services/user';

const CustomerInfo = props => {
  const { selectedUser, setSelectedUser, selectedAddress, setSelectedAddress } =
    props;
  const [searchValue, setSearchValue] = useState('');
  const { data, isLoading } = useQuery(
    ['user-filter', searchValue],
    () => getUserWithAddresses({ search: searchValue }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const handleSelectedUser = value => {
    if (value === null) {
      setSelectedUser({});
    } else {
      setSelectedUser(value.user);
    }
  };
  const userOptions = data?.data.results.map(dt => ({
    label:
      dt.first_name +
      ' ' +
      dt.last_name +
      ' ' +
      ' ' +
      dt.email +
      ' ' +
      dt.phone,
    value: dt.id,
    user: dt
  }));
  // Select optionlarınların nasıl gözükeceğini belirliyoruz
  const formatOptionLabel = (option, { context }) => {
    // eğer ki seçilmiş bir value ise sadece isim soyisim göster
    // Optionlar arasında ise email ve phone bilgisini de göster
    return context === 'value' ? (
      `${option.user.first_name} ${option.user.last_name}`
    ) : (
      <div>
        <h6>{option.user.first_name + ' ' + option.user.last_name}</h6>
        <h6>{option.user.email}</h6>
        <h6>{option.user.phone}</h6>
      </div>
    );
  };
  const defaultUserAddress = () => {
    return selectedUser.user_address.findIndex(dt => dt.isDefault === true);
  };
  useEffect(() => {
    if (!selectedUser.id) return;
    if (selectedUser.user_address.length > 0) {
      setSelectedAddress(defaultUserAddress);
    }
  }, [selectedUser.user_address]);

  const handleChangeAddress = e => {
    setSelectedAddress(Number(e.target.value));
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Customer</h5>
        </Card.Header>
        <Card.Body>
          <Select
            formatOptionLabel={formatOptionLabel}
            isClearable
            isLoading={isLoading}
            onInputChange={val =>
              val.length > 2 ? setSearchValue(val) : setSearchValue('')
            }
            options={userOptions ?? []}
            styles={{ width: '84%' }}
            placeholder="Search a customer"
            classNamePrefix="react-select"
            onChange={handleSelectedUser}
          />
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      {selectedUser.id && (
        <Card className="mb-3">
          <Card.Header>
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Customer Information
            </h5>
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-between">
              <div>
                <b>Name: </b>
                {selectedUser.first_name} {selectedUser.middle_name}{' '}
                {selectedUser.last_name}
              </div>
              <div>
                <b>E-Mail: </b> {selectedUser.email}
              </div>
            </div>
            <div>
              <b>Phone: </b> {selectedUser.phone}
            </div>
            {selectedUser?.user_address.length > 0 ? (
              <>
                <div className="mt-2">
                  <Form.Select
                    value={selectedAddress}
                    onChange={handleChangeAddress}
                  >
                    {selectedUser.user_address.map((dt, index) => (
                      <option key={index} value={index}>
                        {dt.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                {selectedAddress !== undefined && (
                  <div className="mt-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <b>Fullname: </b>{' '}
                        {selectedUser.user_address[selectedAddress].firstName}{' '}
                        {selectedUser.user_address[selectedAddress].lastName}
                      </div>
                      <div>
                        <b>Phone: </b>{' '}
                        {selectedUser.user_address[selectedAddress].phone}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <b>Country: </b>{' '}
                        {selectedUser.user_address[selectedAddress].country}
                      </div>
                      <div>
                        <b>City: </b>{' '}
                        {selectedUser.user_address[selectedAddress].city}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <b>District: </b>{' '}
                        {selectedUser.user_address[selectedAddress].district}
                      </div>
                      <div>
                        <b>Postal Code: </b>{' '}
                        {selectedUser.user_address[selectedAddress].postalCode}
                      </div>
                    </div>
                    <div>
                      <b>Address: </b>{' '}
                      {selectedUser.user_address[selectedAddress].description}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-2">User doesn't have any address</div>
            )}
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
      )}
    </>
  );
};

CustomerInfo.propTypes = {
  selectedUser: PropTypes.object.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  selectedAddress: PropTypes.number.isRequired,
  setSelectedAddress: PropTypes.func.isRequired
};

export default CustomerInfo;
