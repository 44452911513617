import CardDropdown from 'components/common/CardDropdown';
import SelectBox from 'components/common/SelectBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import AppContext from 'context/Context';
import useDelete from 'hooks/useDelete';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import {
  getProductCategoriesMini,
  getProductCollectionsMini,
  getTagsMini
} from 'services/product';
import serviceUrl from 'services/serviceUrlList';
import {
  createInterestArea,
  getInteresAreas,
  updateInterestArea
} from 'services/user';

const InterestArea = props => {
  const { newClickCount } = props;
  const [searchValue, setSearchValue] = useState('');
  const [elementOptions, setElementOptions] = useState([]);
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm();

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };
  const typeOptions = [
    { label: 'Collection', value: 1 },
    { label: 'Product Category', value: 2 },
    { label: 'Tag', value: 3 }
  ];

  const {
    data,
    isLoading: tableLoading,
    refetch
  } = useQuery(['getInteresAreas', queryObj], () => getInteresAreas(queryObj), {
    keepPreviousData: false,
    staleTime: Infinity
  });
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.user.interestArea.detail + selectedRowId
  });

  useEffect(() => {
    if (newClickCount === 0) return;
    openCreateModal(true);
  }, [newClickCount]);

  const onSubmit = data => {
    if (selectedRowId === null) {
      postRequest({ type: data.type.value, name: data.name.label });
    } else {
      updateRequest({ type: data.type.value, name: data.name.label });
    }
  };
  const updateRequest = async payload => {
    const response = await updateInterestArea(payload, selectedRowId);
    if (response.status === 200) {
      closeCreateModal();
      refetch();
      toast.success(`Interest area updated`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data?.name, {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const postRequest = async payload => {
    const response = await createInterestArea(payload);
    if (response.status === 201) {
      closeCreateModal();
      refetch();
      toast.success(`Interest area created`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      console.log(response.data);
      toast.error(response.data?.name, {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const handleEditItem = dt => {
    setSelectedRowId(dt.id);
    setValue('type', typeOptions.filter(el => dt.type === el.value)[0]);
    if (typeOptions.filter(el => dt.type === el.value)[0]) {
      handleGetList(typeOptions.filter(el => dt.type === el.value)[0]);
    }
    setValue('name', { label: dt.name, value: dt.name });
    openCreateModal(true);
  };

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    closeCreateModal();
    refetch();
    setSelectedRowId(null);
    if (deleteResponse.error === null) {
      toast.success(`Interest area deleted`, {
        theme: 'colored'
      });
    } else {
      toast.error(deleteResponse.error.data.message, {
        theme: 'colored'
      });
    }
  }, [deleteResponse]);

  const handleModalDeleteButton = () => {
    if (selectedRowId === null) {
      closeCreateModal();
    } else {
      handleDeleteItem(selectedRowId);
    }
  };

  const clearFormData = () => {
    clearErrors();
    reset();
  };

  const handleGetList = async option => {
    setValue('type', option);
    let res;
    if (option.value === 1) {
      res = await getProductCollectionsMini({ limit: 10, search: searchValue });
    } else if (option.value === 2) {
      res = await getProductCategoriesMini({ limit: 10, search: searchValue });
    } else if (option.value === 3) {
      res = await getTagsMini({ limit: 10, search: searchValue });
    }
    setElementOptions(
      res.data.results.map(dt => ({ label: dt.name, value: dt.name }))
    );
  };

  useEffect(() => {
    if (searchValue === '') return;
    console.log(control);
    handleGetList(getValues('type'));
  }, [searchValue]);

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <a href="#" onClick={() => handleEditItem(rowData.row.original)}>
            {name}
          </a>
        );
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { type } = rowData.row.original;
        return (
          <>
            {type === 1
              ? 'Collection'
              : type === 2
              ? 'Product Category'
              : 'Tag'}
          </>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handleEditItem(dt.row.original)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleSearch = value => {
    console.log(value);
    setQueryObj(prevState => ({
      ...prevState,
      name__istartswith: value
    }));
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={tableLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Row>
            <Col xs={6} className="d-flex align-items-center pe-0">
              <AdvanceTableSearchBox
                size="sm"
                placeholder="Filter interest areas"
                setGlobalFilter={handleSearch}
              />
            </Col>
          </Row>
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 align-middle'
              }}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Add Interest Area</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group controlId="type">
              <Form.Label className="fs-0">Type</Form.Label>
              <SelectBox
                options={typeOptions}
                control={control}
                name="type"
                rules={{ required: 'This field cannot be empty' }}
                className={errors.type ? 'is-invalid' : ''}
                placeholder="Select Type"
                classNamePrefix="react-select"
                onChange={handleGetList}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.type?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="name">
              <Form.Label className="fs-0">Name</Form.Label>
              <SelectBox
                options={elementOptions}
                name="name"
                control={control}
                onInputChange={val =>
                  val.length > 2 ? setSearchValue(val) : setSearchValue('')
                }
                rules={{ required: 'This field cannot be empty' }}
                className={errors.name ? 'is-invalid' : ''}
                placeholder="Select Name"
                classNamePrefix="react-select"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={handleModalDeleteButton}
            >
              {selectedRowId === null ? 'Cancel' : 'Delete'}
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Interest Area"
        description="Are you sure the interest area you selected will be deleted?"
        request={deleteRequest}
        type={'delete'}
        btnSecondary="Cancel"
      />
    </div>
  );
};

InterestArea.propTypes = {
  newClickCount: PropTypes.number
};

export default InterestArea;
