import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { AuthContext } from 'context/Context';
import { updateProduct } from 'pages/product/productList/AddProduct';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getProductListMini } from 'services/product';

function ProductListModal({
  showModal,
  setShowModal,
  param,
  paramId,
  refetchList
}) {
  const { user } = useContext(AuthContext);
  const [wholeData, setWholeData] = useState([]);
  const [offset, setOffset] = useState(0);
  const { data, isLoading } = useQuery(
    [`get-${param}-product-${paramId}`, paramId, offset],
    () =>
      paramId &&
      getProductListMini({ [param]: paramId, limit: 10, offset: offset }),
    {
      keepPreviousData: false,
      onSuccess: dt => {
        if (!dt) return;
        setWholeData(prev => [...prev, ...dt.data.results]);
      }
    }
  );

  const closeModal = () => {
    setShowModal(false);
    setWholeData([]);
  };

  const handleRmFromCollection = async prd => {
    if (prd.main_collection === paramId) {
      toast.error(
        'This collection is main collection for this product. You need to update in the product detail page!',
        { theme: 'colored' }
      );
      return;
    }
    const collections = prd.collections
      .filter(id => id !== paramId)
      .map(id => ({ id: id }));
    const payload = {
      updated_by: user.id,
      collections: collections,
      main_collection: prd.main_collection
    };
    const res = await updateProduct(payload, prd.id);
    if (res.status === 200) {
      toast.success(`Product removed from collection`, {
        theme: 'colored'
      });
      refetchList();
      setWholeData(prev => {
        return prev.filter(p => p.id !== prd.id);
      });
    } else {
      toast.error(`Something went wrong!`, {
        theme: 'colored'
      });
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>Products</Modal.Title>
        <FalconCloseButton onClick={closeModal} />
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: 500,
          overflowY: 'auto'
        }}
      >
        <LoadingWrapper loading={isLoading}>
          <>
            {wholeData?.map((product, index) => {
              return (
                <div
                  key={product.id}
                  className="d-flex gap-3 align-items-center py-1"
                  style={{
                    borderBottom: '1px solid #dee2e6'
                  }}
                >
                  <span>{index + 1}.</span>
                  {product?.files && product?.files[0] && (
                    <Image
                      src={product?.files[0].file}
                      width={50}
                      height={50}
                    />
                  )}
                  <span
                    style={{
                      display: 'inline-block',
                      width: 575,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {product.name}
                  </span>
                  <SoftBadge pill bg="success" className="d-block ml-auto">
                    Active
                  </SoftBadge>
                  {param === 'collections__id' && (
                    <IconButton
                      onClick={() => handleRmFromCollection(product)}
                      variant="falcon-danger"
                      size="sm"
                      icon="trash"
                      transform="shrink-3"
                    />
                  )}
                </div>
              );
            })}
            <p
              className="text-center"
              style={{
                cursor: 'pointer',
                marginTop: 10,
                marginBottom: 0,
                color: '#0d6efd'
              }}
              onClick={() => {
                if (wholeData.length >= data?.data.count) return;
                setOffset(prev => prev + 10);
              }}
            >
              {wholeData.length >= data?.data?.count
                ? 'No More Products to Show'
                : 'Show More Products'}
            </p>
          </>
        </LoadingWrapper>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

ProductListModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  param: PropTypes.string,
  refetchList: PropTypes.func,
  paramId: PropTypes.number
};

export default ProductListModal;
