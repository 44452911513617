import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { LoaderContext } from 'context/Context';

const PageLoadingWrapper = props => {
  const { loading } = useContext(LoaderContext);
  const { children } = props;
  return (
    <div style={{ position: 'relative' }}>
      {children}
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center page-loading-wrapper',
          {
            active: loading,
            passive: !loading
          }
        )}
      >
        <Spinner animation="border" role="status" style={{ color: '#4242a8' }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

PageLoadingWrapper.propTypes = {
  children: PropTypes.element,
  loading: PropTypes.bool
};

export default PageLoadingWrapper;
