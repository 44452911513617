import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { createMenuItem, updateMenuItem } from 'services/product';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const initialForm = {
  display_name: '',
  slug_type: '',
  slug: '',
  parent: ''
};

function AddMenuItem({
  brands,
  collections,
  editItem,
  refetch,
  menuMiniRefetch,
  menuMini,
  zone,
  draggableData
}) {
  const { id } = useParams();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const {
    handleSubmit,
    control,
    register,
    resetField,
    formState: { errors },
    reset,
    setValue
  } = useForm({ defaultValues: initialForm });
  const slug_type = useWatch({ control, name: 'slug_type' });
  const parent = useWatch({ control, name: 'parent' });
  const [modalOpen, setModalOpen] = useState(false);
  const [editItemData, setEditItemData] = useState({});
  const closeModal = () => {
    setModalOpen(false);
    reset();
  };
  const openModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    openModal();
    setTimeout(() => {
      closeModal();
    }, 1);
  }, []);

  const handleAdd = () => {
    openModal();
  };

  const onSubmit = async data => {
    console.log('DATA: ', data);
    const payload = {
      display_name: data.display_name,
      slug_type: data.slug_type.value,
      object_id: data.slug_type.value === 'pages' ? data.slug : data.slug.value,
      zone: zone,
      parent: data.parent ? data.parent.value : null,
      main_menu: id,
      zone_bg_color: data.zone_bg_color
    };
    if (editItemData.id) {
      const response = await updateMenuItem(editItemData.id, payload);
      console.log('UPD: ', response);
      setEditItemData({});
      toast.success(`Updated successfully!`, {
        theme: 'colored'
      });
    } else {
      const order = payload.parent
        ? draggableData.filter(dt => dt.id === payload.parent)[0]
            .child_menu_items.length + 1
        : draggableData.length + 1;
      payload.order = order;
      const response = await createMenuItem(payload);
      console.log('RESPONSE: ', response);
      toast.success(`Saved successfully!`, {
        theme: 'colored'
      });
    }
    refetch();
    menuMiniRefetch();
    closeModal();
  };
  useEffect(() => {
    if (!editItem) return;
    setEditItemData(editItem);
    setValue('display_name', editItem.display_name);
    setValue('slug_type', {
      value: editItem.slug_type,
      label: editItem.slug_type[0].toUpperCase() + editItem.slug_type.slice(1)
    });
    if (editItem.slug_type === 'pages') {
      setValue('slug', editItem.object_id);
    } else {
      switch (editItem.slug_type) {
        case 'brand': {
          const marka = brands.find(dt => dt.slug === editItem.slug);
          setValue('slug', { value: Number(marka.id), label: marka.name });
          break;
        }
        case 'collection': {
          const koleksiyon = collections.find(dt => dt.slug === editItem.slug);
          setValue('slug', {
            value: Number(koleksiyon.id),
            label: koleksiyon.name
          });
          break;
        }
        default:
          break;
      }
    }
    if (editItem.parent) {
      setValue('parent', {
        value: editItem.parent.id,
        label: editItem.parent.display_name
      });
    }
    openModal();
  }, [editItem]);

  const parentOptions = menuMini?.map(dt => ({
    value: dt.id,
    label: dt.display_name
  }));

  const slugOptions = slug_type
    ? slug_type.value === 'brand'
      ? brands.map(dt => ({ value: dt.id, label: dt.name, id: dt.id }))
      : slug_type.value === 'collection'
      ? collections.map(dt => ({ value: dt.id, label: dt.name, id: dt.id }))
      : []
    : [];

  const slugTypeOptions = [
    { value: 'collection', label: 'Collection' },
    { value: 'brand', label: 'Brand' },
    { value: 'pages', label: 'Pages' }
  ];

  const customStylesSlugType = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.slug_type
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };
  const customStylesSlug = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.slug
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };
  const customStylesParent = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.parent
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };
  return (
    <>
      <Button onClick={handleAdd}>Add Item</Button>
      <Modal show={modalOpen} onHide={closeModal}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">
              {editItemData.id ? 'Update' : 'Add'} Menu Item
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="display_name">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                {...register('display_name', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.display_name}
                type="text"
                name="display_name"
                placeholder="Display Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.display_name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="slug_type">
              <Form.Label>Slug Type</Form.Label>
              <Controller
                name="slug_type"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    options={slugTypeOptions}
                    onChange={val => {
                      onChange(val);
                      resetField('slug');
                    }}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    placeholder="Slug Type"
                    isSearchable={false} // Arama çubuğunu devre dışı bırakır
                    classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                    components={{
                      IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                    }}
                    className={errors.slug_type ? 'is-invalid' : ''}
                    styles={{ ...customStylesSlugType }}
                  />
                )}
                rules={{ required: 'This field cannot be empty!' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.slug_type?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="slug">
              <Form.Label>Slug</Form.Label>
              {slug_type.value !== 'pages' ? (
                <Controller
                  name="slug"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      isDisabled={!slug_type}
                      options={slugOptions}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      placeholder="Slug"
                      isSearchable={true} // Arama çubuğunu devre dışı bırakır
                      classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                      components={{
                        IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                      }}
                      className={errors.slug ? 'is-invalid' : ''}
                      styles={{ ...customStylesSlug }}
                    />
                  )}
                  rules={{ required: 'This field cannot be empty!' }}
                />
              ) : (
                <Form.Control
                  {...register('slug', {
                    required: false
                  })}
                  isInvalid={errors.slug}
                  type="text"
                  name="slug"
                  placeholder="Url"
                />
              )}
              <Form.Control.Feedback type="invalid">
                {errors.slug?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="parent">
              <Form.Label>Parent</Form.Label>
              <Controller
                name="parent"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    options={parentOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    placeholder="Parent"
                    isSearchable={false} // Arama çubuğunu devre dışı bırakır
                    classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                    components={{
                      IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                    }}
                    isClearable
                    className={errors.parent ? 'is-invalid' : ''}
                    styles={{ ...customStylesParent }}
                  />
                )}
              />
            </Form.Group>
            {!parent && (
              <Form.Group className="mb-3" controlId="zone_bg_color">
                <Form.Label>Zone Background Color</Form.Label>
                <Form.Control
                  {...register('zone_bg_color', {
                    required: 'This field cannot be empty!'
                  })}
                  isInvalid={errors.zone_bg_color}
                  type="color"
                  name="zone_bg_color"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zone_bg_color?.message}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button variant="danger" className="px-3 mx-2" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => {}}
              className="px-5 mx-0"
            >
              {editItemData.id ? 'Update' : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddMenuItem;

AddMenuItem.propTypes = {
  brands: PropTypes.array,
  collections: PropTypes.array,
  mainMenuData: PropTypes.array,
  editItem: PropTypes.object,
  refetch: PropTypes.func,
  zone: PropTypes.number,
  menuMiniRefetch: PropTypes.func,
  menuMini: PropTypes.array,
  draggableData: PropTypes.array
};
