import PropTypes from 'prop-types';
import React from 'react';

function CustomerInterests({ data }) {
  if (!data) return null;
  return (
    <div>
      <h5>Interest Area</h5>
      <div className="d-flex flex-wrap">
        {data.customer_interest_list?.map((dt, index) => (
          <span key={index} className="mx-2">
            {dt}
          </span>
        ))}
      </div>
    </div>
  );
}

export default CustomerInterests;

CustomerInterests.propTypes = {
  data: PropTypes.object
};
