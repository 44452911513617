import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import serviceUrl from '../../../services/serviceUrlList';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import VariantsTableFilter from './module/VariantsTableFilter';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useDelete from 'hooks/useDelete';
import useUpdate from 'hooks/useUpdate';
import TableHeader from 'components/common/TableHeader';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getVariants } from 'services/product';
import { queryReducer } from 'reducers/queryReducer';

const Variants = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: '',
    request: () => {}
  });
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const { data, isLoading, refetch } = useQuery(
    ['getVariants', queryObj],
    () => getVariants(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);
  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.product.variant.detail + selectedRowId?.id,
    payload: { is_active: false }
  });
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.variant.detail + selectedRowId?.id
  });
  const navigate = useNavigate();

  const handleVariantSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search: value
    }));
  };

  const handleDeleteItem = id => {
    setSelectedRowId({ id: id, type: 'delete' });
    setShowConfirm(true);
  };
  const handleArchieveItem = id => {
    setSelectedRowId({ id: id, type: 'update' });
    setShowConfirm(true);
  };
  useEffect(() => {
    if (!selectedRowId) return;
    if (selectedRowId.type === 'update') {
      setConfirmText({
        title: 'Archieve Variant',
        description: 'Are you sure? Selected variant will be archieved!',
        type: 'update',
        request: updateRequest
      });
    } else if (selectedRowId.type === 'delete') {
      setConfirmText({
        title: 'Delete Variant',
        description: 'Are you sure? Selected variant will be deleted!',
        type: 'delete',
        request: deleteRequest
      });
    }
  }, [selectedRowId]);
  useEffect(() => {
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    refetch();
    setShowConfirm(false);
    toast.success(`Variant deleted`, {
      theme: 'colored'
    });
    setSelectedRowId(null);
  }, [deleteResponse]);
  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.error) return;
    refetch();
    toast.success(`Variant updated`, {
      theme: 'colored'
    });
  }, [updateResponse]);

  const tabFilter = filterValue => {
    const temp = { ...queryObj };
    delete temp.is_active;
    delete temp.is_deleted;
    switch (filterValue) {
      case 'all':
        break;
      case 'active':
        temp.is_active = true;
        break;
      case 'archieved':
        temp.is_active = false;
        break;
      case 'deleted':
        temp.is_deleted = true;
        break;
      default:
        break;
    }
    setQueryObj(temp);
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end
  const columns = [
    {
      accessor: 'product',
      Header: 'Product',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { products, id } = rowData.row.original;
        return (
          <Link to={`/product/variant-update/${id}`}>
            {products?.map(dt => dt.name).join(',')}
          </Link>
        );
      }
    },
    {
      accessor: 'attribute_types',
      Header: 'Attributes',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_active } = rowData.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(`/product/variant-update/${id}`)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleArchieveItem(id);
                }}
              >
                Archive
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleNew = () => {
    navigate('/product/variant-add');
  };
  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Variants"
                handleRefetch={refetch}
                table
                handleNew={handleNew}
                bulkTable={'Option'}
              />
              <VariantsTableFilter
                eventKey={tabFilter}
                handleVariantSearch={handleVariantSearch}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={confirmText.request}
        type={confirmText.type}
      />
    </div>
  );
};

export default Variants;
