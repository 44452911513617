import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';

const CardCombination = props => {
  const { setValue, control, discountTypesWatch } = props;
  const [toggleProduct, setToggleProduct] = useState(false);
  const [toggleShipping, setToggleShipping] = useState(false);
  const [toggleOrder, setToggleOrder] = useState(false);

  const wcombinedWatch = useWatch({ control, name: 'wcombined' });

  useEffect(() => {
    if (wcombinedWatch?.includes('1')) setToggleProduct(true);
    if (wcombinedWatch?.includes('3')) setToggleOrder(true);
    if (wcombinedWatch?.includes('4')) setToggleShipping(true);
  }, [wcombinedWatch]);

  useEffect(() => {
    let temp_arr = [];
    toggleProduct ? temp_arr.push(1) : temp_arr.filter(e => e !== 1);
    toggleOrder ? temp_arr.push(3) : temp_arr.filter(e => e !== 3);
    toggleShipping ? temp_arr.push(4) : temp_arr.filter(e => e !== 4);
    if (toggleProduct || toggleShipping || toggleOrder) {
      setValue('is_combined', true);
      setValue('wcombined', temp_arr.toString());
    } else {
      setValue('is_combined', false);
      setValue('wcombined', null);
    }
  }, [toggleProduct, toggleShipping, toggleOrder]);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Combinations</Card.Title>
        {discountTypesWatch !== 2 && (
          <Form.Group className="mb-1" controlId="toggleProduct">
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={toggleProduct}
                onChange={() => setToggleProduct(!toggleProduct)}
              />
              <Form.Check.Label className="m-0">
                Other product campaigns
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        )}
        {toggleProduct && (
          <Form.Group className="mb-1" controlId="txtProduct">
            <Form.Text>
              No product campaigns are currently set to combine. To let
              customers use more than one campaign, set up at least one product
              campaign that combines with product campaigns.
            </Form.Text>
          </Form.Group>
        )}
        {discountTypesWatch !== 4 && (
          <Form.Group className="mb-1" controlId="toggleShipping">
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={toggleShipping}
                onChange={() => setToggleShipping(!toggleShipping)}
              />
              <Form.Check.Label className="m-0">
                Shipping campaigns
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        )}
        {toggleShipping && (
          <Form.Group className="mb-1" controlId="txtShipping">
            <Form.Text>
              No shipping campaigns are currently set to combine. To let
              customers use more than one campaign, set up at least one shipping
              campaign that combines with product campaigns.
            </Form.Text>
          </Form.Group>
        )}
        {discountTypesWatch === 4 && (
          <Form.Group className="mb-1" controlId="toggleOrder">
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={toggleOrder}
                onChange={() => setToggleOrder(!toggleOrder)}
              />
              <Form.Check.Label className="m-0">
                Order campaigns
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        )}
        {toggleOrder && (
          <Form.Group className="mb-1" controlId="txtOrder">
            <Form.Text>
              No order campaigns are currently set to combine. To let customers
              use more than one campaign, set up at least one order campaign
              that combines with order campaigns.
            </Form.Text>
          </Form.Group>
        )}
      </Card.Body>
    </Card>
  );
};

CardCombination.propTypes = {
  setValue: PropTypes.func,
  control: PropTypes.object,
  handleChange: PropTypes.func,
  discountTypesWatch: PropTypes.number
};

export default CardCombination;
