import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { MdDragIndicator } from 'react-icons/md';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import {
  deleteCancelRefundReasons,
  updateCancelRefundReasons
} from 'services/order';
import { StrictDroppable } from './StrictDroppable';

function CancelReasonsDnd({ data, refetch }) {
  const [draggableData, setDraggableData] = useState(data);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  useEffect(() => {
    setDraggableData(data);
  }, [data]);

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };
  const onDragEnd = async result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = [...draggableData];
      const reorderedItems = reorder(items, source.index, destination.index);

      reorderedItems.forEach(async (item, index) => {
        await updateCancelRefundReasons(item.id, { orderNo: index + 1 });
      });
      // await Promise.all(promise);
      const newList = reorderedItems.map((item, index) => ({
        ...item,
        orderNo: index + 1
      }));
      setDraggableData(newList);
    }
    // Sürükleme işlemi sona erdiğinde yapılacak işlemler
  };

  const handleDeleteItem = async () => {
    await deleteCancelRefundReasons(deleteItem.id);
    await refetch();
    toast.success(`Cancellation Reasons successfully deleted!`, {
      theme: 'colored'
    });
  };

  const handleDelete = async item => {
    setShowDeleteConfirm(true);
    setDeleteItem(item);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row>
        <Col xs={12}>
          <StrictDroppable droppableId="droppable" direction="vertical">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="border bg-white dark__bg-1000 rounded-2 py-3 mb-3"
              >
                {draggableData.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={`drag-${item.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          className="d-flex align-items-center gap-5"
                          style={{
                            backgroundColor: snapshot.isDragging
                              ? 'lightblue'
                              : undefined,
                            padding: 4,
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            margin: 2,
                            fontSize: 12
                          }}
                        >
                          <div {...provided.dragHandleProps}>
                            <MdDragIndicator />
                          </div>
                          <div>{item.orderNo}</div>
                          <div>{item.name}</div>
                          <div style={{ marginLeft: 'auto' }}>
                            <CardDropdown iconClassName="fs--1">
                              <div className="py-2">
                                <Dropdown.Item
                                  onClick={() => handleDelete(item)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </div>
                            </CardDropdown>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictDroppable>
        </Col>
      </Row>
      <ConfirmationModal
        showConfirm={showDeleteConfirm}
        setShowConfirm={setShowDeleteConfirm}
        title="Delete Menu Item"
        description="Are you sure you want to delete this menu item?"
        request={handleDeleteItem}
        type="delete"
      />
    </DragDropContext>
  );
}

export default CancelReasonsDnd;

CancelReasonsDnd.propTypes = {
  data: PropTypes.array,
  refetch: PropTypes.func
};
