import Flex from 'components/common/Flex';
import ModalSelectItem from 'pages/product/discount/module/ModalSelectItem';
import SelectedItems from 'pages/product/discount/module/SelectedItems';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';

const CardCustomerEligibility = props => {
  const { register, setValue, control } = props;
  const [toggleBrowse, setToggleBrowse] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const customerTypeWatch = useWatch({ control, name: 'customer_type' });
  const customersWatch = useWatch({ control, name: 'customers' });
  const itemType = 'user';

  useEffect(() => {
    setToggleBrowse(['2', '3'].includes(customerTypeWatch));
    if (!['2', '3'].includes(customerTypeWatch)) return;
    const temp_arr = Array.isArray(customersWatch)
      ? customersWatch?.map(customer => {
          return customer.user;
        })
      : [];
    setSelectedItems(temp_arr);
  }, [customerTypeWatch]);

  const handleChange = ({ target }) => {
    if (target.value === '1') {
      setToggleBrowse(false);
    } else if (target.value === '3') {
      setToggleBrowse(true);
    }
  };
  const handelInputChange = event => {
    openModal();
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    const temp_arr = selectedItems.map(e => e.id);
    setValue('customers', temp_arr.toString());
  }, [selectedItems]);

  const modalProps = {
    searchValue,
    setSearchValue,
    showModal,
    closeModal,
    selectedItems,
    setSelectedItems,
    itemType
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Customer eligibility</Card.Title>
        <Form.Group className="mb-1" controlId="allCustomer">
          <Form.Check
            {...register('customer_type')}
            inline
            type="radio"
            value={1}
            className="me-2"
            defaultChecked
            onClick={handleChange}
          />
          <Form.Label className="mb-0">All customers</Form.Label>
        </Form.Group>
        <Flex>
          <Form.Group className="mb-3 me-2" controlId="specificCustomer">
            <Form.Check
              {...register('customer_type')}
              inline
              type="radio"
              value={3}
              className="me-2"
              onClick={handleChange}
            />
            <Form.Label className="mb-0">Specific customers</Form.Label>
          </Form.Group>
          {toggleBrowse && (
            <Form.Group className="flex-fill" controlId="search-open-modal">
              <InputGroup>
                <InputGroup.Text>
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="search-open-modal"
                  placeholder={'Search Customers'}
                  value={''}
                  onChange={handelInputChange}
                />
                <Button variant="outline-primary" onClick={handelInputChange}>
                  Browse
                </Button>
              </InputGroup>
            </Form.Group>
          )}
        </Flex>
        {toggleBrowse && (
          <Row style={{ maxHeight: 400, overflow: 'auto' }}>
            <Col xs={12}>
              <SelectedItems
                items={selectedItems}
                setItems={setSelectedItems}
                itemType="user"
              />
            </Col>
          </Row>
        )}
      </Card.Body>
      <ModalSelectItem {...modalProps} />
    </Card>
  );
};
CardCustomerEligibility.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object
};
export default CardCustomerEligibility;
