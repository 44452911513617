import React from 'react';
import PasswordResetForm from './PasswordResetForm';
import bgImg from 'assets/img/generic/20.jpg';
import AuthLayout from './AuthLayout';

const PasswordReset = () => {
  return (
    <AuthLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <h3>Reset password</h3>
      </div>
      <PasswordResetForm layout="split" hasLabel />
    </AuthLayout>
  );
};

export default PasswordReset;
