import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import ProductListModal from 'components/modal/ProductListModal';
import useDelete from 'hooks/useDelete';
import useUpdate from 'hooks/useUpdate';
import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import { getProductCollections } from 'services/product';
import serviceUrl from '../../../services/serviceUrlList';
import CollectionsTableFilter from './module/CollectionsTableFilter';

const ProductCollection = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    is_active: true,
    is_deleted: false
  });
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: '',
    request: () => {}
  });
  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.product.collection.detail + selectedRowId?.id,
    payload: { is_active: false }
  });
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.collection.detail + selectedRowId?.id
  });
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useQuery(
    ['getProductCollections', queryObj],
    () => getProductCollections(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  const handleDeleteItem = id => {
    setSelectedRowId({ id: id, type: 'delete' });
    setShowConfirm(true);
  };
  const handleArchieveItem = id => {
    setSelectedRowId({ id: id, type: 'update' });
    setShowConfirm(true);
  };
  useEffect(() => {
    if (!selectedRowId) return;
    if (selectedRowId.type === 'update') {
      setConfirmText({
        title: 'Collection Status',
        description: 'Are you sure? Selected collection will be passive!',
        type: 'update',
        request: updateRequest
      });
    } else if (selectedRowId.type === 'delete') {
      setConfirmText({
        title: 'Delete Collection',
        description: 'Are you sure? Selected collection will be deleted!',
        type: 'delete',
        request: deleteRequest
      });
    }
  }, [selectedRowId]);
  useEffect(() => {
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    refetch();
    setShowConfirm(false);
    toast.success(`Collection deleted`, {
      theme: 'colored'
    });
    setSelectedRowId(null);
  }, [deleteResponse]);
  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.error) return;
    refetch();
    toast.success(`Collection updated`, {
      theme: 'colored'
    });
  }, [updateResponse]);

  const tabFilter = filterValue => {
    switch (filterValue) {
      case 'all':
        setQueryObj({
          ...queryObj,
          is_active: undefined,
          is_deleted: undefined
        });
        break;
      case 'active':
        setQueryObj({
          ...queryObj,
          is_active: true,
          is_deleted: false
        });
        break;
      case 'passive':
        setQueryObj({
          ...queryObj,
          is_active: false,
          is_deleted: false
        });
        break;
      case 'deleted':
        setQueryObj({
          ...queryObj,
          is_active: undefined,
          is_deleted: true
        });
        break;
      default:
        break;
    }
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data?.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  const handleCollectionSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search: value
    }));
  };

  useEffect(() => {
    if (!data?.data?.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data?.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'text-start', width: 250 },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name, id } = rowData.row.original;
        return <Link to={`/product/collection-update/${id}`}>{name}</Link>;
      }
    },
    {
      accessor: 'description',
      Header: 'Description',
      headerProps: { className: 'pe-1 text-start', width: 300 },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { description } = rowData.row.original;
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `${description
                ?.replace(/<\/?[^>]+(>|$)/g, ' ')
                .slice(0, 40)} ${description?.length >= 40 ? '...' : ''}`
            }}
          ></div>
        );
      }
    },
    {
      accessor: 'image',
      Header: 'Image',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { image, image_alt_text } = rowData.row.original;
        return (
          <Fragment>
            {image ? (
              <img
                className="rounded-1 border border-200 me-2 img-50-contain"
                src={image}
                width="60"
                alt={image_alt_text}
              />
            ) : (
              <Fragment></Fragment>
            )}
          </Fragment>
        );
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_active } = rowData.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'product_count',
      Header: 'Products',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { product_count } = rowData.row.original;
        if (product_count === 0) return product_count;
        return (
          <a
            href="#!"
            onClick={() => {
              setShowProductsModal(true);
              setSelectedRowId(rowData.row.original.id);
            }}
          >
            {product_count}
          </a>
        );
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(`/product/collection-update/${id}`)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleArchieveItem(id);
                }}
              >
                Passive
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleNew = () => {
    navigate('/product/collection-add');
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          data={data?.data.results || []}
          columns={columns}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Collections"
                handleRefetch={refetch}
                table
                handleNew={handleNew}
                bulkTable={'Collection'}
              />
              <CollectionsTableFilter
                eventKey={tabFilter}
                handleCollectionSearch={handleCollectionSearch}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={confirmText.request}
        type={confirmText.type}
      />
      <ProductListModal
        showModal={showProductsModal}
        refetchList={refetch}
        setShowModal={setShowProductsModal}
        paramId={
          selectedRowId && typeof selectedRowId === 'number'
            ? selectedRowId
            : undefined
        }
        param="collections__id"
      />
    </div>
  );
};

export default ProductCollection;
