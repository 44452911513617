import AppContext, { AuthContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createTransaction,
  getTransactions,
  getVendors,
  getWarehouses,
  updateTransaction
} from 'services/warehouse';
import IconButton from 'components/common/IconButton';
import SelectBox from 'components/common/SelectBox';
import DatePickerCustomInput from 'components/common/DatePickerCustomInput';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import EditableCell from 'pages/stock/Inventory/module/EditableCell';
import moment from 'moment';
import { useQuery } from 'react-query';
import { QRCodeCanvas } from 'qrcode.react';
import { printQrCode } from './module/PrintQrcode';

const AddStockEntry = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialForm = {
    stock: null,
    skt: new Date(),
    quantity: 0,
    newStockBarcode: '',
    newStockEntryName: '',
    qrCode: '',
    name: ''
  };
  const initialData = {
    transactionType: 101,
    lotNumber: '',
    storageLocation: '',
    account: '',
    waybill: '',
    invoices: '',
    date: new Date(),
    vehicle: '',
    description: ''
  };

  const [prdList, setPrdList] = useState([initialForm]);
  const [deletedPrdList, setDeletedPrdList] = useState([]);
  const [copyValue, setCopyValue] = useState();
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: '',
    description: '',
    button: ''
  });
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setValueMdl('descriptionMdl', '');
    setValueMdl('copyValue', 0);
  };
  const {
    config: { isDark }
  } = useContext(AppContext);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: initialData });
  const dateWatch = useWatch({ control, name: 'date' });
  const {
    register: registerMdl,
    handleSubmit: handleSubmitMdl,
    setValue: setValueMdl,
    formState: { errors: errorsMdl },
    control: controlMdl
  } = useForm({ defaultValues: { descriptionMdl: '', copyValue: 0 } });

  useEffect(() => {
    if (id) {
      setValue('updatedBy', user.id);
    } else {
      setValue('createdBy', user.id);
    }
  }, []);

  const lotInfo = id
    ? useQuery(['getLotInfo', id], () => getTransactions({ lotNumber: id }), {
        keepPreviousData: true,
        staleTime: Infinity
      })
    : null;

  const { data: warehouseResponse } = useQuery(
    ['getWarehouses', id],
    () => getWarehouses(),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const { data: vendorsResponse } = useQuery(
    ['getVendors', id],
    () => getVendors(),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const warehouseOptions = warehouseResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });
  const vendorOptions = vendorsResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });

  const onSubmit = async data => {
    if (!prdList.length > 0) {
      toast.error('Please add product!', { theme: 'colored' });
      return;
    }
    if (id) {
      setConfirmationInfo({
        title: 'Update Stock Entry',
        description: 'Enter description for the update action.',
        button: 'Update'
      });
      openModal();
      return;
    }
    completeOnSubmit(data);
  };

  const completeOnSubmit = () => {
    const data = { ...control._formValues };
    const dataMdl = { ...controlMdl._formValues };
    const allPrdList = prdList.concat(deletedPrdList);
    closeModal();
    data.storageLocation = data.storageLocation?.value
      ? data.storageLocation.value
      : data.storageLocation;
    data.account = data.account?.value ? data.account.value : data.account;
    data[data.docType.value] = data.docNumber;
    if (dataMdl.descriptionMdl !== '') {
      data.descriptionUpdate = dataMdl.descriptionMdl;
    }
    const payload = allPrdList.map(prd => {
      const temp = { ...prd };
      data.date = moment(data.date).format('YYYY-MM-DD');
      data.skt = moment(temp.skt).format('YYYY-MM-DD');
      if (!temp?.stock && !temp.id) {
        temp.newStockEntryName = temp.name;
        temp.newStockBarcode = temp.barcode.label;
      } else {
        delete temp.newStockEntryName;
        delete temp.newStockBarcode;
      }
      delete temp.qrCode;
      delete temp.barcode;
      delete temp.name;
      delete data.docNumber;
      delete data.docType;
      return { ...temp, ...data };
    });
    if (id) {
      updateRequest(payload);
    } else {
      postRequest(payload);
    }
  };

  const postRequest = async payload => {
    const response = await createTransaction(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Stock entry created.`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };
  const updateRequest = async payload => {
    const response = await updateTransaction(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Stock entry updated.`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const columns = [
    {
      accessor: 'barcode',
      Header: 'Barcode',
      headerProps: { className: 'pe-1 text-start', style: { width: 250 } },
      Cell: dt =>
        EditableCell({
          ...dt,
          setData: setPrdList,
          type: 'select'
        })
    },
    {
      accessor: 'name',
      Header: 'Product Name',
      headerProps: { className: 'pe-1 text-start' },
      Cell: dt =>
        EditableCell({
          ...dt,
          setData: setPrdList,
          isDisabled: !!dt.row.original?.stock,
          type: 'select'
        })
    },
    {
      accessor: 'skt',
      Header: 'Expire Date (SKT)',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: { style: { width: 150 } },
      Cell: dt => EditableCell({ ...dt, setData: setPrdList, type: 'date' })
    },
    {
      accessor: 'quantity',
      Header: 'Quantity',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: { style: { width: 70 } },
      Cell: dt => EditableCell({ ...dt, setData: setPrdList, type: 'number' })
    },
    {
      accessor: 'none',
      Header: '',
      headerProps: { className: 'pe-1 text-end' },
      cellProps: { className: 'pe-1 text-end', style: { width: 150 } },
      Cell: rowData => {
        const { index } = rowData.row;
        const { id: rowId, quantity } = rowData.row.original;
        return (
          <>
            {rowData.data.length > 1 && (
              <IconButton
                onClick={() => handleRemove(index)}
                variant="secondary"
                size="sm"
                icon="trash"
                transform="shrink-3"
                className="me-1"
              ></IconButton>
            )}
            <IconButton
              onClick={() => setPrdList([...prdList, initialForm])}
              variant="primary"
              size="sm"
              icon="plus"
              transform="shrink-3"
            ></IconButton>
            {id && (
              <IconButton
                onClick={() => handlePrint(rowId, quantity)}
                variant="primary"
                size="sm"
                icon="print"
                transform="shrink-3"
                className="ms-1"
              ></IconButton>
            )}
          </>
        );
      }
    }
  ];
  id &&
    columns.splice(1, 0, {
      accessor: 'qrCode',
      Header: 'QrCode',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'pe-1 text-center' }
    });

  useEffect(() => {
    const data = lotInfo?.data?.data.results;
    if (!data) return;
    setValue('lotNumber', data[0].lotNumber);
    setValue('account', {
      label: data[0].account.name,
      value: data[0].account.id
    });
    setValue('storageLocation', {
      label: data[0].storageLocation.name,
      value: data[0].storageLocation.id
    });
    setValue('docType', { label: 'Waybill', value: 'waybill' });
    setValue('docNumber', data[0].waybill);
    setValue('description', data[0].description);
    const tempArr = data.map(e => {
      return {
        id: e.id,
        barcode: { label: e.stock.barcode, value: e.stock.barcode },
        name: { label: e.stock.name, value: e.stock.name },
        skt: e.skt,
        quantity: e.quantity,
        qrCode: <QRCodeCanvas id={e.id} value={e.qr_code ?? ''} size={32} />
      };
    });
    setPrdList(tempArr);
  }, [lotInfo?.data]);

  const handleRemove = index => {
    // open modal only for old records
    if (id && prdList[index].qrCode) {
      setSelectedRowId(index);
      setConfirmationInfo({
        title: 'Delete Product',
        description: 'Enter description for the delete action.',
        button: 'Delete',
        desc: ''
      });
      openModal();
    } else {
      setPrdList(prdList.filter(elem => elem !== prdList[index]));
    }
  };
  const completeRemove = () => {
    const dataMdl = { ...controlMdl._formValues };
    setDeletedPrdList({
      ...prdList[selectedRowId],
      isDeleted: true,
      descriptionDelete: dataMdl.descriptionMdl
    });
    setPrdList(prdList.filter(elem => elem !== prdList[selectedRowId]));

    closeModal();
  };
  const handlePrint = (rowId, quantity) => {
    setSelectedRowId(rowId);
    setCopyValue(quantity);
    setValueMdl('copyValue', quantity);
    setConfirmationInfo({
      title: 'Print Qrcode',
      description: 'Copy count',
      button: 'Print'
    });
    openModal();
  };

  const completePrint = () => {
    printQrCode([selectedRowId], copyValue);
    closeModal();
  };

  const handleRouteList = () => {
    navigate('/stock/inventory');
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {id ? 'Update' : 'Create'} Stock Entry
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="date">
                  <Form.Label as={Col} xs={4}>
                    Date
                  </Form.Label>
                  <Col xs={8}>
                    <DatePickerCustomInput
                      value={moment(dateWatch).format('DD/MM/YYYY')}
                      onChange={date => setValue('date', date)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      placeholder="Date"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="lotNumber">
                  <Form.Label as={Col} xs={4}>
                    Lot Number
                  </Form.Label>
                  <Col xs={8}>
                    <Form.Control
                      {...register('lotNumber')}
                      isInvalid={errors.barcode}
                      type="text"
                      placeholder="Lot number"
                      disabled
                    />
                    {!id && (
                      <Form.Text>
                        Lot number will be automatically generated after create.
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="account">
                  <Form.Label as={Col} xs={4}>
                    Vendor
                  </Form.Label>
                  <Col xs={8}>
                    <SelectBox
                      options={vendorOptions}
                      name="account"
                      control={control}
                      rules={{ required: 'This field cannot be empty' }}
                      className={errors.account ? 'is-invalid' : ''}
                      placeholder="Select Vendor"
                      classNamePrefix="react-select"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.account?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="storageLocation"
                >
                  <Form.Label as={Col} xs={4}>
                    Warehouse
                  </Form.Label>
                  <Col xs={8}>
                    <SelectBox
                      options={warehouseOptions}
                      name="storageLocation"
                      control={control}
                      rules={{ required: 'This field cannot be empty' }}
                      className={errors.storageLocation ? 'is-invalid' : ''}
                      placeholder="Select Warehouse"
                      classNamePrefix="react-select"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.storageLocation?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="docType">
                  <Form.Label as={Col} xs={4}>
                    Document Type
                  </Form.Label>
                  <Col xs={8}>
                    <SelectBox
                      options={[{ label: 'Waybill', value: 'waybill' }]}
                      name="docType"
                      control={control}
                      rules={{ required: 'This field cannot be empty' }}
                      className={errors.docType ? 'is-invalid' : ''}
                      placeholder="Document Type"
                      classNamePrefix="react-select"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.docType?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="docNumber">
                  <Form.Label as={Col} xs={4}>
                    Document Number
                  </Form.Label>
                  <Col xs={8}>
                    <Form.Control
                      {...register('docNumber', {
                        required: 'This field cannot be empty'
                      })}
                      isInvalid={!!errors?.docNumber}
                      type="text"
                      placeholder="Document Number"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.docNumber?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group as={Row} className="mb-3" controlId="description">
                  <Form.Label as={Col} xs={4} lg={2}>
                    Description
                  </Form.Label>
                  <Col xs={8} lg={10}>
                    <Form.Control
                      {...register('description')}
                      as="textarea"
                      placeholder="Description"
                      rows={3}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Col xs={12}>
              <AdvanceTableWrapper columns={columns} data={prdList || []}>
                <Card className="mb-3">
                  <Card.Body className="p-0">
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                      tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-0'
                      }}
                      rowClassName="align-middle"
                    />
                  </Card.Body>
                  <Card.Footer></Card.Footer>
                </Card>
              </AdvanceTableWrapper>
            </Col>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <Modal show={isModalOpen} onHide={closeModal} contentClassName="border">
        <Form
          onSubmit={
            confirmationInfo.button === 'Delete'
              ? handleSubmitMdl(completeRemove)
              : handleSubmitMdl(completeOnSubmit)
          }
        >
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">{confirmationInfo.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="descriptionMdl">
              <Form.Label className="fs-0">
                {confirmationInfo.description}
              </Form.Label>
              {confirmationInfo.button !== 'Print' ? (
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  rows={3}
                  {...registerMdl('descriptionMdl', {
                    required: 'This field cannot be empty!'
                  })}
                  isInvalid={errorsMdl.descriptionMdl}
                />
              ) : (
                <Form.Control
                  {...registerMdl('copyValue')}
                  value={copyValue}
                  onChange={e => setCopyValue(e.target.value)}
                  type="number"
                  className="input-spin-none"
                  onWheel={e => e.target.blur()}
                />
              )}
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button variant="danger" className="px-3 mx-2" onClick={closeModal}>
              Cancel
            </Button>
            {confirmationInfo.button === 'Print' ? (
              <Button
                variant="primary"
                type="submit"
                onClick={completePrint}
                className="px-5 mx-0"
              >
                {confirmationInfo.button}
              </Button>
            ) : (
              <Button variant="primary" type="submit" className="px-5 mx-0">
                {confirmationInfo.button}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddStockEntry;
