import { faSquareMinus, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import moment from 'moment';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryReducer } from 'reducers/queryReducer';
import { getInventoriesByWarehouse } from 'services/warehouse';
import PropTypes from 'prop-types';

const Products = ({ warehouseName }) => {
  const { id } = useParams();
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    storageLocation: id
  });
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const { isLoading, data } = useQuery(
    ['inventoriesByWarehouse', queryObj, sort],
    () =>
      getInventoriesByWarehouse({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);

  useEffect(() => {
    warehouseName(data?.data.results[0].storageLocation.name);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        // eslint-disable-next-line react/prop-types
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <FontAwesomeIcon icon={faSquareMinus} />
            ) : (
              <FontAwesomeIcon icon={faSquarePlus} />
            )}
          </span>
        ),
        headerProps: {
          className: 'text-start',
          style: { maxWidth: 10, paddingRight: 5 }
        },
        cellProps: { style: { maxWidth: 10, paddingRight: 5 } },
        Cell: ({ row }) =>
          row.original.stockSummaryOfLotTrace.length > 0 ? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <FontAwesomeIcon icon={faSquareMinus} />
              ) : (
                <FontAwesomeIcon icon={faSquarePlus} />
              )}
            </span>
          ) : (
            <></>
          )
      },
      {
        accessor: 'stock.name',
        Header: 'Product Name',
        headerProps: { className: 'pe-1 text-start' },
        cellProps: {
          className: 'py-2'
        }
      },
      {
        accessor: 'barcode',
        Header: 'Barcode',
        headerProps: { className: 'pe-1 text-start' },
        cellProps: {
          className: 'py-2'
        },
        disableSortBy: true
      },
      {
        accessor: 'sku',
        Header: 'SKU',
        headerProps: { className: 'pe-1 text-start' },
        cellProps: {
          className: 'py-2'
        },
        disableSortBy: true
      },
      {
        accessor: 'skt',
        Header: 'SKT',
        headerProps: { className: 'pe-1 text-center' },
        cellProps: {
          className: 'py-2 text-center'
        },
        Cell: rowData => {
          const { skt } = rowData.row.original;
          return moment(skt).format('DD/MM/YYYY');
        }
      },
      {
        accessor: 'quantity',
        Header: 'Quantity',
        headerProps: { className: 'pe-1 text-center' },
        cellProps: {
          className: 'py-2 text-center'
        }
      }
    ],
    []
  );

  const renderRowSubComponent = useCallback(({ row }) => {
    return (
      <>
        {row.original.stockSummaryOfLotTrace.map((dt, index) => {
          return (
            <tr key={index}>
              <td role="cell"></td>
              <td role="cell" className="ps-3">
                {dt.lotNumber}
              </td>
              <td role="cell"></td>
              <td role="cell"></td>
              <td role="cell" className="py-2 text-center">
                {moment(dt.skt).format('DD/MM/YYYY')}
              </td>
              <td role="cell" className="py-2 text-center">
                {dt.quantity}
              </td>
            </tr>
          );
        })}
      </>
    );
  }, []);

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          sortable
          pagination
          renderRowSubComponent={renderRowSubComponent}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 align-middle',
              subvalue: 'stockSummaryOfLotTrace'
            }}
            setSort={setSort}
            sort={sort}
          />
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>
    </div>
  );
};
Products.propTypes = {
  warehouseName: PropTypes.func,
  row: PropTypes.array
};

export default Products;
