import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoaderContext } from 'context/Context';

const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  return (
    <LoaderContext.Provider
      value={{
        loading,
        setLoading
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

LoaderProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LoaderProvider;
