import React, { useState } from 'react';
import DefinitionCard from '../DefinitionCard';
import ShippingCost from './tabs/ShippingCost';
import ShippingLimit from './tabs/ShippingLimit';

const Finance = () => {
  const [activeTab, setActiveTab] = useState('shipping_cost');
  const [newClickCount, setNewClickCount] = useState(0);
  const tabs = [
    {
      name: 'Shipping Cost',
      eventKey: 'shipping_cost'
    },
    {
      name: 'Shipping Limit',
      eventKey: 'shipping_limit'
    }
  ];
  const tabChange = tab => {
    setActiveTab(tab);
    setNewClickCount(0);
  };

  const handleNew = () => {
    setNewClickCount(newClickCount + 1);
  };

  return (
    <div>
      <DefinitionCard
        title="Finance"
        tabs={tabs}
        tabChange={tabChange}
        defaultActiveKey={tabs[0].eventKey}
        tabContent={
          activeTab === 'shipping_cost' ? (
            <ShippingCost newClickCount={newClickCount} />
          ) : activeTab === 'shipping_limit' ? (
            <ShippingLimit newClickCount={newClickCount} />
          ) : null
        }
        handleNew={handleNew}
      />
    </div>
  );
};

export default Finance;
