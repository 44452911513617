import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCheck } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { queryReducer } from 'reducers/queryReducer';
import { getAgreementCodes, getUserAgreements } from 'services/user';

const UserAgreements = () => {
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });

  const [columns, setColumns] = useState([
    {
      accessor: 'full_name',
      Header: 'Full Name',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    }
  ]);

  const { data, isLoading } = useQuery(
    ['getUserAgreements', queryObj],
    () => getUserAgreements(queryObj),
    {
      keepPreviousData: false
    }
  );

  useEffect(() => {
    console.log(queryObj);
  }, [queryObj]);

  const { data: codes } = useQuery(
    ['agreement-codes'],
    () => getAgreementCodes(),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  useEffect(() => {
    if (!codes) return;
    // set columns
    const temp = [...columns];
    codes?.map(dt => {
      temp.push({
        accessor: dt.name,
        Header: dt.name,
        headerProps: { className: 'text-center' },
        cellProps: {
          className: 'py-2 text-center'
        },
        Cell: rowData => {
          const { agreements } = rowData.row.original;
          const approved =
            agreements.filter(agr => agr.id === dt.id).length > 0;
          return approved ? <BsCheck /> : <AiOutlineClose />;
        }
      });
    });
    setColumns(temp);
  }, [codes]);

  const handleSearch = value => {
    console.log(value);
    setQueryObj(prevState => ({
      ...prevState,
      search_value: value
    }));
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          pagination
          data={data?.data?.results || []}
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader title="Agreements" table />
              <Row className="flex-start mt-2">
                <Col xs="auto" sm={6} lg={3}>
                  <AdvanceTableSearchBox
                    size="sm"
                    placeholder="Filter Agreements"
                    setGlobalFilter={val => handleSearch(val)}
                  />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>
    </div>
  );
};

export default UserAgreements;
