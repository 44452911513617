import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { BsSearch } from 'react-icons/bs';
import { useState } from 'react';
import ProductListItem from './ProductListItem';
import { AiOutlineLeft } from 'react-icons/ai';
import ProductFilterListItem from './ProductFilterListItem';
import ProductDetailFilterList from './ProductDetailFilterList';
import { getProduct } from 'services/product';
import { useQuery } from 'react-query';

const SelectProductsModal = props => {
  const {
    selectedProducts,
    setSelectedProducts,
    searchValue,
    setSearchValue,
    showModal,
    closeModal
  } = props;
  const [modalScreenStep, setModalScreenStep] = useState(0);
  const [selectedDetailFilter, setSelectedDetailFilter] = useState('');
  const [productFilters, setProductFilters] = useState('');
  const [currentSelectedProducts, setCurrentSelectedProducts] = useState();
  const { data, isLoading } = useQuery(
    ['product-filter', productFilters, searchValue],
    () => getProduct({ filter: productFilters, search: searchValue }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (showModal) {
      setCurrentSelectedProducts(selectedProducts);
    }
  }, [showModal]);

  const handleCloseModal = () => {
    closeModal();
    setSelectedDetailFilter('');
    setModalScreenStep(0);
    setSelectedProducts(currentSelectedProducts);
  };
  const handleAdd = () => {
    closeModal();
    setSelectedDetailFilter('');
    setModalScreenStep(0);
  };

  const handleSearchProducts = event => {
    setSearchValue(event.target.value);
    if (event.target.value.length > 0) {
      setModalScreenStep(2);
    }
  };

  const handleSelectProduct = (event, dt) => {
    const new_arr = [...selectedProducts];
    if (event.target.checked) {
      new_arr.push(dt);
      setSelectedProducts(new_arr);
    } else {
      const filtered_arr = new_arr.filter(product => dt.slug !== product.slug);
      setSelectedProducts(filtered_arr);
    }
  };
  const handleFilters = (key, value) => {
    if (key === 'collection') {
      key = 'collections';
    }
    const filter = '&' + key + '=' + value;
    setProductFilters(filter);
    setModalScreenStep(2);
  };
  const filter_list = [
    {
      name: 'All Products',
      onClick: () => {
        setModalScreenStep(2);
        setProductFilters('');
      }
    },
    {
      name: 'Collections',
      onClick: () => {
        setModalScreenStep(1);
        setSelectedDetailFilter('collection');
      }
    },
    {
      name: 'Brands',
      onClick: () => {
        setModalScreenStep(1);
        setSelectedDetailFilter('brand');
      }
    }
  ];
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      keyboard={false}
    >
      <Modal.Header>
        <AiOutlineLeft
          size={24}
          className="cursor-pointer"
          onClick={() => setModalScreenStep(0)}
        />
        &nbsp;&nbsp;&nbsp;
        <Modal.Title>Select Products</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <InputGroup>
              <InputGroup.Text>
                <BsSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                autoFocus
                name="search-products"
                placeholder="Search Products"
                value={searchValue}
                onChange={handleSearchProducts}
              />
            </InputGroup>
          </Col>
        </Row>
        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {modalScreenStep === 0 ? (
              <div className="mt-3">
                {filter_list.map((filter, index) => (
                  <ProductFilterListItem key={index} {...filter} />
                ))}
              </div>
            ) : modalScreenStep === 1 ? (
              <ProductDetailFilterList
                name={selectedDetailFilter}
                handleFilters={handleFilters}
              />
            ) : (
              <Row>
                <Col xs={12}>
                  <div
                    className="mt-3"
                    style={{
                      height: 300,
                      overflowY: 'auto'
                    }}
                  >
                    {data?.data.results?.map((dt, i) => {
                      dt.quantity = 1;
                      return (
                        <ProductListItem
                          handleSelect={e => handleSelectProduct(e, dt)}
                          key={i}
                          isSelected={selectedProducts.some(
                            d => d.slug === dt.slug
                          )}
                          {...dt}
                        />
                      );
                    })}
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          disabled
          style={{ position: 'absolute', left: 12 }}
        >
          {selectedProducts.length} Selected
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="success" onClick={handleAdd}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SelectProductsModal.propTypes = {
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func
};

export default SelectProductsModal;
