import LoadingWrapper from 'components/loading/LoadingWrapper';
import { createFile } from 'pages/product/productList/AddProduct';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getBlogsMini } from 'services/content';
import {
  createHomepageBanner,
  createHomepageFeed,
  getBrandsMini,
  getHomepageData,
  getProductCollectionsMini,
  getProductListMini
} from 'services/product';
import BannerInput from './modules/BannerInput';
import HomepageList from './modules/HomepageList';
import TypeForm from './modules/TypeForm';

const initialFeedPayload = {
  collection_section: [],
  popular_section: [],
  season_section: [],
  recent_section: []
};

const FeedLimit = {
  collection_section: 5,
  popular_section: 4,
  season_section: 6,
  recent_section: 4
};

function WebHomePage() {
  // eslint-disable-next-line no-unused-vars
  const [bannerPayload, setBannerPayload] = useState({
    1: null,
    2: null,
    3: null
  });
  // eslint-disable-next-line no-unused-vars
  const [feedPayload, setFeedPayload] = useState(initialFeedPayload);
  const [productSearch, setProductSearch] = useState('');

  const [bannerInitial, setBannerInitial] = useState([]);

  const { data: blogs } = useQuery(['getblogmini'], () => getBlogsMini(), {
    keepPreviousData: false,
    staleTime: Infinity
  });
  const { data: products, isLoading: productsLoading } = useQuery(
    ['getproductsmini', productSearch],
    () => getProductListMini({ search: productSearch }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const { data: brands } = useQuery(['getbrandsmini'], () => getBrandsMini(), {
    keepPreviousData: false,
    staleTime: Infinity
  });
  const { data: collections } = useQuery(
    ['getcollectionsmini'],
    () => getProductCollectionsMini(),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const handleSave = async () => {
    const bnPayload = Object.values(bannerPayload);
    if (bnPayload.includes(null) || bnPayload.includes(undefined)) {
      toast.error('Banner field can not be null!', {
        theme: 'colored'
      });
      return;
    }
    if (Object.values(feedPayload).find(item => item.length === 0)) {
      toast.error('Feed field can not be null!', {
        theme: 'colored'
      });
      return;
    }
    if (feedPayload.collection_section.length < FeedLimit.collection_section) {
      toast.error(
        `Collection feed can not be less than ${FeedLimit.collection_section}!`,
        {
          theme: 'colored'
        }
      );
      return;
    }
    if (feedPayload.popular_section.length < FeedLimit.popular_section) {
      toast.error(
        `Popular feed can not be less than ${FeedLimit.popular_section}!`,
        {
          theme: 'colored'
        }
      );
      return;
    }
    if (feedPayload.season_section.length < FeedLimit.season_section) {
      toast.error(
        `Season feed can not be less than ${FeedLimit.season_section}!`,
        {
          theme: 'colored'
        }
      );
      return;
    }
    if (feedPayload.recent_section.length < FeedLimit.recent_section) {
      toast.error(
        `Recent feed can not be less than ${FeedLimit.recent_section}!`,
        {
          theme: 'colored'
        }
      );
      return;
    }

    bnPayload.map(item => {
      if (
        JSON.stringify(item) ===
        JSON.stringify(
          bannerInitial.find(dt => dt.banner_id === item.banner_id)
        )
      ) {
        return;
      }
      setTimeout(async () => {
        if (typeof item.image === 'string') {
          delete item.image;
          await createHomepageBanner(item);
          return;
        }
        const formData = new FormData();
        for (const key in item) {
          if (item[key]) {
            formData.append(key, item[key]);
          }
        }
        await createHomepageBanner(formData);
      }, 300);
    });
    const fdPayload = {};

    const imagePromise = Object.keys(feedPayload).map(async item => {
      const resolvableValue = feedPayload[item].map(async values => {
        const { object_id, slug_type, display_name, image_file } = values;
        if (image_file) {
          const formData = new FormData();
          formData.append('file', image_file);
          formData.append('name', display_name);
          const response = await createFile(formData);
          const obj = {
            object_id,
            slug_type,
            display_name,
            image: {
              id: response.data.id,
              url: response.data.file
            }
          };
          return obj;
        }
        if (typeof values.image === 'string') {
          const obj = {
            object_id,
            slug_type,
            display_name
          };
          return obj;
        }
        const obj = {
          object_id,
          slug_type,
          display_name,
          image: values.image
        };
        return obj;
      });
      fdPayload[item] = await Promise.all(resolvableValue);
    });
    await Promise.all(imagePromise);

    await createHomepageFeed(fdPayload);
    toast.success('Save successfully!', {
      theme: 'colored'
    });
  };

  useEffect(() => {
    const request = async () => {
      const response = await getHomepageData();
      setBannerInitial(response.data.home_banner_list);
      setBannerPayload({
        1: response.data.home_banner_list.find(item => item.banner_id === 1),
        2: response.data.home_banner_list.find(item => item.banner_id === 2),
        3: response.data.home_banner_list.find(item => item.banner_id === 3)
      });
      setFeedPayload(response.data.home_feed ?? initialFeedPayload);
    };

    request();
  }, []);

  const sayMyName = name => {
    switch (name) {
      case 'collection_section':
        return 'Collection Feed';
      case 'popular_section':
        return 'Popular Feed';
      case 'season_section':
        return 'Season Feed';
      case 'recent_section':
        return 'Recent Feed';
      default:
        return 'Unknown';
    }
  };

  const handleBannerPayload = (id, payload) => {
    // if id in bannerPayloads formData object, then update else add
    setBannerPayload(prevState => ({
      ...prevState,
      [id]: payload
    }));
  };

  const debounceTimer = useRef(null);

  const debouncedSetProductSearch = useCallback(value => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      setProductSearch(value);
    }, 400);
  }, []);

  const handleInputChange = (value, type) => {
    if (type === 'product' && value !== '') {
      debouncedSetProductSearch(value);
    }
  };
  const handleInputBlur = () => {
    // if (type === 'product') {
    //   setProductSearch('');
    // }
  };
  const loading = !collections || !brands;
  return (
    <LoadingWrapper loading={loading}>
      <div className="p-2">
        <Card className="mb-3">
          <Card.Header>Web Homepage</Card.Header>
          {!loading ? (
            <Card.Body>
              <div className="row gap-5">
                <div className="col-7">
                  <BannerInput
                    {...{
                      products: products?.data.results ?? [],
                      blogs: blogs,
                      collections: collections?.data.results,
                      brands: brands
                    }}
                    height={300}
                    id={1}
                    handleBannerPayload={handleBannerPayload}
                    initialValue={bannerInitial.find(
                      item => item.banner_id === 1
                    )}
                    handleInputChange={handleInputChange}
                    slugLoading={productsLoading}
                  />
                </div>
                <div className="col-4">
                  <div className="row gap-3">
                    <div className="col-12">
                      <BannerInput
                        {...{
                          products: products?.data.results ?? [],
                          blogs: blogs,
                          collections: collections?.data.results,
                          brands: brands
                        }}
                        height={150}
                        id={2}
                        handleBannerPayload={handleBannerPayload}
                        initialValue={bannerInitial.find(
                          item => item.banner_id === 2
                        )}
                        handleInputChange={handleInputChange}
                        slugLoading={productsLoading}
                      />
                    </div>
                    <div className="col-12">
                      <BannerInput
                        {...{
                          products: products?.data.results ?? [],
                          blogs: blogs,
                          collections: collections?.data.results,
                          brands: brands
                        }}
                        height={150}
                        id={3}
                        handleBannerPayload={handleBannerPayload}
                        initialValue={bannerInitial.find(
                          item => item.banner_id === 3
                        )}
                        handleInputChange={handleInputChange}
                        slugLoading={productsLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {Object.keys(initialFeedPayload).map(item => {
                return (
                  <div key={item}>
                    <hr />
                    <h4>{sayMyName(item)}</h4>
                    <HomepageList
                      state={feedPayload[item]}
                      setState={setFeedPayload}
                      section={item}
                    >
                      <TypeForm
                        {...{
                          products: products?.data.results ?? [],
                          blogs: blogs,
                          collections: collections?.data.results,
                          brands: brands
                        }}
                        setState={setFeedPayload}
                        section={item}
                        feedState={feedPayload}
                        limit={FeedLimit[item]}
                        onInputChange={handleInputChange}
                        onInputBlur={handleInputBlur}
                      />
                    </HomepageList>
                  </div>
                );
              })}
            </Card.Body>
          ) : (
            <Card.Body style={{ height: 500 }}></Card.Body>
          )}
          <Card.Footer className="d-flex justify-content-end">
            <Button onClick={handleSave}>Save</Button>
          </Card.Footer>
        </Card>
      </div>
    </LoadingWrapper>
  );
}

export default WebHomePage;
