import React from 'react';
import PropTypes from 'prop-types';

function CustomerAddress({ data }) {
  if (!data) return null;
  return (
    <div>
      <h5>Address</h5>
      <div className="">
        <span className="d-block">{data.address_line1}</span>
        <span className="d-block">{data.address_line2}</span>
        <span className="d-block">
          {data.district} / {data.city}
        </span>
        <span className="d-block">{data.country}</span>
        <span className="d-block">+90 {data.address_phone}</span>
      </div>
    </div>
  );
}

export default CustomerAddress;

CustomerAddress.propTypes = {
  data: PropTypes.object
};
