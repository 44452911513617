import DatePickerCustomInput from 'components/common/DatePickerCustomInput';
import Divider from 'components/common/Divider';
import SeoSetting from 'components/common/SeoSetting';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { MdOutlineDiscount } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getShippingCost } from 'services/warehouse';
import ProductDescriptions from './ProductDescriptions';
import ProductHighlight from './ProductHighlight';
import ProductMedia from './ProductMedia';
import ProductOrganization from './ProductOrganization';
import ProductPrice from './ProductPrice';
import SetOrBundleCard from './SetOrBundleCard';
import ProductPersona from './ProductPersona';

const AddProductForm = props => {
  const [searchParams] = useSearchParams();
  const {
    slug,
    onSubmit,
    formValues,
    setFormValues,
    handleDeleteItem,
    handleArciveProduct,
    handleRouteList,
    product_detail,
    user
  } = props;
  const [seoSetting, setSeoSetting] = useState({});
  const [typeState, setTypeState] = useState(undefined);
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const sktWatch = useWatch({ control, name: 'skt' });

  useEffect(() => {
    if (!product_detail) return;
    const { data } = product_detail;
    const attributes = [];
    if (data.attributes_order) {
      Object.values(data?.attributes_order).forEach(value => {
        const attr = data?.attributes.filter(dt => dt.id === value.id)[0];
        attributes.push(attr);
      });
    }
    const values = {
      is_update: true,
      is_active: data.is_active,
      type: data.type,
      name: data.name,
      display_name: data.display_name,
      descriptions: data.descriptions,
      brand: data.brand?.id
        ? { value: data.brand.id, label: data.brand.name }
        : undefined,
      category: data.category?.id
        ? { value: data.category.id, label: data.category.name }
        : undefined,
      collections: data.collections?.map(dt => ({
        value: dt.id,
        label: dt.name
      })),
      main_collection: data.main_collection?.id
        ? { value: data.main_collection.id, label: data.main_collection.name }
        : undefined,
      tag_names: data.tags.map(dt => dt.name),
      attributes: attributes,
      files: data.files,
      files_mobile: data.files_mobile,
      prices: data.prices,
      products: data.products,
      set_quantity: data.set_quantity,
      serving_size: data.serving_size,
      serving_capacity: data.serving_capacity,
      serving_unit: data.serving_unit?.id
        ? { value: data.serving_unit.id, label: data.serving_unit.name }
        : undefined,
      highlight_title: data.highlight_title,
      highlight_detail: data.highlight_detail,
      sku: data.sku,
      barcode: data.barcode,
      stock_quantity: data.stock_quantity,
      bundle_quantity: data.bundle_quantity,
      origin: data.origin,
      isDiscounted: data.isDiscounted,
      skt: data.skt,
      persona_and_goals: data.persona_and_goals
    };
    if (values.type === 2) {
      values.defaultPrice = data.prices.find(dt => dt.is_active);
    }
    setFormValues(values);
    setSeoSetting(data.seo_setting);
    setValue('name', data.name);
    setValue('skt', data.skt);
    setValue('display_name', data.display_name);
  }, [product_detail]);

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleDateChange = date => {
    setValue('skt', date);
    setFormValues({ ...formValues, skt: date });
  };

  const handleSeoValue = seoValue => {
    setFormValues({
      ...formValues,
      seo_setting: seoValue
    });
  };
  useEffect(() => {
    const whatIsType = () => {
      if (slug) {
        switch (formValues.type) {
          case 1:
            return 'single';
          case 2:
            return 'set';
          case 3:
            return 'bundle';
          default:
        }
      } else {
        return searchParams.get('type');
      }
    };
    const type_ = whatIsType();
    setTypeState(type_);
  }, [slug, formValues.type]);

  const { data: shippingCost } = useQuery(
    ['shippingCost'],
    () => getShippingCost(),
    {
      keepPreviousData: false
    }
  );

  const isValideDate = date => {
    const currentDate = new Date();
    if (date >= moment(currentDate).startOf('day')) return date;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {typeState !== 'single' && (
          <SetOrBundleCard
            formValues={formValues}
            setFormValues={setFormValues}
            type={typeState}
            setSeoSetting={setSeoSetting}
            slug={slug}
            shippingCost={shippingCost}
          />
        )}
        <Card className="mb-3">
          <Card.Header>
            <Row>
              <Col xs={6}>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  {slug ? 'Update Product' : 'Create Product'}
                </h5>
                {formValues?.isDiscounted && (
                  <div
                    style={{
                      padding: '5px 10px',
                      borderRadius: 5,
                      backgroundColor: '#f8d7da',
                      color: '#721c24',
                      marginRight: 20
                    }}
                    className="d-flex justify-content-between align-items-center gap-2"
                  >
                    <MdOutlineDiscount /> Discounted
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <Row>
                  <Col
                    xs={6}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <h4 className="fs-0 mb-0 text-nowrap font-weight-bold">
                      <b>SKU: </b>
                    </h4>
                    <Form.Control
                      type="text"
                      name="sku"
                      placeholder="SKU"
                      value={formValues.sku}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col
                    xs={6}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <h4 className="fs-0 mb-0 mr-2 text-nowrap py-2 py-xl-0">
                      <b>Barcode: </b>
                    </h4>
                    <Form.Control
                      type="text"
                      name="barcode"
                      placeholder="Barcode"
                      value={formValues.barcode}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-card">
              <Col xs={6}></Col>
              <Col xs={6}>
                <Row>
                  <Col
                    xs={6}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <h4 className="fs-0 mb-0 text-nowrap font-weight-bold">
                      <b>SKT: </b>
                    </h4>
                    <DatePickerCustomInput
                      allowClear
                      filterDate={isValideDate}
                      selected={sktWatch && moment(sktWatch).toDate()}
                      value={sktWatch && moment(sktWatch).format('DD/MM/YYYY')}
                      onChange={handleDateChange}
                      className="form-control"
                      placeholderText="Select SKT"
                      dateFormat="dd/MM/yyyy"
                      fixedHeight
                    />
                  </Col>
                  <Col
                    xs={6}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <h4 className="fs-0 mb-0 text-nowrap font-weight-bold">
                      <b>Stock Count: </b>
                    </h4>
                    <Form.Control
                      type="text"
                      name="stock_quantity"
                      placeholder="Stock Count"
                      value={formValues.stock_quantity}
                      onChange={handleChange}
                      readOnly
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -10
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                name="is_active"
                required
                value={formValues.is_active}
                onChange={handleChange}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                {...register('name', { required: true })}
                isInvalid={errors.name}
                type="text"
                name="name"
                value={formValues.name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="display_name">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                {...register('display_name', { required: true })}
                isInvalid={errors.display_name}
                type="text"
                name="display_name"
                value={formValues.display_name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Divider />
            <ProductDescriptions
              register={register}
              formValues={formValues}
              setFormValues={setFormValues}
            />
            <Divider />
            <ProductHighlight
              errors={errors}
              register={register}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
        <ProductOrganization
          control={control}
          formValues={formValues}
          setFormValues={setFormValues}
          errors={errors}
        />
        {/* <ProductAttributes
          formValues={formValues}
          setFormValues={setFormValues}
        /> */}
        <ProductPersona formValues={formValues} setFormValues={setFormValues} />
        <ProductMedia formValues={formValues} setFormValues={setFormValues} />
        <ProductPrice
          formValues={formValues}
          setFormValues={setFormValues}
          shippingCost={shippingCost}
          user={user}
        />
        <Card className="mb-3">
          <Card.Header></Card.Header>
          <Card.Body>
            <SeoSetting
              seoValues={handleSeoValue}
              defaultValue={seoSetting}
              register={register}
              errors={errors}
            />
            <div className="d-flex justify-content-between">
              <Button
                onClick={handleArciveProduct}
                variant="info"
                disabled={!slug ? true : false}
                className="mx-2"
              >
                Archive
              </Button>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={handleDeleteItem}
                  variant="danger"
                  disabled={!slug ? true : false}
                  className="mx-2"
                >
                  Delete
                </Button>
                <Button
                  onClick={handleRouteList}
                  variant="secondary"
                  className="mx-2"
                  style={{ width: 150 }}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" style={{ width: 150 }}>
                  {slug ? 'Update' : 'Save'}
                </Button>
              </div>
            </div>
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
      </Form>
    </>
  );
};

AddProductForm.propTypes = {
  slug: PropTypes.string,
  onSubmit: PropTypes.func,
  formValues: PropTypes.object,
  user: PropTypes.object,
  product_detail: PropTypes.object,
  setFormValues: PropTypes.func,
  handleRouteList: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  handleArciveProduct: PropTypes.func,
  register: PropTypes.any
};

export default AddProductForm;
