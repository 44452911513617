import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePickerCustomInput from '../../../../components/common/DatePickerCustomInput';
import { getStockLotTraces, getStocksWithSearch } from 'services/warehouse';
import SelectBox from 'components/common/SelectBox';
import { toast } from 'react-toastify';

const EditableCell = props => {
  const {
    value: initialValue,
    row: { index, original },
    column: { id },
    setData,
    control,
    type = 'text',
    isDisabled,
    fromWatch
  } = props;
  // We need to keep and update the state of the cell normally
  const [formValue, setFormValue] = useState(initialValue);
  const [selectOptions, setSelectOptions] = useState([]);

  const onChange = async e => {
    if (type === 'text' || type === 'number') {
      const value = e.target.value;
      setFormValue(value);
    } else if (type === 'date') {
      setData(old =>
        old.map((row, rowIndex) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [id]: e
            };
          }
          return row;
        })
      );
    } else if (id === 'barcode') {
      setFormValue(e);
      let lotTraces;
      if (control._formValues.storageLocation && e?.value) {
        lotTraces = await handleGetLotTraces({
          storageLocation: control._formValues.storageLocation?.value,
          stock: e?.value
        });
      }
      setData(old =>
        old.map((row, rowIndex) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [id]: e,
              stock: e?.value,
              lotOptions: lotTraces ? lotTraces : []
            };
          }
          return row;
        })
      );
    } else if (id === 'lotNumber') {
      setData(old =>
        old.map((row, rowIndex) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              lotNumber: e,
              available: e?.quantity
            };
          }
          return row;
        })
      );
    }
  };
  const onFocus = async () => {
    if (id === 'lotNumber') {
      if (!control._formValues.storageLocation?.value || !original.stock) {
        toast.info('From and Product have to be selected to see lot info!', {
          theme: 'colored'
        });
      } else if (selectOptions.length === 0) {
        let lotTraces = await handleGetLotTraces({
          storageLocation: fromWatch?.value,
          stock: original.stock
        });
        setData(old =>
          old.map((row, rowIndex) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                lotOptions: lotTraces ? lotTraces : []
              };
            }
            return row;
          })
        );
      }
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    setData(old =>
      old.map((row, rowIndex) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [id]: formValue
          };
        }
        return row;
      })
    );
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setFormValue(initialValue);
  }, [initialValue]);
  useEffect(() => {
    if (id === 'lotNumber') {
      setSelectOptions(original?.lotOptions ?? []);
    }
  }, []);

  const handleOptions = search => {
    if (search.length > 1) {
      id === 'barcode' ? handleGetStocks(search) : null;
    }
  };

  const handleGetStocks = async search => {
    try {
      const response = await getStocksWithSearch({ search });
      if (response.data.results.length > 0) {
        const temp_arr = response.data.results.map(dt => ({
          label: dt.barcode + ' - ' + dt.name,
          value: dt.id
        }));
        setSelectOptions(temp_arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetLotTraces = async queryObj => {
    try {
      const response = await getStockLotTraces({
        ...queryObj,
        isActive: true,
        quantity__gt: 0
      });
      if (response.data.results.length > 0) {
        const temp_arr = response.data.results.map(dt => ({
          label: dt.lotNumber,
          value: dt.lotNumber,
          quantity: dt.quantity
        }));
        return temp_arr;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearField = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if (value === '0') {
      setFormValue(name, '');
    }
  };

  return (
    <>
      {type === 'text' || type === 'number' ? (
        <input
          type={type}
          value={formValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={clearField}
          style={{
            width: '100%'
          }}
          className="input-spin-none form-control"
          disabled={(id === 'name' || id === 'qrCode') && isDisabled}
        />
      ) : type === 'select' ? (
        <SelectBox
          classNamePrefix="react-select"
          control={control}
          name={id}
          value={formValue}
          placeholder=""
          onChange={onChange}
          isClearable
          options={selectOptions}
          onInputChange={handleOptions}
          onFocus={onFocus}
        />
      ) : (
        <DatePickerCustomInput
          selected={new Date(formValue)}
          onChange={onChange}
          dateFormat="dd/MM/yyyy"
          className="form-control"
          placeholder="Date"
        />
      )}
    </>
  );
};

EditableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  column: PropTypes.object,
  setData: PropTypes.func,
  setQuery: PropTypes.func,
  type: PropTypes.string,
  control: PropTypes.object,
  fromWatch: PropTypes.object,
  setValue: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default EditableCell;

// used to prevent parent submit in nedted forms
export function stopPropagate(callback) {
  return e => {
    e.preventDefault();
    e.stopPropagation();
    callback();
  };
}
