import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { createCountingDetail, getCounting } from 'services/warehouse';
import TableHeader from 'components/common/TableHeader';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { statusOptions } from './module/Static';
import { AuthContext } from 'context/Context';
import { toast } from 'react-toastify';
import { getTransactions } from 'services/warehouse';

const AddCountingItem = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const initialForm = {
    count: 0,
    qrCode: ''
  };
  const [prdList, setPrdList] = useState([initialForm]);
  const { user } = useContext(AuthContext);

  const { setValue, handleSubmit } = useForm();

  const { data } = useQuery(
    ['getCounting'],
    () =>
      getCounting({
        id: id
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    setValue('createdBy', user.id);
    setValue('inventoryCountingMeta', id);
  }, []);

  // TODO: burada qrcode yerine manuel giriş için lotnumber ve barcode kullanılması gerekiyor.
  const columns = [
    {
      accessor: 'qrCode',
      Header: 'QR Code',
      headerProps: { className: 'pe-1 text-start', style: { width: 120 } }
    },
    {
      accessor: 'barcode',
      Header: 'Barcode (SKU)',
      headerProps: { className: 'pe-1 text-start', style: { width: 100 } }
    },
    {
      accessor: 'name',
      Header: 'Product Name',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'category',
      Header: 'Product Category',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'skt',
      Header: 'SKT',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'count',
      Header: 'Quantity',
      headerProps: { className: 'pe-1 text-start', style: { width: 30 } }
    },
    {
      accessor: 'none',
      Header: '',
      headerProps: { className: 'pe-1 text-end' },
      cellProps: { className: 'pe-1 text-end', style: { width: 90 } }
    }
  ];

  const onSubmit = data => {
    if (!prdList.length > 0) {
      toast.error('Please add product!', { theme: 'colored' });
      return;
    }
    let payload = prdList.map(prd => {
      const temp = { ...prd, ...data };
      temp.qrCode = parseInt(temp.qrCode);
      temp.inventoryCountingMeta = parseInt(temp.inventoryCountingMeta);
      temp.count = parseInt(temp.count);
      delete temp.barcode;
      delete temp.category;
      delete temp.name;
      delete temp.skt;
      delete temp.status;
      return temp;
    });
    postRequest(payload.filter(e => e.qrCode !== ''));
  };

  const postRequest = async payload => {
    const response = await createCountingDetail(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Products added to counting.`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };
  // counting detail action start
  const onChange = ({ target }, rIndex, cName) => {
    const value = target.value;
    setPrdList(old =>
      old.map((row, rowIndex) => {
        if (rIndex === rowIndex) {
          return {
            ...old[rowIndex],
            [cName]: value
          };
        }
        return row;
      })
    );
  };
  const onBlur = (e, i) => {
    handleGetStock(e.target.value, i);
  };
  const handleGetStock = async (search, i) => {
    try {
      const response = await getTransactions({ id: search });
      if (response.data.results.length === 1) {
        const res = response.data.results[0];
        setPrdList(old =>
          old.map((row, rowIndex) => {
            if (i === rowIndex) {
              return {
                ...old[rowIndex],
                barcode: res.stock.barcode,
                name: res.stock.name,
                category: res.stock.category?.name,
                skt: moment(res.skt).format('DD/MM/yyyy'),
                stock: res.stock.id
              };
            }
            return row;
          })
        );
      } else {
        toast.error('Stock does not exist with the qrcode!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const clearField = ({ target }, rIndex, cName) => {
    const value = target.value;
    if (value === '0') {
      setPrdList(old =>
        old.map((row, rowIndex) => {
          if (rIndex === rowIndex) {
            return {
              ...old[rowIndex],
              [cName]: ''
            };
          }
          return row;
        })
      );
    }
  };
  // counting detail action end
  const handleRemove = index => {
    setPrdList(prdList.filter(elem => elem !== prdList[index]));
  };

  const handleRouteList = () => {
    navigate(`/stock/counting-detail/${id}`);
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <TableHeader title="Add Counting Item" isBulkAction={false} table />
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="date">
                  <Col xs={4}>
                    <Form.Label>Date</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      value={
                        moment(data?.data.results[0].date).format(
                          'DD/MM/yyyy'
                        ) ?? ''
                      }
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="status">
                  <Col xs={4}>
                    <Form.Label>Status</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      value={
                        statusOptions.filter(
                          e => e.value === data?.data.results[0].status
                        )[0]?.label ?? ''
                      }
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="storageLocation"
                >
                  <Col xs={4}>
                    <Form.Label>Warehouse</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      value={data?.data.results[0].storageLocation.name ?? ''}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="responsible">
                  <Col xs={4}>
                    <Form.Label>Responsible</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      value={data?.data.results[0].responsibleName ?? ''}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group as={Row} className="mb-3" controlId="description">
                  <Col xs={4} lg={2}>
                    <Form.Label>Description</Form.Label>
                  </Col>
                  <Col xs={8} lg={10}>
                    <Form.Control
                      value={data?.data.results[0].description ?? ''}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Col xs={12}>
              <Card className="mb-3">
                <Card.Body className="p-0">
                  <Table size="sm" striped="true" className="fs--1 mb-0">
                    <thead className="bg-200 text-900 text-nowrap align-middle text-center">
                      <tr>
                        {columns.map((column, index) => (
                          <th key={index} {...column.headerProps}>
                            {column.Header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {prdList.map((row, rIndex) => {
                        return (
                          <Fragment key={rIndex}>
                            <tr className="align-middle">
                              {columns.map((column, cIndex) => {
                                if (
                                  column.accessor === 'qrCode' ||
                                  column.accessor === 'count'
                                ) {
                                  return (
                                    <td key={cIndex} {...column.cellProps}>
                                      {
                                        <input
                                          className="form-control p-0 px-1"
                                          value={row[column.accessor]}
                                          onChange={e =>
                                            onChange(e, rIndex, column.accessor)
                                          }
                                          onFocus={e =>
                                            clearField(
                                              e,
                                              rIndex,
                                              column.accessor
                                            )
                                          }
                                          onBlur={
                                            column.accessor === 'qrCode'
                                              ? e => onBlur(e, rIndex)
                                              : null
                                          }
                                        />
                                      }
                                    </td>
                                  );
                                } else if (column.accessor === 'none') {
                                  return (
                                    <td key={cIndex} {...column.cellProps}>
                                      {prdList.length > 1 && (
                                        <IconButton
                                          onClick={() => handleRemove(rIndex)}
                                          variant="secondary"
                                          size="sm"
                                          icon="trash"
                                          transform="shrink-3"
                                          className="me-1"
                                        ></IconButton>
                                      )}
                                      <IconButton
                                        onClick={() =>
                                          setPrdList([...prdList, initialForm])
                                        }
                                        variant="primary"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                      ></IconButton>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td key={cIndex} {...column.cellProps}>
                                      {row[column.accessor]}
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                Save
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </>
  );
};

export default AddCountingItem;
