import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import CategoryTableFilter from './module/CategoryTableFilter';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import TableHeader from 'components/common/TableHeader';
import { useQuery } from 'react-query';
import { deleteProductCategory, getProductCategories } from 'services/product';
import { queryReducer } from 'reducers/queryReducer';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProductListModal from 'components/modal/ProductListModal';

const CategoryList = () => {
  // eslint-disable-next-line no-unused-vars
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  // eslint-disable-next-line no-unused-vars
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: '',
    request: () => {}
  });
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useQuery(
    ['getProductCategories', queryObj],
    () => getProductCategories(queryObj),
    {
      keepPreviousData: false
    }
  );

  const handleCollectionSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search: value
    }));
  };

  const handleDeleteItem = id => {
    setSelectedRowId({ id: id, type: 'delete' });
    setConfirmText({
      title: 'Delete Category',
      description: 'Are you sure? Selected category will be deleted!',
      type: 'delete',
      request: async () => {
        const response = await deleteProductCategory(id);
        if (response.data.status === 405) {
          toast.error(response.data.message, {
            theme: 'colored'
          });
        } else {
          toast.success('Category deleted', {
            theme: 'colored'
          });
          refetch();
        }
      }
    });
    setShowConfirm(true);
  };

  const tabFilter = filterValue => {
    switch (filterValue) {
      case 'all':
        setQueryObj({
          ...queryObj,
          is_active: undefined,
          is_deleted: undefined
        });
        break;
      case 'active':
        setQueryObj({
          ...queryObj,
          is_active: true,
          is_deleted: undefined
        });
        break;
      case 'archieved':
        setQueryObj({
          ...queryObj,
          is_active: false,
          is_deleted: undefined
        });
        break;
      case 'deleted':
        setQueryObj({
          ...queryObj,
          is_active: undefined,
          is_deleted: true
        });
        break;
      default:
        break;
    }
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1 text-start', width: 250 },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name, id } = rowData.row.original;
        return (
          <Link to={`/product/product-category-update/${id}`}>{name}</Link>
        );
      }
    },
    {
      accessor: 'description',
      Header: 'Description',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { description } = rowData.row.original;
        return (
          <span
            className="category-list-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        );
      }
    },
    {
      accessor: 'product_count',
      Header: 'Products',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { product_count } = rowData.row.original;
        if (product_count === 0) return product_count;
        return (
          <a
            href="#!"
            onClick={() => {
              setShowProductsModal(true);
              setSelectedRowId({
                id: rowData.row.original.id,
                type: 'product_count_list'
              });
            }}
          >
            {product_count}
          </a>
        );
      }
    },
    {
      accessor: 'parent.name',
      Header: 'Parent',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: dt => {
        const parentName = dt.row.original.parent?.label;
        return <span>{parentName}</span>;
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: dt => {
        const { is_active } = dt.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                onClick={() =>
                  navigate(`/product/product-category-update/${id}`)
                }
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleNew = () => {
    navigate('/product/product-category-add');
  };
  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          data={data?.data.results || []}
          columns={columns}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Categories"
                handleRefetch={refetch}
                table
                handleNew={handleNew}
                bulkTable={'Category'}
              />
              <CategoryTableFilter
                eventKey={tabFilter}
                handleCollectionSearch={handleCollectionSearch}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={confirmText.request}
        type={confirmText.type}
      />
      <ProductListModal
        showModal={showProductsModal}
        setShowModal={setShowProductsModal}
        paramId={selectedRowId?.id}
        param="category_id"
      />
    </div>
  );
};

export default CategoryList;
