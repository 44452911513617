import LoadingWrapper from 'components/loading/LoadingWrapper';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useQuery } from 'react-query';
import {
  getBrandsMini,
  getHeaderMenu,
  getProductCollectionsMini
} from 'services/product';
import AddHeaderMenu from './modules/AddHeaderMenu';
import HeaderMenuDnd from './modules/HeaderMenuDnd';

function WebHeaderMenu() {
  const { data: brands } = useQuery(['getbrandsmini'], () => getBrandsMini(), {
    keepPreviousData: false,
    staleTime: Infinity
  });
  const { data: collections } = useQuery(
    ['getcollectionsmini'],
    () => getProductCollectionsMini(),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const { data: mainMenuData, refetch } = useQuery(
    ['header-menu'],
    () => getHeaderMenu(),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const [editItem, setEditItem] = React.useState(null);

  const handleEdit = item => {
    setEditItem(item);
    setTimeout(() => {
      setEditItem(null);
    }, 1000);
  };

  const props = {
    collections: collections?.data.results,
    brands: brands
  };
  const loading = !brands || !collections || !mainMenuData;
  return (
    <LoadingWrapper loading={loading}>
      <div className="p-2">
        <Card className="mb-3">
          <Card.Header className="d-flex justify-content-between align-item-center">
            <span>Web Header Menu</span>
            <AddHeaderMenu
              {...props}
              mainMenuData={mainMenuData}
              editItem={editItem}
              refetch={() => {
                refetch();
              }}
            />
          </Card.Header>
          <Card.Body style={{ minHeight: 500 }}>
            {!loading && (
              <HeaderMenuDnd
                data={mainMenuData}
                handleEdit={handleEdit}
                refetch={() => {
                  refetch();
                }}
              />
            )}
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end"></Card.Footer>
        </Card>
      </div>
    </LoadingWrapper>
  );
}

export default WebHeaderMenu;
