import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import MultiSelect from 'components/common/MultiSelect';
import serviceUrl from 'services/serviceUrlList';
import useFetch from 'hooks/useFetch';

const CardOrganization = props => {
  const { formData, setFormData } = props;
  const { response: categoriesResponse } = useFetch({
    url: serviceUrl.product.productCategory.mini
  });
  const { response: collectionResponse } = useFetch({
    url: serviceUrl.product.collection.mini
  });
  const categoryOptions = categoriesResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });
  const collectionOptions = collectionResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });
  const handleState = (value, info) => {
    if (info.name === 'collections') {
      setFormData({ ...formData, [info.name]: value });
      return;
    }
    setFormData({ ...formData, [info.name]: value });
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Product Organization
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-3" controlId="category">
              <Form.Label>Category</Form.Label>
              <Select
                options={categoryOptions}
                placeholder="Select Category"
                classNamePrefix="react-select"
                name="product_category"
                value={formData.product_category}
                onChange={handleState}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-3" controlId="collection">
              <Form.Label>Collections</Form.Label>
              <MultiSelect
                closeMenuOnSelect={false}
                isMulti
                options={collectionOptions || []}
                name="collections"
                value={formData.collections}
                onChange={handleState}
                placeholder="Select Collections"
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

CardOrganization.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func
};

export default CardOrganization;
