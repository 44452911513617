import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

const CustomSelect = props => {
  const { options, onChange, placeholder = '' } = props;
  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({ ...styles, display: 'flex' }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({ ...baseStyles, fontSize: '.875rem' }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        padding: 0,
        fontSize: '.875rem',
        backgroundColor: isSelected ? '#d8e2ef !important' : ''
      };
    }
  };

  const CustomValueContainer = ({ children, hasValue, getValue, ...props }) => {
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    const LIMIT = 1;
    const [options, otherChildren] = children;
    const overflowCounter = getValue().length;
    const displayOptions = options.slice(0, LIMIT);

    return (
      <components.ValueContainer {...props}>
        {displayOptions}

        {overflowCounter > LIMIT && `+ ${overflowCounter - LIMIT}`}

        {otherChildren}
      </components.ValueContainer>
    );
  };

  CustomValueContainer.propTypes = {
    children: PropTypes.array.isRequired,
    hasValue: PropTypes.bool,
    getValue: PropTypes.func
  };

  return (
    <Select
      styles={selectStyles}
      closeMenuOnSelect={false}
      options={options}
      hideSelectedOptions={false}
      placeholder={placeholder}
      isMulti
      classNamePrefix="react-select"
      components={{
        ValueContainer: CustomValueContainer
      }}
      onChange={onChange}
    />
  );
};

CustomSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default CustomSelect;
