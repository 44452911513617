import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { queryReducer } from 'reducers/queryReducer';
import { getAbandoned } from 'services/order';

const AbandonedList = () => {
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });

  const { data, isLoading } = useQuery(
    ['getAgreements', queryObj],
    () => getAbandoned(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'id',
      Header: 'Checkout ID',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return <Link to={`/order/abandoned-detail/${id}`}>{id}</Link>;
      }
    },
    {
      accessor: 'user_id.full_name',
      Header: 'Customer',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: dt => {
        const { created_at } = dt.row.original;
        return <>{moment(created_at).format('DD/MM/YYYY HH:mm')}</>;
      }
    },
    {
      accessor: 'total_price',
      Header: 'Total',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2 text-truncate'
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          pagination
          data={data?.data.results || []}
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Abandoned Basket List"
                table
                isBulkAction={false}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>
    </div>
  );
};

export default AbandonedList;
