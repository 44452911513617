import React, { useEffect, useReducer, useState } from 'react';
import { Card } from 'react-bootstrap';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { useQuery } from 'react-query';
import { getHeaderBanners } from 'services/content';
import { queryReducer } from 'reducers/queryReducer';
import HeaderBannerDnd from 'components/dnd/HeaderBannerDnd';
import TableHeader from 'components/common/TableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { useNavigate } from 'react-router-dom';

function HeaderBanner() {
  const navigate = useNavigate();
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    site: 1
  });
  const { data, isLoading, refetch } = useQuery(
    ['getHeaderBanners', queryObj],
    () => getHeaderBanners(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.count
    });
  }, [data?.count]);
  // ss pagination end
  const handleNew = () => {
    navigate('/cms/header-banner-add');
  };

  const columns = [
    {
      accessor: 'publish_order',
      Header: 'Order',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    }
  ];
  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <Card className="mb-3">
          <Card.Header>
            <AdvanceTableWrapper
              data={data?.results || []}
              columns={columns}
              selection
              sortable
              pagination
              perPage={queryObj.limit}
              queryPageIndex={pageIndex}
              totalCount={totalCount}
              dispatch={dispatch}
            >
              <TableHeader
                title="Header Banner"
                handleRefetch={refetch}
                table
                handleNew={handleNew}
                bulkTable={'Header Banner'}
                bulkService="content"
              />
            </AdvanceTableWrapper>
          </Card.Header>

          <Card.Body className="p-0">
            <HeaderBannerDnd data={data?.results} refetch={refetch} />
          </Card.Body>
        </Card>
      </LoadingWrapper>
    </div>
  );
}

export default HeaderBanner;
