import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { IMAGE_MIN_WIDTH } from 'helpers/image';
import { getSize } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Image as BImage,
  Button,
  Dropdown,
  Form,
  Modal,
  Row
} from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

const SingleImageUpload = props => {
  const [files, setFiles] = React.useState([]);
  const [defaultImg, setDefaultImg] = useState(undefined);
  const [altText, setAltText] = useState('');
  const [isAltTextModalOpen, setIsAltTextModalOpen] = useState(false);
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    getFilesFromEvent: async event => {
      const files = event.target.files || event.dataTransfer.files;
      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise(resolve => {
          const image = new Image();
          let url;
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }
      return await Promise.all(promises);
    },
    validator: file => {
      // You can access width/height properties
      if (file.width < IMAGE_MIN_WIDTH) {
        return {
          title: 'Small Width',
          message: `Image width must be greater than ${IMAGE_MIN_WIDTH}px`
        };
      }
      return null;
    },
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toast.error(fileRejections[0].errors[0].message, {
          theme: 'colored'
        });
        return;
      }
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });
  const altTextRef = useRef();

  useEffect(() => {
    setDefaultImg(props.defaultImg?.path);
    setAltText(props.defaultImg?.alt || '');
  }, [props.defaultImg]);

  useEffect(() => {
    props.file(files[0]);
  }, [files]);

  useEffect(() => {
    if (altText === props.defaultImg?.alt) return;
    props.altText(altText);
  }, [altText]);

  const handleRemove = path => {
    if (path) {
      setFiles(files.filter(file => file.path !== path));
      setAltText('');
    } else {
      setDefaultImg(undefined);
      setAltText('');
      props.file('');
    }
  };

  const handleSubmitModal = e => {
    e.preventDefault();
    setIsAltTextModalOpen(false);
    setAltText(altTextRef.current.value);
  };
  const closeAltTextModal = () => {
    setIsAltTextModalOpen(false);
  };
  const handleDeleteAltText = () => {
    setIsAltTextModalOpen(false);
    setAltText('');
  };

  return (
    <Row className="px-3">
      <div {...getRootProps({ className: 'dropzone-area py-6 col-lg-6' })}>
        <input {...getInputProps()} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">Drop your images here</p>
        </Flex>
      </div>

      <div className="mt-3 col-lg-6">
        {files.map((file, index) => (
          <Flex
            alignItems="center"
            className="py-1 h-100 border-bottom btn-reveal-trigger"
            key={index}
          >
            <BImage
              rounded
              src={file.preview}
              alt={altText}
              style={{ maxHeight: 130, maxWidth: '50%' }}
            />
            <Flex
              justifyContent="between"
              alignItems="center"
              className="ms-3 flex-1"
            >
              <div>
                <h6>{file.path}</h6>
                <Flex className="position-relative" alignItems="center">
                  <p className="mb-0 fs--1 text-400 line-height-1">
                    <strong>{getSize(file.size)}</strong>
                  </p>
                </Flex>
                {altText !== '' && (
                  <Flex className="position-relative" alignItems="center">
                    <p className="mb-0 fs--1 text-400 line-height-1">
                      <strong>{`Alt Text: ${altText}`}</strong>
                    </p>
                  </Flex>
                )}
              </div>
            </Flex>
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => handleRemove(file.path)}
                >
                  Remove
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setIsAltTextModalOpen(true)}>
                  {altText === '' ? 'Set' : 'Edit'} Alt Text
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        ))}
        {files.length === 0 && defaultImg && (
          <Flex
            alignItems="center"
            justifyContent="between"
            className="py-1 h-100 border-bottom btn-reveal-trigger"
          >
            <BImage
              rounded
              src={defaultImg}
              alt={altText}
              style={{ maxHeight: 130, maxWidth: '50%' }}
            />
            <Flex
              justifyContent="between"
              alignItems="center"
              className="ms-3 flex-1"
            >
              <div>
                <h6>{defaultImg.split('/').slice(-1)[0]}</h6>
                {altText !== '' && (
                  <Flex className="position-relative" alignItems="center">
                    <p className="mb-0 fs--1 text-400 line-height-1">
                      <strong>{`Alt Text: ${altText}`}</strong>
                    </p>
                  </Flex>
                )}
              </div>
            </Flex>
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => handleRemove()}
                >
                  Remove
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setIsAltTextModalOpen(true)}>
                  {altText === '' ? 'Set' : 'Edit'} Alt Text
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        )}
      </div>

      <Modal
        show={isAltTextModalOpen}
        onHide={closeAltTextModal}
        contentClassName="border"
      >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">
            {altText === '' ? 'Set' : 'Edit'} Alt Text
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="alt_text">
            <Form.Label className="fs-0">Alt Text</Form.Label>
            <Form.Control
              type="text"
              name="alt_text"
              placeholder="Alt Text"
              required
              defaultValue={altText}
              ref={altTextRef}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="danger"
            className="px-3 mx-2"
            onClick={handleDeleteAltText}
          >
            {altText === '' ? 'Cancel' : 'Delete'}
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmitModal}
            className="px-5 mx-0"
          >
            {altText === '' ? 'Save' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};
SingleImageUpload.propTypes = {
  file: PropTypes.func,
  altText: PropTypes.func,
  defaultImg: PropTypes.object
};
export default SingleImageUpload;
