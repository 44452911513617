import React from 'react';
import PropTypes from 'prop-types';

const CharacterLimit = props => {
  const { children } = props;
  const { maxLength } = children.props;
  return (
    <div className="position-relative">
      {children}
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: '-24px',
          color: '#9da9bb',
          fontSize: '75%',
          marginTop: '0.25rem'
        }}
      >
        {children.props.value?.length ?? 0}/{maxLength}
      </div>
    </div>
  );
};
CharacterLimit.propTypes = {
  children: PropTypes.element,
  usedChar: PropTypes.number,
  maxLength: PropTypes.number
};

export default CharacterLimit;
