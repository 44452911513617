const CONFIG = [
  {
    // Local api info
    environment: 'test',
    host: 'localhost:8080',
    apiBase: 'https://api.fit1001.com/',
    apiBaseClear: 'https://api.fit1001.com',
    webBase: 'https://admin.fit1001.com/'
  },
  {
    // Test api info
    environment: 'test',
    host: 'admin.fit1001.com',
    apiBase: 'https://api.fit1001.com/',
    apiBaseClear: 'https://api.fit1001.com',
    webBase: 'https://admin.fit1001.com/'
  },
  {
    // Prod api info
    environment: 'test',
    host: 'admin.fit1001.com',
    apiBase: 'https://api.fit1001.com/',
    apiBaseClear: 'https://api.fit1001.com/',
    webBase: 'https://admin.fit1001.com/'
  },
  {
    // cdn api info
    environment: 'prod',
    host: 'yutak.fit1001.com',
    apiBase: 'https://cdn.fit1001.com/',
    apiBaseClear: 'https://cdn.fit1001.com/',
    webBase: 'https://yutak.fit1001.com/'
  }
];

const getConfig = () => {
  const parsedUrl = new URL(window.location.href);
  const host = parsedUrl.host;
  const config = CONFIG.filter(data => data.host === host)[0];
  return config;
};

export const getApiBase = () => {
  const config = getConfig();
  return config.apiBase;
};
export const getApiBaseClear = () => {
  const config = getConfig();
  return config.apiBaseClear;
};
export const getWebBase = () => {
  const config = getConfig();
  return config.webBase;
};
export const getEnvironment = () => {
  const config = getConfig();
  return config.environment;
};
