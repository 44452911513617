import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import serviceUrl from 'services/serviceUrlList';
import { useNavigate, useParams } from 'react-router-dom';
import useDelete from 'hooks/useDelete';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import useFetch from 'hooks/useFetch';
import useUpdate from 'hooks/useUpdate';
import usePost from 'hooks/usePost';
import AddGiftCardForm from './module/AddGiftCardForm';
import { toast } from 'react-toastify';
import moment from 'moment';
import { AuthContext } from 'context/Context';
import PropTypes from 'prop-types';
import {
  createGiftCard,
  deleteGiftCard,
  getGiftCard,
  updateGiftCard
} from '../api';

const AddGiftCard = props => {
  const { readOnly = false } = props;
  const [formData, setFormData] = useState({
    is_active: true,
    status: 1,
    title: '',
    description: '',
    expiration_date: moment().add(1, 'years').format('YYYY-MM-DD'),
    activation_date: moment().format('YYYY-MM-DD'),
    send_date: moment().format('YYYY-MM-DD'),
    price: 0,
    value: 0,
    type: 2,
    count: 1,
    product_category: { label: '', value: '' },
    collections: []
  });
  const [formDataUser, setFormDataUser] = useState({
    customer_name: '',
    email: '',
    phone: ''
  });
  const [seoSetting, setSeoSetting] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });

  const { id } = useParams();

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const titleRef = useRef(null);
  const emailRef = useRef(null);

  const { response } = id
    ? useFetch({
        url: serviceUrl.product.giftcard.detail + id
      })
    : {};
  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.product.giftcard.detail + id,
    payload: {
      ...formData,
      product_category: formData.product_category?.value,
      collections: formData?.collections?.map(dt => ({
        id: dt.value
      }))
    }
  });

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.giftcard.detail + id
  });

  // customer requests
  const [userPostResponse, userPostRequest] = usePost({
    url: serviceUrl.user.giftcard.list,
    payload: formDataUser
  });

  useEffect(() => {
    if (id) {
      setFormData({ ...formData, updated_by: user.id });
      setFormDataUser({ ...formDataUser, updatedBy: user.id });
    } else {
      setFormData({ ...formData, created_by: user.id });
      setFormDataUser({ ...formDataUser, createdBy: user.id });
    }
  }, []);

  useEffect(() => {
    if (!userPostResponse.data && !userPostResponse.errors) return;
    if (!userPostResponse.errors) {
      try {
        createGiftCard({
          ...formData,
          gift_user_id: userPostResponse && userPostResponse.data.id,
          product_category: formData.product_category?.value,
          collections: formData?.collections?.map(dt => ({
            id: dt.value
          }))
        });
        handleRouteList();
        toast.success(`Gift card created`, {
          theme: 'colored'
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      if (userPostResponse.errors) {
        toast.error(userPostResponse.errors.email[0], {
          theme: 'colored'
        });
      } else {
        toast.error('Something went wrong', {
          theme: 'colored'
        });
      }
    }
  }, [userPostResponse]);

  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.errors) return;
    if (!updateResponse.error) {
      try {
        updateGiftCard(formDataUser, updateResponse.data.gift_user_id);
        handleRouteList();
        toast.success(`Gift card updated`, {
          theme: 'colored'
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      if (updateResponse.errors) {
        toast.error(updateResponse.errors.email[0], {
          theme: 'colored'
        });
      } else {
        toast.error('Something went wrong', {
          theme: 'colored'
        });
      }
    }
  }, [updateResponse]);

  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === '') {
      try {
        deleteGiftCard(formData.gift_user_id);
        setShowConfirm(false);
        handleRouteList();
        toast.success(`Gift card deleted`, {
          theme: 'colored'
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (!response) return;
    const { data } = response;
    setFormData({
      ...formData,
      is_active: data.is_active,
      title: data.title,
      description: data.description,
      expiration_date: data.expiration_date,
      send_date: data.send_date,
      activation_date: data.activation_date,
      gift_card_theme: data.gift_card_theme,
      gift_user_id: data.gift_user_id,
      seo_setting: data.seo_setting,
      collections: data.collections?.map(dt => ({
        value: dt.id,
        label: dt.name
      })),
      product_category: data.product_category?.id
        ? { value: data.product_category.id, label: data.product_category.name }
        : undefined,
      note: data.note ?? '',
      price: data.price,
      value: data.value
    });
    setSeoSetting(data.seo_setting);

    const getGiftCarduser = async id => {
      try {
        const response = await getGiftCard(id);

        const { data } = response;
        setFormDataUser({
          ...formDataUser,
          customer_name: data.customer_name,
          email: data.email,
          phone: data.phone
        });
      } catch (error) {
        console.log(error);
      }
    };
    getGiftCarduser(data?.gift_user_id);
  }, [response]);

  const onSubmit = async () => {
    if (id) {
      updateRequest();
    } else {
      userPostRequest();
    }
  };
  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Gift Card',
      description: 'Are you sure? Selected gift card will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };

  const handleRouteList = () => {
    navigate('/product/gift-card');
  };

  const formProps = {
    id,
    onSubmit,
    formData,
    setFormData,
    formDataUser,
    setFormDataUser,
    seoSetting,
    setSeoSetting,
    handleDeleteItem,
    handleRouteList,
    readOnly,
    titleRef,
    emailRef
  };

  return (
    <Fragment>
      <AddGiftCardForm {...formProps} />
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </Fragment>
  );
};

AddGiftCard.propTypes = {
  readOnly: PropTypes.bool
};

export default AddGiftCard;
