import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import Flex from 'components/common/Flex';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { getBlogCategory } from 'services/content';

const BlogTableFilter = ({ handleSearch, handleFilter }) => {
  const { data: categoryData } = useQuery(
    ['blogCategories'],
    () => getBlogCategory(),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );
  const categoryOptions =
    categoryData?.data.results?.map(dt => ({
      value: dt.id,
      label: dt.name
    })) ?? [];
  const statusOptions = [
    { value: 'true', label: 'Active' },
    { value: 'false', label: 'Passive' }
  ];
  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      // display: 'flex',
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset'
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  return (
    <Row className=" mt-2">
      <Col xs="auto" sm={6} lg={3}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter Blogs"
          setGlobalFilter={handleSearch}
        />
      </Col>
      <Col xs="auto" lg={3}>
        <Flex justifyContent={'start'}>
          <Col>
            <Select
              className="me-2"
              options={categoryOptions}
              placeholder="Category"
              styles={selectStyles}
              onChange={val => handleFilter('category', val?.value)}
              isClearable
            />
          </Col>
        </Flex>
      </Col>
      <Col xs="auto" lg={3}>
        <Flex justifyContent={'start'}>
          <Col>
            <Select
              className="me-2"
              options={statusOptions}
              placeholder="Status"
              styles={selectStyles}
              onChange={val => handleFilter('is_active', val?.value)}
              isClearable
            />
          </Col>
        </Flex>
      </Col>
    </Row>
  );
};

export default BlogTableFilter;

BlogTableFilter.propTypes = {
  handleSearch: PropTypes.func,
  handleFilter: PropTypes.func
};
