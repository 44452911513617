import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { protectedApi } from 'services/axios';
import { getProductListMini } from 'services/product';
import serviceUrl from 'services/serviceUrlList';

const SetOrBundleCard = props => {
  const [inputValue, setInputValue] = useState('');
  const { formValues, setFormValues, type, setSeoSetting, slug, shippingCost } =
    props;

  const { data: response, isLoading } = useQuery(
    ['product-mini', inputValue],
    () => getProductListMini({ type: 1, search: inputValue }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  function calculatePrice(items) {
    let totalQuantity = 0;
    const result = items.reduce(
      (acc, curr) => {
        const { price, quantity } = curr;
        totalQuantity += quantity;
        const itemTotalPrice = price.total_price * quantity;
        const itemTotalPostPrice = price.total_post_price * quantity;
        const itemPostPrice = price.post_price * quantity;
        const itemPriceWithoutKdv = price.price * quantity;
        acc.price = parseFloat(
          (parseFloat(acc.price) + itemPriceWithoutKdv).toFixed(2)
        );
        acc.post_price = parseFloat(
          (parseFloat(acc.post_price) + itemPostPrice).toFixed(2)
        );
        acc.total_price = parseFloat(
          (parseFloat(acc.total_price) + itemTotalPrice).toFixed(2)
        );
        acc.total_post_price = parseFloat(
          (parseFloat(acc.total_post_price ?? 0) + itemTotalPostPrice).toFixed(
            2
          )
        );

        // Kdv sadece bir kez eklenir
        if (!acc.kdvApplied) {
          acc.kdv = parseFloat(acc.kdv + price.kdv).toFixed(2);
          acc.kdvApplied = true;
        }

        return acc;
      },
      {
        price: '0.00',
        post_price: '0.00',
        total_price: '0.00',
        kdv: '0.00',
        kdvApplied: false,
        is_active: true
      }
    );

    let shippingPrice = 0;
    const hepsiJet = shippingCost?.data?.results.find(
      dt => dt.company_name === 'HepsiJet'
    );
    if (hepsiJet) {
      const desiList = [0, 1, 2, 3, 4, 5];
      const desi = desiList.includes(formValues.desi) ? formValues.desi : 0;
      shippingPrice = hepsiJet.desi_with_tax.find(dt => dt.desi === desi).price;
    }
    const deger =
      totalQuantity >= 6
        ? 2.5
        : totalQuantity === 5
        ? 2
        : totalQuantity === 4
        ? 1.5
        : totalQuantity === 3
        ? 1
        : totalQuantity === 2
        ? 0.5
        : 0;
    const shippingCostDiscount = (shippingPrice * deger).toFixed(2);
    result.price -= shippingCostDiscount;
    result.post_price -= shippingCostDiscount;
    result.total_price -= shippingCostDiscount;

    return result;
  }
  useEffect(() => {
    if (!formValues.bundle_quantity || formValues.bundle_quantity.length === 0)
      return;
    // const p = formValues.bundle_quantity.map(dt => )
    const result = calculatePrice(formValues.bundle_quantity);
    setFormValues(prevState => ({
      ...prevState,
      bundlePrice: [{ ...result }]
    }));
  }, [formValues.bundle_quantity]);
  const handleBundleQuantityChange = (id, value, type = 'input') => {
    const updatedBundleQuantity = [...formValues.bundle_quantity]; // Mevcut bundle_quantity dizisinin kopyasını oluştur
    const index = updatedBundleQuantity.findIndex(dt => dt.id === id);

    if (index === undefined || index === -1) {
      // Belirtilen id'ye sahip bir öğe bulunamadıysa, yeni bir öğe oluştur
      updatedBundleQuantity.push({ id, quantity: value });
    } else {
      let updatedQuantity = 0;
      if (type === 'input') {
        updatedQuantity = value;
      } else if (type === 'increase') {
        updatedQuantity = updatedBundleQuantity[index].quantity + 1;
      } else if (type === 'decrease') {
        if (updatedBundleQuantity[index].quantity === 1) {
          return;
        }
        updatedQuantity = updatedBundleQuantity[index].quantity - 1;
      }
      updatedBundleQuantity[index].quantity = updatedQuantity;
    }

    // formValues içindeki diğer değerlerle birlikte güncellenmiş bundle_quantity dizisini set et
    setFormValues(prevState => ({
      ...prevState,
      bundle_quantity: updatedBundleQuantity,
      sku: updatedBundleQuantity
        .map(dt => {
          const product = prevState.products.find(p => p.id === dt.id);
          return `${product.sku}-${dt.quantity}`;
        })
        .join('-'),
      barcode: updatedBundleQuantity
        .map(dt => {
          const product = prevState.products.find(p => p.id === dt.id);
          return `${product.barcode}-${dt.quantity}`;
        })
        .join('-')
    }));
  };

  const handleSetProductInfo = async val => {
    const response = await getProduct(val.slug);
    const { data } = response;
    const attributes = [];
    if (data.attributes_order) {
      Object.values(data?.attributes_order).forEach(value => {
        const attr = data?.attributes.filter(dt => dt.id === value.id)[0];
        attributes.push(attr);
      });
    }
    const activePrice = data.prices.find(dt => dt.is_active);
    const values = {
      is_update: true,
      is_active: data.is_active,
      descriptions: data.descriptions,
      brand: data.brand?.id
        ? { value: data.brand.id, label: data.brand.name }
        : undefined,
      category: data.category?.id
        ? { value: data.category.id, label: data.category.name }
        : undefined,
      collections: data.collections?.map(dt => ({
        value: dt.id,
        label: dt.name
      })),
      tag_names: data.tags.map(dt => dt.name),
      attributes: attributes,
      products: [{ id: val.value, name: val.label, slug: val.slug }],
      prices: [
        {
          price: activePrice.price,
          post_price: activePrice.post_price,
          total_price: activePrice.total_price,
          kdv: activePrice.kdv,
          is_active: true
        }
      ],
      defaultPrice: activePrice,
      desi: data.desi,
      sku: data.sku ? `${data.sku}-${formValues.set_quantity ?? ''}` : '',
      barcode: data.barcode
        ? `${data.barcode}-${formValues.set_quantity ?? ''}`
        : ''
    };
    setFormValues({ ...formValues, ...values });
    setSeoSetting(data.seo_setting);
  };
  const handleProductsSelect = async value => {
    if (value === null) {
      setFormValues({ ...formValues, products: [] });
      return;
    }
    if (type === 'set') {
      handleSetProductInfo(value);
      return;
    }

    const prod = value?.map(dt => ({
      id: dt.value,
      name: dt.label,
      slug: dt.slug,
      price: dt.price,
      sku: dt.sku,
      barcode: dt.barcode
    }));
    const bundleSku = prod
      .map(
        dt =>
          `${dt.sku}-${
            formValues.bundle_quantity?.find(d => d.id === dt.id)?.quantity || 1
          }`
      )
      .join('-');

    const bundleBarcode = prod
      .map(
        dt =>
          `${dt.barcode}-${
            formValues.bundle_quantity?.find(d => d.id === dt.id)?.quantity || 1
          }`
      )
      .join('-');
    setFormValues({
      ...formValues,
      products: prod,
      bundle_quantity: prod.map(dt => ({
        id: dt.id,
        quantity:
          formValues.bundle_quantity?.find(d => d.id === dt.id)?.quantity || 1,
        price: dt.price
      })),
      sku: bundleSku,
      barcode: bundleBarcode
    });
  };
  const productOptions = response?.data.results.map(dt => ({
    value: dt.id,
    label: dt.name,
    slug: dt.slug,
    price: dt.active_prices[0],
    sku: dt.sku,
    barcode: dt.barcode
  }));
  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Product Selection
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Form.Group className="mb-3" controlId="products">
          <Form.Label>
            Select{' '}
            {type?.replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })}{' '}
            Products
          </Form.Label>
          <Select
            filterOption={null}
            closeMenuOnSelect={true}
            isMulti={type === 'bundle'}
            options={productOptions || []}
            name="products"
            onInputChange={setInputValue}
            value={
              type === 'bundle'
                ? formValues.products.map(dt => ({
                    value: dt?.id,
                    label: dt?.name,
                    slug: dt?.slug,
                    price: dt?.price,
                    sku: dt?.sku,
                    barcode: dt?.barcode
                  }))
                : {
                    value: formValues.products[0]?.id,
                    label: formValues.products[0]?.name,
                    slug: formValues.products[0]?.slug
                  }
            }
            onChange={handleProductsSelect}
            placeholder="Select Products"
            isDisabled={slug ? true : false}
            isLoading={isLoading}
          />
        </Form.Group>
        {type === 'set' && (
          <Form.Group className="mb-3" controlId="set_quantity">
            <Form.Label>Set Quantity</Form.Label>
            <Form.Control
              type="text"
              name="set_quantity"
              value={
                formValues.set_quantity === 0 ? '' : formValues.set_quantity
              }
              onChange={event => {
                // if value is not includes number, return
                if (!event.target.value.match(/^[0-9]*$/)) {
                  const val = event.target.value.replace(/[^0-9]/g, '');
                  setFormValues({
                    ...formValues,
                    [event.target.name]: Number(val ?? 0)
                  });
                  return;
                }
                let value = event.target.value;
                if (value.length > 1 && value[0] === '0') {
                  value = value.slice(1);
                }
                setFormValues({
                  ...formValues,
                  [event.target.name]: Number(value),
                  sku: formValues.sku
                    ? `${formValues.sku.split('-')[0]}-${value}`
                    : '',
                  barcode: formValues.barcode
                    ? `${formValues.barcode.split('-')[0]}-${value}`
                    : ''
                });
              }}
              disabled={slug ? true : false}
              className="input-spin-none"
              onWheel={e => e.target.blur()}
            />
          </Form.Group>
        )}
        {type === 'bundle' &&
          formValues.products?.map(dt => (
            <Row
              key={dt.id}
              className="align-items-center"
              style={{ borderBottom: '1px solid #ccc', paddingBottom: 5 }}
            >
              <Col xs={9}>{dt.name}</Col>
              <Col xs={3} className="d-flex gap-1">
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="minus"
                  transform="shrink-3"
                  onClick={() =>
                    handleBundleQuantityChange(dt.id, 0, 'decrease')
                  }
                  disabled={slug ? true : false}
                ></IconButton>
                <Form.Group>
                  <Form.Control
                    type="number"
                    name="bundle_quantity"
                    onChange={event => {
                      handleBundleQuantityChange(
                        dt.id,
                        Number(event.target.value)
                      );
                    }}
                    min={1}
                    maxLength="1"
                    disabled={slug ? true : false}
                    className="input-spin-none text-center"
                    onWheel={e => e.target.blur()}
                    value={
                      formValues.bundle_quantity?.find(d => d.id === dt.id)
                        ?.quantity
                    }
                    onBlur={e => {
                      if (e.target.value === '' || e.target.value === '0') {
                        handleBundleQuantityChange(dt.id, 1, 'input');
                      }
                    }}
                    style={{ width: 100 }}
                  />
                </Form.Group>
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() =>
                    handleBundleQuantityChange(dt.id, 0, 'increase')
                  }
                  disabled={slug ? true : false}
                ></IconButton>
              </Col>
            </Row>
          ))}
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

SetOrBundleCard.propTypes = {
  formValues: PropTypes.object,
  setFormValues: PropTypes.func,
  type: PropTypes.string,
  setSeoSetting: PropTypes.func,
  slug: PropTypes.string,
  shippingCost: PropTypes.object
};

export default SetOrBundleCard;

export const getProduct = async slug => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.product.detail + slug
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
