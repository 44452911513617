import { protectedApi, serializeQuery } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const createFaq = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.faq.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateFaq = async (payload, slug) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.content.faq.detail + slug,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const contentBulkAction = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.bulk_actions,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getContentTags = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.tag.mini + query
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};

export const createTag = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.tag.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createTagFromProduct = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.tagFromProduct.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateTagFromProduct = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.tagFromProduct.detail,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const checkTagFromProduct = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.tagFromProduct.check,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteTagFromProduct = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.tagFromProduct.delete,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getTags = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.tag.list + serializeQuery(query)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateTag = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.content.tag.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteTag = async (user_id, tag_id) => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.content.tag.detail + tag_id,
      { data: { updated_by: user_id } }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getBlogCategory = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.blogCategory.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getFaqCategory = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.faqcategory.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getBlogs = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.blog.list + '?' + query
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getBlogsMini = async () => {
  try {
    const response = await protectedApi.get(serviceUrl.content.blog.mini);
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};
export const getFaqs = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.faq.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getGlossaries = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.glossary.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getHeaderBanners = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.headerBanner.list + serializeQuery(queryObj)
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const createHeaderBanner = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.content.headerBanner.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateHeaderBanner = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.content.headerBanner.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteHeaderBanner = async slug => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.content.headerBanner.detail + slug
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getBlogComments = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.content.contentComment.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
