/* eslint-disable react/react-in-jsx-scope */
import { AiOutlineStock } from 'react-icons/ai';
import { BsColumnsGap, BsPeople, BsTags, BsTools } from 'react-icons/bs';
import { GiHumanPyramid } from 'react-icons/gi';
import { MdWeb } from 'react-icons/md';
import { SlBasketLoaded } from 'react-icons/sl';

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: <BsColumnsGap />,
      children: [
        {
          name: 'Default',
          to: '/',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

export const orderRoutes = {
  label: 'order',
  labelDisable: true,
  children: [
    {
      name: 'Orders',
      active: true,
      icon: <SlBasketLoaded />,
      to: '/order/order-list',
      children: [
        {
          name: 'Baskets',
          to: '/order/baskets',
          active: true
        },
        {
          name: 'Returns',
          to: '/order/returns',
          active: true
        }
      ]
    }
  ]
};

export const productRoutes = {
  label: 'product',
  labelDisable: true,
  children: [
    {
      name: 'Product',
      active: true,
      icon: <BsTags />,
      to: '/product/product-list',
      children: [
        // {
        //   name: 'Variants',
        //   to: '/product/product-variants',
        //   active: true
        // },
        {
          name: 'Brands',
          to: '/product/product-brands',
          active: true
        },
        {
          name: 'Categories',
          to: '/product/product-categories',
          active: true
        },
        {
          name: 'Collections',
          to: '/product/product-collection',
          active: true
        },
        {
          name: 'Gift Cards',
          to: '/product/gift-card',
          active: true
        },
        {
          name: 'Campaigns',
          to: '/order/campaigns',
          active: true
        },
        {
          name: 'User Questions',
          to: '/product/user-questions',
          active: true
        }
      ]
    }
  ]
};
export const stockRoutes = {
  label: 'stock',
  labelDisable: true,
  children: [
    {
      name: 'Stock',
      icon: <AiOutlineStock />,
      // to: '/product/product-grid',
      active: true,
      children: [
        {
          name: 'Inventory',
          to: '/stock/inventory',
          active: true
        },
        {
          name: 'Warehouse',
          to: '/stock/warehouse',
          active: true
        },
        {
          name: 'Transfers',
          to: '/stock/transfer',
          active: true
        },
        {
          name: 'Actions',
          to: '/stock/action',
          active: true
        },
        {
          name: 'Counting',
          to: '/stock/counting',
          active: true
        }
      ]
    }
  ]
};
export const customerRoutes = {
  label: 'customer',
  labelDisable: true,
  children: [
    {
      name: 'Customers',
      icon: <GiHumanPyramid />,
      active: true,
      to: '/customers',
      children: [
        // Messaging servisi kaldırıldı
        // {
        //   name: 'Messaging',
        //   to: 'customer/messaging',
        //   active: true
        // },
        {
          name: 'Agreements',
          to: 'customer/agreements',
          active: true
        },
        {
          name: 'Newsletter',
          to: 'customer/newsletter',
          active: true
        }
      ]
    }
  ]
};

export const cmsRoutes = {
  label: 'cms',
  labelDisable: true,
  children: [
    {
      name: 'CMS',
      icon: <MdWeb />,
      active: true,
      children: [
        {
          name: 'Blog',
          to: '/cms/blog',
          active: true,
          children: [
            {
              name: 'Blog Comments',
              to: '/cms/blog/comments',
              active: true
            }
          ]
        },
        {
          name: 'Faq',
          to: '/cms/faq',
          active: true
        },
        {
          name: 'Glossary',
          icon: '',
          to: '/cms/glossary',
          active: true
        },
        {
          name: 'Header Banner',
          icon: '',
          to: '/cms/header-banner',
          active: true
        },
        {
          name: 'Home Page',
          icon: '',
          to: '/cms/homepage',
          active: true
        },
        {
          name: 'Mega Menu',
          icon: '',
          to: '/cms/megamenu',
          active: true
        },
        {
          name: 'Agreements',
          icon: '',
          // icon: <BsBookFill />,
          to: '/agreements',
          active: true
        }
      ]
    }
  ]
};

export const systemRoutes = {
  label: 'System',
  children: [
    {
      name: 'Users & Groups',
      icon: <BsPeople />,
      active: true,
      children: [
        {
          name: 'Users',
          icon: 'user',
          to: '/users',
          active: true
        }
      ]
    },
    {
      name: 'Definitions',
      icon: <BsTools />,
      active: true,
      children: [
        {
          name: 'Product',
          icon: '',
          to: '/definitions/product',
          active: true
        },
        {
          name: 'Content',
          icon: '',
          to: '/definitions/content',
          active: true
        },
        {
          name: 'Stock',
          icon: '',
          to: '/definitions/stock',
          active: true
        },
        {
          name: 'Finance',
          icon: '',
          to: '/definitions/finance',
          active: true
        },
        {
          name: 'General',
          icon: '',
          to: '/definitions/general',
          active: true
        }
      ]
    }
  ]
};

export default [
  dashboardRoutes,
  orderRoutes,
  productRoutes,
  stockRoutes,
  customerRoutes,
  cmsRoutes,
  systemRoutes
];
