import { LoaderContext } from 'context/Context';
import { useState, useCallback, useContext } from 'react';
import { protectedApi } from 'services/axios';

const useDelete = params => {
  const { setLoading } = useContext(LoaderContext);
  const { url, payload } = params;
  const [response, setResponse] = useState({
    data: null,
    error: null
  });

  const deleteRequest = useCallback(() => {
    setLoading(true);
    protectedApi
      .delete(url, { data: payload })
      .then(res => {
        setResponse({ data: res.data, error: null });
      })
      .catch(error => setResponse({ data: null, error: error.response }))
      .finally(() => setLoading(false));
  }, [url]);

  return [response, deleteRequest];
};

export default useDelete;
