export const formDataReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'update':
      return { ...state, ...payload };

    default:
      return state;
  }
};
