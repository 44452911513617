import { protectedApi, serializeQuery } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const createWarehouse = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.warehouse.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateWarehouse = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.warehouse.warehouse.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getWarehouses = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.warehouse.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getVendors = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.account.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getStocksWithSearch = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.stock.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getStockLotTraces = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.stocklottraces.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getInventories = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.inventoryskt.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getInventoriesByWarehouse = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.inventory.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getTransactionsByWarehouse = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.stocktransaction.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createInventory = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.stock.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateInventory = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.warehouse.stock.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createStock = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.stock.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getTransactions = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.stocktransaction.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getTransaction = async id => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.stocktransaction.detail + id
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};
export const getTransactions101 = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.stocktransaction101.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createTransaction = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.stocktransaction.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateTransaction = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.stocktransactionupdate.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createShippingCost = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.shippingcost.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateShippingCost = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.warehouse.shippingcost.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getCounting = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.stockcounting.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createCounting = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.stockcounting.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateCounting = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.warehouse.stockcounting.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getCountingDetail = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.countingdetail.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getCountingReport = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.countingreport.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createCountingDetail = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.countingdetail.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateCountingDetail = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.warehouse.countingdetail.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteCountingDetail = async (payload, id) => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.warehouse.countingdetail.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getStocksWithQrcode = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.qrcode.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getMetrics = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.metric.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createShippingLimit = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.warehouse.shippinglimit.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getShippingLimits = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.shippinglimit.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getShippingCost = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.shippingcost.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getCurrencies = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.warehouse.currency.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
