import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import serviceUrl from '../../../services/serviceUrlList';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

const TransferDetail = () => {
  const [prdList, setPrdList] = useState([]);

  const { id } = useParams();

  const { response } = id
    ? useFetch({
        url: serviceUrl.warehouse.stocktransaction.detail + id
      })
    : {};

  const columns = [
    {
      accessor: 'barcode',
      Header: 'Barcode',
      headerProps: { className: 'pe-1 text-start', style: { width: 150 } }
    },
    {
      accessor: 'name',
      Header: 'Product Name',
      headerProps: { className: 'pe-1 text-start' }
    },
    {
      accessor: 'quantity',
      Header: 'Quantity',
      headerProps: { className: 'pe-1' },
      cellProps: { className: 'text-center', style: { width: 100 } }
    }
  ];

  useEffect(() => {
    if (!response) return;
    const { data } = response;

    setPrdList([
      {
        stock: data.stock.id,
        name: data.stock.name,
        quantity: data.quantity
      }
    ]);
  }, [response]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {response?.data.description !== ''
                  ? response?.data.description.toUpperCase()
                  : 'TRANSFER'}{' '}
                DETAIL
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-1" controlId="date">
                  <Form.Label column sm="6">
                    Issue Date
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={response?.data.date}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-1" controlId="shippingDate">
                  <Form.Label column sm="6">
                    Actual Shipping Date
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={response?.data.shippingDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-1"
                  controlId="storageLocation"
                >
                  <Form.Label column sm="6">
                    From
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={response?.data.storageLocation.name}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-1"
                  controlId="targetStorageLocation"
                >
                  <Form.Label column sm="6">
                    To
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={response?.data.targetStorageLocation.name}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-1" controlId="delivery">
                  <Form.Label column sm="6">
                    Delivery Person
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={response?.data.deliveryPerson}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-1" controlId="receiver">
                  <Form.Label column sm="6">
                    Receiver
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={response?.data.receiverPerson}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Col xs={12}>
            <AdvanceTableWrapper columns={columns} data={prdList || []}>
              <Card className="mb-3">
                <Card.Body className="p-0">
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0'
                    }}
                    rowClassName="align-middle"
                    height="auto"
                  />
                </Card.Body>
              </Card>
            </AdvanceTableWrapper>
          </Col>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </>
  );
};

export default TransferDetail;
