import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import AppContext, { AuthContext } from 'context/Context';
import { formatNumber } from 'helpers/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import { createShippingLimit, getShippingLimits } from 'services/warehouse';

const ShippingLimit = props => {
  const { newClickCount } = props;
  const { user } = useContext(AuthContext);
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm();

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };

  const {
    data,
    isLoading: tableLoading,
    refetch
  } = useQuery(
    ['getShippingLimit', queryObj],
    () => getShippingLimits(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (newClickCount === 0) return;
    openCreateModal(true);
  }, [newClickCount]);

  const onSubmit = async data => {
    console.log('DATA: ', data);
    handleCreate({
      ...data,
      created_by: user.id
    });
  };

  const handleCreate = async data => {
    const response = await createShippingLimit(data);
    if (response.status === 201) {
      closeCreateModal();
      refetch();
      if (selectedRowId) {
        toast.success(`Shipping limit updated`, {
          theme: 'colored'
        });
      } else {
        toast.success(`Shipping limit created`, {
          theme: 'colored'
        });
      }
    } else {
      toast.error(`Something went wrong`, {
        theme: 'colored'
      });
    }
  };

  const handleEditItem = dt => {
    setSelectedRowId(dt.id);
    setValue('limit', dt.limit);
    openCreateModal(true);
  };

  const clearFormData = () => {
    clearErrors();
    reset();
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'limit',
      Header: 'Limit',
      headerProps: { className: 'pe-1 text-start', width: 100 },
      cellProps: {
        className: 'py-2 text-end'
      },
      Cell: rowData => {
        const { limit } = rowData.row.original;
        return <span>{formatNumber(limit ?? 0)} ₺</span>;
      }
    },
    {
      accessor: 'shipmentAmount',
      Header: 'Shipment Amount',
      headerProps: { className: 'pe-1 text-start', width: 100 },
      cellProps: {
        className: 'py-2 text-end'
      },
      Cell: rowData => {
        const { shipmentAmount } = rowData.row.original;
        return <span>{formatNumber(shipmentAmount ?? 0)} ₺</span>;
      }
    },
    {
      accessor: 'created_at',
      Header: 'Create Date',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <span>{moment(created_at).format('DD/MM/YYYY')}</span>;
      }
    },
    {
      accessor: 'created_by.full_name',
      Header: 'Created By',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_active } = rowData.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { isActive } = dt.row.original;
        if (isActive) {
          return (
            <CardDropdown iconClassName="fs--1">
              <div className="py-2">
                <Dropdown.Item onClick={() => handleEditItem(dt.row.original)}>
                  Edit
                </Dropdown.Item>
              </div>
            </CardDropdown>
          );
        } else {
          return <></>;
        }
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={tableLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 align-middle'
              }}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">
              {selectedRowId !== null ? 'Edit' : 'Add'} Shipping Limit
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="limit">
              <Form.Label className="fs-0">Limit</Form.Label>
              <Form.Control
                {...register('limit', {
                  required: 'This field cannot be empty!'
                })}
                onChange={event => {
                  setValue('limit', event.target.value.replace(/[^0-9.]/g, ''));
                }}
                isInvalid={errors.limit}
                type="text"
                name="limit"
                placeholder="Limit"
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                {errors?.limit?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="shipmentAmount">
              <Form.Label className="fs-0">Shipping Amount</Form.Label>
              <Form.Control
                {...register('shipmentAmount', {
                  required: 'This field cannot be empty!'
                })}
                onChange={event => {
                  setValue(
                    'shipmentAmount',
                    event.target.value.replace(/[^0-9.]/g, '')
                  );
                }}
                isInvalid={errors.shipmentAmount}
                type="text"
                name="shipmentAmount"
                placeholder="Shipping Amount"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.shipmentAmount?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={closeCreateModal}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

ShippingLimit.propTypes = {
  newClickCount: PropTypes.number
};

export default ShippingLimit;
