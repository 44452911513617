import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';

const CardRequirements = props => {
  const { register, control, clearField } = props;
  const [requirement, setRequirement] = useState('');

  const requirementTypeWatch = useWatch({ control, name: 'requirement_type' });

  useEffect(() => {
    setRequirement(requirementTypeWatch);
  }, [requirementTypeWatch]);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Minimum purchase requirements</Card.Title>
        <Form.Group className="mb-1" controlId="no">
          <Form.Check
            {...register('requirement_type')}
            inline
            type="radio"
            value="0"
            className="me-2"
            defaultChecked
            onChange={e => setRequirement(e.target.value)}
          />
          <Form.Label className="mb-0">No minimum requirements</Form.Label>
        </Form.Group>
        <Flex>
          <Form.Group className="mb-1 me-2" controlId="minAmount">
            <Form.Check
              {...register('requirement_type')}
              inline
              type="radio"
              value={1}
              className="me-2"
              onChange={e => setRequirement(e.target.value)}
            />
            <Form.Label className="mb-0">
              Minimum purchase amount (₺)
            </Form.Label>
          </Form.Group>
          {requirement === '1' && (
            <Form.Group className="flex-fill" controlId="requirementValue">
              <InputGroup>
                <InputGroup.Text>₺</InputGroup.Text>
                <Form.Control
                  {...register('requirement_value')}
                  placeholder="0.00"
                  type="number"
                  className="input-spin-none"
                  onFocus={clearField}
                  onWheel={e => e.target.blur()}
                />
              </InputGroup>
              <Form.Text>Applies to all products.</Form.Text>
            </Form.Group>
          )}
        </Flex>
        <Flex>
          <Form.Group className="mb-3 me-2" controlId="minQuantity">
            <Form.Check
              {...register('requirement_type')}
              inline
              type="radio"
              value={2}
              className="me-2"
              onChange={e => setRequirement(e.target.value)}
            />
            <Form.Label className="mb-0">Minimum quantity of items</Form.Label>
          </Form.Group>
          {requirement === '2' && (
            <Form.Group className="flex-fill" controlId="requirementValue">
              <InputGroup>
                <Form.Control
                  {...register('requirement_value')}
                  type="number"
                  className="input-spin-none"
                  onFocus={clearField}
                  onWheel={e => e.target.blur()}
                />
              </InputGroup>
              <Form.Text>Applies to all products.</Form.Text>
            </Form.Group>
          )}
        </Flex>
      </Card.Body>
    </Card>
  );
};
CardRequirements.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  clearField: PropTypes.func,
  control: PropTypes.object,
  handleChange: PropTypes.func
};
export default CardRequirements;
