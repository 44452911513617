import DatePickerCustomInput from 'components/common/DatePickerCustomInput';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import MultipleImageUpload from 'components/common/MultipleImageUpload';
import SeoSetting from 'components/common/SeoSetting';
import TextEditor from 'components/common/TextEditor';
import AutocompleteTag from 'components/input/AutocompleteTag';
import { IMAGE_SIZES } from 'helpers/image';
import { isEmpty } from 'helpers/utils';
import useFetch from 'hooks/useFetch';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getProductListMini } from 'services/product';
import serviceUrl from 'services/serviceUrlList';

const AddBlogForm = props => {
  const {
    slug,
    formData,
    setFormData,
    handleSubmit,
    setConfirmText,
    setShowConfirm,
    handleRouteList,
    seoSetting,
    defaultImg,
    pageOptions,
    deviceOptions,
    setRemovedFiles
  } = props;
  const audioRef = useRef(null);
  const { response: blogCategoryResponse } = useFetch({
    url: serviceUrl.content.blogCategory.mini
  });

  const [pageName, setPageName] = useState({});
  const [deviceType, setDeviceType] = useState({});

  const [searchValue, setSearchValue] = useState('');
  const { data: productResponse, isLoading } = useQuery(
    ['blog-product-list-mini', searchValue],
    () => getProductListMini({ search: searchValue, limit: 10 }),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const {
    register,

    formState: { errors }
  } = useForm();

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ type: 'update', payload: { [name]: value } });
  };
  // const handleCheckboxChange = ({ target }) => {
  //   let name = target.name;
  //   let value = target.checked;
  //   setFormData({ type: 'update', payload: { [name]: value } });
  // };
  const handleChangeEditor = value => {
    setFormData({ type: 'update', payload: { content: value } });
  };
  const handleChangeDate = (value, name) => {
    setFormData({
      type: 'update',
      payload: { [name]: moment(value).format('YYYY-MM-DD HH:mm') }
    });
  };
  const handleChangeSelect = (target, info) => {
    setFormData({ type: 'update', payload: { [info.name]: target } });
  };

  const handleFileChange = event => {
    setFormData({ type: 'update', payload: { audio: event.target.files[0] } });
  };
  const handleSeoValue = seoValue => {
    setFormData({
      type: 'update',
      payload: { seo_setting: JSON.stringify(seoValue) }
    });
  };

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Blog',
      description: 'Are you sure? Selected blog will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };
  const productOptions = productResponse?.data?.results.map(dt => ({
    label: (
      <div key={dt.slug}>
        {dt.files && dt.files[0] && (
          <img
            src={dt.files[0].file}
            alt={dt.files[0].alt_text}
            width={50}
            height={50}
            className="pe-2"
          />
        )}
        <span>{dt.name}</span>
      </div>
    ),
    value: dt.slug
  }));
  const handleSelectedProduct = value => {
    setFormData({ type: 'update', payload: { related_product: value.value } });
  };
  const blogCategoryOptions = blogCategoryResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });
  const setSelectedTags = tags => {
    setFormData({ type: 'update', payload: { tag_names: tags } });
  };

  // Media start

  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      // display: 'flex',
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset'
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  const handleImgFile = imageObjs => {
    if (!pageName.value || !deviceType.value) return;
    imageObjs.map(imageObj => {
      setFormData({
        type: 'update',
        payload: { [`image_${imageObj.id}`]: imageObj.file }
      });
    });
  };

  const handleImageClick = e => {
    if (isEmpty(pageName) || isEmpty(deviceType)) {
      e.preventDefault();
      toast.error('Please select page name and device type!', {
        theme: 'colored'
      });
    } else if (formData[`image_${pageName.value}_${deviceType.value}`]) {
      e.preventDefault();
      toast.error('An image already selected for this field!', {
        theme: 'colored'
      });
    }
  };
  // Media end

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group
        className="mb-3"
        controlId="is_active"
        style={{
          width: 120,
          position: 'absolute',
          right: 20,
          marginTop: -10
        }}
      >
        <Form.Select
          size="sm"
          className="mb-3"
          name="is_active"
          required
          value={formData.is_active}
          onChange={handleChange}
        >
          <option value={Boolean(true)}>Active</option>
          <option value={Boolean(false)}>Passive</option>
        </Form.Select>
      </Form.Group>
      <Row className="mb-3 pt-lg-4">
        <Col lg={8}>
          <Form.Group as={Row} controlId="name">
            <Form.Label as={Col} lg={3}>
              Title
            </Form.Label>
            <Col lg={9}>
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Blog title"
                value={formData.name}
                onChange={handleChange}
                tabIndex={1}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group as={Row} controlId="publish_date">
            <Form.Label as={Col} lg={4}>
              Publish Date
            </Form.Label>
            <Col lg={8}>
              <DatePickerCustomInput
                selected={new Date(formData.publish_date)}
                onChange={date => handleChangeDate(date, 'publish_date')}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="dd.MM.yyyy HH:mm"
                className="form-control"
                placeholder="Publish Date"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group as={Row} className="mb-3" controlId="summary">
        <Form.Label as={Col} lg={2}>
          Summary
        </Form.Label>
        <Col lg={10}>
          <Form.Control
            as="textarea"
            placeholder="Blog summary"
            rows={3}
            name="summary"
            value={formData.summary}
            onChange={handleChange}
            tabIndex={2}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="content">
        <Form.Label as={Col} lg={2}>
          Content
        </Form.Label>
        <Col lg={10}>
          <TextEditor
            value={formData.content}
            name="content"
            tabIndex={3}
            onChange={handleChangeEditor}
          />
        </Col>
      </Form.Group>
      <Divider />
      <Form.Group controlId="imageUpload" className="mb-3">
        <Form.Label>Upload Image</Form.Label>
        <MultipleImageUpload
          file={handleImgFile}
          defaultImg={defaultImg}
          onClick={handleImageClick}
          page={pageName}
          device={deviceType}
          altText={formData.image_alt_text}
          setRemovedFiles={setRemovedFiles}
          imageSize={
            pageName?.value && deviceType?.value
              ? IMAGE_SIZES['blog'][pageName.value][deviceType.value]
              : []
          }
        >
          <Flex>
            <Select
              className="me-2 mb-2"
              options={pageOptions}
              styles={selectStyles}
              placeholder="Page Name"
              onChange={val => setPageName(val)}
            />
            <Select
              className="me-2"
              options={deviceOptions}
              styles={selectStyles}
              placeholder="Device Type"
              onChange={val => setDeviceType(val)}
            />
          </Flex>
        </MultipleImageUpload>
        <Form.Control
          {...register('image_alt_text')}
          type="text"
          size="sm"
          className="w-25 mt-2"
          placeholder="Alt Text"
          onChange={handleChange}
        />
      </Form.Group>

      {/* <BlogMedia formData={formData} setFormData={setFormData} /> */}
      <Form.Group as={Row} className="mb-3" controlId="audio">
        <Form.Label as={Col} lg={2}>
          Audio
        </Form.Label>
        <Col lg={10}>
          <Form.Control
            type="file"
            name="audio"
            accept="audio/mp3,audio/*;capture=microphone"
            onChange={handleFileChange}
            ref={audioRef}
            style={{ display: 'none' }}
          />
          <div
            className="upload-button w-25"
            onClick={() => audioRef.current.click()}
          >
            {formData.audio?.name
              ? formData.audio.name
              : 'Please select a file'}
          </div>
        </Col>
      </Form.Group>
      <Row>
        <Col lg={6}>
          <Form.Group as={Row} className="mb-3" controlId="embedded_code">
            <Form.Label as={Col} lg={4}>
              Embedded Code
            </Form.Label>
            <Col lg={8}>
              <Form.Control
                as="textarea"
                placeholder="Youtube video embedded code"
                rows={3}
                name="embedded_code"
                value={formData.embedded_code}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group as={Row} className="mb-3" controlId="video_id">
            <Form.Label as={Col} lg={4}>
              Video
            </Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name="video_id"
                value={formData.video_id}
                placeholder="Please insert Medianova ID"
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group as={Row} className="mb-3" controlId="author">
        <Form.Label as={Col} lg={2}>
          Author
        </Form.Label>
        <Col lg={10}>
          <Form.Control
            type="text"
            name="author"
            value={formData.author}
            placeholder="Author"
            onChange={handleChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="category">
        <Form.Label as={Col} lg={2}>
          Category
        </Form.Label>
        <Col lg={10}>
          <Select
            closeMenuOnSelect={true}
            isClearable
            isMulti={false}
            options={blogCategoryOptions}
            placeholder="Categories"
            classNamePrefix="react-select"
            name="category"
            value={formData.category}
            onChange={handleChangeSelect}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="tag">
        <Form.Label as={Col} lg={2}>
          Tags
        </Form.Label>
        <Col lg={10}>
          <AutocompleteTag
            placeholder="Select your tags"
            selected={formData.tag_names}
            onChange={setSelectedTags}
            service="content"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="content">
        <Form.Label as={Col} lg={2}>
          Select Product
        </Form.Label>
        <Col lg={10}>
          <Select
            isClearable
            filterOption={null}
            isLoading={isLoading}
            onInputChange={val =>
              val.length > 2 ? setSearchValue(val) : setSearchValue('')
            }
            options={productOptions ?? []}
            styles={{ width: '84%' }}
            placeholder="Product Name, SKU, Barcode"
            classNamePrefix="react-select"
            onChange={handleSelectedProduct}
            value={
              formData.related_product?.value
                ? formData.related_product
                : productOptions?.filter(
                    prd => prd.value === formData.related_product
                  )[0]
            }
          />
        </Col>
      </Form.Group>
      <SeoSetting
        seoValues={handleSeoValue}
        defaultValue={seoSetting}
        register={register}
        errors={errors}
      />
      <div className="d-flex justify-content-end">
        <Button
          onClick={handleDeleteItem}
          variant="danger"
          disabled={!slug ? true : false}
          className="mx-2"
        >
          Delete
        </Button>
        <Button
          onClick={handleRouteList}
          variant="secondary"
          className="mx-2"
          style={{ width: 150 }}
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" style={{ width: 150 }}>
          {slug ? 'Update' : 'Save'}
        </Button>
      </div>
    </Form>
  );
};

AddBlogForm.propTypes = {
  slug: PropTypes.string,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  handleSubmit: PropTypes.func,
  setConfirmText: PropTypes.func,
  setShowConfirm: PropTypes.func,
  handleRouteList: PropTypes.func,
  setRemovedFiles: PropTypes.func,
  defaultImg: PropTypes.array,
  pageOptions: PropTypes.array,
  deviceOptions: PropTypes.array,
  seoSetting: PropTypes.object
};

export default AddBlogForm;
