/* eslint-disable react/prop-types */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState
} from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useNavigate } from 'react-router-dom';
import InventoryTableFilter from '../module/InventoryTableFilter';
import { useQuery } from 'react-query';
import { getInventories } from 'services/warehouse';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import { queryReducer } from 'reducers/queryReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import PropTypes from 'prop-types';

const InventoryList = props => {
  const { newClickCount } = props;
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });

  const { isLoading, data } = useQuery(
    ['inventories', queryObj, sort],
    () =>
      getInventories({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: true
    }
  );
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        // eslint-disable-next-line react/prop-types
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <FontAwesomeIcon icon={faSquareMinus} />
            ) : (
              <FontAwesomeIcon icon={faSquarePlus} />
            )}
          </span>
        ),
        headerProps: {
          className: 'text-start',
          style: { width: 10, paddingRight: 5 }
        },
        cellProps: { style: { width: 10, paddingRight: 5 } },
        Cell: ({ row }) =>
          row.original.stockSktReport.length > 0 ? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <FontAwesomeIcon icon={faSquareMinus} />
              ) : (
                <FontAwesomeIcon icon={faSquarePlus} />
              )}
            </span>
          ) : (
            <></>
          )
      },
      {
        accessor: 'name',
        Header: 'Product Name',
        headerProps: { className: 'pe-1 text-start' },
        cellProps: {
          className: 'py-2'
        }
      },
      {
        accessor: 'category',
        Header: 'Product Category',
        headerProps: { className: 'pe-1 text-start' },
        cellProps: {
          className: 'py-2'
        },
        Cell: dt => {
          const { category } = dt.row.original;
          return category?.name;
        },
        disableSortBy: true
      },
      {
        accessor: 'barcode',
        Header: 'Barcode (SKU)',
        headerProps: { className: 'pe-1 text-start' },
        cellProps: {
          className: 'py-2'
        },
        disableSortBy: true
      },
      {
        accessor: 'skt',
        Header: 'Expire Date (SKT)',
        headerProps: { className: 'pe-1 text-center' },
        cellProps: {
          className: 'py-2 text-center'
        },
        disableSortBy: true
      },
      {
        accessor: 'warehouse',
        Header: 'Warehouse',
        headerProps: { className: 'pe-1 text-center' },
        cellProps: {
          className: 'py-2 text-center'
        },
        disableSortBy: true
      },
      {
        accessor: 'stockQuantity',
        Header: 'Quantity',
        headerProps: { className: 'pe-1 text-center' },
        cellProps: {
          className: 'py-2 text-center'
        },
        Cell: dt => {
          const { stockQuantity } = dt.row.original;
          return stockQuantity[0]?.quantity;
        },
        disableSortBy: true
      }
    ],
    []
  );

  const renderRowSubComponent = useCallback(({ row }) => {
    return (
      <>
        {row.original.stockSktReport.map((dt, index) => {
          return (
            <Fragment key={index}>
              {dt.warehouse.map((wRow, i) => (
                <tr key={i}>
                  <td role="cell" colSpan={4}></td>
                  <td role="cell" className="py-2 text-center">
                    <span className="px-3">
                      {moment(dt.skt).format('DD-MM-YYYY')}
                    </span>
                  </td>
                  <td role="cell" className="py-2 text-center">
                    {wRow.storageLocation__name}
                  </td>
                  <td role="cell" className="py-2 text-center">
                    {wRow.quantity}
                  </td>
                </tr>
              ))}
            </Fragment>
          );
        })}
      </>
    );
  }, []);
  useEffect(() => {
    if (newClickCount === 0) return;
    handleNew();
  }, [newClickCount]);

  const handleNew = () => {
    navigate('/stock/inventory-add');
  };
  return (
    <div className="p-3">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          sortable
          pagination
          renderRowSubComponent={renderRowSubComponent}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <InventoryTableFilter />
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              rowClassName="align-middle"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0',
                subvalue: 'stockSktReport'
              }}
              setSort={setSort}
              sort={sort}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>
    </div>
  );
};
InventoryList.propTypes = {
  newClickCount: PropTypes.number
};

export default InventoryList;
