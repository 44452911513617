import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const InventoryTableFilter = () => {
  return (
    <Row className="flex-between-center">
      <Col xs="auto" sm={6} lg={4}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter Inventories by product name, SKU, barcode"
        />
      </Col>
    </Row>
  );
};

export default InventoryTableFilter;
