import LoadingWrapper from 'components/loading/LoadingWrapper';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import MenuZone from './modules/MenuZone';
import { useQuery } from 'react-query';
import {
  getBrandsMini,
  getMenuItems,
  getProductCollectionsMini
} from 'services/product';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';

function WebMegaMenuItem() {
  const { mmid, id } = useParams();
  const { data: brands } = useQuery(['getbrandsmini'], () => getBrandsMini(), {
    keepPreviousData: false,
    staleTime: Infinity
  });
  const { data: collections } = useQuery(
    ['getcollectionsmini'],
    () => getProductCollectionsMini(),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const { data: menuItem, refetch } = useQuery(
    [`menu-item-get`],
    () => getMenuItems({ parent__isnull: true, main_menu: id }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const props = {
    collections: collections?.data.results,
    brands: brands
  };

  const loading = !brands || !collections || !menuItem;
  return (
    <LoadingWrapper loading={loading}>
      <div className="p-2">
        <Card className="mb-3">
          <Card.Header className="d-flex justify-content-between align-item-center">
            <span>{menuItem?.main_menu_display_name}</span>
            <IconButton
              className="border-300 me-2"
              iconClassName="me-1"
              variant="outline-secondary"
              size="sm"
              icon="chevron-left"
              transform="shrink-4"
              as={Link}
              to={`/cms/megamenu/${mmid}`}
            >
              Go Back
            </IconButton>
          </Card.Header>
          <Card.Body style={{ minHeight: 500 }}>
            {loading ? null : (
              <Row>
                {[1, 2, 3, 4, 5].map(zone => (
                  <Col xs={6} key={zone}>
                    <h4>Zone {zone}</h4>
                    <MenuZone
                      {...props}
                      menuItem={[
                        ...menuItem.results.filter(dt => dt.zone === zone)
                      ]}
                      refetch={refetch}
                      zone={zone}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end"></Card.Footer>
        </Card>
      </div>
    </LoadingWrapper>
  );
}

export default WebMegaMenuItem;
