import { AuthContext } from 'context/Context';
import useFetch from 'hooks/useFetch';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createTransaction } from 'services/warehouse';
import serviceUrl from '../../../services/serviceUrlList';
import IconButton from 'components/common/IconButton';
import SelectBox from 'components/common/SelectBox';
import DatePickerCustomInput from 'components/common/DatePickerCustomInput';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import EditableCell from './module/EditableCell';
import moment from 'moment';

const AddTransfer = () => {
  const initialForm = {
    stock: null,
    quantity: 0,
    name: '',
    lotNumber: ''
  };
  const initialData = {
    transactionType: 301,
    targetStorageLocation: '',
    storageLocation: '',
    deliveryPerson: '',
    receiverPerson: '',
    shippingDate: new Date(),
    date: new Date(),
    description: ''
  };

  const [prdList, setPrdList] = useState([initialForm]);

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: initialData });
  const fromWatch = useWatch({ control, name: 'storageLocation' });
  const dateWatch = useWatch({ control, name: 'date' });
  const shippingDateWatch = useWatch({ control, name: 'shippingDate' });

  useEffect(() => {
    setValue('createdBy', user.id);
  }, []);

  const { response: warehouseResponse } = useFetch({
    url: serviceUrl.warehouse.warehouse.list
  });
  const warehouseOptions = warehouseResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });

  const onSubmit = async data => {
    if (!prdList.length > 0) {
      toast.error('Please add product!', { theme: 'colored' });
      return;
    }
    data.storageLocation = data.storageLocation?.value
      ? data.storageLocation.value
      : data.storageLocation;
    data.targetStorageLocation = data.targetStorageLocation?.value
      ? data.targetStorageLocation.value
      : data.targetStorageLocation;
    const payload = prdList.map(prd => {
      console.log('data', data);

      data.shippingDate = moment(data.shippingDate).format('YYYY-MM-DD HH:mm');
      data.date = moment(data.date).format('YYYY-MM-DD');
      data.lotNumber = prd.lotNumber?.value
        ? prd.lotNumber.value
        : prd.lotNumber;
      const temp = { ...prd, ...data };
      delete temp.available;
      delete temp.barcode;
      delete temp.lotOptions;
      delete temp.name;
      return temp;
    });

    postRequest(payload);
  };

  const postRequest = async payload => {
    const response = await createTransaction(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Stock transfer created.`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const columns = [
    {
      accessor: 'barcode',
      Header: 'Barcode/Product Name',
      headerProps: { className: 'pe-1 text-start' },
      Cell: dt =>
        EditableCell({
          ...dt,
          setData: setPrdList,
          type: 'select',
          control
        })
    },
    {
      accessor: 'lotNumber',
      Header: 'Lot Number',
      headerProps: { className: 'pe-1 text-start', style: { width: 200 } },
      Cell: dt =>
        EditableCell({
          ...dt,
          setData: setPrdList,
          data: prdList,
          type: 'select',
          control,
          fromWatch
        })
    },
    {
      accessor: 'quantity',
      Header: 'Quantity',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: { style: { width: 30 } },
      Cell: dt => EditableCell({ ...dt, setData: setPrdList, type: 'number' })
    },
    {
      accessor: 'available',
      Header: 'Available',
      headerProps: { className: 'pe-1 text-start', style: { width: 30 } },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'none',
      Header: '',
      headerProps: { className: 'pe-1 text-end', style: { width: 90 } },
      cellProps: { className: 'pe-1 text-end' },
      Cell: rowData => {
        const { index } = rowData.row;
        return (
          <>
            {rowData.data.length > 1 && (
              <IconButton
                onClick={() => handleRemove(index)}
                variant="secondary"
                size="sm"
                icon="trash"
                transform="shrink-3"
                className="me-1"
              ></IconButton>
            )}
            <IconButton
              onClick={() => setPrdList([...prdList, initialForm])}
              variant="primary"
              size="sm"
              icon="plus"
              transform="shrink-3"
            ></IconButton>
          </>
        );
      }
    }
  ];

  const handleRemove = index => {
    setPrdList(prdList.filter(elem => elem !== prdList[index]));
  };

  const handleRouteList = () => {
    navigate('/stock/transfer');
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                Create Transfer
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="storageLocation"
                >
                  <Form.Label as={Col} xs={4}>
                    From
                  </Form.Label>
                  <Col xs={8}>
                    <SelectBox
                      options={warehouseOptions}
                      name="storageLocation"
                      control={control}
                      rules={{ required: 'This field cannot be empty' }}
                      className={errors.storageLocation ? 'is-invalid' : ''}
                      placeholder="Select From"
                      classNamePrefix="react-select"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.storageLocation?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="targetStorageLocation"
                >
                  <Form.Label as={Col} xs={4}>
                    To
                  </Form.Label>
                  <Col xs={8}>
                    <SelectBox
                      options={warehouseOptions}
                      name="targetStorageLocation"
                      control={control}
                      rules={{ required: 'This field cannot be empty' }}
                      className={
                        errors.targetStorageLocation ? 'is-invalid' : ''
                      }
                      placeholder="Select To"
                      classNamePrefix="react-select"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.targetStorageLocation?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="deliveryPerson"
                >
                  <Form.Label as={Col} xs={4}>
                    Delivery Person
                  </Form.Label>
                  <Col xs={8}>
                    <Form.Control
                      {...register('deliveryPerson', {
                        required: 'This field cannot be empty'
                      })}
                      isInvalid={!!errors?.deliveryPerson}
                      type="text"
                      placeholder="Delivery Person"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.deliveryPerson?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="receiverPerson"
                >
                  <Form.Label as={Col} xs={4}>
                    Reciever
                  </Form.Label>
                  <Col xs={8}>
                    <Form.Control
                      {...register('receiverPerson', {
                        required: 'This field cannot be empty'
                      })}
                      isInvalid={!!errors?.receiverPerson}
                      type="text"
                      placeholder="Receiver"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.receiverPerson?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="date">
                  <Form.Label as={Col} xs={4}>
                    Issue Date
                  </Form.Label>
                  <Col xs={8}>
                    <DatePickerCustomInput
                      value={moment(dateWatch).format('DD/MM/YYYY')}
                      onChange={date => setValue('date', date)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      placeholder="Issue Date"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="shippingDate">
                  <Form.Label as={Col} xs={4}>
                    Actual Shipping Date
                  </Form.Label>
                  <Col xs={8}>
                    <DatePickerCustomInput
                      selected={shippingDateWatch}
                      onChange={date => {
                        setValue('shippingDate', date);
                      }}
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat="HH:mm"
                      showTimeSelect
                      timeIntervals={60}
                      className="form-control"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group as={Row} className="mb-3" controlId="description">
                  <Form.Label as={Col} xs={2}>
                    Description
                  </Form.Label>
                  <Col xs={10}>
                    <Form.Control
                      {...register('description')}
                      as="textarea"
                      placeholder="Description"
                      rows={3}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Col xs={12}>
              <AdvanceTableWrapper columns={columns} data={prdList || []}>
                <Card className="mb-3">
                  <Card.Body className="p-0">
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                      tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-0'
                      }}
                      rowClassName="align-middle"
                    />
                  </Card.Body>
                  <Card.Footer></Card.Footer>
                </Card>
              </AdvanceTableWrapper>
            </Col>
            <div className="d-flex justify-content-end">
              <Button variant="danger" disabled={true} className="mx-2">
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                Save
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </>
  );
};

export default AddTransfer;
