import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomerInfo from './modules/CustomerInfo';
import PaymentInfo from './modules/PaymentInfo';
import SelectProducts from './modules/SelectProducts';

const AddOrderForm = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(0);
  const customerProps = {
    selectedUser,
    setSelectedUser,
    selectedAddress,
    setSelectedAddress
  };
  // console.log('SELECTED USER: ', selectedUser);
  // console.log('SELECTED ADDRESS: ', selectedAddress);
  return (
    <Row>
      <Col xs={12} lg={8}>
        <SelectProducts
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
        <PaymentInfo
          selectedProducts={selectedProducts}
          selectedUser={selectedUser}
          selectedAddress={selectedAddress}
        />
      </Col>
      <Col xs={12} lg={4}>
        <CustomerInfo {...customerProps} />
      </Col>
    </Row>
  );
};

export default AddOrderForm;
