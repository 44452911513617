import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';

const ModalSelectItemList = props => {
  const { handleSelect, name, email, isSelected, itemType, files, id } = props;
  return (
    <Row className="gx-card mx-0 align-items-center border-bottom border-200">
      <Col xs={12} className="py-3">
        <div className="d-flex align-items-center">
          <Form.Check
            type="checkbox"
            className="d-flex"
            label=""
            id={`chk-${id}`}
            style={{ marginBottom: 0 }}
          >
            <Form.Check.Input
              name={`${itemType}-select`}
              checked={isSelected}
              onChange={handleSelect}
              type="checkbox"
              className="me-2 align-self-center"
              style={{ minWidth: 16 }}
            />
            <Form.Check.Label
              className="d-flex align-items-center cursor-pointer"
              style={
                itemType !== 'product' ? { marginBottom: 0, paddingTop: 5 } : {}
              }
            >
              {itemType === 'product' && files.length > 0 && (
                <img
                  src={files[0].file}
                  width="60"
                  alt={'dummy'}
                  className="img-fluid rounded-1 me-3 d-none d-md-block"
                />
              )}
              <div className="flex-1">
                <h5 className="fs-0 mb-0">{name ?? email}</h5>
              </div>
            </Form.Check.Label>
          </Form.Check>
        </div>
      </Col>
    </Row>
  );
};

ModalSelectItemList.propTypes = {
  handleSelect: PropTypes.func,
  image: PropTypes.string,
  itemType: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  stock_quantity: PropTypes.number,
  price: PropTypes.string,
  isSelected: PropTypes.bool,
  files: PropTypes.array,
  id: PropTypes.number
};

export default ModalSelectItemList;
