import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

function ODRefundReason({ data }) {
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex gap-2">
          <span
            style={{
              fontWeight: 600
            }}
          >
            Refund/Cancel Reason:{' '}
          </span>
          <div>
            {data.cancellationReason
              ? data.cancellationReason.name
              : data.cancellationReason_other}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
ODRefundReason.propTypes = {
  data: PropTypes.object
};

export default ODRefundReason;
