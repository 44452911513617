import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

function CustomerFavorites({ data }) {
  if (!data) return null;
  return (
    <div>
      <h5>Favorites</h5>
      <div className="mt-1">
        <div>
          {data.customer_favorite_list ? (
            data.customer_favorite_list?.map((dt, index) => (
              <Table
                key={index}
                bordered
                responsive
                size="sm"
                className="fs--1 mb-0"
              >
                <tbody>
                  <tr>
                    <td width={150}>{dt.product__barcode}</td>
                    <td>{dt.product__display_name}</td>
                  </tr>
                </tbody>
              </Table>
            ))
          ) : (
            <div>Customer has 0 favorite products</div>
          )}
        </div>
      </div>
      <hr />
      {/* <div className="">
        <h5>Favorite Blogs</h5>
      </div> */}
    </div>
  );
}

export default CustomerFavorites;

CustomerFavorites.propTypes = {
  data: PropTypes.object
};
