import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SeoSetting from 'components/common/SeoSetting';
import CardOrganization from './CardOrganization';
import CardSpecification from './CardSpecification';
import CardTheme from './CardTheme';
import CardAssignment from './CardAssignment';
import CardNotes from './CardNotes';
import { useForm } from 'react-hook-form';

const AddGiftCardForm = props => {
  const {
    id,
    onSubmit,
    formData,
    setFormData,
    formDataUser,
    setFormDataUser,
    seoSetting,
    handleDeleteItem,
    handleRouteList
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ reValidateMode: 'onSubmit' });

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;

    if (name === 'value') {
      setFormData({ ...formData, [name]: value, price: value });
    } else if (name === 'count') {
      setFormData({ ...formData, [name]: parseInt(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSeoValue = seoValue => {
    setFormData({
      ...formData,
      seo_setting: seoValue
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  {id ? 'Update Gift Card' : 'Create Gift Card'}
                </h5>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                marginTop: -10
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                name="is_active"
                defaultValue={formData.is_active}
                onChange={handleChange}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Name</Form.Label>
              <Form.Control
                {...register('title', { required: true })}
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                isInvalid={errors?.title}
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Description"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
        <CardOrganization formData={formData} setFormData={setFormData} />
        <CardTheme formData={formData} setFormData={setFormData} id={id} />
        <CardSpecification
          id={id}
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />
        <CardAssignment
          formData={formDataUser}
          setFormData={setFormDataUser}
          errors={errors}
          register={register}
        />
        <CardNotes
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />

        <Card className="mb-3">
          <Card.Header></Card.Header>
          <Card.Body>
            <SeoSetting
              seoValues={handleSeoValue}
              defaultValue={seoSetting}
              register={register}
              errors={errors}
            />
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit}
                style={{ width: 150 }}
              >
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
      </Form>
    </>
  );
};

AddGiftCardForm.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  formDataUser: PropTypes.object,
  setFormDataUser: PropTypes.func,
  seoSetting: PropTypes.object,
  setSeoSetting: PropTypes.func,
  handleRouteList: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  titleRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  emailRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default AddGiftCardForm;
