import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { getApiBase } from 'services/config';
import serviceUrl from 'services/serviceUrlList';
import 'assets/scss/ckeditor.scss';
import { Controller } from 'react-hook-form';

const TextEditor = props => {
  const { name, tabIndex = 1, value, onChange, control, maxLength } = props;
  // const [restrictedText, setRestrictedText] = useState('');
  const [length, setLength] = useState(0);
  const handleOnChange = (event, editor) => {
    manupulateText(event, editor);

    onChange(handleMaxLength(editor));
  };
  const manupulateText = (event, editor) => {
    editor.conversion.elementToElement({
      model: 'blockQuote',
      view: { name: 'div', classes: 'newsletter-card' },
      converterPriority: 'high'
    });
    editor.conversion.attributeToElement({
      model: 'code',
      view: { name: 'div', classes: 'product-card' },
      converterPriority: 'high'
    });
  };

  const handleMaxLength = editor => {
    const htmlClear = /(&nbsp;|<([^>]+)>)/gi;
    let data = editor.getData();
    const len = data.replaceAll(htmlClear, '').length;
    setLength(len);

    if (maxLength && len > maxLength) {
      // Metin sınırına ulaşıldığında metni kırp.
      data = data.substring(0, maxLength);
      // setRestrictedText(data);
      return data;
    } else {
      return data;
    }
  };

  return (
    <>
      {control ? (
        <>
          <Controller
            name={name}
            control={control}
            // eslint-disable-next-line no-unused-vars
            render={({ field: { ref, ...rest } }) => (
              <CKEditor
                {...rest}
                editor={Editor}
                data={control._formValues[name] ?? ''}
                config={{
                  tabIndex: tabIndex,
                  extraPlugins: [MyCustomUploadAdapterPlugin]
                }}
                onChange={(_, editor) => {
                  manupulateText(_, editor);
                  return rest.onChange(handleMaxLength(editor));
                }}
              />
            )}
          />
          {maxLength && (
            <div
              id="char-counter"
              style={{ position: 'absolute', right: 20, marginTop: 5 }}
            >
              {' '}
              {length} / {maxLength}
            </div>
          )}
        </>
      ) : (
        <>
          <CKEditor
            name={name}
            editor={Editor}
            data={value}
            config={{
              tabIndex: tabIndex,
              extraPlugins: [MyCustomUploadAdapterPlugin]
            }}
            onChange={handleOnChange}
          />
          {maxLength && (
            <div
              id="char-counter"
              style={{ position: 'absolute', right: 20, marginTop: 5 }}
            >
              {' '}
              {length} / {maxLength}
            </div>
          )}
        </>
      )}
    </>
  );
};

TextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  control: PropTypes.object,
  maxLength: PropTypes.number
};

export default TextEditor;

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
    this.url = getApiBase() + serviceUrl.product.file.list;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open('POST', this.url, true);
    xhr.responseType = 'json';
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader(
      'Authorization',
      'Bearer ' + JSON.parse(window.localStorage.getItem('user')).access_token
    );
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: response.file
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();

    this.loader.file.then(result => {
      data.append('file', result);
      this.xhr.send(data);
    });
  }
}
