import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import AppContext, { AuthContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import { createPersona, deletePersona, getPersonas } from 'services/product';

const Persona = props => {
  const { newClickCount } = props;
  const { user } = useContext(AuthContext);
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    search: ''
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    is_active: true,
    name: ''
  });
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm();

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };
  const {
    data,
    isLoading: tableLoading,
    refetch
  } = useQuery(
    ['product-persona', queryObj],
    () => getPersonas({ ...queryObj }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (newClickCount === 0) return;
    openCreateModal(true);
  }, [newClickCount]);

  const onSubmit = async () => {
    formData.created_by = user.id;
    const response = await createPersona(formData);
    if (response.status === 201) {
      closeCreateModal();
      refetch();
      toast.success(`Persona created`, {
        theme: 'colored'
      });
    } else {
      if (response.data.name) {
        toast.error(`Name field must be unique!`, {
          theme: 'colored'
        });
      } else {
        toast.error(`Unexpected Error! Please try again`, {
          theme: 'colored'
        });
      }
    }
  };

  const handleDelete = async () => {
    const response = await deletePersona(selectedRowId);
    if (response.status === 204) {
      setShowConfirm(false);
      closeCreateModal();
      refetch();
      toast.success(`Persona deleted`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error(response.data.message, {
        theme: 'colored'
      });
    } else {
      toast.error(`Unexpected Error! Please try again`, {
        theme: 'colored'
      });
    }
    setSelectedRowId(null);
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditItem = dt => {
    setSelectedRowId(dt.id);
    setFormData({ ...dt });
    setValue('name', dt.name);
    openCreateModal(true);
  };

  const handleDeleteItem = (id, name) => {
    setSelectedRowId(id);
    setFormData({ id: id, name: name });
    setShowConfirm(true);
  };

  const handleModalDeleteButton = () => {
    if (selectedRowId === null) {
      closeCreateModal();
    } else {
      handleDeleteItem(selectedRowId, formData.name);
    }
  };

  const clearFormData = () => {
    setFormData({
      is_active: true,
      name: ''
    });
    clearErrors();
    reset();
  };
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <a href="#" onClick={() => handleEditItem(rowData.row.original)}>
            {name}
          </a>
        );
      }
    },
    // {
    //   accessor: 'is_active',
    //   Header: 'Status',
    //   cellProps: { className: 'text-center' },
    //   Cell: dt => {
    //     const { is_active } = dt.row.original;
    //     if (is_active) {
    //       return (
    //         <SoftBadge pill bg="success" className="d-block">
    //           Active
    //         </SoftBadge>
    //       );
    //     }
    //     return (
    //       <SoftBadge pill bg="danger" className="d-block">
    //         Passive
    //       </SoftBadge>
    //     );
    //   }
    // },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id, name } = dt.row.original;
        return (
          <IconButton
            onClick={() => handleDeleteItem(id, name)}
            variant="falcon-danger"
            size="sm"
            icon="trash"
            transform="shrink-3"
          />
        );
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={tableLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <TableHeader
            table
            handleRefetchAll={refetch}
            setQueryObj={setQueryObj}
          />
          <div className="my-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 align-middle'
              }}
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Add Persona</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                top: 10
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                name="is_active"
                required
                value={formData.is_active}
                onChange={handleChange}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="fs-0">Name</Form.Label>
              <Form.Control
                {...register('name', {
                  required: 'This field cannot be empty!'
                })}
                isInvalid={errors.name}
                type="text"
                name="name"
                placeholder="Persona name"
                value={formData.name}
                onChange={handleChange}
                maxLength={254}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={handleModalDeleteButton}
            >
              {selectedRowId === null ? 'Cancel' : 'Delete'}
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete Persona"
        description="Are you sure the persona you selected will be deleted?"
        request={handleDelete}
        type={'delete'}
      />
    </div>
  );
};

Persona.propTypes = {
  newClickCount: PropTypes.number
};

export default Persona;

const TableHeader = props => {
  const { setQueryObj } = props;

  const handlePersonaSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search: value
    }));
  };

  return (
    <Row className="">
      <Col xs={6} className="d-flex align-items-center pe-0">
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Filter Personas by the name"
          setGlobalFilter={handlePersonaSearch}
        />
      </Col>
    </Row>
  );
};

TableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  ids: PropTypes.array,
  handleRefetchAll: PropTypes.func,
  setQueryObj: PropTypes.func
};
