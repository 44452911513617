import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import avatar from 'assets/img/team/avatar.png';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import useFetch from 'hooks/useFetch';
import serviceUrl from 'services/serviceUrlList';
import { AuthContext, LoaderContext } from 'context/Context';
import { updateProfile, updateUser } from './api';
import LoadingWrapper from 'components/loading/LoadingWrapper';

const ProfileForm = () => {
  const { setLoading } = useContext(LoaderContext);
  const { user, setUser } = useContext(AuthContext);
  const uploader = useRef(null);
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
    department: '',
    photo: ''
  });
  const { response, loading } = useFetch({
    url: serviceUrl.user.profile.detail + user.id
  });

  useEffect(() => {
    if (!response) return;
    setFormData({
      ...formData,
      firstName: response.data.user.first_name,
      middleName: response.data.user.middle_name,
      lastName: response.data.user.last_name,
      email: response.data.user.email,
      phone: response.data.user.phone,
      title: response.data.title,
      department: response.data.department,
      photo: response.data.photo
    });
  }, [response]);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const user_payload = {
      first_name: formData.firstName,
      middle_name: formData.middleName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phone
    };
    const profile_payload = {
      title: formData.title,
      department: formData.department,
      photo: formData.photo,
      user: user.id
    };

    let fData = new FormData();
    for (const key in profile_payload) {
      if (profile_payload[key]) {
        fData.append(key, profile_payload[key]);
      }
    }
    await updateUser(user_payload, user.id);
    const updateProfile_ = await updateProfile(fData, user.id);
    console.log('UPDATEPROFILE : ', updateProfile_);
    setUser({ ...user, photo: updateProfile_.data.photo });
    setLoading(false);
    toast.success(`Your information is updated!`, {
      theme: 'colored'
    });
  };
  const handleUploadImage = event => {
    if (event.target.files[0].size > 5245329) {
      toast.error(`Image size is too big!`, {
        theme: 'colored'
      });
    }
    const file = Object.assign(event.target.files[0], {
      preview: URL.createObjectURL(event.target.files[0])
    });
    setFormData({ ...formData, photo: file });
  };
  return (
    <LoadingWrapper loading={loading}>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Profile</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className={'avatar-5xl cursor-pointer image-zoom-hover'}>
                  <input
                    className="d-none"
                    type="file"
                    accept="image/*"
                    ref={uploader}
                    name="image_uploader"
                    onChange={handleUploadImage}
                  />
                  <img
                    className={'img-thumbnail shadow-sm rounded-circle'}
                    src={
                      !formData.photo
                        ? avatar
                        : typeof formData.photo === 'string'
                        ? formData.photo
                        : formData.photo.preview
                    }
                    alt="profile picture"
                    onClick={() => uploader.current.click()}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="middleName">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Middle Name"
                  value={formData.middleName}
                  name="middleName"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  disabled
                  name="email"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  value={formData.phone}
                  name="phone"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  value={formData.title}
                  name="title"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="department">
                <Form.Label>Department</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Department"
                  value={formData.department}
                  name="department"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <div className="text-end">
              <Button variant="primary" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </LoadingWrapper>
  );
};

export default ProfileForm;
