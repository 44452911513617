import React, { useContext, useLayoutEffect } from 'react';
import LogoutContent from './LogoutContent';
import bgImg from 'assets/img/generic/19.jpg';
import AuthLayout from './AuthLayout';
import { AuthContext } from 'context/Context';

const Logout = () => {
  const { setUser } = useContext(AuthContext);
  useLayoutEffect(() => {
    window.localStorage.removeItem('user');
    setUser({});
  }, []);
  return (
    <AuthLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <LogoutContent layout="split" titleTag="h3" />
      </div>
    </AuthLayout>
  );
};

export default Logout;
