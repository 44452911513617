import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import Divider from 'components/common/Divider';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineDown,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineUp
} from 'react-icons/ai';
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs';
import useFetch from 'hooks/useFetch';
import serviceUrl from 'services/serviceUrlList';
import { protectedApi } from 'services/axios';
import usePost from 'hooks/usePost';
import useUpdate from 'hooks/useUpdate';
import { toast } from 'react-toastify';
import useDelete from 'hooks/useDelete';
import AppContext, { AuthContext } from 'context/Context';

const icons = {
  moveLeft: <AiOutlineLeft />,
  moveAllLeft: [<AiOutlineDoubleLeft key={0} />],
  moveRight: <AiOutlineRight />,
  moveAllRight: [<AiOutlineDoubleRight key={0} />],
  moveDown: <AiOutlineDown />,
  moveUp: <AiOutlineUp />,
  moveTop: <BsChevronDoubleUp />,
  moveBottom: <BsChevronDoubleDown />
};

const AddVariants = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [attributeTypes, setAttributeTypes] = useState([]);
  const [selectedAttributeTypes, setSelectedAttributeTypes] = useState([]);
  const [note, setNote] = useState('');
  const [isActive, setIsActive] = useState(true);

  const { response: res } = id
    ? useFetch({
        url: serviceUrl.product.variant.detail + id
      })
    : {};
  const { response } = useFetch({
    url: serviceUrl.product.product.mini + '?is_active=true'
  });
  const [postResponse, postRequest] = usePost({
    url: serviceUrl.product.variant.list,
    payload: {
      products: selectedProducts.map(dt => ({ id: dt })),
      attribute_types: selectedAttributeTypes.join(','),
      note: note,
      is_active: isActive,
      created_by: user.id
    }
  });
  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.product.variant.detail + id,
    payload: {
      products: selectedProducts.map(dt => ({ id: dt })),
      attribute_types: selectedAttributeTypes.join(','),
      note: note,
      is_active: isActive,
      updated_by: user.id
    }
  });
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.variant.detail + id
  });

  useEffect(() => {
    // UPDATE SURECINDE DEFAULT VERILERIN GETIRILMESI
    if (!res) return;
    const selected_products = res.data.products.map(dt => dt.id);
    const selected_attributes = res.data.attribute_types.split(',');
    setSelectedProducts(selected_products);
    setSelectedAttributeTypes(selected_attributes);
    setIsActive(res.data.is_active);
    if (res.data.note) {
      setNote(res.data.note);
    }
  }, [res]);

  useEffect(() => {
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.error === null) {
      toast.success(`Variant created`, {
        theme: 'colored'
      });
      handleRouteList();
    } else if (postResponse.error.status === 405) {
      toast.error(postResponse.error.data.message, {
        theme: 'colored'
      });
    } else {
      toast.error(`You must select product or attribute!`, {
        theme: 'colored'
      });
    }
  }, [postResponse]);

  useEffect(() => {
    if (!updateResponse.data && !updateResponse.error) return;
    if (updateResponse.error === null) {
      toast.success(`Variant updated`, {
        theme: 'colored'
      });
      handleRouteList();
    } else if (updateResponse.error.status === 405) {
      toast.error(updateResponse.data.message, {
        theme: 'colored'
      });
    } else {
      toast.error(`You must select product or attribute!`, {
        theme: 'colored'
      });
    }
  }, [updateResponse]);

  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    toast.success(`Variant deleted`, {
      theme: 'colored'
    });
    handleRouteList();
  }, [deleteResponse]);

  const handleSubmit = event => {
    event.preventDefault();
    if (!id) {
      postRequest();
    } else {
      updateRequest();
    }
  };

  const handleGetAttributeTypes = async value => {
    if (value.length === 0) {
      setAttributeTypes([]);
      return;
    }
    const payload = {
      id_list: value
    };
    const response = await getAttributeTypes(payload);
    setAttributeTypes(response.data.distinct_att_type_list);
  };

  useEffect(() => {
    handleGetAttributeTypes(selectedProducts);
  }, [selectedProducts]);

  const handleChange = value => {
    setSelectedProducts(value);
    handleGetAttributeTypes(value);
  };

  const handleRouteList = () => {
    navigate('/product/product-variants');
  };
  const handleDeleteItem = () => {
    setShowConfirm(true);
  };

  const productOptions = response?.data.results.map(dt => ({
    value: dt.id,
    label: dt.name
  }));
  const attributeOptions = attributeTypes.map(dt => ({
    value: dt,
    label: dt
  }));
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {id ? 'Update Variant' : 'Create Variant'}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                top: 10
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                name="is_active"
                required
                value={isActive}
                onChange={e => setIsActive(e.target.value)}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Products</Form.Label>
              <DualListBox
                className={isDark ? 'dual-list-dark-mode' : undefined}
                options={productOptions || []}
                selected={selectedProducts}
                onChange={handleChange}
                icons={icons}
              />
            </Form.Group>
            <Form.Group controlId="imageUpload" className="mb-3">
              <Form.Label>Attributes</Form.Label>
              <DualListBox
                className={isDark ? 'dual-list-dark-mode' : undefined}
                options={attributeOptions}
                selected={selectedAttributeTypes}
                onChange={setSelectedAttributeTypes}
                icons={icons}
              />
            </Form.Group>
            <Form.Group controlId="note" className="mb-3">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                name="note"
                placeholder="Note"
                onChange={e => setNote(e.target.value)}
                value={note}
                rows={3}
              />
            </Form.Group>
            <Divider />
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={'Delete Variant'}
        description={'Are you sure? Selected variant will be deleted!'}
        type={'delete'}
        request={deleteRequest}
      />
    </>
  );
};
export default AddVariants;

const getAttributeTypes = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.attribute_type_distinct,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
