import { protectedApi, serializeQuery } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const getDiscounts = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.order.discount.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getDiscount = async id => {
  try {
    const response = await protectedApi.get(
      serviceUrl.order.discount.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createDiscount = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.order.discount.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateDiscount = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.order.discount.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const generateCode = async () => {
  try {
    const response = await protectedApi.post(
      serviceUrl.order.discount.code_generate
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getDiscountsForOrder = async user_id => {
  try {
    const response = await protectedApi.post(
      serviceUrl.order.discount.forOrder,
      { user_id }
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};

export const createOrder = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.order.order.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getOrders = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.order.order.admin + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getOrder = async id => {
  try {
    const response = await protectedApi.get(
      `${serviceUrl.order.order.detail}${id}`
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getShippingDiscounts = async () => {
  try {
    const response = await protectedApi.get(
      serviceUrl.order.discount.shipping_discounts
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};

export const checkDiscountForOrder = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.order.discount.check_for_order,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const payment3DSecureInit = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.order.payment['3ds_init'],
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAbandoned = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.order.abandoned.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getReturn = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.order.return.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateReturnOrder = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.order.return.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCancelRefundReasons = async () => {
  try {
    const response = await protectedApi.get(serviceUrl.order.cancelReason.list);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const createCancelRefundReasons = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.order.cancelReason.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateCancelRefundReasons = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.order.cancelReason.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteCancelRefundReasons = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.order.cancelReason.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getDeclinedReasons = async () => {
  try {
    const response = await protectedApi.get(
      serviceUrl.order.declinedReason.list
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const createDeclinedReasons = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.order.declinedReason.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateDeclinedReasons = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.order.declinedReason.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteDeclinedReasons = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.order.declinedReason.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
