import Flex from 'components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';
import avatar from '../../../../assets/img/team/avatar.png';
import { Link } from 'react-router-dom';

const CustomerInfo = () => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Customer</h5>
      </Card.Header>
      <Card.Body className="py-1 fs--1 border-bottom">
        <Flex direction="column">
          <img src={avatar} alt="avatar" width={100} className="mb-2" />
          <Link to="#!">İlyas M Gökçek</Link>
          <Link to="#!" className="mb-2">
            ilyas@ilyas.com
          </Link>
          <span className="text-600">Accepts email marketing</span>
          <span className="text-600">No account</span>
        </Flex>
      </Card.Body>
      <Card.Body className="py-1 fs--1 border-bottom">
        <Flex direction="column">
          <h5 className="fs-0 fw-normal text-nowrap py-2 py-xl-0">
            Shipping Address
          </h5>
          <span>İlyas M Gökçek</span>
          <span>
            Mersin Tarsus akşamsettin mh 0114 sk no 8 Özgürlük 33000 Tarsus
          </span>
          <span>Turkey</span>
          <span>0544 111 11 11</span>
        </Flex>
      </Card.Body>
      <Card.Footer className="pt-2 fs--1">
        <Flex direction="column">
          <h5 className="fs-0 fw-normal text-nowrap py-2 py-xl-0">
            Billing Address
          </h5>
          <span className="text-600">Same as shipping address</span>
        </Flex>
      </Card.Footer>
    </Card>
  );
};

export default CustomerInfo;
