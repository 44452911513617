import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { getDynamicProductApi } from 'services/product';
import ProductFilterListItem from './ProductFilterListItem';

const ProductDetailFilterList = props => {
  const { name, handleFilters } = props;
  const { data } = useQuery(
    ['product-detail-filter', name],
    () => getDynamicProductApi(name),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  return (
    <div
      className="mt-3"
      style={{
        height: 300,
        overflowY: 'auto'
      }}
    >
      {data?.data.results.map((dt, i) => (
        <ProductFilterListItem
          key={i}
          name={dt.name}
          onClick={() => handleFilters(name, dt.id)}
        />
      ))}
    </div>
  );
};

ProductDetailFilterList.propTypes = {
  name: PropTypes.string.isRequired,
  handleFilters: PropTypes.func
};

export default ProductDetailFilterList;
