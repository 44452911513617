import { AuthContext } from 'context/Context';
import useFetch from 'hooks/useFetch';
import React, { useContext, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createCounting } from 'services/warehouse';
import serviceUrl from '../../../services/serviceUrlList';
import SelectBox from 'components/common/SelectBox';
import DatePickerCustomInput from 'components/common/DatePickerCustomInput';
import moment from 'moment';
import { statusOptions } from './module/Static';

const AddCounting = () => {
  const initialData = {
    date: new Date(),
    storageLocation: '',
    responsible: '',
    status: { value: 1, label: 'Active' },
    description: ''
  };

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: initialData });
  const dateWatch = useWatch({ control, name: 'date' });

  useEffect(() => {
    setValue('createdBy', user.id);
  }, []);

  const { response: warehouseResponse } = useFetch({
    url: serviceUrl.warehouse.warehouse.list
  });
  const { response: usersResponse } = useFetch({
    url: serviceUrl.user.user.list
  });
  const warehouseOptions = warehouseResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });
  const userOptions = usersResponse?.data.results.map(dt => {
    return {
      value: `${dt.first_name} ${dt.last_name}`,
      label: `${dt.first_name} ${dt.last_name}`
    };
  });

  const onSubmit = async data => {
    let paylaod = { ...data };
    paylaod.storageLocation = paylaod.storageLocation?.value
      ? paylaod.storageLocation.value
      : paylaod.storageLocation;
    paylaod.responsible = paylaod.responsible?.value
      ? paylaod.responsible.value
      : paylaod.responsible;
    paylaod.status = paylaod.status?.value
      ? paylaod.status.value
      : paylaod.status;
    paylaod.date = moment(paylaod.date).format('YYYY-MM-DD');
    postRequest(paylaod);
  };

  const postRequest = async payload => {
    const response = await createCounting(payload);
    if (response.status === 201) {
      handleRouteList();
      toast.success(`Inventory counting created.`, {
        theme: 'colored'
      });
    } else if (response.status === 400) {
      toast.error('Something went wrong', {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  const handleRouteList = () => {
    navigate('/stock/counting');
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                Create Inventory Counting
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="date">
                  <Col xs={4}>
                    <Form.Label>Date</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <DatePickerCustomInput
                      value={moment(dateWatch).format('DD/MM/YYYY')}
                      onChange={date => setValue('date', date)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      placeholder="Date"
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="status">
                  <Col xs={4}>
                    <Form.Label>Status</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <SelectBox
                      options={statusOptions.filter(e => e.value < 2)}
                      onChange={value => setValue('status', value)}
                      name="status"
                      control={control}
                      rules={{ required: 'This field cannot be empty' }}
                      className={errors.status ? 'is-invalid' : ''}
                      placeholder="Status"
                      classNamePrefix="react-select"
                    />
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {errors?.status?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="storageLocation"
                >
                  <Col xs={4}>
                    <Form.Label>Warehouse</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <SelectBox
                      options={warehouseOptions}
                      name="storageLocation"
                      control={control}
                      rules={{ required: 'This field cannot be empty' }}
                      className={errors.storageLocation ? 'is-invalid' : ''}
                      placeholder="Select Warehouse"
                      classNamePrefix="react-select"
                    />
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {errors?.storageLocation?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group as={Row} className="mb-3" controlId="responsible">
                  <Col xs={4}>
                    <Form.Label>Responsible</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <SelectBox
                      options={userOptions}
                      name="responsible"
                      control={control}
                      rules={{ required: 'This field cannot be empty' }}
                      className={errors.responsible ? 'is-invalid' : ''}
                      placeholder="Select Responsible"
                      classNamePrefix="react-select"
                    />
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {errors?.responsible?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group as={Row} className="mb-3" controlId="description">
                  <Col xs={4} lg={2}>
                    <Form.Label className="fs-0">Description</Form.Label>
                  </Col>
                  <Col xs={8} lg={10}>
                    <Form.Control
                      {...register('description')}
                      as="textarea"
                      placeholder="Description"
                      rows={3}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                Start
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </>
  );
};

export default AddCounting;
