import { formatNumber } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

function ODProducts({ data }) {
  return (
    <Card className="mb-3">
      <Card.Body>
        {data.order_items.map(dt => (
          <div
            className="d-flex align-items-center gap-1"
            style={{
              borderBottom: '1px solid #ccc',
              borderRadius: 0,
              padding: '2px 24px',
              marginBottom: 4
            }}
            key={dt.id}
          >
            {dt.product_photo && (
              <img
                src={dt.product_photo?.file}
                width={50}
                height={50}
                className="img-50-contain"
              />
            )}
            <div style={{ width: 500 }}>
              <span
                style={{
                  maxWidth: 390,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  marginRight: 50,
                  fontWeight: 'bold',
                  fontSize: 14,
                  marginBottom: '-8px'
                }}
              >
                {dt.product_name}
              </span>
              <span
                className="d-block"
                style={{
                  fontSize: 12
                }}
              >
                SKU: {dt.sku}
              </span>
            </div>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ width: 100 }}
            >
              <span>₺{formatNumber(dt.product_price)}</span>
              <span>x</span>
              <span>{dt.quantity}</span>
            </div>
            <span className="ml-auto">
              ₺{formatNumber(dt.product_price * dt.quantity)}
            </span>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
}
ODProducts.propTypes = {
  data: PropTypes.object
};

export default ODProducts;
