import LoadingWrapper from 'components/loading/LoadingWrapper';
import React from 'react';
import { Card } from 'react-bootstrap';
import MegaMenuDnd from './modules/MegaMenuDnd';
import AddMainMenu from './modules/AddMainMenu';
import { useQuery } from 'react-query';
import {
  getBrandsMini,
  getMainMenu,
  getProductCollectionsMini
} from 'services/product';
import { Link, useParams } from 'react-router-dom';
import IconButton from 'components/common/IconButton';

function WebMegaMenu() {
  const { mmid } = useParams();
  const { data: brands } = useQuery(['getbrandsmini'], () => getBrandsMini(), {
    keepPreviousData: false,
    staleTime: Infinity
  });
  const { data: collections } = useQuery(
    ['getcollectionsmini'],
    () => getProductCollectionsMini(),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const { data: mainMenuData, refetch } = useQuery(
    ['mainmenu'],
    () => getMainMenu({ header_menu: mmid }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const [editItem, setEditItem] = React.useState(null);

  const handleEdit = item => {
    setEditItem(item);
    setTimeout(() => {
      setEditItem(null);
    }, 1000);
  };

  const props = {
    collections: collections?.data.results,
    brands: brands
  };
  const loading = !brands || !collections || !mainMenuData;
  return (
    <LoadingWrapper loading={loading}>
      <div className="p-2">
        <Card className="mb-3">
          <Card.Header className="d-flex justify-content-between align-item-center">
            <span>Web Mega Menu</span>
            <div>
              <IconButton
                className="border-300 me-2"
                iconClassName="me-1"
                variant="outline-secondary"
                size="sm"
                icon="chevron-left"
                transform="shrink-4"
                as={Link}
                to="/cms/megamenu"
              >
                Go Back
              </IconButton>
              <AddMainMenu
                {...props}
                mainMenuData={mainMenuData}
                editItem={editItem}
                refetch={() => {
                  refetch();
                }}
              />
            </div>
          </Card.Header>
          <Card.Body style={{ minHeight: 500 }}>
            {!loading && (
              <MegaMenuDnd
                data={mainMenuData}
                handleEdit={handleEdit}
                refetch={() => {
                  refetch();
                }}
              />
            )}
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end"></Card.Footer>
        </Card>
      </div>
    </LoadingWrapper>
  );
}

export default WebMegaMenu;
