import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import serviceUrl from '../../../services/serviceUrlList';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { toast } from 'react-toastify';
import useDelete from 'hooks/useDelete';
import { useNavigate } from 'react-router-dom';
import TableHeader from 'components/common/TableHeader';
import { useQuery } from 'react-query';
import { getFaqs } from 'services/content';
import { queryReducer } from 'reducers/queryReducer';

const Faq = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();

  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    site: 1
  });
  const { data, isLoading, refetch } = useQuery(
    ['getFaqs', queryObj],
    () => getFaqs(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.content.faq.detail + selectedRowId
  });

  const handleDeleteItem = slug => {
    setSelectedRowId(slug);
    setConfirmText({
      title: 'Delete Faq',
      description: 'Are you sure? Selected faq will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };

  useEffect(() => {
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    refetch();
    setShowConfirm(false);
    toast.success(`Faq deleted`, {
      theme: 'colored'
    });
    setSelectedRowId(null);
  }, [deleteResponse]);

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'category.name',
      Header: 'Category',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { category, slug } = rowData.row.original;
        return (
          <a href="#" onClick={() => navigate(`/cms/faq-update/${slug}`)}>
            {category?.name}
          </a>
        );
      }
    },
    {
      accessor: 'title',
      Header: 'Title',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'description',
      Header: 'Description',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2 text-truncate'
      },
      Cell: rowData => {
        const { description } = rowData.row.original;
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `${description
                .replace(/<\/?[^>]+(>|$)/g, ' ')
                .slice(0, 80)} ${description.length >= 80 ? '...' : ''}`
            }}
          ></div>
        );
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_active } = rowData.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { slug } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(`/cms/faq-update/${slug}`)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(slug)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleNew = () => {
    navigate('/cms/faq-add');
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Faqs"
                handleRefetch={refetch}
                table
                handleNew={handleNew}
                bulkTable={'Faq'}
                bulkService="content"
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={deleteRequest}
        type={confirmText.type}
      />
    </div>
  );
};

export default Faq;
