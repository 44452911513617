import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';

function TypeForm({
  products,
  blogs,
  brands,
  collections,
  feedState,
  setState,
  section,
  limit,
  onInputChange,
  onInputBlur
}) {
  const initialForm = {
    slug_type: '',
    slug: ''
  };
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
    resetField
  } = useForm({ defaultValues: initialForm });

  const slug_type = useWatch({ control, name: 'slug_type' });

  const getImage = (type, slug) => {
    switch (type) {
      case 'product':
        return products.find(dt => dt.slug === slug).files[0].file;
      case 'blog':
        return blogs.find(dt => dt.slug === slug).image;
      case 'brand':
        return brands.find(dt => dt.slug === slug).image;
      case 'collection':
        collections.find(dt => dt.slug === slug).image;
        break;
      default:
        break;
    }
  };

  const onSubmit = data => {
    // if data.object_id is in state[section] return
    if (feedState[section].find(dt => dt.object_id === data.slug.id)) {
      toast.error('This item already exists!', {
        theme: 'colored'
      });
      return;
    }
    if (feedState[section].length >= limit) {
      toast.error(`You can add max ${limit} items!`, {
        theme: 'colored'
      });
      return;
    }
    const image =
      data.image.length > 0
        ? Object.assign(data.image[0], {
            preview: URL.createObjectURL(data.image[0])
          }).preview
        : getImage(data.slug_type.value, data.slug.value);

    const image_file = data.image.length > 0 ? data.image[0] : null;
    const payload = {
      image: image,
      image_file: image_file,
      slug_type: data.slug_type.value,
      slug: data.slug.value,
      name: data.slug.label,
      object_id: data.slug.id,
      display_name: data.display_name
    };
    setState(prev => ({
      ...prev,
      [section]: [...prev[section], payload]
    }));
    reset();
  };
  const slugOptions = slug_type
    ? slug_type.value === 'product'
      ? products.map(dt => ({ value: dt.slug, label: dt.name, id: dt.id }))
      : slug_type.value === 'blog'
      ? blogs.map(dt => ({ value: dt.slug, label: dt.name, id: dt.id }))
      : slug_type.value === 'brand'
      ? brands.map(dt => ({ value: dt.slug, label: dt.name, id: dt.id }))
      : slug_type.value === 'collection'
      ? collections.map(dt => ({ value: dt.slug, label: dt.name, id: dt.id }))
      : []
    : [];

  const slugTypeOptions =
    section === 'popular_section' || section === 'recent_section'
      ? [
          { value: 'product', label: 'Product' },
          { value: 'blog', label: 'Blog' }
        ]
      : [
          { value: 'product', label: 'Product' },
          { value: 'blog', label: 'Blog' },
          { value: 'collection', label: 'Collection' },
          { value: 'brand', label: 'Brand' }
        ];

  const customStylesSlugType = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.slug_type
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };
  const customStylesSlug = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? '#80bdff'
        : errors.slug
        ? '#dc3545 !important'
        : provided.borderColor
    })
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-8">
          <Form.Group className="mb-3" controlId="slug_type">
            <Controller
              name="slug_type"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  options={slugTypeOptions}
                  onChange={e => {
                    onChange(e);
                    resetField('slug');
                  }}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  placeholder="Slug Type"
                  isSearchable={false} // Arama çubuğunu devre dışı bırakır
                  classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                  components={{
                    IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                  }}
                  className={errors.slug_type ? 'is-invalid' : ''}
                  styles={{ ...customStylesSlugType }}
                />
              )}
              rules={{ required: 'This field cannot be empty!' }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.slug_type?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="slug">
            <Controller
              name="slug"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  isDisabled={!slug_type}
                  options={slugOptions}
                  onChange={onChange}
                  onInputChange={val => {
                    onInputChange(val, slug_type.value);
                  }}
                  onBlur={event => {
                    onBlur(event);
                    onInputBlur(slug_type.value);
                  }}
                  value={value}
                  ref={ref}
                  placeholder="Slug"
                  isSearchable={true} // Arama çubuğunu devre dışı bırakır
                  classNamePrefix="react-select" // Özelleştirilecek CSS sınıf adı
                  components={{
                    IndicatorSeparator: () => null // Göstergeler arası ayırıcıyı gizler
                  }}
                  className={errors.slug ? 'is-invalid' : ''}
                  styles={{ ...customStylesSlug }}
                />
              )}
              rules={{ required: 'This field cannot be empty!' }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.slug?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="display_name">
            <Form.Control
              {...register('display_name', {
                required: 'This field cannot be empty!'
              })}
              isInvalid={errors.display_name}
              type="text"
              name="display_name"
              placeholder="Display Name"
            />
            <Form.Control.Feedback type="invalid">
              {errors.display_name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="image">
            <Form.Control
              {...register('image')}
              isInvalid={errors.image}
              type="file"
              name="image"
              accept="image/*"
            />
            <Form.Control.Feedback type="invalid">
              {errors.image?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-4">
          <Button type="submit" className="h-100" variant="falcon-default">
            Add
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default TypeForm;

TypeForm.propTypes = {
  products: PropTypes.array,
  blogs: PropTypes.array,
  brands: PropTypes.array,
  collections: PropTypes.array,
  setState: PropTypes.func,
  section: PropTypes.string,
  feedState: PropTypes.object,
  limit: PropTypes.number,
  onInputChange: PropTypes.func,
  onInputBlur: PropTypes.func
};
