import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { getSize } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Image as BImage, Dropdown, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

const MultipleImageUpload = props => {
  const {
    children,
    onClick,
    page,
    device,
    altText,
    setRemovedFiles,
    imageSize
  } = props;
  const [files, setFiles] = useState([]);
  const [defaultImgs, setDefaultImgs] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    getFilesFromEvent: async event => {
      const fl = event.target.files || event.dataTransfer.files;
      const promises = [];
      for (let index = 0; index < fl.length; index++) {
        const file = fl[index];
        const promise = new Promise(resolve => {
          const image = new Image();
          let url;
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }
      return await Promise.all(promises);
    },
    validator: file => {
      // You can access width/height properties
      if (file.width < imageSize[0] && file.height < imageSize[1]) {
        return {
          title: 'Small Size',
          message: `Image size must be greater than ${imageSize[0]}X${imageSize[1]}px`
        };
      }
      return null;
    },
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toast.error(fileRejections[0].errors[0].message, {
          theme: 'colored'
        });
        return;
      }
      const acc_files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const fileList = [...files];
      const id =
        page !== undefined
          ? `${page.value}_${device.value}`
          : `${device.value}`;
      const label =
        page !== undefined
          ? `${page.label}-${device.label}`
          : `${device.label}`;
      const fileObj = {
        id: id,
        label: label,
        file: acc_files[0],
        alt_text: '',
        size: acc_files[0].size
      };
      fileList.push(fileObj);
      setFiles(fileList);
    }
  });

  useEffect(() => {
    setDefaultImgs(props.defaultImg);
  }, [props.defaultImg]);

  useEffect(() => {
    props.file(files);
  }, [files]);

  const handleRemove = fileObj => {
    if (fileObj.file) {
      setFiles(files.filter(obj => obj !== fileObj));
    } else {
      setDefaultImgs(defaultImgs.filter(obj => obj !== fileObj));
      setRemovedFiles(prev => [...prev, fileObj.key]);
      props.file([]);
    }
  };

  return (
    <>
      {children}
      <Row className="px-3">
        <div {...getRootProps({ className: 'dropzone-area py-6 col-lg-3' })}>
          <input {...getInputProps()} onClick={onClick} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Drop your images here</p>
          </Flex>
        </div>
        <div className="mt-3 col-lg-9">
          <Flex wrap="wrap">
            {files.map((fileObj, index) => (
              <Flex key={index} direction="column">
                <span className="fs--1">{fileObj.label}</span>
                <Flex
                  alignItems="center"
                  className="py-1 h-100 border-bottom btn-reveal-trigger"
                >
                  <BImage
                    rounded
                    src={fileObj.file.preview}
                    alt={altText}
                    style={{ maxHeight: 130, maxWidth: '50%' }}
                  />
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="ms-3 flex-1"
                  >
                    <div>
                      <h6>{fileObj.file.path}</h6>
                      <Flex className="position-relative" alignItems="center">
                        <p className="mb-0 fs--1 text-400 line-height-1">
                          <strong>{getSize(fileObj.file.size)}</strong>
                        </p>
                      </Flex>
                      {fileObj.alt_text !== '' && (
                        <Flex className="position-relative" alignItems="center">
                          <p className="mb-0 fs--1 text-400 line-height-1">
                            <strong>{`Alt Text: ${fileObj.alt_text}`}</strong>
                          </p>
                        </Flex>
                      )}
                    </div>
                  </Flex>
                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleRemove(fileObj)}
                      >
                        Remove
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </Flex>
              </Flex>
            ))}
            {defaultImgs.length > 0 &&
              defaultImgs.map((fileObj, index) => (
                <Flex key={index} direction="column">
                  <span className="fs--1">{fileObj.label}</span>
                  <Flex
                    alignItems="center"
                    className="py-1 h-100 border-bottom btn-reveal-trigger"
                  >
                    <BImage
                      rounded
                      src={fileObj.image}
                      alt={altText}
                      style={{ maxHeight: 130, maxWidth: '50%' }}
                    />
                    <Flex
                      justifyContent="between"
                      alignItems="center"
                      className="ms-3 flex-1"
                    >
                      <div>
                        <h6>{fileObj.image?.split('/').slice(-1)[0]}</h6>
                      </div>
                    </Flex>
                    <CardDropdown>
                      <div className="py-2">
                        <Dropdown.Item
                          className="text-danger"
                          onClick={() => handleRemove(fileObj)}
                        >
                          Remove
                        </Dropdown.Item>
                      </div>
                    </CardDropdown>
                  </Flex>
                </Flex>
              ))}
          </Flex>
        </div>
      </Row>
    </>
  );
};
MultipleImageUpload.propTypes = {
  file: PropTypes.func,
  altText: PropTypes.string,
  defaultImg: PropTypes.array,
  onClick: PropTypes.func,
  setRemovedFiles: PropTypes.func,
  page: PropTypes.object,
  device: PropTypes.object,
  imageSize: PropTypes.array,
  children: PropTypes.any
};
export default MultipleImageUpload;
