import React, { useContext } from 'react';
import { useState } from 'react';
import Button from '../../../node_modules/react-bootstrap/esm/Button';
import Flex from './Flex';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';

const CharFilter = props => {
  const { selected } = props;
  const {
    config: { isDark }
  } = useContext(AppContext);
  const chars = [
    '#',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ];
  const [active, setActive] = useState('');
  const btnStyle = {
    width: 25,
    padding: 0,
    background: isDark ? '' : '#d8e2ef',
    margin: 2,
    border: isDark ? '1px solid #344050' : '1px solid #ccc'
  };
  const activeStyle = {
    ...btnStyle,
    background: '#2c7be5',
    color: '#fff',
    border: '1px solid #2362b7'
  };
  const handleFilter = char => {
    if (char === active) {
      setActive('');
      selected('');
    } else {
      setActive(char);
      selected(char);
    }
  };

  return (
    <Flex>
      {chars.map(char => {
        return (
          <Button
            key={char}
            variant="ghost"
            style={char === active ? activeStyle : btnStyle}
            value={char}
            onClick={e => handleFilter(e.target.value)}
          >
            {char}
          </Button>
        );
      })}
    </Flex>
  );
};
CharFilter.propTypes = {
  selected: PropTypes.func
};
export default CharFilter;
