import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import moment from 'moment';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const CheckoutDetail = () => {
  const navigate = useNavigate();
  const handleRouteList = () => {
    navigate('/order/abandoned');
  };
  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="bg-300 py-2">
          <Col xs="auto" className="align-self-center">
            <IconButton
              onClick={handleRouteList}
              variant="default"
              size="sm"
              icon="arrow-left"
              transform="shrink-3"
              className="me-2 text-600 btn-link border-500"
            ></IconButton>
          </Col>
          <Col xs="auto">
            <h5 className="mb-0">#33871185182944</h5>
            <h6 className="mb-0">{moment().format('MMM DD, YYYY HH:mm')}</h6>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="py-0">
        <Flex justifyContent="between" className="mt-3">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            Checkout Details
          </h5>
          <h6 className="mb-0 text-nowrap py-2 py-xl-0">
            <span className="text-600">From</span> Online Store
          </h6>
        </Flex>
        <Flex
          className="mb-5 fs--1"
          justifyContent="between"
          alignItems="center"
        >
          <Flex className="gap-2">
            <img src="https://dummyimage.com/40x40/000/fff" alt="dummy" />
            <Flex direction="column">
              <Link to="#!">Hardline Nutrition Progainer Çilekli 1406 g</Link>
              <span className="text-500">SKU: 8697448351485</span>
            </Flex>
          </Flex>
          <span>₺419.00 × 1</span>
          <span>₺419.00</span>
        </Flex>
        <Flex justifyContent="between" className="mb-1 fs--1">
          <Flex className="row flex-fill">
            <span className="col-3 fw-semi-bold">Subtotal</span>
            <span className="col-9">1 Item</span>
          </Flex>
          ₺419.00
        </Flex>
        <Flex justifyContent="between" className="mb-1 fs--1">
          <Flex className="row flex-fill">
            <span className="col-3 fw-semi-bold">Shipping</span>
            <span className="col-9">Kargo Bedava (0.12 kg)</span>
          </Flex>
          ₺0.00
        </Flex>
        <Flex justifyContent="between" className="mb-1 fs--1">
          <Flex className="row flex-fill">
            <span className="col-3 fw-semi-bold">Estimated tax</span>
            <span className="col-9">KDV 1% (Included)</span>
          </Flex>
          ₺4.15
        </Flex>
        <Flex justifyContent="between" className="mb-1 fs--1">
          <span className="fw-semi-bold">Total</span>
          ₺419.00
        </Flex>
        <Divider />
        <Flex justifyContent="between" className="mb-1 fs--1">
          <span className="fw-semi-bold">To be paid by customer</span>
          ₺419.00
        </Flex>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

export default CheckoutDetail;
