import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const AuthContext = createContext({ user: {} });

export const LoaderContext = createContext({ loading: false });

export default AppContext;
