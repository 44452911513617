import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import serviceUrl from 'services/serviceUrlList';
import useFetch from 'hooks/useFetch';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';

const CardTheme = props => {
  const { id, formData, setFormData } = props;
  const [selecetedTheme, setSelecetedTheme] = useState(null);
  const [themeList, setThemeList] = useState([]);
  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);
  const { response } = useFetch({
    url: serviceUrl.product.giftcardtheme.list + '?is_active=true'
  });
  const {
    config: { isDark }
  } = useContext(AppContext);

  useEffect(() => {
    // response formData'dan önce geliyor. O yüzden dependancy olarak formData eklendi.
    console.log('response: ', response);
    if (!response || !formData || selecetedTheme) return;
    setThemeList(response.data.results);
    if (id) {
      const temp_selected = response.data.results.filter(
        theme => theme.id === formData.gift_card_theme
      )[0];
      setSelecetedTheme(temp_selected);
    } else {
      if (response.data.results.length === 0) return;
      handleChange(response.data.results[0]);
    }
  }, [response, formData]);

  const closeMoreModal = () => {
    setIsMoreModalOpen(false);
  };

  const cancelMoreModal = () => {
    setSelecetedTheme(themeList[0] ?? null);
    setIsMoreModalOpen(false);
  };

  const handleChange = theme => {
    setSelecetedTheme(theme);
    setFormData({ ...formData, gift_card_theme: theme?.id });
  };

  const selecetedThemeOption = (
    <img
      src={selecetedTheme?.image}
      alt={selecetedTheme?.image_alt_text}
      className="rounded-1 border border-200 me-2"
      width="100%"
    />
  );
  const themeOptions = (
    <>
      <Flex alignItems={'start'} wrap={'wrap'} className="h-100">
        {themeList.map((theme, i) => {
          if (i < 5) {
            return (
              <img
                key={theme.id}
                className="rounded-1 me-2"
                src={theme.image}
                width="80"
                height="80"
                style={{
                  objectFit: 'cover',
                  cursor: 'pointer',
                  border:
                    selecetedTheme?.id === theme.id
                      ? '2px solid blue'
                      : '2px solid transparent'
                }}
                alt={theme.image_alt_text}
                onClick={() => handleChange(theme)}
              />
            );
          } else if (i === 5) {
            return (
              <div
                key={theme.id}
                style={{
                  height: 80,
                  width: 80,
                  cursor: 'pointer',
                  color: 'blue',
                  textAlign: 'center'
                }}
                className="d-flex align-items-center rounded-1 border border-200 me-2"
                onClick={() => setIsMoreModalOpen(true)}
              >
                Show More
              </div>
            );
          }
        })}
      </Flex>
      <Modal
        show={isMoreModalOpen}
        onHide={closeMoreModal}
        contentClassName="border"
      >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Add Gift Card Theme</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Flex alignItems={'center'} className="h-100">
            {themeList.map(theme => {
              return (
                <img
                  key={theme.id}
                  className="rounded-1 me-2"
                  src={theme.image}
                  width="80"
                  height="80"
                  style={{
                    objectFit: 'cover',
                    cursor: 'pointer',
                    border:
                      selecetedTheme?.id === theme.id
                        ? '2px solid blue'
                        : '2px solid transparent'
                  }}
                  alt={theme.image_alt_text}
                  onClick={() => handleChange(theme)}
                />
              );
            })}
          </Flex>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="danger"
            className="px-3 mx-2"
            onClick={cancelMoreModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={closeMoreModal}
            className="px-5 mx-0"
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Card Theme</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={6} lg={3}>
            {selecetedThemeOption}
          </Col>
          <Col xs={6} lg={8}>
            {themeOptions}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

CardTheme.propTypes = {
  id: PropTypes.string,
  formData: PropTypes.object,
  setFormData: PropTypes.func
};

export default CardTheme;
