import PropTypes from 'prop-types';
import CardDropdown from 'components/common/CardDropdown';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { MdDragIndicator } from 'react-icons/md';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { deleteMenuItems, updateMenuItem } from 'services/product';
import { toast } from 'react-toastify';
function MenuChildDraggableItem({
  provided,
  snapshot,
  item,
  handleEdit,
  refetch,
  menuMiniRefetch,
  draggableData
}) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  const handleDeleteItem = async () => {
    const response = await deleteMenuItems(deleteItem.id);
    console.log('RESPONSE: ', response);
    if (deleteItem.id !== draggableData[draggableData.length - 1].id) {
      const filteredData = draggableData.filter(dt => dt.id !== deleteItem.id);
      filteredData.forEach(async (item, index) => {
        await updateMenuItem(item.id, { order: index + 1 });
      });
      setTimeout(async () => {
        refetch();
        menuMiniRefetch();
        toast.success(`Menu item successfully deleted!`, {
          theme: 'colored'
        });
      }, 1000);
    } else {
      refetch();
      menuMiniRefetch();
      toast.success(`Menu item successfully deleted!`, {
        theme: 'colored'
      });
    }
  };
  const handleDelete = item => {
    setShowDeleteConfirm(true);
    setDeleteItem(item);
  };
  return (
    <>
      <div ref={provided.innerRef} {...provided.draggableProps}>
        <div
          className="d-flex gap-1"
          style={{
            backgroundColor: snapshot.isDragging ? 'lightblue' : undefined,
            padding: 8,
            border: '1px solid #ccc',
            borderRadius: 4,
            margin: 2
          }}
        >
          <div {...provided.dragHandleProps}>
            <MdDragIndicator />
          </div>
          {/* <div>{item.order}</div> */}
          <div>{item.display_name}</div>
          <div style={{ marginLeft: 'auto' }}>
            <CardDropdown iconClassName="fs--1">
              <div className="py-2">
                <Dropdown.Item
                  onClick={() => {
                    handleEdit(item);
                  }}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(item)}>
                  Delete
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </div>
        </div>
      </div>
      <ConfirmationModal
        showConfirm={showDeleteConfirm}
        setShowConfirm={setShowDeleteConfirm}
        title="Delete Menu Item"
        description="Are you sure you want to delete this menu item?"
        request={handleDeleteItem}
        type="delete"
      />
    </>
  );
}

export default MenuChildDraggableItem;

MenuChildDraggableItem.propTypes = {
  provided: PropTypes.object,
  snapshot: PropTypes.object,
  item: PropTypes.object,
  handleEdit: PropTypes.func,
  refetch: PropTypes.func,
  menuMiniRefetch: PropTypes.func,
  draggableData: PropTypes.array
};
