import React, { useContext, useEffect, useReducer, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import UserTableFilter from './module/UserTableFilter';
import serviceUrl from 'services/serviceUrlList';
import moment from 'moment';
import TableHeader from 'components/common/TableHeader';
import AppContext from 'context/Context';
import usePost from 'hooks/usePost';
import useUpdate from 'hooks/useUpdate';
import useDelete from 'hooks/useDelete';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import PhoneInput from 'components/input/PhoneInput';
import useAuthentication from 'hooks/useAuthentication';
import SoftBadge from 'components/common/SoftBadge';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getUsers } from 'services/user';
import { queryReducer } from 'reducers/queryReducer';
import LoadingWrapper from 'components/loading/LoadingWrapper';

const UserList = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    is_active: true,
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    phone: '',
    department: '',
    isFit1001: true,
    is_staff: true,
    is_superuser: true,
    password: ''
  });
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    is_superuser: true,
    is_staff: true
  });
  const [passToggle, setPassToggle] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const { data, isLoading, refetch } = useQuery(
    ['getUsers', queryObj],
    () => getUsers(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);
  const [postResponse, postRequest] = usePost({
    url: serviceUrl.user.user.list,
    payload: formData
  });
  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.user.user.detail + selectedRowId,
    payload: formData
  });
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.user.user.detail + selectedRowId
  });
  const [passResetResponse, passResetRequest] = useAuthentication({
    url: serviceUrl.authentication.forgot_password,
    payload: { email: formData.email }
  });
  const {
    config: { isDark }
  } = useContext(AppContext);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.data.status === 201) {
      closeCreateModal();
      refetch();
      toast.success(`User created`, {
        theme: 'colored'
      });
    } else if (postResponse.data.errors.email) {
      toast.error(postResponse.data.errors.email[0], {
        theme: 'colored'
      });
    }
  }, [postResponse]);
  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.error) return;
    if (updateResponse.error === null) {
      closeCreateModal();
      refetch();
      toast.success(`User updated`, {
        theme: 'colored'
      });
    }
  }, [updateResponse]);
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    closeCreateModal();
    refetch();
    toast.success(`User deleted`, {
      theme: 'colored'
    });
    setSelectedRowId(null);
  }, [deleteResponse]);

  useEffect(() => {
    if (passResetResponse.data === null && passResetResponse.error === null)
      return;
    if (passResetResponse.data?.detail) {
      toast.success(`Password reset mail sent.`, {
        theme: 'colored'
      });
      setSelectedRowId(null);
      clearFormData();
    } else {
      toast.error(`Something went wrong.`, {
        theme: 'colored'
      });
    }
  }, [passResetResponse]);

  useEffect(() => {
    if (passToggle) {
      passResetRequest();
      setPassToggle(false);
      clearFormData();
    }
  }, [formData.email]);

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    clearFormData();
    setSelectedRowId(null);
  };
  const clearFormData = () => {
    setFormData({
      is_active: true,
      first_name: '',
      last_name: '',
      title: '',
      email: '',
      phone: '',
      department: '',
      isFit1001: true,
      is_staff: true,
      is_superuser: true,
      password: ''
    });
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditItem = dt => {
    setSelectedRowId(dt.id);
    setFormData({ ...dt });
    setValue('email', dt.email);
    openCreateModal(true);
  };

  const handleDeleteItem = id => {
    setSelectedRowId(id);
    setShowConfirm(true);
  };

  const onSubmit = () => {
    if (selectedRowId === null) {
      postRequest();
    } else {
      // Update Request !
      updateRequest();
    }
  };

  const handleModalDeleteButton = () => {
    if (selectedRowId === null) {
      closeCreateModal();
    } else {
      handleDeleteItem(selectedRowId);
    }
  };
  const handleResetPassword = email => {
    setFormData({ ...formData, email: email });
    setPassToggle(true);
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'name',
      Header: 'Full Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { first_name, last_name, middle_name } = rowData.row.original;
        return (
          <a
            href="#"
            onClick={() => handleEditItem(rowData.row.original)}
          >{`${first_name} ${middle_name} ${last_name}`}</a>
        );
      }
    },
    {
      accessor: 'title',
      Header: 'Title',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'email',
      Header: 'E-mail',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'phone',
      Header: 'Gsm No',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      }
    },
    {
      accessor: 'groups',
      Header: 'Groups',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      }
    },
    {
      accessor: 'roles',
      Header: 'Roles',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      }
    },
    {
      accessor: 'date_joined',
      Header: 'Joined',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { date_joined } = rowData.row.original;
        return <span>{moment(date_joined).format('DD/MM/YYYY')}</span>;
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_active } = rowData.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      headerProps: { className: 'pe-1 text-end' },
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id, email } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handleEditItem(dt.row.original)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleResetPassword(email)}>
                Reset Password
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Users"
                handleNew={openCreateModal}
                isBulkAction={false}
                table
              />
              <UserTableFilter />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                rowClassName="align-middle"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCreateModalOpen}
        onHide={closeCreateModal}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card">
            <Form.Group
              className="mb-3"
              controlId="is_active"
              style={{
                width: 120,
                position: 'absolute',
                right: 20,
                top: 10
              }}
            >
              <Form.Select
                size="sm"
                className="mb-3"
                name="is_active"
                required
                value={formData.is_active}
                onChange={handleChange}
              >
                <option value={Boolean(true)}>Active</option>
                <option value={Boolean(false)}>Passive</option>
              </Form.Select>
            </Form.Group>
            <Row className="mt-3">
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="first_name">
                  <Form.Label className="fs-0">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="last_name">
                  <Form.Label className="fs-0">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label className="fs-0">Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="department">
                  <Form.Label className="fs-0">Department</Form.Label>
                  <Form.Control
                    type="text"
                    name="department"
                    placeholder="Department"
                    value={formData.department}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label className="fs-0">e-Mail</Form.Label>
                  <Form.Control
                    {...register('email', {
                      required: 'This field cannot be empty!',
                      pattern: {
                        value: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
                        message: 'Entered value does not match email format'
                      }
                    })}
                    isInvalid={errors.email}
                    type="text"
                    name="email"
                    placeholder="e-Mail address"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label className="fs-0">Phone</Form.Label>
                  <PhoneInput
                    name="phone"
                    onChange={handleChange}
                    value={formData.phone}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={handleModalDeleteButton}
            >
              {selectedRowId === null ? 'Cancel' : 'Delete'}
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              {selectedRowId === null ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title="Delete User"
        description="Are you sure the user you selected will be deleted?"
        request={deleteRequest}
        type={'delete'}
      />
    </div>
  );
};

export default UserList;
