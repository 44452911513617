import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Flex from 'components/common/Flex';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import Select from 'react-select';

const OrderTableFilter = ({
  handleSearch,
  handleFilter,
  handleExport,
  isLoadingExport
}) => {
  const [range, setRange] = useState([]);
  const statusOptions = [
    { value: '1', label: 'New' },
    { value: '3', label: 'In Progress' },
    { value: '2', label: 'Shipped' },
    { value: '4', label: 'Delivered' },
    { value: '5', label: 'Cancelled' },
    { value: '6', label: 'Returned' },
    { value: '7', label: 'Declined' },
    { value: '8', label: 'Closed' }
  ];

  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      // display: 'flex',
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset'
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  return (
    <Row className="flex-between-center">
      <Col xs={12} className="mb-4">
        <Nav variant="tabs" defaultActiveKey="open">
          <Nav.Item>
            <Nav.Link
              eventKey="open"
              onClick={() => handleFilter('status__in', '1,3')}
            >
              Open
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="closed"
              onClick={() => handleFilter('status__in', '5,7,8')}
            >
              Closed
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="all"
              onClick={() => handleFilter('status__in', null)}
            >
              All
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col xs="auto" sm={6} lg={4}>
        <AdvanceTableSearchBox
          table
          size="sm"
          placeholder="Search Orders"
          setGlobalFilter={handleSearch}
        />
      </Col>
      <Col xs="auto" lg={8}>
        <Flex justifyContent={'start'}>
          <div className="me-2">
            <DateRangePicker
              onChange={val => {
                if (!val) {
                  handleFilter('created_at__gte', null);
                  handleFilter('created_at__lte', null);
                } else {
                  handleFilter('created_at__gte', moment(val[0]).format());
                  handleFilter('created_at__lte', moment(val[1]).format());
                }
                setRange(val);
              }}
              value={range}
              format="dd/MM/yyyy"
            />
          </div>
          <Col xs="auto" sm={6} lg={3}>
            <Select
              className="me-2"
              isClearable
              styles={selectStyles}
              options={statusOptions}
              placeholder="Status"
              onChange={val => {
                handleFilter('status', val ? val.value : null);
              }}
            />
          </Col>
          <Col xs="auto" sm={6} lg={3}>
            <LoadingWrapper loading={isLoadingExport}>
              <Button
                variant="falcon-default"
                className="me-2 text-600 btn-link"
                size="sm"
                onClick={e => handleExport(e.target.name)}
              >
                Export
              </Button>
            </LoadingWrapper>
          </Col>
        </Flex>
      </Col>
    </Row>
  );
};

OrderTableFilter.propTypes = {
  eventKey: PropTypes.func,
  handleSearch: PropTypes.func,
  handleExport: PropTypes.func,
  isLoadingExport: PropTypes.bool,
  handleFilter: PropTypes.func
};

export default OrderTableFilter;
