import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const UserTableFilter = () => {
  return (
    <Row className="flex-between-center mt-2">
      <Col xs="auto" sm={6} lg={5}>
        <AdvanceTableSearchBox table size="sm" placeholder="Filter Users" />
      </Col>
    </Row>
  );
};

export default UserTableFilter;
