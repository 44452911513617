import React, { useState } from 'react';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import AddOrderForm from './AddOrderForm';

const AddOrder = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  return (
    <>
      <AddOrderForm />
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={() => console.log('REQUEST')}
      />
    </>
  );
};

export default AddOrder;
