import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row, Card, Nav } from 'react-bootstrap';
import InventoryList from './tabs/InventoryList';
import LotList from './tabs/LotList';
import IconButton from 'components/common/IconButton';

const InventoryCard = () => {
  const [newClickCount, setNewClickCount] = useState(0);
  const [activeTab, setActiveTab] = useState('inventoryList');
  const tabs = [
    {
      name: 'Inventory List',
      eventKey: 'inventoryList'
    },
    {
      name: 'Lot List',
      eventKey: 'lotList'
    }
  ];
  const tabChange = tab => {
    setActiveTab(tab);
    setNewClickCount(0);
  };
  const handleNew = () => {
    setNewClickCount(newClickCount + 1);
  };
  const showTabs = tabs?.map(tab => {
    return (
      <Nav.Item key={tab.name}>
        <Nav.Link eventKey={tab.eventKey}>{tab.name}</Nav.Link>
      </Nav.Item>
    );
  });

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Inventory</h5>
          </Col>
          <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            <div id="orders-actions">
              <IconButton
                onClick={handleNew}
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Nav
          variant="tabs"
          defaultActiveKey={tabs[0].eventKey}
          onSelect={tabChange}
        >
          {showTabs}
        </Nav>
        <div>
          {activeTab === 'inventoryList' ? (
            <InventoryList newClickCount={newClickCount} />
          ) : activeTab === 'lotList' ? (
            <LotList newClickCount={newClickCount} />
          ) : null}
        </div>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

InventoryCard.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.array,
  tabChange: PropTypes.func,
  defaultActiveKey: PropTypes.string,
  tabContent: PropTypes.element,
  handleNew: PropTypes.func
};

export default InventoryCard;
