import FalconCloseButton from 'components/common/FalconCloseButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { getAppliesToList } from 'services/product';
import { getDynamicUsers } from 'services/user';
import ModalSelectItemList from './ModalSelectItemList';

const ModalSelectItem = props => {
  const {
    selectedItems,
    setSelectedItems,
    searchValue,
    setSearchValue,
    showModal,
    closeModal,
    itemType
  } = props;
  const [currentSelectedProducts, setCurrentSelectedProducts] = useState();
  const { data, isLoading } = useQuery(
    ['discount', searchValue, itemType],
    () => {
      return itemType === 'collection' ||
        itemType === 'product' ||
        itemType === 'productCategory'
        ? getAppliesToList({ api: itemType, search: searchValue })
        : getDynamicUsers({ api: itemType, search: searchValue });
    },
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (showModal) {
      setCurrentSelectedProducts(selectedItems);
    }
  }, [showModal]);

  const handleCloseModal = () => {
    closeModal();
    setSelectedItems(currentSelectedProducts);
  };
  const handleAdd = () => {
    closeModal();
  };

  const handleSearchProducts = event => {
    setSearchValue(event.target.value);
  };

  const handleSelectItem = (event, dt) => {
    const new_arr = [...selectedItems];
    if (event.target.checked) {
      new_arr.push(dt);
      setSelectedItems(new_arr);
    } else {
      const filtered_arr = new_arr.filter(item => dt.id !== item.id);
      setSelectedItems(filtered_arr);
    }
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          Select{' '}
          {itemType === 'productCategory'
            ? 'Category'
            : itemType.charAt(0).toUpperCase() + itemType.slice(1)}
        </Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <InputGroup>
              <InputGroup.Text>
                <BsSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                autoFocus
                name="search-products"
                placeholder={`Search ${itemType
                  .charAt(0)
                  .toUpperCase()}${itemType.slice(1)}`}
                value={searchValue}
                onChange={handleSearchProducts}
              />
            </InputGroup>
          </Col>
        </Row>
        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Row>
              <Col xs={12}>
                <div
                  className="mt-3"
                  style={{
                    height: 300,
                    overflowY: 'auto'
                  }}
                >
                  {data?.data.results?.map((dt, i) => {
                    dt.image = 'https://dummyimage.com/600x400/000/fff';
                    return (
                      <ModalSelectItemList
                        handleSelect={e => handleSelectItem(e, dt)}
                        key={i}
                        itemType={itemType}
                        isSelected={selectedItems.some(d => d.id === dt.id)}
                        {...dt}
                      />
                    );
                  })}
                </div>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          disabled
          style={{ position: 'absolute', left: 12 }}
        >
          {selectedItems.length} Selected
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="success" onClick={handleAdd}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalSelectItem.propTypes = {
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  searchValue: PropTypes.string,
  itemType: PropTypes.string,
  setSearchValue: PropTypes.func,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func
};

export default ModalSelectItem;
