import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { default as PhoneInput_ } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AppContext from 'context/Context';

const PhoneInput = props => {
  const { name, onChange, value } = props;
  const {
    config: { isDark }
  } = useContext(AppContext);
  const handleChange = phone => {
    onChange({ target: { name: name, value: phone } });
  };

  return (
    <PhoneInput_
      placeholder="+90 (123) 456 78 90"
      country={'tr'}
      value={value}
      masks={{ tr: '(...) ... .. ..' }}
      onChange={handleChange}
      inputClass={isDark ? 'dark-phone-input' : undefined}
      buttonClass={isDark ? 'dark-phone-input-flag' : undefined}
    />
  );
};

PhoneInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default PhoneInput;
