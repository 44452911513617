import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Image,
  Modal,
  Nav,
  NavDropdown,
  Row,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { queryReducer } from 'reducers/queryReducer';
import {
  getDeclinedReasons,
  getReturn,
  updateReturnOrder
} from 'services/order';
import ReturnTableFilter from './module/ReturnTableFilter';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { toast } from 'react-toastify';

const ReturnList = () => {
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    is_active: true
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: '',
    request: () => {}
  });

  const [selectedRow, setSelectedRow] = useState({});

  const { data, isLoading, refetch } = useQuery(
    ['getReturns', queryObj],
    () => getReturn(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const { data: feedbackData } = useQuery(
    ['declined-reasons'],
    () => getDeclinedReasons(),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const handleSubmit = () => {
    // Her zaman yalnızca 1 adet selectedRow.item olacak. o sebeple 0. indexi alıyorum.

    const action = selectedRow.items[0].action;
    const feedback = selectedRow.items[0].feedback;
    if (!action) {
      toast.error(`You must select an action!`, {
        theme: 'colored'
      });
      return;
    } else if (action === 4 && !feedback) {
      // if action is decline, feedback is required
      toast.error(`You must select a feedback!`, {
        theme: 'colored'
      });
      return;
    }

    setShowConfirm(true);
    setConfirmText({
      title: 'Submitting Return Request',
      description: 'Are you sure you want to submit this return request?',
      type: 'delete',
      request: async () => {
        // replace = 2, refund = 3, decline = 4
        if (action === 3) {
          // refund
          const response = await updateReturnOrder(selectedRow.id, {
            returnStatus: 3,
            isProcessed: true
          });
          if (response.status === 200) {
            toast.success(`Return request successfully refunded!`, {
              theme: 'colored'
            });
            refetch();
            setIsModalOpen(false);
          } else {
            toast.error(`An error occurred!`, {
              theme: 'colored'
            });
          }
        }
      },
      buttonText: 'Ok',
      btnSecondary: 'Cancel'
    });
  };

  const closeModal = () => {
    setShowConfirm(true);
    setConfirmText({
      title: 'Closing Return Request',
      description: 'Are you sure you want to close this return request?',
      type: 'delete',
      request: () => {
        setIsModalOpen(false);
      },
      buttonText: 'Ok',
      btnSecondary: 'Cancel'
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const selectStyles = {
    indicatorSeparator: () => ({ padding: 0 }),
    clearIndicator: styles => ({ ...styles, padding: 0 }),
    valueContainer: styles => ({
      ...styles,
      // display: 'flex',
      padding: '0 4px'
    }),
    dropdownIndicator: () => ({ padding: '0 5px' }),
    control: baseStyles => ({
      ...baseStyles,
      fontSize: '.875rem',
      minHeight: 'unset',
      width: 200
    }),
    option: styles => {
      return {
        ...styles,
        padding: '0 10px',
        fontSize: '.875rem'
      };
    }
  };

  const feedbackOptions =
    feedbackData?.results?.map(dt => ({
      value: dt.id,
      label: dt.name
    })) ?? [];

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );

  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const handleFilter = (key, value) => {
    if (value === null) {
      // eslint-disable-next-line no-unused-vars
      const { [key]: omit, ...rest } = queryObj;
      setQueryObj(rest);
      return;
    }
    setQueryObj(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search_value: value
    }));
  };
  const handleChangeAction = (val, id) => {
    setSelectedRow(prev => {
      return {
        ...prev,
        items: prev.items.map(item => {
          return item.product_id === id ? { ...item, action: val } : item;
        })
      };
    });
  };
  const handleChangeFeedback = (val, id) => {
    setSelectedRow(prev => {
      return {
        ...prev,
        items: prev.items.map(item => {
          return item.product_id === id ? { ...item, feedback: val } : item;
        })
      };
    });
  };

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'order_id',
      Header: 'Order',
      headerProps: { className: 'text-center' },
      cellProps: {
        className: 'py-2 text-center'
      }
    },
    {
      accessor: 'date',
      Header: 'Date',
      headerProps: { className: 'pe-1', width: 150 },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: dt => {
        const { date } = dt.row.original;
        return <>{moment(date).format('DD/MM/YYYY HH:mm')}</>;
      }
    },
    {
      accessor: 'customer',
      Header: 'Customer',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: { className: 'text-start' }
    },
    {
      accessor: 'items',
      Header: 'Items',
      headerProps: { className: 'pe-1 text-center', width: 80 },
      disableSortBy: true,
      Cell: rowData => {
        const { items } = rowData.row.original;
        const item_count = items?.reduce((sum, dt) => sum + dt.quantity, 0);
        return (
          items?.length > 0 && (
            <Nav className="text-center d-block">
              <NavDropdown
                title={
                  <div className="d-flex gap-2 align-items-center justify-content-center">
                    <span>
                      {item_count > 1 ? `${item_count} items` : '1 item'}
                    </span>
                    <FaChevronDown size={'10px'} />
                  </div>
                }
              >
                <div
                  style={{
                    padding: '8px 0px 0px 8px',
                    width: 300,
                    maxHeight: 306,
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  {items?.map(dt => (
                    <Row key={dt.product_id} style={{ minHeight: 80 }}>
                      <Col xs={2}>
                        <div style={{ position: 'relative' }}>
                          <div className="circle-number">{dt.quantity}</div>
                          <Image
                            style={{
                              padding: '4px',
                              border: '1px solid #dde0e4',
                              borderRadius: '8px',
                              objectFit: 'contain',
                              maxHeight: 38,
                              maxWidth: 38
                            }}
                            src={dt.product_photo && dt.product_photo.file}
                            width={38}
                            height={38}
                            alt={dt.product_photo.file}
                          />
                        </div>
                      </Col>
                      <Col xs={9} style={{ marginLeft: 10 }}>
                        <a href={`/product/product-update/${dt.product_slug}`}>
                          {dt.product_name}
                        </a>
                        <h6>SKU {dt.sku}</h6>
                      </Col>
                    </Row>
                  ))}
                </div>
              </NavDropdown>
            </Nav>
          )
        );
      }
    },
    {
      accessor: 'reason',
      Header: 'Return Reason',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'py-2 text-center' }
    },
    {
      accessor: 'returnStatus',
      Header: 'Status',
      headerProps: { className: 'pe-1 text-start', width: 50 },
      disableSortBy: true,
      cellProps: { className: 'text-start' },
      Cell: rowData => {
        const { returnStatus } = rowData.row.original;

        switch (returnStatus) {
          case 1:
            return 'New';
          case 2:
            return 'Replaced';
          case 3:
            return 'Refunded';
          case 4:
            return 'Declined';
          default:
            break;
        }
      }
    },
    {
      accessor: 'feedback?.description',
      Header: 'Feedback',
      headerProps: { className: 'pe-1 text-start', width: 50 },
      disableSortBy: true,
      cellProps: { className: 'text-start' }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      headerProps: { className: 'pe-1 text-end' },
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const row = dt.row.original;
        return (
          <IconButton
            variant="default"
            size="sm"
            icon="ellipsis"
            transform="shrink-3"
            className="text-600"
            onClick={() => {
              if (row.isProcessed) {
                toast.error(`This return request is already processed!`, {
                  theme: 'colored'
                });
                return;
              }
              setSelectedRow(row);
              openModal();
            }}
          />
        );
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          pagination
          data={data?.data.results || []}
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Return Request List"
                table
                isBulkAction={false}
              />
              <ReturnTableFilter
                handleSearch={handleSearch}
                handleFilter={handleFilter}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal size="xl" show={isModalOpen} onHide={closeModal} keyboard={false}>
        <Modal.Header>
          <Modal.Title>Send To Operation</Modal.Title>
          <FalconCloseButton onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          {selectedRow?.items?.map((item, i) => {
            return (
              <Row
                key={i}
                className="gx-card mx-0 align-items-center border-bottom border-200"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      src={item.product_photo.file}
                      width="60"
                      alt={'dummy'}
                      className="img-fluid rounded-1 me-3 d-none d-md-block"
                    />
                    <div>
                      <h6>{item.product_name}</h6>
                      <span>Sku: {item.sku}</span> <br />
                      <span>
                        Quantity:{' '}
                        <span className="color-red">{item.quantity}</span>
                      </span>
                    </div>
                  </div>
                  {item?.action === 4 && (
                    <Select
                      styles={selectStyles}
                      options={feedbackOptions}
                      name="feedback"
                      value={item.feedback}
                      className="me-2"
                      onChange={val =>
                        handleChangeFeedback(val, item.product_id)
                      }
                      placeholder="Feedback"
                    />
                  )}
                  <ToggleButtonGroup
                    type="radio"
                    value={item?.action}
                    onChange={val => handleChangeAction(val, item.product_id)}
                    name={`action-${item.product_id}`}
                  >
                    <ToggleButton
                      id={`replace-btn-${item.product_id}`}
                      value={2}
                      size="sm"
                      color={item?.action === 2 ? 'danger' : 'primary'}
                    >
                      Replace
                    </ToggleButton>
                    <ToggleButton
                      id={`refund-btn-${item.product_id}`}
                      value={3}
                      size="sm"
                      color={item?.action === 3 ? 'danger' : 'primary'}
                    >
                      Refund
                    </ToggleButton>
                    <ToggleButton
                      id={`decline-btn-${item.product_id}`}
                      value={4}
                      size="sm"
                      color={item?.action === 4 ? 'danger' : 'primary'}
                    >
                      Decline
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <Col xs={12} className="py-3">
                  {/* <Form.Check
                      type="checkbox"
                      className="d-flex"
                      label=""
                      id={`chk-${i}`}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Check.Input
                        name={`${itemType}-select`}
                        checked={isSelected}
                        onChange={handleSelect}
                        type="checkbox"
                        className="me-2 align-self-center"
                        style={{ minWidth: 16 }}
                      />
                      <Form.Check.Label
                        className="d-flex align-items-center cursor-pointer"
                        style={
                          itemType !== 'product'
                            ? { marginBottom: 0, paddingTop: 5 }
                            : {}
                        }
                      >
                        {itemType === 'product' && files.length > 0 && (
                          <img
                            src={files[0].file}
                            width="60"
                            alt={'dummy'}
                            className="img-fluid rounded-1 me-3 d-none d-md-block"
                          />
                        )}
                        <div className="flex-1">
                          <h5 className="fs-0 mb-0">{name ?? email}</h5>
                        </div>
                      </Form.Check.Label>
                    </Form.Check> */}
                </Col>
              </Row>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        {...confirmText}
      />
    </div>
  );
};

export default ReturnList;
