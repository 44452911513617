import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

const baseStyle = {
  display: 'block'
};
const labelStyle = {
  ...baseStyle,
  fontSize: 13,
  fontWeight: 400
};

const titleStyle = {
  ...baseStyle,
  fontSize: 14,
  fontWeight: 600,
  marginBottom: 5,
  marginTop: 15
};

function ODCustomer({ data }) {
  const RenderAddress = type => {
    if (!data[type]) return null;
    return (
      <>
        <span style={{ ...titleStyle }}>
          {type === 'shipmentAddress' ? 'Shipping' : 'Billing'} address
        </span>
        <span style={{ ...labelStyle }}>
          {data[type].firstName} {data[type].lastName}
        </span>
        <span style={{ ...labelStyle }}>
          {data[type].addrLine1} {data[type].addrLine2}
        </span>
        <span style={{ ...labelStyle }}>{data[type].name}</span>
        <span style={{ ...labelStyle }}>{data[type].postalCode}</span>
        <span style={{ ...labelStyle }}>
          {data[type].district}/{data[type].city}/{data[type].country}
        </span>
        <span style={{ ...labelStyle }}>
          +90 {data[type].phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
        </span>
      </>
    );
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <span style={{ ...titleStyle, marginTop: 0 }}>Customer</span>
        <span style={{ ...labelStyle }}>{data.user_full_name}</span>
        <span style={{ ...labelStyle }}>{data.user_order_count}</span>
        <span style={{ ...titleStyle }}>Contact information</span>
        <span style={{ ...labelStyle }}>{data.user_email}</span>
        {RenderAddress('shipmentAddress')}
        {RenderAddress('invoiceAddress')}
      </Card.Body>
    </Card>
  );
}
ODCustomer.propTypes = {
  data: PropTypes.object
};

export default ODCustomer;
