import React, { useEffect, useReducer, useState } from 'react';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Card } from 'react-bootstrap';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useQuery } from 'react-query';
import { getTransactions } from 'services/warehouse';
import { queryReducer } from 'reducers/queryReducer';
import moment from 'moment';

const TransactionList = () => {
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const { isLoading, data } = useQuery(
    ['transactions', queryObj, sort],
    () =>
      getTransactions({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: true
    }
  );
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);

  const columns = [
    {
      accessor: 'stock__name',
      Header: 'Product Name',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: dt => {
        const { stock } = dt.row.original;
        return stock?.name;
      }
    },
    {
      accessor: 'stock.barcode',
      Header: 'Barcode (SKU)',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true
    },
    {
      accessor: 'transactionType.name',
      Header: 'Stock Action',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true
    },
    {
      accessor: 'account.name',
      Header: 'Customer/Vendor',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      disableSortBy: true
    },
    {
      accessor: 'date',
      Header: 'Date&Time',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { date } = rowData.row.original;
        return moment(date).format('DD/MM/YYYY');
      }
    },
    {
      accessor: 'storageLocation__name',
      Header: 'Warehouse',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: rowData => {
        const { storageLocation } = rowData.row.original;
        return storageLocation?.name;
      }
    },
    {
      accessor: 'quantity',
      Header: 'Quantity',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      }
    }
  ];

  return (
    <LoadingWrapper loading={isLoading}>
      <AdvanceTableWrapper
        columns={columns}
        data={data?.data.results || []}
        sortable
        pagination
        queryPageIndex={pageIndex}
        totalCount={totalCount}
        dispatch={dispatch}
      >
        <Card className="mb-3">
          <Card.Header>
            <TableHeader title="Stock Actions" isBulkAction={false} table />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
              rowClassName="align-middle"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0'
              }}
              sort={sort}
              setSort={setSort}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </LoadingWrapper>
  );
};

export default TransactionList;
