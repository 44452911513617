import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import { MegaMenuStrictDroppable } from '../../modules/MegaMenuStrict';
import AddMenuItem from './AddMenuItem';
import { getMenuMini, updateMenuItem } from 'services/product';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import MenuDraggableItem from './MenuDraggableItem';

function MenuZone({ brands, collections, menuItem, refetch, zone }) {
  const { id } = useParams();
  const [draggableData, setDraggableData] = useState([]);
  const [editItem, setEditItem] = React.useState(null);

  const { data: menuMini, refetch: menuMiniRefetch } = useQuery(
    [`menu-mini-zone-${zone}`],
    () => getMenuMini({ zone, parent__isnull: true, main_menu: id }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (menuItem) {
      setDraggableData(menuItem);
    }
  }, [menuItem]);

  const editParentMenu = item => {
    setEditItem(item);
    setTimeout(() => {
      setEditItem(null);
    }, 1000);
  };
  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };
  const onDragEnd = async result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = [...draggableData];
      const reorderedItems = reorder(items, source.index, destination.index);

      reorderedItems.forEach(async (item, index) => {
        await updateMenuItem(item.id, { order: index + 1 });
      });
      const newList = reorderedItems.map((item, index) => ({
        ...item,
        order: index + 1
      }));

      setDraggableData(newList);
    }
    // Sürükleme işlemi sona erdiğinde yapılacak işlemler
  };

  const props = {
    collections,
    brands
  };
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          <Col xs={12}>
            <MegaMenuStrictDroppable
              droppableId="droppable"
              direction="vertical"
            >
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="border bg-white dark__bg-1000 rounded-2 py-3 mb-3"
                  style={{ minHeight: 250 }}
                >
                  {draggableData.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={`drag-${item.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <MenuDraggableItem
                          provided={provided}
                          snapshot={snapshot}
                          item={item}
                          editParentMenu={editParentMenu}
                          refetch={refetch}
                          menuMiniRefetch={menuMiniRefetch}
                          draggableData={draggableData}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </MegaMenuStrictDroppable>
          </Col>
        </Row>
      </DragDropContext>
      <div className="d-flex justify-content-center">
        <AddMenuItem
          {...props}
          zone={zone}
          refetch={refetch}
          menuMiniRefetch={menuMiniRefetch}
          menuMini={menuMini}
          editItem={editItem}
          draggableData={draggableData}
        />
      </div>
    </div>
  );
}

export default MenuZone;

MenuZone.propTypes = {
  brands: PropTypes.array,
  collections: PropTypes.array,
  zone: PropTypes.number,
  menuItem: PropTypes.array,
  refetch: PropTypes.func
};
