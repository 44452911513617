import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import serviceUrl from '../../../services/serviceUrlList';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import useDelete from 'hooks/useDelete';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext } from 'context/Context';
import { useForm } from 'react-hook-form';
import { updateProductQuestion } from 'services/product';

const ReplyUserQuestion = () => {
  const initialForm = {
    is_replied: true,
    response: ''
  };
  const [formData, setFormData] = useState({
    product: { name: '' },
    created_by: { full_name: '' },
    replied_by: { full_name: '' },
    question: ''
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: initialForm });

  const { response } = id
    ? useFetch({
        url: serviceUrl.product.question.detail + id
      })
    : {};
  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.product.question.detail + id,
    payload: { updated_by: user.id }
  });

  const onSubmit = async data => {
    updateRequest({ ...data, replied_at: new Date() });
  };

  const updateRequest = async payload => {
    const response = await updateProductQuestion(payload, id);
    if (response.status === 200) {
      handleRouteList();
      toast.success(`Question is replied.`, {
        theme: 'colored'
      });
    } else {
      console.log('ERROR:', response.data);
    }
  };

  useEffect(() => {
    setValue('updated_by', user.id);
    setValue('replied_by', user.id);
  }, []);

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Question',
      description: 'Are you sure? Selected question will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    setShowConfirm(false);
    handleRouteList();
    if (deleteResponse.error) {
      toast.error(`Something went wrong!`, {
        theme: 'colored'
      });
      return;
    }
    toast.success(`Question deleted`, {
      theme: 'colored'
    });
  }, [deleteResponse]);

  const handleRouteList = () => {
    navigate('/product/user-questions');
  };

  useEffect(() => {
    if (!response) return;
    const { data } = response;
    setFormData(data);
    setValue('response', data.response);
  }, [response]);

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                Reply Question
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row} className="mb-3" controlId="product">
              <Form.Label as={Col} lg={2} className="d-flex align-items-center">
                Product Name
              </Form.Label>
              <Col lg={10}>
                <Form.Control
                  value={formData?.product?.name}
                  readOnly
                  plaintext
                  placeholder="Product Name"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="userName">
              <Form.Label as={Col} lg={2} className="d-flex align-items-center">
                User Name
              </Form.Label>
              <Col lg={10}>
                <Form.Control
                  value={formData?.created_by?.full_name}
                  readOnly
                  plaintext
                  placeholder="User Name"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="replied_by">
              <Form.Label as={Col} lg={2} className="d-flex align-items-center">
                Replied By
              </Form.Label>
              <Col lg={10}>
                <Form.Control
                  value={formData?.replied_by?.full_name}
                  readOnly
                  plaintext
                  placeholder="Replied By"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="question">
              <Form.Label as={Col} lg={2} className="d-flex align-items-center">
                Question
              </Form.Label>
              <Col lg={10}>
                <Form.Control
                  value={formData?.question}
                  readOnly
                  plaintext
                  placeholder="Question"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="title">
              <Form.Label as={Col} lg={2} className="d-flex align-items-center">
                Response
              </Form.Label>
              <Col lg={10}>
                <Form.Control
                  {...register('response', { required: true })}
                  isInvalid={errors.response}
                  as="textarea"
                  placeholder="Response"
                  maxLength={255}
                />
                <Form.Control.Feedback type="invalid">
                  This field cannot be empty!
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                Reply
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </Fragment>
  );
};

export default ReplyUserQuestion;
