import React, { useState } from 'react';
import DefinitionCard from '../DefinitionCard';
import Currency from './tabs/Currency';
import Metric from './tabs/Metric';

const Stock = () => {
  const [activeTab, setActiveTab] = useState('metric');
  const [newClickCount, setNewClickCount] = useState(0);
  const tabs = [
    {
      name: 'Metric',
      eventKey: 'metric'
    },
    {
      name: 'Currency',
      eventKey: 'currency'
    }
  ];
  const tabChange = tab => {
    setActiveTab(tab);
    setNewClickCount(0);
  };

  const handleNew = () => {
    setNewClickCount(newClickCount + 1);
  };

  return (
    <div>
      <DefinitionCard
        title="Stock"
        tabs={tabs}
        tabChange={tabChange}
        defaultActiveKey={tabs[0].eventKey}
        tabContent={
          activeTab === 'metric' ? (
            <Metric newClickCount={newClickCount} />
          ) : activeTab === 'currency' ? (
            <Currency newClickCount={newClickCount} />
          ) : null
        }
        handleNew={handleNew}
      />
    </div>
  );
};

export default Stock;
