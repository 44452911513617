import { protectedApi } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const updateUser = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.user.user.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateProfile = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.user.profile.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
