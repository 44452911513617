import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const LoadingWrapper = props => {
  const { children, loading } = props;
  return (
    <div style={{ position: 'relative' }}>
      {children}
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: 8,
          width: '100%',
          height: '100%',
          visibility: loading === false ? 'hidden' : 'visible',
          opacity: loading === false ? 0 : 1,
          transition: 'all 0.5s ease-in'
        }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

LoadingWrapper.propTypes = {
  children: PropTypes.element,
  loading: PropTypes.bool
};

export default LoadingWrapper;
