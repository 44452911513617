import React, { useState } from 'react';
import DefinitionCard from '../DefinitionCard';
import Attributes from './tabs/Attributes';
import GitfCardTheme from './tabs/GiftCardTheme';
import Vendor from './tabs/Vendor';
import ServingUnit from './tabs/ServingUnit';
import Tag from './tabs/Tag';
import Persona from './tabs/Persona';
import Goal from './tabs/Goal';

const Definition = () => {
  const [activeTab, setActiveTab] = useState('attributes');
  const [newClickCount, setNewClickCount] = useState(0);
  const tabs = [
    {
      name: 'Attributes',
      eventKey: 'attributes'
    },
    {
      name: 'Gift Card Theme',
      eventKey: 'giftcardtheme'
    },
    {
      name: 'Vendor',
      eventKey: 'vendor'
    },
    {
      name: 'Serving Unit',
      eventKey: 'servingUnit'
    },
    {
      name: 'Tag',
      eventKey: 'tag'
    },
    {
      name: 'Persona',
      eventKey: 'persona'
    },
    {
      name: 'Goal',
      eventKey: 'goal'
    }
  ];
  const tabChange = tab => {
    setActiveTab(tab);
    setNewClickCount(0);
  };

  const handleNew = () => {
    setNewClickCount(newClickCount + 1);
  };

  return (
    <div>
      <DefinitionCard
        title="Product"
        tabs={tabs}
        tabChange={tabChange}
        defaultActiveKey={tabs[0].eventKey}
        tabContent={
          activeTab === 'vendor' ? (
            <Vendor newClickCount={newClickCount} />
          ) : activeTab === 'attributes' ? (
            <Attributes newClickCount={newClickCount} />
          ) : activeTab === 'giftcardtheme' ? (
            <GitfCardTheme newClickCount={newClickCount} />
          ) : activeTab === 'servingUnit' ? (
            <ServingUnit newClickCount={newClickCount} />
          ) : activeTab === 'tag' ? (
            <Tag newClickCount={newClickCount} />
          ) : activeTab === 'persona' ? (
            <Persona newClickCount={newClickCount} />
          ) : activeTab === 'goal' ? (
            <Goal newClickCount={newClickCount} />
          ) : null
        }
        handleNew={handleNew}
      />
    </div>
  );
};

export default Definition;
