import React from 'react';
import PropTypes from 'prop-types';
import visa from 'assets/img/icons/visa.png';
import mastercard from 'assets/img/logos/mastercard.svg';
import troy from 'assets/img/logos/troy.png';

function CustomerPaymentMethods({ data }) {
  if (!data) return null;
  return (
    <div>
      <h5>Payment Methods</h5>
      <div className="row">
        {data.customer_payment_methods ? (
          data.customer_payment_methods?.map(dt => (
            <div key={dt.id} className="col-6">
              <div className="d-flex align-items-center gap-3">
                <img
                  src={
                    dt.cardAssociation === 'VISA'
                      ? visa
                      : dt.cardAssociation === 'MASTER_CARD'
                      ? mastercard
                      : troy
                  }
                  width={50}
                  alt="payment method"
                />
                <div>
                  <span className="d-block">{dt.cardBankName}</span>
                  <span className="d-block">
                    **** **** **** {dt.lastFourDigits}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>Customer has not a payment method</div>
        )}
      </div>
    </div>
  );
}

export default CustomerPaymentMethods;

CustomerPaymentMethods.propTypes = {
  data: PropTypes.object
};
