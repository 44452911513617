import { AuthContext } from 'context/Context';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getApiBase } from 'services/config';
import serviceUrl from 'services/serviceUrlList';

const LoginForm = ({ hasLabel }) => {
  // State
  const { setUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const navigate = useNavigate();

  const [postResponse, postRequest] = useAuthentication({
    url: serviceUrl.authentication.login,
    payload: formData
  });

  useEffect(() => {
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.error === null) {
      // route to dashboard
      const BASE_URL = getApiBase() + 'auth/media/';
      const userData = {
        access_token: postResponse.data.access_token,
        refresh_token: postResponse.data.refresh_token,
        id: postResponse.data.user?.id,
        expire: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
        photo: postResponse.data.user.photo
          ? BASE_URL + postResponse.data.user.photo
          : undefined
      };
      window.localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
      if (postResponse.data.user.last_login) {
        navigate('/');
      } else {
        navigate('/user/profile');
      }
    } else {
      toast.error(`Provided credentials are not correct!`, {
        theme: 'colored'
      });
    }
  }, [postResponse]);

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    postRequest();
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
