import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import { currencyFormat } from 'helpers/utils';

const ProductListItem = ({
  handleSelect,
  file,
  name,
  stock_quantity,
  price,
  isSelected,
  slug
}) => {
  return (
    <Row className="gx-card mx-0 align-items-center border-bottom border-200">
      <Col xs={7} className="py-3">
        <div>
          <Form.Check
            type="checkbox"
            label=""
            className="d-flex"
            id={`prd-${slug}`}
          >
            <Form.Check.Input
              name="product-select"
              checked={isSelected}
              onChange={handleSelect}
              type="checkbox"
              className="me-2 align-self-center"
              style={{ minWidth: 16 }}
            />
            <Form.Check.Label className="d-flex align-items-center cursor-pointer">
              <img
                src={file}
                width="60"
                alt={'dummy'}
                className="img-fluid rounded-1 me-3 d-none d-md-block"
              />
              <div className="flex-1">
                <h5 className="fs-0">{name}</h5>
              </div>
            </Form.Check.Label>
          </Form.Check>
        </div>
      </Col>
      <Col xs={5} className="py-3">
        <Row className="align-items-center">
          <Col
            md={{ span: 8, order: 0 }}
            xs={{ order: 1 }}
            className="d-flex justify-content-end justify-content-md-center"
          >
            {stock_quantity} Available
          </Col>
          <Col
            md={{ span: 4, order: 1 }}
            xs={{ order: 0 }}
            className="text-end ps-0 mb-2 mb-md-0 text-600"
          >
            {currencyFormat(price.total_price)} ₺
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ProductListItem.propTypes = {
  handleSelect: PropTypes.func,
  file: PropTypes.string,
  name: PropTypes.string,
  stock_quantity: PropTypes.number,
  price: PropTypes.object,
  isSelected: PropTypes.bool,
  slug: PropTypes.string
};

export default ProductListItem;
