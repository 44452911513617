import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PhoneInput from 'components/input/PhoneInput';

const CardAssignment = props => {
  const { formData, setFormData, errors, register } = props;

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Assign to a customer
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={4}>
            <Form.Group className="mb-3" controlId="customer_name">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Customer e-Mail</Form.Label>
              <Form.Control
                {...register('email', {
                  pattern: {
                    value: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
                    message: 'Entered value does not match email format'
                  }
                })}
                isInvalid={errors?.email}
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Customer Phone</Form.Label>
              <PhoneInput
                name="phone"
                onChange={handleChange}
                value={formData.phone}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};
CardAssignment.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  emailRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};
export default CardAssignment;
